import { login } from "../api/index";

const initState = {
	number: "1",
	info: "",
	userName: "",
	firstLogin: "",
	lastUpdateTime: "",
	nsrsbh: "",
	isLogin: false,
};

export default (state = initState, action) => {
	// 根据不同的action.type, 做不同的处理， 每次返回一个新的state, 返回的类型要一样。
	switch (action.type) {
		case "LOGIN":
			return login(action.param).then((res) => {
				return res;
			});
		case "info":
			return { ...state, info: action.text };
		case "firstLogin":
			return { ...state, firstLogin: action.text };
		case "lastUpdateTime":
			return { ...state, lastUpdateTime: action.text };
		case "userName":
			return { ...state, userName: action.text };
		case "nsrsbh":
			return { ...state, nsrsbh: action.text };
		case "clearWeb":
			return { ...initState };
		case "isLogin":
			return { ...state, isLogin: action.text };
		// 一定要有default, 当actionType不对的时候，就不做任何处理，返回上一次的state
		default:
			return state;
	}
};

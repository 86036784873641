import React, { Component } from "react"
import './printInvoiceQRcode.less'
import { Button, message, Input, Select, Spin,Modal} from 'antd';
import { queryGoodsNoPageByUser, createQrCode,queryEnterpriseInfoByUser,updateValidity,emailQrCode,queryTaxPlateByPage } from '../../../api'
import utils from "../../../utils"
import { DeleteFilled, PlusSquareFilled } from '@ant-design/icons'

const { Option } = Select;



export default class printInvoiceQRcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            splist_form: [{
                id: '',
                name: '',
                money: '0.00',
                // splist: []
            }],
            selectList: [],
            param:{
                id:"",
                validity:"",
            },
            faPiao: [],
            loading:false,
            email:"",
            showModal:false,
            saleInfo: {
                bz: '',
                companyName: '',
                address: '',
                registeredNo: '',
                bankInfo: '',
                reviewer: '',
                payee: '',
                biller:""
            },
            invoiceType: null
        }
    }

    

    //初始化数据
    componentWillMount = () => {
        queryGoodsNoPageByUser().then(res => {
            if (res.code === "0000") {
                this.setState({ selectList: res.resultList })
            }
        })
        queryEnterpriseInfoByUser().then(res=>{
            if (res.code === "0000") {
                let {validity,id} = res.resultObj
                this.setState({
                    param: {validity,id}
                })
                const { address, contactPhone, registeredNo, companyName, bankName, bankAccount, biller, reviewer, payee } = res.resultObj
                const needSetData = {
                    address: address + '-' + contactPhone,
                    registeredNo,
                    companyName,
                    bankInfo: bankName + bankAccount,
                    reviewer,
                    payee,
                    biller
                }
                this.setState({ saleInfo: {...this.state.saleInfo, ...needSetData } },()=>{
                    this.getInvoice()
                })
            }
        })

    }

    //显示下拉框
    moreOption = () => {
        let options = [] //[<Option value="0" key="all_key">全部</Option>];
        this.state.selectList.map((item, index) => {
            options.push(<Option value={item.id} key={index}>{item.spmc}</Option>)
        })
        return options;
    }
    // 输入金额
    resetJE = (index, e) => {
        this.state.splist_form[index].money = e.target.value
        this.setState({})
    }

    // 选中动作
    handleChange = (index, id) => {
        let selectList = this.state.selectList

        let list = selectList.filter(item => {
            if (item.id == id) {
                return item
            }
        })
        let name = list[0].spmc
        this.state.splist_form[index].name = name
        this.state.splist_form[index].id = id
        this.setState({})
    }

    handleInvoiceChange = (invoiceType) => {
        this.setState({ invoiceType })
    }



    // 初始化渲染
    forList = () => {
        let options = [] //[<Option value="0" key="all_key">全部</Option>];
        this.state.splist_form.map((item, index) => {
            options.push(<div value={item.id} key={index}>
                <div className="print-form-row">
                    <label htmlFor={"ipid" + index}><span>*</span>开票金额：</label> <Input id={"ipid" + index} autoComplete="off" className='ipid' ref={'focus' + index} onChange={this.resetJE.bind(this, index)} value={item.money} onFocus={() => {
                        this.refs['focus' + index].select()
                    }} />
                </div>
                <div className="print-form-row">
                    <label htmlFor={"slid" + index}><span>*</span>商品名称：</label><Select id={"slid" + index} value={item.name == '' ? '请选择商品' : item.name} style={{ width: 200 }} onChange={this.handleChange.bind(this, index)}>
                        {this.moreOption()}
                    </Select>
                </div>
                <div className="print-form-row keep">
                    <Button type="link" onClick={this.add_print_form}><PlusSquareFilled />添加一行</Button>
                    <Button type="link" className="bClor" onClick={this.remove_print_form.bind(this, index)}><DeleteFilled />删除本行</Button>
                </div>
            </div>)
        })
        return options;
    }
    //添加一行
    add_print_form = () => {
        this.state.splist_form.push({
            id: '',
            name: '',
            money: '0.00',
        })
        this.setState({})
    }
    //删除一行  index 下标
    remove_print_form = (index) => {
        if (this.state.splist_form.length > 1) {
            let list = this.state.splist_form
            list.splice(index, 1)
            this.setState({
                splist_form: [...list]
            })
            // this.forList()  视图有直接调用，数据更改自动调用
        }
        else {
            message.warning('至少保留一条！')
            return false
        }

    }

    //验证数据
    verification = () => {
        return this.state.splist_form.every((item, index) => {
            if (!(item.money > 0)) {
                message.warning(`请填写第${index + 1}条的金额，最小金额为0.01`)
            } else if (item.name===''){
                message.warning(`请选择第${index + 1}条的商品，此选项必须选择`)
            }
            return item.money > 0 && item.name!==''
        })
    }

    // 发送打印二维码请求
    printQR = () => {
        if (!this.verification()) {
            return false
        }
        let money = 0
        let list = this.state.splist_form.map(item => {
            let obj = {}
            obj.goodCodeId = item.id
            obj.amount = item.money
            obj.invoiceType = this.state.invoiceType
            money += Number(item.money)
            return obj
        })
        let urlLocation = window.location.href
        let url = ''
        if (urlLocation.includes(process.env.REACT_APP_SEARCH)) {
            url = process.env.REACT_APP_SEARCH+'/#/print/printInfo'
        } else {
            url = process.env.REACT_APP_SEARCH_NO+'/#/print/printInfo'
        }
        // 发请求
        createQrCode(list).then(res => {
            if (res.code === '0000') {
                localStorage.setItem("info", JSON.stringify(money))
                localStorage.setItem('orderUrl', res.resultObj)
                let win = window.open(url, '_blank');
                win.focus();
            }
        })
    }
    // 发送打印二维码请求
    sendQR=()=>{
        let email = this.state.email
        let regEmail = utils.regEmail(email)
        if(!regEmail)return message.error("请填写正确的邮箱！")
        let voValidList = this.state.splist_form.map(item => {
            let obj = {}
            obj.goodCodeId = item.id
            obj.amount = item.money
            obj.invoiceType = this.state.invoiceType
            return obj
        })
        this.setState({loading:true})
        emailQrCode({email,voValidList}).then(res=>{
            this.setState({loading:false})
            if(res.code==="0000"){
                message.success(res.msg)
                this.setState({showModal:false})
            }else{
                message.error(res.msg)
            }
        })
    }

    changeVal=(validity)=>{
        this.setState({param:{...this.state.param,validity}})
    }
    updateVal=()=>{
        let param = this.state.param
        if(!param.validity)return message.error("请填写正确的天数！")
        this.setState({loading:true})
        updateValidity(param).then(res=>{
            if(res.code==="0000"){
                message.success(res.msg)
            }else{
                message.error(res.msg)
            }
            this.setState({loading:false})
        })
    }

    openModal=()=>{
        if (!this.verification()) {
            return false
        }
        this.setState({showModal:true})
    }

    changeEmail=(e)=>{
        this.setState({email:e.target.value})
    }

    getInvoice=()=>{
        let nsrsbh = this.state.saleInfo.registeredNo
        let param ={
            nsrmc: "",
            nsrsbh,
            page: 1,
            plateNo: "",
            rows: 10,
        }
        let faPiaos = [
            { id: '004', name: '增值税专用发票' },
            { id: '007', name: '增值税普通发票' },
            { id: '025', name: '卷式发票' },
            { id: '026', name: '增值税电子发票' },
            { id: '028', name: '增值税电子专用发票' },
            { id: '81', name: '全电专用发票' },
            { id: '82', name: '全电普通发票' }
        ]
        queryTaxPlateByPage(param).then(res=>{
            if(res.code==="0000"){
                
                if(Array.isArray(res.resultList)&&res.resultList.length>0){
                    res.resultList.forEach(e=>{
                        let faPiaoList = e.fplxdm.split(',').filter(e=>{
                            if(e)return e
                        })
                        let faPiao = faPiaos.filter(e=>{
                            if(faPiaoList.includes(e.id))return e
                        })
                        this.setState({faPiao},()=>{
                            this.setState({invoiceType:faPiao[0].id})
                            // if(faPiaoList.includes('026')){
                            //     this.setState({invoiceType:"026"})
                            // }else{
                            //     this.setState({invoiceType:"请选择发票类型"})
                            // }
                        })
                    })
                }else{
                    this.setState({ faPiao:[],invoiceType: '请选择发票类型',})
                    console.log('出错了')
                }
            }else{
                this.setState({ faPiao:[],invoiceType: '请选择发票类型',})
                console.log('出错了')
            }
        })
    }

    render() {
        return (
            <Spin spinning={this.state.loading} size="large">
            <div className="container-fluid">
                <div className='shixiaoBox'>
                    <label htmlFor="sxInput" >二维码时效性：</label>
                    <Input type="number" min={0} id='sxInput' value={this.state.param.validity} onChange={(e)=>this.changeVal(e.target.value)} addonAfter="天"/>
                    <Button type="primary" onClick={this.updateVal} >修改</Button>
                </div>
                <div className="print-form">
                  <div value>
                        <div className="print-form-row">
                                <label htmlFor={"fplx"}><span>*</span>发票类型：</label>
                                <Select id="fplxSelect" value={this.state.invoiceType} style={{ width: "160px", marginBottom: 0 }} onChange={this.handleInvoiceChange}>
                                    {  
                                        this.state.faPiao.map((item,index) => {
                                            return <Option value={item.id} key={index}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                        </div>
                 </div>
                    {this.forList()}
                </div>
                <Button type="primary" className='marginRight' onClick={this.printQR}>打印发票二维码</Button>
                <Button type="primary" onClick={this.openModal}>发送发票二维码</Button>
            </div>
            {
                    this.state.showModal ? (<Modal
                        title="邮箱地址"
                        visible={this.state.showModal}
                        onOk={this.sendQR}
                        onCancel={() => {
                            this.setState({
                                showModal: false,
                                // email:""
                            })
                        }}
                    >
                        <Input placeholder="请输入您的邮箱" allowClear value={this.state.email} onChange={this.changeEmail} />
                    </Modal>) : null
                }
            </Spin>
        )
    }
}
import React, { Component } from "react"
import { Button, Divider, Table, Modal, Form, Input, message, Spin, Icon } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
import Highlighter from 'react-highlight-words';
import { invoiceBuySync, queryTaxPlateByNsrsbh, invoiceBuy } from '../../../api'
import './ticketsOnline.less'
const FormItem = Form.Item;

const addData = [
	// {
	// 	type: 'SELECT',
	// 	label: '盘组设备名称',
	// 	field: 'userName',
	// 	placeholder: '请选择盘组设备名称',
	// 	width: "100%",
	// 	initialValue:'0',
	// 	list: [
	//         {id: '0', name: '集中托管开票员'},
	//         {id: '1', name: '企业开票员'}
	//     ]
	// },
	{
		type: 'INPUT',
		label: '税控盘编号',
		field: 'roleUuid',
		width: "100%",
		initialValue: '',
		placeholder: '请输入税控盘编号',
	},
	{
		type: 'INPUT',
		label: '纳税人识别号',
		field: 'userPhone',
		placeholder: '请输入纳税人识别号',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '纳税人名称',
		field: 'idCard',
		placeholder: '请输入纳税人名称',
		width: "100%",
		initialValue: '',
	}
]

export default class TicketsOnline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableList: [],
			tableList2: [],
			//修改回显数据
			updateData: [],
			isRoleVisible: false,
			flag: false,
			deviceNo: '',
			selectFpInfo: {
				kpzdbs: '',
				fplxdm: '',
				fpdm: '',
				qshm: '',
				zzhm: '',
				fpfs: ''
			},
			loading: false
		}
	}

	add = () => {
		this.setState({ loading: true })
		queryTaxPlateByNsrsbh().then(res => {
			const { resultObj: tableList2 } = res
			tableList2.forEach((item, index) => {
				item.key = index + 1
			})
			if (tableList2 && tableList2.length !== 0) return this.setState({ tableList2, isRoleVisible: true, flag: false })
			message.error('获取税盘列表失败')
			this.setState({ loading: false })
		})
	}

	//刷新发票段信息
	refreshAll() {
		this.getData()
	}

	handleRoleSubmit = () => { }
	addUserDataBack = (e) => { }


	getData() {
		const param = {
			kpzdbs: this.state.deviceNo
		}
		this.setState({ loading: true })
		invoiceBuySync(param).then(res => {
			if (res.code === '0000') {
				try {
					const { group: tableList } = res.resultObj.body.returndata.fpxl
					Array.isArray(tableList)&&tableList.forEach((e,i)=>{
						e.key = i+1
					})
					this.setState({ tableList })
				} catch {
					// message.info('系统繁忙')
					this.setState({ tableList: [] })
				}
			} else {
				message.error(res.msg)
			}
			this.setState({ loading: false })
		})
	}



	filterFpStatus(val) {
		switch (val) {
			case '0':
				return '待分发'
			case '1':
				return '分发中'
			case '2':
				return '已分发'
		}
	}

	filterFpType(val) {
		switch (val) {
			case '004':
				return '增值税专用发票'
			case '007':
				return '增值税普通发票'
			case "025":
				return "卷式发票"
			case '026':
				return '增值税电子发票'
			case '028':
				return '电子专用发票'
		}
	}

	getFpfs(e) {
		const val = (e.target.value === '' ? 1 : e.target.value).toString()
		const zzhm = (parseInt(this.state.selectFpInfo.qshm) + parseInt(val) - 1).toString()
		this.setState({
			selectFpInfo: { ...this.state.selectFpInfo, fpfs: val, zzhm }
		})
	}

	toInvoiceBuy = (info) => {
		// const info = this.state.selectFpInfo
		if (!info.fpdm || !info.fpfs || !info.fplxdm || !info.qshm || !info.zzhm) {
			return message.warning('信息不完整！')
		}

		const param = [{
			...info,
			kpzdbs: this.state.deviceNo
		}]
		this.setState({ loading: true })
		invoiceBuy(param).then(res => {
			if (res.code === '0000') {
				message.success('领购成功')
				this.getData()
			} else {
				message.error(res.msg)
			}
			this.setState({ loading: false })
		})
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`查询 纳税人名称`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					查询
			</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					重置
			</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type="search" style={{ color: filtered ? '#1890ff' : "#000" }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
					text
				),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	render() {
		const self = this
		const columns = [
			{
				title: '发票类型',
				dataIndex: 'fplxdm',
				render: (text, record, index) => (<span>{this.filterFpType(record.fplxdm)}</span>)
			},
			{
				title: '发票代码',
				dataIndex: 'fpdm',
				key: 'fpdm',
			},
			{
				title: '起始号码',
				dataIndex: 'qshm',
				key: 'qshm',
			},
			{
				title: '终止号码',
				dataIndex: 'zzhm',
				key: 'zzhm',
			},
			{
				title: '发票份数',
				dataIndex: 'fpfs',
				key: 'fpfs',
			},
			{
				title: '状态',
				render: (text, record, index) => (<span>{self.filterFpStatus(record.gpzt)}</span>)
			},
			{
				title: '操作',
				render: (text, record, index) => (<Button type="primary" onClick={() => this.toInvoiceBuy(record)} >领购</Button>)
			},

		];
		const columns2 = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
				...this.getColumnSearchProps('nsrmc'),
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},

		];
		const formList = [
			{
				type: 'INPUT',
				label: '发票类型代码',
				field: 'fileName',
				placeholder: '请输入发票类型代码',
				width: "100%",
			},
			{
				type: 'INPUT',
				label: '发票代码',
				field: 'fileName',
				placeholder: '请输入发票代码',
				width: "100%",
			},
			{
				type: 'INPUT',
				label: '发票份数',
				field: 'fileName',
				placeholder: '请输入发票份数',
				width: "100%",
			},
			{
				type: 'INPUT',
				label: '终止号码',
				field: 'fileName',
				placeholder: '请输入终止号码',
				width: "100%",
			},
		]

		const tabelRowEvent = record => {
			return {
				onDoubleClick: e => {
					this.setState({ deviceNo: record.jqbh, isRoleVisible: false }, () => {
						this.getData()
					})
				}
			}
		}

		const outsideTableRowEvent = record => {
			return {
				onDoubleClick: e => {
					return 
					let changeState = {}
					Object.keys(this.state.selectFpInfo).forEach(key => {
						changeState[key] = record[key]
					})
					changeState.kpzdbs = this.state.deviceNo
					this.setState({ selectFpInfo: record })
				}
			}
		}

		const { selectFpInfo } = this.state

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div>
					<h2 style={{ color: "#666", fontWeight: 700, letterSpacing: "1px", }} >{sessionStorage.getItem("pageTitle") || "发票分发"}</h2>
				</div>
				<Divider />
				<div style={{ padding: "10px 0", display: 'flex', alignItems: 'center' }}>
					<div>
						<span>税控盘编号：</span>
						<Button onDoubleClick={() => this.add()}>{this.state.deviceNo === '' ? '双击选择税控盘' : this.state.deviceNo}</Button>
					</div>
					<Button type="link" style={{ color: '#333333' }} onClick={this.refreshAll.bind(this)} ><img src={require('../../../assets/icon/update.png')} style={{ marginRight: '10px' }} />刷新发票段信息</Button>
				</div>
				{/* <div style={{ background: '#C7E2FF', padding: '6px 12px' }}>
					<h3>领购服务</h3>
					<Form layout="inline">
						<FormItem label='发票类型代码'>
							<Input type="text" placeholder='请输入发票类型代码' disabled value={this.filterFpType(selectFpInfo.fplxdm)} />
						</FormItem>
						<FormItem label='发票代码'>
							<Input type="text" placeholder='请输入发票代码' disabled value={selectFpInfo.fpdm} />
						</FormItem>
						<FormItem label='起始号码'>
							<Input type="text" placeholder='请输入起始号码' disabled value={selectFpInfo.qshm} />
						</FormItem>
						<FormItem label='终止号码'>
							<Input type="text" placeholder='请输入终止号码' disabled value={selectFpInfo.zzhm} />
						</FormItem>
						<FormItem label='发票份数'>
							<Input type="number" placeholder='请输入发票份数' onChange={this.getFpfs.bind(this)} maxLength={Number(selectFpInfo.fpfs)} minLength={1} value={selectFpInfo.fpfs} />
						</FormItem>
						<FormItem>
							<Button type="primary" onClick={this.toInvoiceBuy.bind(this)}>领购</Button>
						</FormItem>
					</Form>
				</div> */}
				<h3 style={{ background: '#C7E2FF', textAlign: 'center',padding:"10px 0",marginTop:10 }}>局端发票段信息</h3>

				<div>
					<Table columns={columns} dataSource={this.state.tableList} onRow={outsideTableRowEvent} className='cant-select' />
				</div>
				<Modal
					width={820}
					title="双击选择税控盘"
					visible={this.state.isRoleVisible}
					onOk={this.handleRoleSubmit}
					onCancel={() => {
						this.setState({
							isRoleVisible: false
						})
					}}
				>
					<div style={{ background: '#C7E2FF' }}>
						{/* <ModalForm formList={this.state.flag ? this.state.updateData : addData} modalData={this.addUserDataBack.bind(this)} /> */}
					</div>
					{/* <p style={{ lineHeight: '32px', marginBottom: '0' }}>双击选择税控盘</p> */}
					<Table columns={columns2} dataSource={this.state.tableList2} onRow={tabelRowEvent} className='cant-select' />
				</Modal>
			</Spin>
		)
	}
}
import React, { Component } from "react";
import Footer2 from "../../../components/webfooter2/webFooter2";

import "./coordination.less";

// import imgBan1 from "../../../assets/img/coordination/banner1.png";
import banner from '../../../assets/img/coordination/banner.png'
import b1 from '../../../assets/img/coordination/1.png'
import b2 from '../../../assets/img/coordination/2.png'
import b3 from '../../../assets/img/coordination/3.png'
import b4 from '../../../assets/img/coordination/4.png'
import b5 from '../../../assets/img/coordination/5.png'

import j1 from '../../../assets/img/coordination/j1.png'
import j2 from '../../../assets/img/coordination/j2.png'
import j3 from '../../../assets/img/coordination/j3.png'
import j4 from '../../../assets/img/coordination/j4.png'
import j5 from '../../../assets/img/coordination/j5.png'

import h1 from '../../../assets/img/coordination/h1.png'
import h2 from '../../../assets/img/coordination/h2.png'
import h3 from '../../../assets/img/coordination/h3.png'
import h4 from '../../../assets/img/coordination/h4.png'



export default class Coordination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: {
                title: "助力企业开启供应链协同新体验！",
                phone: '400-9958-578'
            },
        };
    }


    render() {
        // const bgImg1 = {
        // 	backgroundRepeat: "no-repeat",
        // 	backgroundImage: "url(" + bg1 + ")", //图片的路径
        // };

        return (
            <div className="coordination_content">
                <section className="coordination_banner">
                    <img src={banner} alt="" />
                </section>
                <section className="coordination_box2">
                    <h3>产品介绍</h3>
                    <div className="coo_box">
                        <div>
                            <div>
                                供应链协同平台是将企业和供应商之间从订单到结算业务流程全线打通，通过平台上达成协议解决企业与供应商在物流、信息流、合同流、发票流、资金流上信息不对称的行业问题，实现从发票到业务，业务到发票统一平台操作，从而帮助企业双方规避成本，降低风险。
                            </div>
                        </div>
                        <div className="coo_box_1">
                            <ul>
                                <li>
                                    <img src={b1} alt="" />
                                    <span>物流</span>
                                </li>
                                <li>
                                    <img src={b3} alt="" />
                                    <span>合同流</span>
                                </li>
                                <li>
                                    <img src={b5} alt="" />
                                    <span>资金流</span>
                                </li>
                                <li>
                                    <img src={b2} alt="" />
                                    <span>信息流</span>
                                </li>
                                <li>
                                    <img src={b4} alt="" />
                                    <span>发票流</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="coordination_box2  coordination_box3">
                    <h3>产品亮点</h3>
                    <ul>
                        <li>
                            <div>结算确认单</div>
                            <div><img src={j5} alt="" /></div>
                            <div>购销双方结算单的协同和确认</div>
                        </li>
                        <li>
                            <div>电子化开票</div>
                            <div><img src={j4} alt="" /></div>
                            <div>按照定义好发票模板、自动填开、智能赋码等开具电子发票</div>
                        </li>
                        <li>
                            <div>结算确认单</div>
                            <div><img src={j2} alt="" /></div>
                            <div>线上开票模式随时随地在线预览、下载、推送电子发票</div>
                        </li>
                        <li>
                            <div>采集认证</div>
                            <div><img src={j3} alt="" /></div>
                            <div>及时准确从总局获取全票面信息，集中采集认证发票</div>
                        </li>
                        <li>
                            <div>三单匹配</div>
                            <div><img src={j1} alt="" /></div>
                            <div>结算单与结算数据、发票与结算单、电票与纸票相互匹配</div>
                        </li>
                    </ul>
                </section>
                <section className="coordination_box2  coordination_box4">
                    <h3>行业痛点</h3>
                    <ul>
                        <li>
                            <img src={h1} alt="" />
                            <span>线上开票</span>
                            <div>
                                全票种线上开票流程，颠覆以往面对面开票模式，实现自主开票，节约人力成本
                            </div>
                        </li>
                        <li>
                            <img src={h2} alt="" />
                            <span>发票管理</span>
                            <div>
                                通过线上全流程自动化，减少开票误开、多开、少开问题，规避税务风险
                            </div>
                        </li>
                        <li>
                            <img src={h3} alt="" />
                            <span>风险监控</span>
                            <div>
                                最大程度减少违规业务的发生，降低税务风险
                            </div>
                        </li>
                        <li>
                            <img src={h4} alt="" />
                            <span>提高效率</span>
                            <div>
                                企业：提高对账效率、开票效率、发票认证准确率等
                                供应商：提高物流准确率、提高对账效率、降低发票退票等
                            </div>
                        </li>

                    </ul>
                </section>


                <Footer2 title={this.state.footer} />
            </div>
        );
    }
}

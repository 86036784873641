import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./hotel.less";

import imgBan from "../../../../assets/img/solution/banner8.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/16.png";
import i2 from "../../../../assets/img/solution/43.png";
import i3 from "../../../../assets/img/solution/20.png";
import i4 from "../../../../assets/img/solution/18.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";
import i7 from "../../../../assets/img/solution/13.png";

import i21 from "../../../../assets/img/solution/21.png";
import i22 from "../../../../assets/img/solution/1.png";
import i23 from "../../../../assets/img/solution/41.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";




export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer: {
				title: "我想要咨询此行业",
				phone: '400-9958-578'
			},
			active: [true, false, false, false]

		};
	}

	clickActive = (key) => {
		let list = this.state.active
		for (let i in list) {
			if (i == key) {
				list[i] = true
			} else {
				list[i] = false
			}
		}
		this.setState({ active: [...list] })
		let str = 'active' + key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};



		return (
			<div className="solution-content solution-content-8">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0] ? 'active' : ''} onClick={this.clickActive.bind(this, 0)}>行业趋势</li>
						<li className={this.state.active[1] ? 'active' : ''} onClick={this.clickActive.bind(this, 1)}>行业痛点</li>
						<li className={this.state.active[2] ? 'active' : ''} onClick={this.clickActive.bind(this, 2)}>解决方案</li>
						<li className={this.state.active[3] ? 'active' : ''} onClick={this.clickActive.bind(this, 3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>追求服务</span>
							<span>酒店整体服务逐步从简单的休息之地成为商旅、休假、工作的重要组成部分</span>
						</li>
						<li style={bgImg2}>
							<span>优化行业管理</span>
							<span>在互联网+的背景下，积极探索如何运用云计算、大数据等应用于酒店行业链条中 </span>
						</li>
						<li style={bgImg3}>
							<span>连锁管理</span>
							<span>酒店连锁化发展，需要建立票据体系化标准，实现数据化管理</span>
						</li>

					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>服务质量低</span>
							<span>开票高峰期，客户等待开票时间长，客户体验差，严重影响服务质量</span>
						</li>
						<li>
							<span>报销难</span>
							<span>报销难，外地出差，发票需邮寄回公司</span>
						</li>
						<li>
							<span>成本高</span>
							<span>发票领用、存放、打印、邮递、遗失成本高</span>
						</li>
						<li>
							<span>管理难</span>
							<span>对于连锁酒店，总部无法直接监管各分店开票情况，不利于管理</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户通过沿店app或公号定酒店时，选挥开票里为电子发票，井填写相关开票信息后提交订单，等到店时，可直接进入酒店app或信公众号自助获取电子发</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>线上下单</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i2} />
								<span>线下结账</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<div className="ul_div">
							<li>
								<img alt="" src={i3} />
								<span>酒店订单系统</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i7} />
								<span>扫描二维码录入</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<li>
							<img alt="" src={i4} />
							<span>接口服务</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i6} />
							<span>发送发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>方便管理</span>
								<img alt="" src={i21} />
							</div>
							<p>满足酒店行业开票需求，实时掌握各店开票情况和发票库存情况</p>
						</li>
						<li>
							<div>
								<span>降低经营成本</span>
								<img alt="" src={i22} />
							</div>
							<p>帮助企业节约经营成本，提升用户体验，省大量不必要的人力、物力支出</p>
						</li>
						<li>
							<div>
								<span>提升用户体验</span>
								<img alt="" src={i23} />
							</div>
							<p>消费者自助开票、省去排队打票环节、提高用户体验和用户粘度</p>
						</li>

					</ul>
				</section>



				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

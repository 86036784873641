import React, { Component } from "react"
import { Button} from 'antd';

const MIME_TYPE='image/png'
export default class Sign extends Component {

	constructor(props) {
		super(props);

		this.canvas=''
		this.canvasBlack=''
		this.text='XXX专用印章'
		this.company='北京中税深圳信息信息有限公司'
		
	}
    componentWillMount(){
	
	}
	componentDidMount(){
         this.cricle()
	}
  
    componentWillUpdate(){
		
	}
   click=()=>{

	    const canvas=document.getElementById("canvas")
		canvas.setAttribute("width","200")
		canvas.setAttribute("height","200")
		const canvasBlack=canvas.getContext("2d")
		canvasBlack.clearRect(0, 0,canvas.width,canvas.height);
		this.company="北京中税深圳信息信息有限公司"
		this.cricle()
   }
   //绘制圆形印章
   cricle=()=>{
	   //初始化
		const canvas=document.getElementById("canvas")
	    const canvasBlack=document.getElementById("canvas").getContext("2d")
		//绘制印章边框
		var width=canvas.width/2
		var height=canvas.height/2
		canvasBlack.lineWidth=5
		canvasBlack.strokeStyle="#f00"
		canvasBlack.beginPath()
		canvasBlack.arc(width,height,90,0,Math.PI*2)
		canvasBlack.stroke()
		//绘制五角星
		this.create5Star(canvasBlack,"red",width,height,0,25)
		//绘制印章名称
		canvasBlack.font = '18px 宋体';
		canvasBlack.textBaseline = 'middle';//设置文本的垂直对齐方式
		canvasBlack.textAlign = 'center'; //设置文本的水平对对齐方式	
		canvasBlack.lineWidth=1;	
		canvasBlack.strokeStyle = '#f00';	
		canvasBlack.strokeText(this.text,width,height+55);
		//绘制印章单位
		canvasBlack.translate(width,height)
		canvasBlack.font='23px 宋体'
		var count=this.company.length
		var angle= 4*Math.PI/(3*(count - 1));// 字间角度 
		var chars=this.company.split("")
		var c=''
		for(var i=0;i<count;i++){
			c=chars[i];
			if(i==0){
				canvasBlack.rotate(5 * Math.PI / 6)
			}else{
				canvasBlack.rotate(angle)
			}
			canvasBlack.save()
			canvasBlack.translate(70, 0);// 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
			canvasBlack.rotate(Math.PI / 2);// 旋转90度,让字平行于x轴
			canvasBlack.strokeText(c, 0, 0);// 此点为字的中心点
			canvasBlack.restore();
		}
		// //绘制编号
		// canvasBlack.translate(0,0)
		// canvasBlack.font='13px 宋体'
		// // canvasBlack.rotate(-30*Math.PI/180);
		// // canvasBlack.strokeText("145265", 0, 0+70);
		// var str="145566666"
		// var number=str.split("")
		// var charsNum
		// for(var i=number.length-1;i>=0;i--){
		// 	charsNum=number[i];
		// 	if(i==0){
		// 		canvasBlack.rotate(-90*Math.PI/180)
		// 	}else{
		// 		canvasBlack.rotate(-8*Math.PI/180)
		// 	}
		// 	canvasBlack.save()
		// 	canvasBlack.translate(90, 0);// 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
		// 	canvasBlack.rotate(-90*Math.PI/180);// 旋转90度,让字平行于x轴
		// 	canvasBlack.strokeText(charsNum, 0, -10);// 此点为字的中心点
		// 	canvasBlack.restore();
		// }
   }
   //绘制五角星
   create5Star=(canvasBlack,color,sx,sy,rotate,radius)=>{
		canvasBlack.save()
		canvasBlack.fillStyle=color //颜色
		canvasBlack.translate(sx, sy);//坐标原点
		canvasBlack.rotate(Math.PI+rotate)//旋转角度
		canvasBlack.beginPath()
		var x = Math.sin(0);
        var y = Math.cos(0);
        var dig = Math.PI / 5 * 4;
        for (var i = 0; i < 5; i++) {//画五角星的五条边
            var x = Math.sin(i * dig);
            var y = Math.cos(i * dig);
            canvasBlack.lineTo(x * radius, y * radius);
        }

        canvasBlack.closePath();
        canvasBlack.stroke();
        canvasBlack.fill();
        canvasBlack.restore();

   }
	render() {
		return (
			<div className="sign">
			     <div className="sign-example">
					  <canvas id="canvas" width="200" height="200">

					  </canvas>
				 </div>
				 {/* <span onClick={()=>this.click()}>点击</span> */}
				 <div>
				
				 </div>
			</div>

		)
	}
}
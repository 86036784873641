import React, { Component } from "react";

import { webRouter } from "../../../router";
import Webheader from "../../../components/webHeader/webHeader";
import WebFooter from "../../../components/webFooter/webFooter";
import ToTop from "../../../components/toTop";
import { Affix } from "antd";
import "./enter.less";

export default class Index extends Component {
	render() {
		return (
			<div className="enter_home">
				{/* <Affix offsetTop={0} style={{zIndex:99999,position:"relative"}}>
				</Affix> */}
				<Webheader props={this.props} />
				<ToTop>{webRouter}</ToTop>
				<WebFooter />
			</div>
		);
	}
}


import emitter from "../services/event";
import axios from "axios";
import QS from "qs";


axios.defaults.timeout = 300000;
axios.interceptors.request.use(config => {
	config.headers.token = sessionStorage.getItem("token")
	for(let key in config.data){
		if(typeof config.data[key] =="string"){
			config.data[key] = config.data[key].trim()
			config.data[key] = (config.data[key]).replace(/null|undefinde/g,"")
		}
	}
    return config;
}, )


axios.interceptors.response.use(response => {
	return response
  }, err => {
	let status,statusText;
	try{
		status = err.response.status
		statusText = err.response.statusText
	}catch{
		status = "拦截了"
		statusText = "未知错误！"
	}
	let msg = `${String(status).startsWith(5)?"服务器端":""}出错了 ${status} ${statusText}`;
	console.log('error：',msg)
	return Promise.resolve({data:{status,msg}})
})


export default function ajax(url, data = {}, type, isJSON) {
	return new Promise((resolve, reject) => {
		let promise;
		if (type === "get") {
			if (isJSON == "downFile") {
				data.token = sessionStorage.getItem("token")
				promise = axios.get(
					url,
					{ params: data },
					{
						headers: {
							"Content-Type": "application/json",
						},
						responseType: 'blob'
					}
				);
			} else {
				promise = axios.get(
					url,
					{ params: data },
				);
			}
		} else {
			if (isJSON == "upFile") {
				promise = axios.post(url, data, {
					headers: {
						"Content-Type": "multipart/form-data",
						'token': sessionStorage.getItem("token")
					},
				});
			} else if (isJSON== "downFile") {
				promise = axios.post(url, data, {
					headers: {
						"Content-Type": "application/json",
						'token': sessionStorage.getItem("token")
					 },
					credentials: "include",
					responseType: 'blob'
				});
			}else if (isJSON) {
				promise = axios.post(url, data, {
					headers: {
						"Content-Type": "application/json",
						'token': sessionStorage.getItem("token")
					 },
				});
			} else{
                promise=axios.post(url+'?'+QS.stringify(data))
            }
		}
		promise.then((res) => {
				if (res.data.code === "401") {
					emitter.emit("isLogin", false);
				}else {
					resolve(res.data);
				}
			})
			.catch((err) => {
				// console.log('err', err.response)
			});
		return promise;
	});
}

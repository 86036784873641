import React, { Component } from "react"
import { Button, Divider, Table, message,Spin,Modal} from 'antd';
import BaseForm from '../../../components/baseForm/baseForm.fork_two'
import ControlPanel from "../../../components/controlPanel/controlPanel"
import { invoiceCollect,exportInvoiceCollect } from '../../../api'
import utils from "../../../utils"
import moment from 'moment'
import './sumStatistics.less'

export default class SumStatistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			jqbh:'',
			tableList: [],
			tjinformation:{},
			selectList:[],
			loading:false,
			nsrsbh:'',
			visible:false,
			addList:[],
			param:{}
		}
	}


	getData(param) {
		this.setState({loading:true})
		invoiceCollect(param).then(res => {
			if (res.code === '0000') {
				try{
					const { xxqk: tableList } = res.resultObj.body.returndata.jqbhs.xxqks
					const {tjinformation} = res.resultObj.body.returndata.jqbhs
					tableList.forEach((item,index)=>{
						item.key = index+1
						try{
							let xx = item.xxs.xx
							if(Array.isArray(xx)&&xx.length>0){
								let obj = this.resObj(xx)
								for(let k in obj){
									item[k] = obj[k]
								}
								if(index==0){
									this.restList(xx)
								}
							}
						}catch{}
						
					})
					this.setState({ tableList,tjinformation,visible:true })

				}catch{
					message.warning('数据异常！')
					this.setState({ tableList:[],tjinformation:{} })
				}
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}

	restList=(items)=>{
		let addList = []
		items.forEach(item=>{
			addList.push({
				title:item.sl,
				dataIndex:item.sl,
			})
		})
		this.state.addList = addList
	}

	resObj=(list)=>{
		let obj = {}
		list.forEach(item=>{
			obj[item.sl]=item.se
		})
		return obj
	}

	getInfo=(data)=>{
		let {deviceNo:jqbh,fplxdm:selectList,nsrsbh} = data
		this.setState({
			jqbh,
			selectList,
			nsrsbh
		})
	}

	handleFilter = e => {
		if (this.state.jqbh === "")return message.warning("请选择税控盘编号")
		if(e.fpzl==="请选择发票类型") return message.warning("请选择发票类型")
		if(!e.ssyf) return message.warning("请选择月份")
		if(this.state.nsrsbh=="")return message.warning('请输入纳税人识别号')

		e.jqbh = this.state.jqbh
		e.nsrsbh = this.state.nsrsbh
		e.ssyf = moment(e.ssyf).format("YYYY-MM")
		this.getData(e)
	}

	restColumns=(list)=>{
		this.state.addList.forEach(item=>{
			list.push(item)
		})
		return list
	}

	disabledDate2 = (current) => {
		return current && current > moment().endOf("month");
	}

	downLoad=()=>{
		let e = {...this.state.param}
		if (this.state.jqbh === "")return message.warning("请选择税控盘编号")
		if(e.fpzl==="请选择发票类型") return message.warning("请选择发票类型")
		if (!e.ssyf) return message.warning("请选择月份")
		if(this.state.nsrsbh=="")return message.warning('请输入纳税人识别号')

		e.jqbh = this.state.jqbh
		e.nsrsbh = this.state.nsrsbh

		exportInvoiceCollect(e).then(res=>{
			if(!res)return message.error("导出失败！")
            utils.downLoad(res,`汇总统计${e.ssyf}`)
		})
	}

	chengeFilter=(param)=>{
		if (!!param.ssyf){
			param.ssyf = moment(param.ssyf).format("YYYY-MM")
		}
		this.state.param = param
	}
	
	render() {
		let columns = [
			{
				title: '序号',
				dataIndex: 'key',
			},
			{
				title: '税控盘编号',
				dataIndex: 'userName',
				render: () => <p>{this.state.jqbh}</p>
			},

			{
				title: '名称',
				dataIndex: 'mc',
			},
			{
				title: '金额(合计)',
				dataIndex: 'hj',
			},
		];

		((columns)=>{
			this.restColumns(columns)
		})(columns)

		const formList = [
			{
				type: 'SELECT',
				label: '发票类型',
				field: 'fpzl',
				placeholder: '请选择发票类型',
				width: "100%",
				initialValue: "请选择发票类型",
				list: this.state.selectList
			},
			 {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'nsrsbh',
				placeholder: this.state.nsrsbh?this.state.nsrsbh:'请输入纳税人识别号',
				initialValue:this.state.nsrsbh?this.state.nsrsbh:'',
				width: "100%",
			},
			 {
				type: 'DATE',
				pickerType: 'month',
				label: '请选择月份',
				field: 'ssyf',
				placeholder: '请选择月份',
				width: "100%",
				disabledDate:this.disabledDate2
			},
		]

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div>
					<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"月度统计"}</h2>
				</div>
				<Divider />
				<div style={{ padding: "10px 0",display:'flex',justifyContent:"start",alignItems:"center" }}>
					<ControlPanel getInfo={this.getInfo} />
					<BaseForm formList={formList} filterSubmit={this.handleFilter} chengeFilter={this.chengeFilter} />
					<Button type="primary" style={{margin:"0 35px"}} onClick={this.downLoad}>导出</Button>
				</div>
				<div className="box-1" style={{ background: '#C7E2FF' }}>
						<div><span>期初库存份数：</span><span>{this.state.tjinformation.qckcfs}</span></div>
						<div><span>期末库存份数：</span><span>{this.state.tjinformation.qmkcfs}</span></div>
						<div><span>正数发票份数：</span><span>{this.state.tjinformation.zsfpfs}</span></div>
						<div><span>作废发票份数：</span><span>{this.state.tjinformation.zffpfs}</span></div>
						<div><span>负数发票份数：</span><span>{this.state.tjinformation.fsfpfs}</span></div>
						<div><span>负数废票发票份数：</span><span>{this.state.tjinformation.fffpfs}</span></div>
						<div><span>空废发票份数：</span><span>{this.state.tjinformation.kffpfs}</span></div>
						<div><span>合计份数：</span><span>{this.state.tjinformation.hjfs}</span></div>
						<div><span>购进份数：</span><span>{this.state.tjinformation.gjfpfs}</span></div>
						<div><span>退回份数：</span><span>{this.state.tjinformation.thfpfs}</span></div>
				</div>
				{
					this.state.visible?(<Table columns={columns} dataSource={this.state.tableList} pagination={{hideOnSinglePage:true}} />):null
				}
				
			</Spin>
		)
	}
}
import React, { Component } from "react"
import './ensurePlate.less'
import { Button, Divider, Select, Table, message, Modal, Spin,Icon } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
// import ControlPanel from "../../../components/controlPanel/controlPanel"
import { queryTaxPlateByPage, synchronizationTaxPlate,queryTaxPlateByNsrsbhFromThird, querySysEnterpriseList,listenManageinfoQuery } from '../../../api'

const { Option } = Select;
const typeList = [
	{
		id: '0', name: "100口主板盘组"
	}, {
		id: '1', name: "税控服务器（500）"
	}, {
		id: '2', name: "单Ukey终端"
	}, {
		id: '3', name: "32口主板盘组"
	}, {
		id: '4', name: "32口USB盘组"
	}, {
		id: '5', name: "40口Ukey盘组"
	}, {
		id: '6', name: "84口UKey盘组"
	},
]
const addData = [
	{
		type: 'INPUT',
		label: '盘组名称',
		field: 'loginName',
		placeholder: '请输入盘组名称',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'SELECT',
		label: '盘组类型',
		field: 'userName',
		placeholder: '请选择盘组类型',
		width: "100%",
		initialValue: '0',
		list: typeList
	},
	{
		type: 'INPUT',
		label: '服务地址或域名',
		field: 'roleUuid',
		width: "100%",
		initialValue: '',
		placeholder: '请输入服务地址或域名',
	},
	{
		type: 'INPUT',
		label: '服务端口号',
		field: 'userPhone',
		placeholder: '请输入服务端口号',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '发票上传服务地址',
		field: 'idCard',
		placeholder: '请输入发票上传服务地址',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '所属企业',
		field: 'password',
		placeholder: '请输入所属企业',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '备注',
		field: 'remark',
		placeholder: '请输入备注',
		width: "100%",
		initialValue: '',
	},

]
export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//修改回显数据
			updateData: [],
			isRoleVisible: false,
			flag: false,
			tableList: [],
			getDataInfo: {
				nsrsbh: '',
				nsrmc: '',
				plateNo: '',
				page: 1,
				rows: 10
			},
			total: 1,
			loading: false,
			list2:[],
			nsrsbh:'',
		}
	}
	add = () => {
		this.state.updateData.forEach(res => {
			res.initialValue = ''
		})
		this.setState({
			isRoleVisible: true,
			flag: false
		})
	}

	handleFilter = e => {
		this.setState({ getDataInfo: { ...this.state.getDataInfo, ...e, page: 1 } }, () => {
			this.getData()
		})

	}
	addUserDataBack = (e) => { }
	handleRoleSubmit = () => { }

	componentDidMount() {

		this.setState({ loading: true })
		this.getList()
		this.getData()

	}

	getList = () => {
		let list2 = []
		querySysEnterpriseList({}).then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				tableList.forEach((item, index) => {
					list2.push({
						id: item.registeredNo,
						name: item.companyName,
					})
				})
				this.setState({list2})
			}
		})
	}

	getData() {
		const param = {
			...this.state.getDataInfo
		}
		queryTaxPlateByPage(param).then(res => {
			if (res.code == "0000") {
				const { resultList: tableList } = res
				tableList.forEach((item, index) => {
					item.key = index + 1
				})
				this.setState({
					tableList,
					total: res.pager.totalCount,
				})
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false},()=>{
				this.getStatus()
			})
		})
	}

	setPage = (val) => {
		this.state.getDataInfo.page = val
		this.getData()
	}

	tbDengJi = () => {
		let nsrsbh = this.state.nsrsbh
		if(!nsrsbh)return message.error('请选择公司名称！');
		this.setState({ loading: true })
		synchronizationTaxPlate({nsrsbh}).then(res => {
		// queryTaxPlateByNsrsbhFromThird().then(res => {
			if (res.code === '0000') {
				message.success(res.msg)
				this.state.getDataInfo.page = 1
				this.getData()
			} else {
				message.error(res.msg)
			}
			this.setState({ loading: false })
		})
	}

	getInfo = (data) => {
		let { nsrsbh, nsrmc, deviceNo: plateNo } = data
		this.setState({ getDataInfo: { ...this.state.getDataInfo, nsrsbh, nsrmc, plateNo } }, () => {
			this.getData()
		})
	}
	onChange=(nsrsbh)=> {
		this.state.nsrsbh = nsrsbh
	}

	restList = (val) => {
		let list = val.split(",")
		let str = []
		for(let item of list){
			if(item=="004"){
				str.push("增值税专用发票")
			}else if(item=="007"){
				str.push("增值税普通发票")
			}else if(item=="026"){
				str.push("增值税电子发票")
			}else if(item=="028"){
				str.push("电子专用发票")
			}else if(item=="025"){
				str.push("卷式发票")
			}else if(item=="005"){
				str.push("机动车发票")
			}else if(item=="006"){
				str.push("二手车销售统一发票")
			}
		}
		return str.join('，')
	}

	getStatus=()=>{
        let list = this.state.tableList
        list.forEach((item,index)=>{
			let {jqbh:kpzdbs,fplxdm} = item
			fplxdm = fplxdm.split(',')[0]
            listenManageinfoQuery({kpzdbs,fplxdm}).then(res=>{
                if(res.code==="0000"){
                    item.gzzt = '成功！'
                }else{
                    item.gzzt = '失败！'
                    item.xinxi = res.msg
                }
                this.setState({})
            })
        })
    }

	renderSpan=(text)=>{
        if(!text)return
        if(text.includes('成功')){
            return <span style={{color:'green'}}><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{marginRight:8}} />{text}</span>
        }else{
            return <span style={{color:'red'}}><Icon type="exclamation-circle" theme="twoTone" twoToneColor="red" style={{marginRight:8}} />{text}</span>
        }
    }

	render() {
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},
			{
				title: '单张开票最高限额',
				dataIndex: 'amountLimit',
				key: 'amountLimit',
			},
			{
				title: '票种类型',
				dataIndex: 'fplxdm',
				key: 'fplxdm',
				render:(text)=>this.restList(text)
			},
			{
				title: '税盘状态',
                dataIndex: 'gzzt',
                render:(text)=>this.renderSpan(text)
            },
            {
				title: '错误信息',
                dataIndex: 'xinxi',
                width:200
            },

		];
		const formList = [

			{
				type: 'INPUT',
				label: '税控盘编号',
				field: 'plateNo',
				placeholder: '请输入税控盘编号',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'nsrsbh',
				placeholder: '请输入纳税人识别号',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '纳税人名称',
				field: 'nsrmc',
				placeholder: '请输入纳税人名称',
				width: "100%",
			},
		]

		return (
			<Spin spinning={this.state.loading} size="large">
				<div className="menberManager">
					<div className="menberManager-title">
						<h2>{sessionStorage.getItem("pageTitle")||"税控盘管理"}</h2>
					</div>
					<Divider />
					<div style={{ padding: "10px 0" }}>
						<BaseForm formList={formList} filterSubmit={this.handleFilter} />
						{/* <ControlPanel getInfo={this.getInfo} /> */}
					</div>
					<div className="ensure_box">
						<label>公司名称</label>：
						<Select
							showSearch
							style={{ width: 462 }}
							placeholder="请选择公司名称"
							optionFilterProp="children"
							onChange={this.onChange}
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								this.state.list2.map((item,index)=>{
									return <Option value={item.id} key={index+1}>{item.name}</Option>
								})
							}
						</Select>
						<Button type="primary" onClick={this.tbDengJi} style={{ marginLeft:15 }}>同步登记信息</Button>
					</div>
					<div>
						<Table columns={columns} dataSource={this.state.tableList} pagination={{
							onChange: this.setPage,
							total: this.state.total,
							current: this.state.getDataInfo.page,
						}} />
					</div>
					{
						this.state.isRoleVisible ? (<Modal
							title="添加盘组设备"
							visible={this.state.isRoleVisible}
							onOk={this.handleRoleSubmit}
							onCancel={() => {
								this.setState({
									isRoleVisible: false
								})
							}}
						>
							<ModalForm formList={this.state.flag ? this.state.updateData : addData} modalData={this.addUserDataBack.bind(this)} />
						</Modal>) : null
					}
				</div>
			</Spin>
		)
	}
}
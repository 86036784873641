import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./property.less";

import imgBan from "../../../../assets/img/solution/banner6.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/12.png";
import i2 from "../../../../assets/img/solution/16.png";
import i3 from "../../../../assets/img/solution/13.png";
import i4 from "../../../../assets/img/solution/18.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";

import i21 from "../../../../assets/img/solution/25.png";
import i22 from "../../../../assets/img/solution/44.png";
import i23 from "../../../../assets/img/solution/41.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";





export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer:{
				title: "我想要咨询此行业",
				phone:'400-9958-578'
			},
			active:[true,false,false,false]
			
		};
	}
	
	clickActive=(key)=>{
		let list  = this.state.active
		for(let i in list){
			if(i==key){
				list[i] = true
			}else{
				list[i] = false
			}
		}
		this.setState({active:[...list]})
		let str = 'active'+key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};

		

		return (
			<div className="solution-content solution-content-6">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0]?'active':''} onClick={this.clickActive.bind(this,0)}>行业趋势</li>
						<li className={this.state.active[1]?'active':''} onClick={this.clickActive.bind(this,1)}>行业痛点</li>
						<li className={this.state.active[2]?'active':''} onClick={this.clickActive.bind(this,2)}>解决方案</li>
						<li className={this.state.active[3]?'active':''} onClick={this.clickActive.bind(this,3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>监管要求</span>
							<span>对服务领域要求风险管控，简化流程</span>
						</li>
						<li  style={bgImg2}>
							<span>数字化管理</span>
							<span>线上管理的方便、安全带来物业发票线上数据化 </span>
						</li>
						<li  style={bgImg3}>
							<span>矛盾紧张</span>
							<span>物业开票缓慢引不满，行业需深度整合</span>
						</li>
						
					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>市场层面</span>
							<span>电子发票政策致使电子发票在市场普及，在服务领域已经大范围提供电子发票</span>
						</li>
						<li>
							<span>业务层面</span>
							<span>手工开具纸质发票效率极低且容易出错，存在人工风险，财务人员开票压力大，用户体验差，快递成本高</span>
						</li>
						<li>
							<span>性能层面</span>
							<span>传统开票系统开票效率低，并发性差，大量开票易卡顿</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>物业管理公司系统(物业管理公司自有微信公众号、APP、网站等)与中创平台开票系统对接,业主缴费完毕后可登录物业公司自有平台自助开票,无需人工介入</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>打印小票和二维码</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i2} />
								<span>线上缴费</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						<div className="ul_div">
							<li>
								<img alt="" src={i3} />
								<span>扫描二维码</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i4} />
								<span>接口服务</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i6} />
							<span>发送发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>节省时间</span>
								<img alt="" src={i21} />
							</div>
							<p>快速开具发票省时省事</p>
						</li>
						<li>
							<div>
								<span>提高效率</span>
								<img alt="" src={i22} />
							</div>
							<p>简化操作，提升效率</p>
						</li>
						<li>
							<div>
								<span>客户满意高</span>
								<img alt="" src={i23} />
							</div>
							<p>开票和接收形式多样化，提升用户体验，提高用户满意度</p>
						</li>
						
					</ul>
				</section>
				
				

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

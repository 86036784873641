import React, { Component } from "react"
import {Icon, message} from 'antd'
import {Link,withRouter} from 'react-router-dom'
import emitter from '../../services/event'
import store from "../../store";
import action from "../../actions/action"

import './navigationJump.less'

 class NavJump extends Component {
	constructor(props) {
		super(props);
		this.state={
			flag:false
		}
		this.jumpLink=[] //跳转列表
		this.select=''   //当前被选中元素
	}

	del=(e)=>{
		if(this.jumpLink.length<=1){
			return message.error('至少保留一个页面！')
		}
		let str = window.location.href.split('/').pop()
	    for(var i=0;i<this.jumpLink.length;i++){
			if(this.jumpLink[i].refs===e){
				this.jumpLink.splice(i,1)
				if((i-1)>=0&&e==str){
					this.props.history.push(this.jumpLink[this.jumpLink.length-1].key)
				    this.select=this.jumpLink[this.jumpLink.length-1].refs
				}else if(i-1<0){
					this.props.history.push(this.jumpLink[0].key)
				    this.select=this.jumpLink[0].refs
				}
			}
		}
		try{
			str = window.location.href.split('/').pop()
			let selectPage=document.getElementById(str)
			selectPage.classList.add("active")
			
		}catch{
			this.jumpLink.splice(0)
		}
		this.forceUpdate()
	}

	componentWillMount=()=>{
		this.jumpLink = store.getState().system.jumpLink
	}

	restObj=(list)=>{
		let newList = list.map(item => {
			let element;
			if(typeof item === "string"){
				element = JSON.parse(item)
			}else{
				element = JSON.stringify(item)
			}
			return element
		});
		return [...new Set(newList)]
	}

	componentDidMount () { 
		emitter.addListener('pathValue', (item) => {	 //监听事件，接收
			sessionStorage.setItem('pageTitle',item.title)
			this.jumpLink.push( item )
			this.jumpLink = this.restObj(this.jumpLink)
			this.jumpLink = this.restObj(this.jumpLink)
		    this.select=item.refs
		})
	}

	removeCname=()=>{
		let allActive=document.getElementsByClassName("active")
		if(allActive.length>0){
			for(let i of allActive){
				i.classList.remove("active")
			}
			this.removeCname()
		}
	}
	//组件重新渲染完成
	componentDidUpdate=()=>{
		store.dispatch(action("jumpLink",this.jumpLink))
		let allActive=document.getElementsByClassName("active")
		let str = window.location.href.split('/').pop()
		if(allActive.length>0){
			this.removeCname()
			try{
				let selectPage=document.getElementById(str)
				sessionStorage.setItem('pageTitle',selectPage.getElementsByTagName('span')[0].innerText)
				selectPage.classList.add("active")
			}catch{
				this.jumpLink.splice(0)
				this.forceUpdate()
			}
		}
	}

	componentWillUnmount () { //组件销毁前移除事件监听
		emitter.removeListener('pathValue', (msg) => { })
	}

	mySelect = (val,title)=>{
		this.select=val
		sessionStorage.setItem('pageTitle',title)
	}
	
	render() {
		return (
	         <div className="nav-jump" style={{display:(this.jumpLink.length>0)?'block':'none'}}>
			     {		 
					 this.jumpLink.map((item)=>(
						<li  className="active" style={{userSelect:"none"}} id={item.refs} key={item.key} onClick={()=>{this.mySelect(item.refs,item.title)}}>
							<Link to={item.key} >
							<span>{item.title}</span>
							</Link>
							<Icon type="close"  onClick={()=>{this.del(item.refs)}}/>
				      	</li>
					 ))
				 }
			 </div>
		)
	}
}

export default withRouter(NavJump);
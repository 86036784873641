import React, { Component } from "react";
import { Carousel } from "antd";
import "./home.less";
import Footer1 from "../../../components/webfooter1/webFooter1";
import Partners from "../../../components/partners/partners";
import Course from "../../../components/course/course"

import ban1 from "../../../assets/img/home/banner1.png";
import ban2 from "../../../assets/img/home/banner2.png";
import ban3 from "../../../assets/img/home/banner3.png";


import icon_1 from "../../../assets/img/home/1_xxgl.png";
import icon_2 from "../../../assets/img/home/1_jxgl.png";
import icon_3 from "../../../assets/img/home/1_gylxt.png";
import icon_4 from "../../../assets/img/home/1_swfx.png";
import icon_5 from "../../../assets/img/home/1_swch.png";
import icon_6 from "../../../assets/img/home/1_shzx.png";

import icon_2_1 from "../../../assets/img/home/2-1.png";
import icon_2_2 from "../../../assets/img/home/2-2.png";
import icon_2_3 from "../../../assets/img/home/2-3.png";
import icon_2_4 from "../../../assets/img/home/2-4.png";
import icon_2_5 from "../../../assets/img/home/2-5.png";

import icon_3_1 from "../../../assets/img/home/3-1.png";
import icon_3_2 from "../../../assets/img/home/3-2.png";
import icon_3_3 from "../../../assets/img/home/3-3.png";
import icon_3_4 from "../../../assets/img/home/3-4.png";
import icon_3_5 from "../../../assets/img/home/3-5.png";
import icon_3_6 from "../../../assets/img/home/3-6.png";

import bz from "../../../assets/img/home/tz.png"


export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardList: [true, false, false],
			cardList2: [true, false, false],
			carOpen:false,
			// widths:9999,
			// list:[],
		};
	}

	componentWillMount(){
		// let list = ['【平台公告】中税创新持续为客户提供免费的电子发票基础服务。包含生成、打印、查询、存储和交付，明确区分基础服务与增值服务，不违反自愿原则强制纳税人购买增值服务。']
		// this.setState({
		// 	list:[...list,list[0]]
		// })
	}

	componentDidMount(){
		// let allLists = document.getElementsByClassName('newsList')
		// let widths = 0;
		// for(let i of allLists){
		// 	widths += i.offsetWidth
		// }
		// this.setState({widths},()=>{
		// 	this.move()
		// })
	}

	componentWillUnmount(){
		// clearInterval(this.idTime)
	}
	

	// handlePrev = () => {
	// 	this.refs.imgs.prev();
	// };
	// handleNext = () => {
	// 	this.refs.imgs.next();
	// };
	// showCard = (val) => {
	// 	for (let i in this.state.cardList) {
	// 		if (i == val) {
	// 			this.state.cardList[i] = true;
	// 			this.state.cardList2[i] = true;
	// 		} else {
	// 			this.state.cardList[i] = false;
	// 			this.state.cardList2[i] = false;
	// 		}
	// 	}
	// 	this.setState({
	// 		cardList: [...this.state.cardList],
	// 		cardList2: [...this.state.cardList2],
	// 		carOpen:true
	// 	});
	// };
	// clearShows = ()=>{
	// 	this.setState({
	// 		carOpen:false
	// 	});
	// }

	// move=()=>{
	// 	let box =document.getElementsByClassName('cursor_text_box')[0]
	// 	let lastWidth = document.getElementsByClassName("newsList")[0].offsetWidth
	// 	let wds = this.state.widths - lastWidth
	// 	let distance = 0;
	// 	this.idTime=setInterval(()=>{
	// 		distance -=1;
	// 		if(-distance>=wds){
	// 			distance = 0
	// 		}
	// 		box.style.transform = 'translateX(' + distance + 'px)';
	// 	},20)
	// }

	jump=(url)=>{
		this.props.history.push(url)
	}

	render() {
		return (
			<div className="home_page">
				{/*轮播图 */}
				<div className="home-content1">
					<Carousel autoplay>
						<div>
							<img src={ban1} alt="" />
						</div>
						<div>
							<img src={ban2} alt="" />
						</div>
						<div>
							<img src={ban3} alt="" />
						</div>
					</Carousel>
				</div>
				{/* 通知 */}
				{/* <div className="home-content2">
					<img className="content2-img" src={bz} alt="" />
					<div className="cursor_text" >
						<div className="cursor_text_box" style={{width:this.state.widths*2}}>
							{
								this.state.list.map((item,index)=>{
									return (<div className='newsList' key={index}>{item}</div>)
								})
							}
						</div>
					</div>
				</div> */}
				{/* section1 */}
				<div className="home-content3">
					<div className="title">安全、稳定、高效的发票平台</div>
					<div className="title2">电子发票升级，助力企业高效管理</div>
					<ul>
						<li onClick={()=>this.jump('/web/electronic')}>
							<img src={require("../../../assets/img/home/dzfpwjf.png")} alt="电子发票" />
							<div>
								<h4>电子发票</h4>
								<div>为您提供专业的电子发票解决方案</div>
							</div>
						</li>
						<li onClick={()=>this.jump('/web/income')}>
							<img src={require("../../../assets/img/home/dzpjwjf.png")} alt="进项管理" />
							<div>
								<h4>进项管理</h4>
								<div>规范的进项管理尽在中税创新</div>
							</div>
						</li>
						<li onClick={()=>this.jump('/web/check')} >
							<img src={require("../../../assets/img/home/dzhtwjf.png")} alt="发票查验"/>
							<div>
								<h4>发票查验</h4>
								<div>方便，准确，快速的发票查验系统</div>
							</div>
						</li>
					</ul>
				</div>
				{/* section2 */}
				<div className="home-content4">
					<div className="title">
						更垂直的发票解决方案，满足广泛用户需求
					</div>
					<div className="title2">
						电商、停车场、餐饮、物业等衣食住行，我们拥有完整的解决方案
					</div>
					{/* <div>
						<Course obj={{}} />
					</div> */}
					<div className="solution-top">
						<div onClick={()=>this.jump('/web/solution/restaurant')}>
							<img src={require("../../../assets/img/home/solution1.png")}/>
							<p>餐饮行业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/hotel')}>
							<img src={require("../../../assets/img/home/solution2.png")}/>
							<p>酒店行业解决方案</p>
						</div>
					</div>
					<div className="solution-bottom">
						<div onClick={()=>this.jump('/web/solution/chain')}>
							<img src={require("../../../assets/img/home/solution3.png")}/>
							<p>连锁行业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/enterprise')}>
							<img src={require("../../../assets/img/home/solution4.png")}/>
							<p>中小微企业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/financial')}>
							<img src={require("../../../assets/img/home/solution5.png")}/>
							<p>金融行业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/market')}>
							<img src={require("../../../assets/img/home/solution6.png")}/>
							<p>商超行业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/property')}>
							<img src={require("../../../assets/img/home/solution7.png")}/>
							<p>物业管理解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/onlineRetailers')}>
							<img src={require("../../../assets/img/home/solution8.png")}/>
							<p>电商行业解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/express')}>
							<img src={require("../../../assets/img/home/solution9.png")}/>
							<p>快递物流解决方案</p>
						</div>
						<div onClick={()=>this.jump('/web/solution/parkingLot')}>
							<img src={require("../../../assets/img/home/solution10.png")}/>
							<p>停车场解决方案</p>
						</div>
					</div>
				</div>
				{/* section3 */}
				{/* <div className="home-content5">
					<div className="title">
						一体化管理服务，无需陷入开票烦恼
					</div>
					<div className="title2">
						大型企业，中小型企业，个人全周期生态管理
					</div>

					<div className="box-flex">
						<div className="left-css1">
							<div className={ this.state.cardList2[0] ? "activeLeft" : null }
								onClick={() => this.showCard(0)}
							>
								<div className="left-css1-title">连锁企业</div>
							</div>
							<div className={
									this.state.cardList2[1] ? "activeLeft" : null
								}
								onClick={() => this.showCard(1)}
							>
								<div className="left-css1-title">
									中小型企业
								</div>
							</div>
							<div className={
									this.state.cardList2[2] ? "activeLeft" : null
								}
								onClick={() => this.showCard(2)}
							>
								<div className="left-css1-title">个人用户</div>
							</div>
						</div>
						<div className={this.state.carOpen?"right-css1 right-css2":"right-css1"}
							style={{
								display: this.state.cardList[0] ? "flex" : "none",
							}}
						>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_1} alt="" />
								<span>销项管理</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2} alt="" />
								<span>进项管理</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3} alt="" />
								<span>供应链协同</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_4} alt="" />
								<span>税务风险</span>
							</div>

							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_5} alt="" />
								<span>税务筹划</span>
							</div>

							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_6} alt="" />
								<span>售后咨询</span>
							</div>
						</div>
						<div
							className={this.state.carOpen?"right-css1 right-css2":"right-css1"}
							style={{ display: this.state.cardList[1] ? "flex" : "none", }}
						>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2_1} alt="" />
								<span>发票管理平台</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2_2} alt="" />
								<span>风险管理</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2_3} alt="" />
								<span>管理辅助</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2_4} alt="" />
								<span>售后咨询</span>
							</div>

							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_2_5} alt="" />
								<span>行业法规</span>
							</div>
						</div>
						<div
							className={this.state.carOpen?"right-css1 right-css2":"right-css1"}
							style={{ display: this.state.cardList[2] ? "flex" : "none", }}
						>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_1} alt="" />
								<span>开票</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_2} alt="" />
								<span>查验</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_3} alt="" />
								<span>记账</span>
							</div>
							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_4} alt="" />
								<span>归集</span>
							</div>

							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_5} alt="" />
								<span>报销</span>
							</div>

							<div className="right-css1-item">
								<img className="right-css1-item-img" src={icon_3_6} alt="" />
								<span>打印</span>
							</div>
						</div>
					</div>
				</div> */}
				{/* section4 */}
				<div className="home-content6">
					<div className="title">行业闭环，让用户没有难开的发票</div>
					<div className="title2">产品售后全流程，实现业务统一</div>
					<div className="content6">
						<div className="content-box">
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/cb.png")} alt="" />
										<span>节省成本</span>
								</div>
								<div className="content-box-block">  
									平台提供成熟解决方案，为企业用户节省开具、部署、管理等成本
								</div>
							</div>
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/dzkpfs.png")} alt="" />
									<span>多种开票方式</span>
								</div>
								<div className="content-box-block">
									提供标准的API接口，扫码开票、批量开票、APP开票、公众号开票等任用户选择
								</div>
							</div>
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/ssqm.png")} alt="" />
									<span>生态能力全面</span>
								</div>
								<div className="content-box-block">
									已对接百家企业用户，平台与各行业紧密协作，提供更多优质服务
								</div>
							</div>
						</div>
						<div className="content-box">
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/jrysjk.png")} alt="" />
									<span>兼容开票终端</span>
								</div>
								<div className="content-box-block">
									支持金税盘、税控盘、UKEY等操作，开电子发票您有更多选择
								</div>
							</div>
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/fgl.png")} alt="" />
									<span>财税票全面覆盖</span>
								</div>
								<div className="content-box-block">
									全流程的行业覆盖，发票开具、流转、查验、存储、签单、合同全搞定
								</div>
							</div>
							<div>
								<div className="content-img-block">
									<img src={require("../../../assets/img/home/qthshtd.png")} alt="" />
									<span>全天候服务</span>
								</div>
								<div className="content-box-block">
									产品应用在线交流，7*24小时在线服务
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* 合作伙伴 */}
				<Partners />
				{/* 底部footer1 */}
				<Footer1 />
			</div>
		);
	}
}

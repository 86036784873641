import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.less';
import { Upload, Button, Icon, message } from 'antd';
import {importInvoice} from "../../api"
import utils from "../../utils"

export default class UpFile extends React.Component {
  state = {
    fileList: [],
    uploading: false,
    list:'',
    open:false
  };

  componentDidMount(){
  }

  handleUpload = () => {
    const { fileList } = this.state;
    if(fileList.length<1){
      return message.error('未选择文件')
    }
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('file', file);
    });

    this.setState({
      uploading: true,
    });

    importInvoice(formData).then(res=>{
      if(res.code=="0000"){
        message.success(res.msg)
        let list = res.resultObj
        this.setState({list})
      }else{
        message.error(res.msg)
      }
      this.setState({
              fileList: [],
              uploading: false,
              open:true
            });
    })
  };

  //下载模板
  exportsMuban = () => {
    let url = require('../../assets/pdf/batch_demo1.xlsx')
    utils.aDownLoad(url, "batch_demo.xlsx")
}

  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const {failCount,failDetails,successCount,totalCount,beforeLimit,afterLimit} = this.state.list
    return (
      <div>
        <Button type="primary" style={{ display:"block",marginBottom:16 }} onClick={() => { this.exportsMuban() }}>下载标准模板</Button>
        <Upload {...props}>
          <Button  disabled={fileList.length !== 0}>
            <Icon type="upload" />&nbsp;选择文件
          </Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? '正在上传文件' : '开始上传文件'}
        </Button>
        {
          this.state.open?(<div>
            <ul className="upLpadeNumber">
                <br></br>
                <li><span>总订单数</span>：{totalCount}</li>
                <li><span>拆分前条数</span>：{beforeLimit}</li>
                <li><span>拆分后条数</span>：{afterLimit}</li>
                <li><span>成功条数</span>：{successCount}</li>
                <li><span>失败条数</span>：{failCount}</li>
                {
                  Array.isArray(failDetails)&&failDetails.map((item,index)=>{
                    return <li key={index}>{item}</li>
                  })
                }
            </ul>
        </div>):null
        }
        
      </div>
    );
  }
}
         
import React, { Component } from "react"
//import {Link,withRouter} from 'react-router-dom'
import {Menu,Icon} from 'antd';

import './headerTwo.less'
import NavData from '../slideNav/slideNavData'
const { SubMenu } = Menu
export default class headerTwo extends Component {
	  constructor(props) {
			super(props);
			this.state = {
				current: '',
				theme:"dark"
			}
			this.navbar=[]
	  }

	  handleClick = e => {
		this.setState({
		  current: e.key,
		});
	  }
	  
	  jump=(path,e)=>{
		console.log(e.domEvent.currentTarget)//获取该节点
		e.domEvent.currentTarget.style.backgroundColor='inherit'
		this.props.props.history.push(path)
	    
	  }

	  Data=(menuList)=>{
		return menuList.reduce((pre, item) => {
			if(!item.children){
				pre.push((
					<Menu.Item key={item.key} onClick={(e)=>this.jump(item.key,e)}>
					  <Icon type={item.icon}/>
					  <span>{item.title}</span>
				  </Menu.Item>
				))
			}else{
				pre.push((
					<SubMenu
						key={item.key}
						title={
						 <span>
							<Icon type={item.icon}/>
							<span>{item.title}</span>
						 </span>
						}
					>
					{this.Data(item.children)}
					</SubMenu>
				))
			}
			return pre
		},[])
	 }
     componentWillMount() {
		this.navbar = this.Data(NavData)
	 }
      
   
	render() {

		return (
				<div style={{padding:"10px 20px",backgroundColor:"#001529"}}>
					<Menu onClick={this.handleClick}  selectedKeys={[this.state.current]} mode="horizontal"   theme={this.state.theme}>
						{this.navbar}
					</Menu>
				</div>
		)
	}
}
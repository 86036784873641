import React, { Component } from "react"
import {querySysEnterpriseList} from "../../../api"
import QRCode from 'qrcode.react'
import './printInfo.less'

export default class printInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            money: '',
            data: '',
            time: '',
            url: '',
            companyName:'',
            contactPhone:'',
            validity:'',
        }
    }

    //初始化数据
    componentWillMount = () => {
        let b = new Date();
        let data = b.toLocaleDateString().replace(/\//g, "-");
        let time = b.toTimeString().slice(0, 8);
        let url;
        let number;
        let registeredNo;
        try{
            url =  localStorage.getItem('orderUrl')
            number = (JSON.parse(localStorage.getItem('info'))).toFixed(2)
            registeredNo = sessionStorage.getItem("registeredNo")
        }catch{
            url = "";
            number = "0";
            registeredNo="";
        }
        if(registeredNo){
            querySysEnterpriseList({registeredNo}).then(res=>{
                if(res.code==="0000"){
                    let {companyName,contactPhone,validity} = res.resultObj.parent
                    this.setState({companyName,contactPhone,validity},()=>{
                        window.print()
                    })
                }
            })
        }
        this.setState({
            money: number, 
            data, 
            time,
            url,
        })
    }

    render() {
        return (
            <div className="fp-body">
                <div className="header-info">
                    <h4 className="gsinfo">{this.state.companyName}</h4>
                    <p>商家电话：{this.state.contactPhone}</p>
                </div>
                <div className="fp-date">
                    <div className="fa-date-item">
                        <span>日期:</span>
                        <span id="date">{this.state.data}</span>
                    </div>
                    <div className="fa-date-item">
                        <span>时间:</span>
                        <span id="time">{this.state.time}</span>
                    </div>
                    <div className="fa-date-item">
                        <span>金额:</span>
                        <span id="money">{this.state.money}</span>
                    </div>
                </div>
                <div className="hr"></div>
                <div>1.此二维码及提取码自消费日{this.state.validity}天内有效。</div>
                <div>2.打开微信或支付宝“扫一扫”扫描下方二维码。</div>
                <div id="qrcode">
                    <QRCode value={this.state.url} />
                </div>
                <div>2.也可以登录网站</div>
                <div id="href">{this.state.url}</div>
                <div>根据指引步骤开具电子发票。</div>
                <div className="hr"></div>
                <p>友情提示：如果长时间查收不到发票时，可通过关注'票慧'微信公众号【智慧发票】查看开票状态并提取发票</p>
                <p>若您对发票还有任何疑问,请拨打服务热线 400-9958-578(9:30-18:30)</p>
                <div className="blank"></div>
            </div>
        )
    }
}
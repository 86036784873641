import React, { Component } from "react"
import { Button, Divider, Table, Modal, Form, Input, message, Spin, Select, DatePicker, Row, Col, } from 'antd';
// import ModalForm from '../../../components/modal/modal'
import ControlPanel from "../../../components/controlPanel/controlPanel"
import { queryTaxPlateByNsrsbh, queryStatus, tbApplyInvoice, tbApplyInvoiceRevocation, paperInvoiceAffirm } from '../../../api'
import moment from "moment";
import './applyInvoice.less'
const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;


export default class TicketsOnline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //查询状态
            comment: "",
            //列表数据1
            tableList: [],
            tableList1: [],
            tableList2: [],
            tableList0: [],
            loading: false,
            item: {},
            itemList: [],
            itemList2: [],
            nsrmc: "", //公司名称
            nsrmc2: "", //公司名称
            //查询数据
            infoObj: {
                jqbh: '',
                registeredNo: '',
                fplxdm: '',
                startTime: '',
                entTime: '',
                status: "",
            },
            statusList: [
                { status: '0', name: '申领中' },
                { status: '1', name: '成功' },
                { status: '2', name: '失败' },
            ],
            status: "",
            showDetails: false,
            detailsList: [],
            showShen: false,
            shenDetails: {
                slsm: "",//说明
                slsl: "",//份数
                bz: "",//备注
                yddh: "",//移动电话
                gddh: "",//固定电话
                yb: "",//收件地址
                identityType: '',
                deviceType:'',
                invoiceCode:'',
                identityCode: '',
                sjrxm: "",//收件姓名
                slfs: "1",//申领方式  默认1
                fplxdm: "",//发票类型代码
                jqbh: "",//机器编号
                registeredNo: "",//纳税人识别号
            },
        }
    }


    componentWillMount() {

    }


    getFaPiao = (fplxdm) => {
        this.setState({
            infoObj: {
                ...this.state.infoObj,
                fplxdm
            },
        })
    }

    filterFpType(val) {
        switch (val) {
            case '004':
                return '增值税专用发票'
            case '007':
                return '增值税普通发票'
            case '025':
                return '卷式发票'
            case '026':
                return '增值税电子发票'
            case '028':
                return '电子专用发票'
        }
    }


    getStatus = (status) => {
        this.setState({
            infoObj: {
                ...this.state.infoObj,
                status
            },
        })
    }

    getFaPiao2 = (fplxdm) => {
        this.setState({
            shenDetails: {
                ...this.state.shenDetails,
                fplxdm
            },
        })
    }

    // 获取时间
    onChangeDate = (date, dateString) => {
        let startTime = dateString[0].replace(/-/g, '')
        let entTime = dateString[1].replace(/-/g, '')
        this.setState({
            infoObj: {
                ...this.state.infoObj,
                startTime, entTime
            }
        })
    }

    getQueryData = (param) => {
        this.setState({ loading: true })
        return queryStatus(param).then(res => {
            let tableList = []
            if (res.code == "0000") {
                message.success(res.msg)
                tableList = res.resultObj.body.slxx.group
                Array.isArray(tableList) && tableList.forEach((item, index) => {
                    item.nsrsbh = res.resultObj.body.nsrsbh
                    item.jqbh = res.resultObj.body.jqbh
                    item.slsj = this.restDate(item.slsj)
                    item.clsj = this.restDate(item.clsj)
                    // item.status = param.status
                    item.key = param.status + "_" + index
                })
            } else {
                message.error(res.msg)
                tableList = []
            }
            this.setState({ loading: false, tableList })
        })
    }

    toInvoiceBuy = () => {
        let param = this.state.infoObj
        if (!param.fplxdm) {
            return message.error('发票类型未选择')
        }
        if (!param.registeredNo) {
            return message.error('公司名称未选择')
        }
        if (!param.startTime) {
            return message.error('开始时间未选择')
        }
        if (!param.entTime) {
            return message.error('结束时间未选择')
        }
        this.getQueryData({ ...param })
    }

    restDate = (data) => {
        let year = data.substr(0, 4)
        let month = data.substr(4, 2)
        let day = data.substr(6, 2)
        return `${year}-${month}-${day}`
    }

    details = (detailsList) => {
        detailsList = detailsList.sljgmx.group
        this.setState({
            detailsList,
            showDetails: true
        })
    }

    getValue = (key, e) => {
        this.state.shenDetails[key] = e.target.value
        this.setState({
            shenDetails: { ...this.state.shenDetails }
        })
    }

    getSlfs = (slfs) => {

        this.setState({
            shenDetails: { ...this.state.shenDetails, slfs }
        })
    }
    getSflx = (sflx) => {

        this.setState({
            shenDetails: { ...this.state.shenDetails, ...{ identityType: sflx } }
        })
    }
    getSblx = (sblx) => {

        this.setState({
            shenDetails: { ...this.state.shenDetails, ...{ deviceType: sblx } }
        })
    }
    
    //清空数据
    clearAll = () => {
        this.setState({
            nsrmc2: '',
            itemList2: []
        })
    }

    goShen = () => {
        let param = this.state.shenDetails

        tbApplyInvoice(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
                this.setState({ showShen: false })
                this.clearAll()
            } else {
                message.error(res.msg)
            }
        })
    }

    goChe = (record) => {
        let { fplxdm, registeredNo, jqbh } = this.state.infoObj
        let { slxh, key: index } = record
        let param = { slxh, fplxdm, registeredNo, jqbh }

        tbApplyInvoiceRevocation(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
                this.state.tableList.splice(index, 1)
            } else {
                message.error(res.msg)
            }
            this.forceUpdate()
        })
    }


    getInfo = (data) => {
        let { nsrmc, nsrsbh: registeredNo, deviceNo: jqbh, fplxdm: itemList } = data
        this.setState({
            itemList, nsrmc,
            infoObj: {
                ...this.state.infoObj,
                jqbh,
                registeredNo,
            },
        })
    }

    getInfo2 = (data) => {
        let { nsrmc: nsrmc2, nsrsbh: registeredNo, deviceNo: jqbh, fplxdm: itemList2 } = data
        this.setState({
            itemList2, nsrmc2,
            shenDetails: {
                ...this.state.shenDetails,
                jqbh,
                registeredNo,
            }
        })
    }

    queryOk = (data) => {
        let { jqbh, registeredNo: nsrsbh } = this.state.infoObj
        let { fplxdm, slxh, sljgnx: { group: [{ fpdm, qshm, zzhm, fs },] } } = data
        paperInvoiceAffirm({ jqbh, nsrsbh, fplxdm, slxh, fpdm, qshm, zzhm, fs }).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    resetClzt = (val) => {
        let name;
        this.state.statusList.forEach(e => {
            if (e.status == val) {
                name = e.name
            }
        })
        return name
    }

    render() {
        const self = this
        //纳税人识别号 nsrsbh  jqbh机器编号  fplxdm发票类型
        const columns = [
            {
                title: '申领时间',
                dataIndex: 'slsj',
                key: 'slsj',
            },
            {
                title: '纳税人识别号',
                dataIndex: 'nsrsbh',
                key: 'nsrsbh',
            },
            {
                title: '机器编号',
                dataIndex: 'jqbh',
                key: 'jqbh',
            },
            {
                title: '发票类型',
                dataIndex: 'fplxdm',
                key: 'fplxdm',
                render: (text, record) => (<span>{self.filterFpType(record.fplxdm)}</span>)

            },
            {
                title: '处理时间',
                dataIndex: 'clsj',
                key: 'clsj',
            },
            {
                title: '申领数量',
                dataIndex: 'slsl',
                key: 'slsl',
            },
            {
                title: '状态',
                dataIndex: 'clzt',
                key: 'clzt',
                render: (text) => this.resetClzt(text)
            },
            {
                title: '详情',
                render: (text, record, index) => (<div>
                    {
                        record.clzt == '0' ? (<Button type="primary" onClick={() => {
                            this.goChe(record)
                        }}>撤销申领</Button>) : (record.clzt == '1' ? (
                            <div>
                                {
                                    record.fplxdm == "007" ? (
                                        <div>
                                            <Button type="primary" onClick={() => { this.details(record) }}>详情</Button>
                                            <Button type="primary" disabled={record.jgqrbz == "N" ? false : true} onClick={() => { this.queryOk(record) }}>确定</Button>
                                        </div>
                                    ) : <Button type="primary" onClick={() => { this.details(record) }}>详情</Button>
                                }
                            </div>
                        ) : record.clxx)

                    }
                </div>)
            },
        ];
        const columns2 = [
            {
                title: '发票代码',
                dataIndex: 'fpdm',
                key: 'fpdm',
            },
            {
                title: '起始号码',
                dataIndex: 'qshm',
                key: 'qshm',
            },
            {
                title: '终止号码',
                dataIndex: 'zzhm',
                key: 'zzhm',
            },
            {
                title: '份数',
                dataIndex: 'fs',
                key: 'fs',
            },

        ];



        return (
            <Spin spinning={this.state.loading} size="large" >
                <div>
                    <h2 style={{ color: "#666", fontWeight: 700, letterSpacing: "1px", }} >{sessionStorage.getItem("pageTitle") || "发票申领"}</h2>
                </div>
                <Divider />
                <div style={{ background: '#C7E2FF', padding: '6px 12px' }} className="shenLing-box">
                    <Form layout="inline">
                        <FormItem >
                            <ControlPanel getInfo={this.getInfo} />
                        </FormItem>
                        <FormItem label='公司名称' style={{ padding: "10px 0" }}>
                            <Input disabled value={this.state.nsrmc} />
                        </FormItem>
                        <FormItem label='发票类型' style={{ padding: "10px 0" }}>
                            <Select defaultValue="请选择类型" style={{ width: 140 }} onChange={this.getFaPiao}>
                                {
                                    this.state.itemList.map((item, index) => {
                                        return (
                                            <Option value={item.id} key={index}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label='状态' style={{ padding: "10px 0" }}>
                            <Select defaultValue="请选择状态" style={{ width: 120 }} onChange={this.getStatus}>
                                <Option value="">{"全部"}</Option>
                                {
                                    this.state.statusList.map((item, index) => {
                                        return (
                                            <Option value={item.status} key={index}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label='起始日期' style={{ padding: "10px 0" }}>
                            <RangePicker onChange={this.onChangeDate} defaultPickerValue={moment().subtract(1, "M")} />
                        </FormItem>
                        <FormItem style={{ padding: "10px 0" }}>
                            <Button type="primary" onClick={this.toInvoiceBuy.bind(this)}>查询</Button>
                        </FormItem>
                    </Form>

                    <Button type="primary" onClick={() => { this.setState({ showShen: true }); this.clearAll() }}>申领</Button>
                </div>

                <div>
                    <Table columns={columns} dataSource={this.state.tableList} />
                </div>
                <Modal title="详情" visible={this.state.showDetails} footer={null} onOk={() => { this.setState({ showDetails: false, }) }} onCancel={() => { this.setState({ showDetails: false }) }}>
                    <Table columns={columns2} dataSource={this.state.detailsList} pagination={{ hideOnSinglePage: true }} />
                </Modal>
                <Modal title="申领信息" width={800} visible={this.state.showShen} onOk={this.goShen} onCancel={() => { this.setState({ showShen: false }); this.clearAll() }} className="first_write" destroyOnClose={true}>
                    <Form layout="inline" className={'slForm'}>
                    <Row>
                        <Col >
                            <FormItem style={{ marginLeft: "18px" }}>
                                <ControlPanel style={{ width: "190px" }} getInfo={this.getInfo2} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='公司名称' >
                                <Input disabled value={this.state.nsrmc2} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='发票类型'>
                                <Select defaultValue="请选择……" style={{ width: 140 }} onChange={this.getFaPiao2}>
                                    {
                                        this.state.itemList2.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={index}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12}><FormItem label='申领方式'>
                            <Select defaultValue="1" style={{ width: "100%" }} onChange={this.getSlfs}>
                                <Option value='1' key='1'>自行领取</Option>
                                <Option value='2' key='2'>快递领取</Option>
                            </Select>
                        </FormItem></Col>

                        <Col span={12}><FormItem label='申领说明'>
                            <Input placeholder="请输入申领说明" value={this.state.shenDetails.slsm} onChange={(e) => { this.getValue("slsm", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='份数'>
                            <Input placeholder="请输入申领份数" value={this.state.shenDetails.slsl} onChange={(e) => { this.getValue("slsl", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='收件人姓名'>
                            <Input placeholder="请输入收件人姓名" value={this.state.shenDetails.sjrxm} onChange={(e) => { this.getValue("sjrxm", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='证件类型'>
                            <Select placeholder="请选择证件类型" style={{ width: "100%" }} onChange={this.getSflx}>
                                <Option value={201} key='1'>身份证</Option>
                                <Option value={208} key='2'>护照</Option>
                            </Select>
                        </FormItem></Col>

                        <Col span={12}><FormItem label='证件号码'>
                            <Input placeholder="请输入证件号码" value={this.state.shenDetails.identityCode} onChange={(e) => { this.getValue("identityCode", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='发票种类代码'>
                            <Input placeholder="请输入发票种类代码" value={this.state.shenDetails.invoiceCode} onChange={(e) => { this.getValue("invoiceCode", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='设备类型'>
                            <Select placeholder="请选择设备类型" style={{ width: "100%" }} onChange={this.getSblx}>
                                <Option value={0} key='1'>金税盘</Option>
                                <Option value={1} key='2'>税控盘</Option>
                                <Option value={2} key='3'>税务UKEY</Option>
                            </Select>
                        </FormItem></Col>

                        <Col span={12}><FormItem label='收件地址'>
                            <Input placeholder="请输入收件地址" value={this.state.shenDetails.yb} onChange={(e) => { this.getValue("yb", e) }} />
                        </FormItem></Col>

                        <Col span={12}><FormItem label='固定电话'>
                            <Input placeholder="请输入固定电话" value={this.state.shenDetails.gddh} onChange={(e) => { this.getValue("gddh", e) }} />
                        </FormItem></Col>

                        <Col span={12}>
                            <FormItem label='移动电话'>
                                <Input placeholder="请输入移动电话" value={this.state.shenDetails.yddh} onChange={(e) => { this.getValue("yddh", e) }} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='备注'>
                                <Input placeholder="请输入备注" value={this.state.shenDetails.bz} onChange={(e) => { this.getValue("bz", e) }} />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            </Spin >
        )
    }
}
import React, { Component } from "react"
import './uploadServer.less'
import { Button,Divider,Table } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
export default class UploadServer extends Component {
	constructor(props) {
		super(props);
		this.state={
			tableList: []
		}
	}
	handleFilter=(e)=>{
	}
	render() {
		const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
            },
            {
			    title: '纳税人识别号',
			    dataIndex: 'userName',
			    key: 'userName',
			},
			{
                title: '税控盘编号',
                dataIndex: 'idCard',
                key: 'idCard',
			},
			{
			    title: '登陆端名称',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
			    title: '线程号',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '线程状态',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '上传的间隔时间',
				dataIndex: 'x',
				key: 'x',
			},
			{
			    title: '最后扫描时间',
				dataIndex: 'x',
				key: 'x',
			},
			{
			    title: '上传状态',
				dataIndex: 'x',
				key: 'x',
            },
		  ];
		const formList = [
			{
				type: 'SELECT',
				label: '盘组设备名称',
				field: '1',
				placeholder: '请选择盘组设备名称',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"未发送"
					},
					
				]
			}, {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'fileName',
				placeholder: '请输入纳税人识别号',
				width: "100%",
			},{
				type: 'INPUT',
				label: '税控盘编号',
				field: 'fileName',
				placeholder: '请输入税控盘编号',
				width: "100%",
			},{
				type: 'SELECT',
				label: '上传状态',
				field: '2',
				placeholder: '请选择上传状态',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"全部",					
					},
					{
						id:'1',name:"正常",	
					},
					{
						id:'2',name:"异常",
					},
					{
						id:'3',name:"未启动",
					}
					
				]
			},
		]
		return (
			<div>
				<div>
					<h2>上传服务监控</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
				<div style={{ background: '#C7E2FF', height: '36px'}}>
   
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>

			</div>
		)
	}
}
import React, { Component } from "react";
import { Button ,message,Spin} from "antd";
// import axios from "axios";
import "./index.less";
import {getWxCardPkgAuthUrl} from "../../../api"
import Utils from "../../../utils"
let wx = window.wx

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pdf:'',
			id:"",
			abled:false,
			fileName:'',
			loading:false,
			display:false,
		};
	}
	
	componentDidMount(){
		let pdf;
		let id;
		let fileName;
		try{
			pdf = localStorage.getItem("step_pdfUrl")
			id = localStorage.getItem("step_id")
			fileName = pdf.split('/').pop()
		}catch{
			pdf = ""
			id = ""
			fileName = ""
		}
		this.setState({
			pdf,fileName,id
		})
		//检测坏境
		var userAgent = navigator.userAgent.toLowerCase();

		if (userAgent.match(/Alipay/i) == 'alipay') {
			this.environmental = "alipay";
		} else if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
			this.environmental = "micromessenger";
			this.setState({ display:true })
		}else{
			this.setState({ display:false })
		}
	}

	onAbled=()=>{
		this.setState({ loading:true },()=>{
			let timeid = setTimeout(()=>{
				this.setState({loading:false})
				clearTimeout(timeid)
			},1000)
		})
	}

	onAbleds=()=>{
		this.setState({loading:true})
		let oa = document.createElement('a');
		oa.href = this.state.pdf;
		oa.setAttribute('target', '_blank');
		document.body.appendChild(oa);
		oa.click();
		oa.remove();
		this.setState({loading:false})
	}

	addCard=()=>{
		let id = this.state.id
		if(!id)return message.error('缺少ID值！')
		this.setState({location:true})
		getWxCardPkgAuthUrl({id}).then(res=>{
			if(res.code=="0000"){
				let url = res.resultObj
				window.location.href = url
				message.success(res.msg)
			}else{
				message.error(res.msg)
			}
			this.setState({location:false})
		})
	}

	go = () => {
		this.props.history.go(-1);
		this.setState({ abled:false })
	};
	
	close =()=>{
		window.opener = null;
		window.open("", "_self");
		window.close();
		wx.closeWindow();
	}

	render() {
		return (
			<Spin spinning={this.state.loading} size="large"  >
			<div className="content_s1">
				<div className="successTitle">
					<div className="left">
						<img
							onClick={this.go}
							src={require("../../../assets/img/success/go.png")}
							alt=""
						/>
						<span className="title">电子发票</span>
					</div>
					<img
						src={require("../../../assets/img/success/more.png")}
						alt=""
					/>
				</div>
				<div className="inv-success-content">
					<img
						src={require("../../../assets/img/success/chenggong.png")}
						alt=""
					/>

					<p>发票开具成功</p>

					<div className="inv-suc-case">
						{
							this.state.pdf?(this.state.display?(<a onClick={this.onAbled} disabled={this.state.abled} href={this.state.pdf} download={this.state.fileName}>点击下载发票</a>):<Button type="link" onClick={this.onAbleds} >点击下载发票</Button>):null
						}
						{
							this.state.id&&this.state.display?(<Button type="primary" style={{marginTop:"10pt"}} onClick={this.addCard}>加入卡包</Button>):null
						}
						<Button type="default" style={{marginTop:"10pt"}} onClick={this.close} >关闭页面</Button>
					</div>
				</div>
			</div>
			</Spin>
		);
	}
}

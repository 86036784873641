import React, { Component } from 'react'
import './invoice.less'
export default class invoice extends Component {
	
	render() {
		return (
			<div className="inovice-page">
				<div className="inovice-content1">
					<img src={require('../../../assets/img/inovice/banner1.png')} alt=""/>
				</div>
				<div className="inovice-content2">
				   <div className="center">
					<div className='left'>
						<h1>票汇云发票</h1>
						<div>
							<img src={require('../../../assets/img/inovice/banner2-01.png')} alt=""/><p>• 可帮助企业完成开具、交付、查验、报销、入账等全部流程、有效降低企业的经营成本</p>
						</div>
						<div>
							<img src={require('../../../assets/img/inovice/banner2-02.png')} alt=""/><p>• 个人可以通过票慧云网页端或公众号完成 发票接收、查验、下载、归集、保存发票， 方便个人的发票管理</p>
						</div>
					</div>
					<div className='right'>
						<img src={require('../../../assets/img/inovice/banner2-03.png')} alt=""/>
					</div>
				   </div>
                  
				</div>
				<div className="inovice-content3">
					<div className="center">
					<div className='left'>
					  <div className="form">
						  <h1>增值税发票查验</h1>
						  <p>发票代码<span></span></p>
						  <p>发票号码<span></span></p>
						  <p>开盘日期<span></span></p>
						  <p>开票金额<span></span></p>
						  <p>验证码<span></span></p>
						  <button>查询</button>
					  </div>
				   </div>
				   <div className='right'>
						<p>企业接入模式</p>
					    <ul>
							<li>
								<h1>
								1、租赁服务器+票慧云平台 。
								</h1>
								<p>企业通过租赁开票服务器等硬件设备，接入票慧云 平台来开具发票。</p>
							</li>
							<li>
								<h1>
								2、购买服务器+票慧云平台 。
								</h1>
								<p>企业通过购买开票服务器等硬件设备，接入票慧云 平台来开具发票。</p>
							</li>
							<li>
								<h1>
								3、购买服务器+自建平台 。
								</h1>
								<p>企业通过购买开票服务器等硬件设备，搭建一个云平 台来开具发票。</p>
							</li>
						</ul>		 
                    </div>
					</div>	
				</div>
				<div className="inovice-content4">
					<div className="center">
						<h1>企业接入流程</h1>
						<ul>
							<li>
								<div>
									<img src={require('../../../assets/img/inovice/banner4-01.png')} alt=""/>
								</div>
								<p>
									资格申请
								</p>
								<p>准备相关资料，向当地税局申请电子发票授权。</p>
							</li>
							<li>
								<div>
									<img src={require('../../../assets/img/inovice/banner4-02.png')} alt=""/>
								</div>
								<p>
								  系统对接
								</p>
								<p>消费者开起发票申请后，商户将订单信息等传给税控设备生成电票数据，由票慧平台生成电子发票凭证文件。</p>
							</li>
							<li>
								<div>
									<img src={require('../../../assets/img/inovice/banner4-03.png')} alt=""/>
								</div>
								<p>
								  开具发票
								</p>
								<p>消费者开起发票申请后，商户将订单信息等传给税控设备生成电票数据，由票慧平台生成电子发票凭证文件。</p>
							</li>
							<li>
								<div>
									<img src={require('../../../assets/img/inovice/banner4-04.png')} alt=""/>
								</div>
								<p>
								 资格申请
								</p>
								<p>消费者开起发票申请后，商户将订单信息等传给税控设备生成电票数据，由票慧平台生成电子发票凭证文件。</p>
							</li>
						</ul>
						<button>
							立即咨询
						</button>
					</div>
				</div>
				<div className="inovice-content5">
					<div className="center">
						<h1>企业用户优势</h1>
						<div className="cricle">
							<div className='cricle-center'>
								<img src={require('../../../assets/img/inovice/banner5-08.png')} alt=""/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

import React, { Component } from "react"
import { Button, Divider, Modal, message, Spin, Table, Radio, Input, DatePicker } from 'antd';
// import ControlPanel from "../../../components/controlPanel/controlPanel"
import { authToken, queryCheck, confirmCheck } from '../../../api'
import "./longRange.less"
import store from "../../../store"
import action from "../../../actions/action"
import moment from "moment";

const { RangePicker } = DatePicker;

export default class LongRange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			visibleLogin: false,
			loading: false,
			kpzdbs: '',
			nsrsbh: "",
			tableList: [],
			pages: 0,
			userObj: {
				fpdm: "",
				fphm: "",
				rq_q: '',
				rq_z: '',
				xfsh: "",
				gxzt: 0,
				nsrsbh: "",
			},
			user: {
				nsrsbh: "",
				Passwor: '',
				pin: '',
			},
			paramObj: {},
			num: 0,
			je: 0,
			se: 0,
			selectedRowKeys: [],
		}
	}

	componentDidMount() {
		let nsrsbh = store.getState().web.nsrsbh
		if (!nsrsbh) {
			this.setState({ visibleLogin: true })
		} else {
			this.setState({ nsrsbh, user: { ...this.state.user, nsrsbh }, userObj: { ...this.state.userObj, nsrsbh } })
		}
	}

	handleOk = () => {
		let param = this.state.paramObj
		this.setState({ loading: true })
		confirmCheck(param).then(res => {
			if (res.code == "80009" || res.code == "40303" || res.code == "40403" || res.code == "40301" || res.code == "40409" || (res.msg).includes("会话已失效")) {
				this.setState({ visibleLogin: true, loading: false })
				return message.error(res.msg)
			}
			try {
				if (res.resultObj.statusCode == "200") {
					this.click()
				}
			} catch {
				console.log('数据错误')
			}
			this.setState({ loading: false })
		})
		this.setState({ visible: false });
	};

	handleCancel = e => {
		this.setState({
			visible: false,
			visibleLogin: false
		});
	};

	submit = () => {
		this.setState({
			visible: true,
		});
	}

	// getInfo =(data)=>{
	// 	let {nsrsbh} = data
	// 	this.setState({user:{...this.state.user,nsrsbh},userObj:{...this.state.userObj,nsrsbh}})
	// }

	//查询
	click = () => {
		let param = this.state.userObj
		if (!param.nsrsbh) {
			this.setState({ visibleLogin: true, loading: false })
			return message.error("纳税人识别号不能为空！")
		}
		if (!param.rq_q || !param.rq_z) {
			return message.error("开票起止日期不能为空！")
		}
		this.setState({ loading: true })
		queryCheck(param).then(res => {
			if (res.code == "80009" || res.code == "40303" || res.code == "40403" || res.code == "40301" || res.code == "40409" || (res.msg).includes("会话已失效")) {
				this.setState({ visibleLogin: true, loading: false })
				return message.error(res.msg)
			}
			if (res.code == "0000") {
				try {
					message.success(res.resultObj.message)
					let tableList = res.resultObj.data
					if (Array.isArray(tableList)) {
						tableList.forEach((item, index) => {
							item.key = index + 1
						})
					}
					this.setState({ tableList, nsrsbh: param.nsrsbh })
					store.dispatch(action('nsrsbh', param.nsrsbh))
				} catch {
					console.log("数据错误")
				}
			} else {
				message.error(res.msg)
			}
			this.setState({ loading: false })
		})
	}

	clickLogin = () => {
		let param = this.state.user
		if (!param.nsrsbh) {
			return message.error('纳税人识别号不能为空！')
		}
		if (!param.pin) {
			return message.error('盘的证书口令不能为空！')
		}
		this.setState({ loading: true })
		authToken(param).then(res => {
			if (res.code == "0000") {
				try {
					if (res.resultObj.statusCode == "200") {
						message.success(res.resultObj.message)
						this.setState({ visibleLogin: false, nsrsbh: param.nsrsbh }, () => {
							store.dispatch(action('nsrsbh', param.nsrsbh))
						})
					} else {
						message.error(res.resultObj.message)
					}
				} catch {
					console.log('数据错误')
				}
			} else {
				message.error(res.msg)
			}
			this.setState({ loading: false })
		})
	}

	rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			let nsrsbh = this.state.nsrsbh
			// console.log(selectedRowKeys,selectedRows)
			let fpdm = ""
			let fphm = ""
			let kprq = ""
			let yxse = ""

			let num = selectedRows.length
			let je = 0
			let se = 0
			selectedRows.forEach((item, index) => {
				if (index == 0) {
					fpdm += (item.fpdm)
					fphm += (item.fphm)
					kprq += (item.kprq)
					yxse += (item.se)
				} else {
					fpdm += ("=" + item.fpdm)
					fphm += ("=" + item.fphm)
					kprq += ("=" + item.kprq)
					yxse += ("=" + item.se)
				}
				je += Number(item.je)
				se += Number(item.se)
			})
			this.setState({
				paramObj: { ...this.state.paramObj, nsrsbh, fpdm, fphm, kprq, yxse },
				num,
				je: je.toFixed(2),
				se: se.toFixed(2),
				selectedRowKeys
			})
		},
	};

	//分页管理
	onChangePage = page => {
		console.log('分页', page)
	}

	ycChange = (val, key) => {
		if (key == "gxzt") {
			this.state.userObj.rq_q = ''
			this.state.userObj.rq_z = ''
		}
		let obj = {}
		obj[key] = val
		this.setState({ userObj: { ...this.state.userObj, ...obj } })
		if (key == "nsrsbh") {
			this.setState({ user: { ...this.state.user, ...obj } })
		}

	}

	userChange = (val, key) => {
		let obj = {}
		obj[key] = val
		this.setState({ user: { ...this.state.user, ...obj } })
		if (key == "nsrsbh") {
			this.setState({ userObj: { ...this.state.userObj, ...obj } })
		}
	}

	onChangeTime = (value, dateString) => {
		let [rq_q, rq_z] = dateString
		this.setState({ userObj: { ...this.state.userObj, rq_q, rq_z } })
	}
	disabledDate = (current) => {
		return current && current > moment().endOf("day");
	}
	disabledDate2 = (current) => {
		return current && current > moment().subtract(1, "M").endOf("month");
	}

	downLoad = () => {
		let aElement = document.createElement('a')
		aElement.style.display = 'none'
		aElement.href = "https://invoicefileservice.bjzsxx.com/invoice/20200914.zip"
		aElement.setAttribute('download', ``)
		document.body.appendChild(aElement)
		aElement.click()
		document.body.removeChild(aElement);
	}

	render() {
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '发票代码',
				dataIndex: 'fpdm',
				key: 'fpdm',
			},
			{
				title: '发票号码',
				dataIndex: 'fphm',
				key: 'fphm',

			},
			{
				title: '开票日期',
				dataIndex: 'kprq',
				key: 'kprq',
			},
			{
				title: '销方名称',
				dataIndex: 'xfmc',
				key: 'xfmc',
			},
			{
				title: '金额',
				dataIndex: 'je',
				key: 'je',
			},
			{
				title: '税额',
				dataIndex: 'se',
				key: 'se',
			},
			{
				title: '有效税额',
				dataIndex: 'yxse',
				key: 'yxse',
				render: (text, record) => <Input autoComplete="off" value={record.se} />
			},
			{
				title: '发票状态',
				dataIndex: 'fpzt',
				key: 'fpzt',
				render: (text) => {
					if (text == "0") {
						return '正常'
					} else if (text == "2") {
						return "已作废"
					} else if (text == "3") {
						return "已红冲"
					}
				}
			},
			{
				title: '发票类型',
				dataIndex: 'fplx',
				key: 'fplx',
				render: (text) => {
					if (text == "01") {
						return '增值税专用发票'
					}
				}
			},

			{
				title: '是否勾选',
				dataIndex: 'isCheck',
				render: (text) => text == 0 ? "未勾选" : "已勾选"
			},

		];

		const options = [
			{ label: '未勾选', value: 0 },
			{ label: '已勾选', value: 1 },
		];

		const colors = { color: "red" }
		const modal_label = { width: 120, textAlignLast: "justify" }
		const modal_style = { display: "flex", justifyContent: "start", alignItems: "center", marginBottom: 10 }
		const modal_span = { color: 'red', verticalAlign: 'middle' }

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div>
					<h2 style={{ color: "#666", fontWeight: 700, letterSpacing: "1px", }} >{sessionStorage.getItem("pageTitle")||"远程勾选"}</h2>
				</div>
				<Divider />
				<div className="longRange_box">
					<section className="yc_fpgx_box">
						<div><label htmlFor="yc_gxzt">勾选状态</label>：&nbsp;&nbsp;<Radio.Group id="yc_gxzt" options={options} onChange={(e) => this.ycChange(e.target.value, "gxzt")} value={this.state.userObj.gxzt} /></div>
						<ul className="setLabel">
							<li>
								<label htmlFor="yc_fpdm">发票代码</label>：&nbsp;&nbsp;<Input autoComplete="off" id="yc_fpdm" onChange={(e) => this.ycChange(e.target.value, "fpdm")} value={this.state.userObj.fpdm} />
							</li>
							<li>
								<label htmlFor="yc_fphm">发票号码</label>：&nbsp;&nbsp;<Input autoComplete="off" id="yc_fphm" onChange={(e) => this.ycChange(e.target.value, "fphm")} value={this.state.userObj.fphm} />
							</li>
							{
								this.state.userObj.gxzt == 1 ? (<li><label>勾选日期</label>：&nbsp;&nbsp;<RangePicker id="yc_kprq" format="YYYY-MM-DD" defaultPickerValue={moment().subtract(1, "M")} disabledDate={this.disabledDate} placeholder={['开始时间', '结束时间']} onChange={this.onChangeTime} /></li>) : null
							}
							{
								this.state.userObj.gxzt == 1 ? null : (<li><label>开票日期</label>：&nbsp;&nbsp;<RangePicker id="yc_kprq2" format="YYYY-MM-DD" defaultPickerValue={moment().subtract(1, "M")} disabledDate={this.disabledDate2} placeholder={['开始时间', '结束时间']} onChange={this.onChangeTime} /></li>)
							}

							<li>
								<Button type="primary" onClick={this.click} >查询</Button>
								<Button type="primary" onClick={this.submit} >提交</Button>
								<Button type="primary" onClick={this.downLoad} >下载文件</Button>
							</li>
						</ul>

					</section>
					<Table columns={columns} dataSource={this.state.tableList} rowSelection={this.rowSelection} pagination={{ onChange: this.onChangePage, total: this.state.pages }} />
					{
						this.state.visible ? (
							<Modal title="勾选认证信息" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} >
								<h3>本次勾选的发票汇总如下:</h3>
								<p style={{ textIndent: "2em" }}>
									本次勾选：<span style={colors}>{this.state.num}</span>份，
								金额合计：<span style={colors}>{this.state.je}</span>元，
								税额合计：<span style={colors}>{this.state.se}</span>元，
								有效税额合计：<span style={colors}>{this.state.se}</span>元
							</p>
								<h4>请确认是否提交?</h4>
							</Modal>) : null
					}
					{
						this.state.visibleLogin ? (
							<Modal title="登录认证" visible={this.state.visibleLogin} onOk={this.clickLogin} onCancel={this.handleCancel} >
								<div style={modal_style}>
									<label htmlFor="yc_nsrsbh1" style={modal_label}><span style={modal_span}>*</span>纳税人识别号</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_nsrsbh1" onChange={(e) => this.userChange(e.target.value, "nsrsbh")} value={this.state.user.nsrsbh} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_name1" style={modal_label}><span style={modal_span}>*</span>盘的证书口令</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_name1" onChange={(e) => this.userChange(e.target.value, "pin")} value={this.state.user.pin} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_psd1" style={modal_label}>二级密码</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_psd1" onChange={(e) => this.userChange(e.target.value, "Passwor")} value={this.state.user.Passwor} />
								</div>
							</Modal>) : null
					}

				</div>

			</Spin>
		)
	}
}
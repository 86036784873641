import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./restaurant.less";

import imgBan from "../../../../assets/img/solution/banner4.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/42.png";
import i2 from "../../../../assets/img/solution/43.png";
import i3 from "../../../../assets/img/solution/20.png";
import i4 from "../../../../assets/img/solution/12.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";

import i21 from "../../../../assets/img/solution/1.png";
import i22 from "../../../../assets/img/solution/44.png";
import i23 from "../../../../assets/img/solution/33.png";
import i24 from "../../../../assets/img/solution/45.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";
import bg4 from "../../../../assets/img/solution/bg7.png";





export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer:{
				title: "我想要咨询此行业",
				phone:'400-9958-578'
			},
			active:[true,false,false,false]
			
		};
	}
	
	clickActive=(key)=>{
		let list  = this.state.active
		for(let i in list){
			if(i==key){
				list[i] = true
			}else{
				list[i] = false
			}
		}
		this.setState({active:[...list]})
		let str = 'active'+key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};
		const bgImg4 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg4 + ")", //图片的路径
		};
		

		return (
			<div className="solution-content solution-content-4">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0]?'active':''} onClick={this.clickActive.bind(this,0)}>行业趋势</li>
						<li className={this.state.active[1]?'active':''} onClick={this.clickActive.bind(this,1)}>行业痛点</li>
						<li className={this.state.active[2]?'active':''} onClick={this.clickActive.bind(this,2)}>解决方案</li>
						<li className={this.state.active[3]?'active':''} onClick={this.clickActive.bind(this,3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>政策改革推动</span>
							<span>个税制度的推进导致消费者索票意识会越来越强，开票需求量越来越大</span>
						</li>
						<li  style={bgImg2}>
							<span>数据化趋势</span>
							<span>线上交易的普及带来发票线上数据化</span>
						</li>
						<li  style={bgImg3}>
							<span>电子化趋势</span>
							<span>推行电子化在线办理业务，对电子化票据凭证提出更高要求</span>
						</li>
						<li  style={bgImg4}>
							<span>新传播渠道</span>
							<span>商户通过线上营销增加消费粘性，电子发票成为新的渠道</span>
						</li>
						
					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>时间长</span>
							<span>排队领票，浪费大量时间</span>
						</li>
						<li>
							<span>设备成本高</span>
							<span>需配备专业打印设备及耗材，增加企业成本</span>
						</li>
						<li>
							<span>出错率高</span>
							<span>传统手工录入开票速度慢、抬头信息出错率高</span>
						</li>
						<li>
							<span>推广受阻</span>
							<span>传统发票管理无法对消费者信息进行有效管理，导致后续营销推广受阻</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>消者在线上办国业后自接开发,手机号起作为接量课道可进行一记录、归档、查询</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>线上订餐</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i2} />
								<span>门店消费</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						<div className="ul_div">
							<li>
								<img alt="" src={i3} />
								<span>订单系统录入发票</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i4} />
								<span>商家打印二维码小票</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i6} />
							<span>发送发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>低成本高收益</span>
								<img alt="" src={i21} />
							</div>
							<p>降低发票申领、开具、应用、存储等诸多环节的成本</p>
						</li>
						<li>
							<div>
								<span>提高效率</span>
								<img alt="" src={i22} />
							</div>
							<p>满足餐饮行业开票需求，客户全程自助开票，无需排队等待</p>
						</li>
						<li>
							<div>
								<span>冲红便捷</span>
								<img alt="" src={i23} />
							</div>
							<p>发票冲红便捷，无需退回错票</p>
						</li>
						<li>
							<div>
								<span>多种开票方式</span>
								<img alt="" src={i24} />
							</div>
							<p>公众号/短信/邮箱接收方式</p>
						</li>
					</ul>
				</section>
				
				

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

import React, {Component} from "react"
import {Divider, message, PageHeader, Spin, Table} from 'antd';
import {
  createNegativeInvoice,
  errorEnterpriseMonitor,
  invoiceReapet,
  invoiceVoid,
  pushDzfp,
  redInfoPaperApply,
  redInfoPaperQuery
} from '../../../api'
import moment from 'moment'
import utils from "../../../utils/index"
import './errorInvoice.less'


let treeData = []

export default class WritingState extends Component {
    constructor(props) {
        super(props);
        this.menberManager = React.createRef()
        this.state = {
            switchDetails: false,
            invoiceVoids: false,
            tableList: [],
            getDataInfo: {
                fpdm: '',
                fphm: '',
                jqbh: '',
                minAmount: '',
                maxAmount: '',
                startTime: '',
                endTime: '',
                biller: '',
                buyerName: '',
                invoiceType: '',
                slv: '',
                orderNo: '',
                nsrsbh: '',
                status: '',
                page: 1,
                rows: 10
            },
            tableList2: [],
            total: 1,
            xq: {},
            showInfo: false,
            detailInfo: '',
            loading: false,
            openKey: false,
            value: [],
            showEmail: false,
            paramPul: {},
            showStatus: false,
            paramStatus: {},
            statusList: [
                { id: 0, name: "已抵扣" },
                { id: 1, name: "未抵扣" },
                { id: 2, name: "销方申请" },
            ],
            openRepair: false,
        }
    }

    componentDidMount() {
        this.getData()
        let lists = JSON.parse(sessionStorage.getItem('switchDetails')) || [];
        let switchDetails, invoiceVoids;
        try {
            switchDetails = lists.includes("createNegaticeInvoice")
        } catch {
            switchDetails = false
        }
        try {
            invoiceVoids = lists.includes("copyAndReport/invoiceVoid")
        } catch {
            invoiceVoids = false
        }
        this.setState({ switchDetails, invoiceVoids })
    }

    getData() {
        const { getDataInfo: param } = this.state
        this.setState({ loading: true })
      errorEnterpriseMonitor(param).then(res => {
            if (res.code === '0000') {
                const { resultList: tableList } = res
                tableList.forEach((element, index) => {
                    element.key = index + 1
                });

                const { currentPage: page, pageSize: rows, totalCount: total } = res.pager
                this.setState({
                    tableList,
                    getDataInfo: { ...this.state.getDataInfo, page, rows },
                    total
                })
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    //推送
    pushInvoice = data => {
        let { fpdm, fphm, gfkhyx: email } = data
        let paramPul = { fpdm, fphm, email }
        this.setState({ showEmail: true, paramPul })
    }


    //导出
    exports = () => {
        let { startTime, endTime, invoiceType, fpdm, fphm, buyerName, nsrsbh, orderNo } = this.state.getDataInfo

        if (!startTime) {
            return message.error('开票起始日期,不能为空')
        }
        if (!endTime) {
            return message.error('开票结束时间,不能为空')
        }

        // exportSuccessInvoices({startTime, endTime, invoiceType,fpdm,fphm,buyerName,nsrsbh,orderNo}).then(res=>{
        //     console.log(res)
        //         utils.downLoad(res,"票据列表")
        // })
        // return

        let token = sessionStorage.getItem("token")
        let urlLocation = window.location.href
        let baseUrl = ''

        if (urlLocation.includes(process.env.REACT_APP_SEARCH)) {
            baseUrl = process.env.REACT_APP_KPBASE_PRO
        } else {
            baseUrl = process.env.REACT_APP_KPBASE
        }

        let url = baseUrl + `/simpleInvoice/exportSuccessInvoices?startTime=${startTime}&endTime=${endTime}&invoiceType=${invoiceType}&token=${token}&fpdm=${fpdm}&fphm=${fphm}&buyerName=${buyerName}&nsrsbh=${nsrsbh}&orderNo=${orderNo}`;
        utils.aDownLoad(url)
    }


    getInfo = data => {
        this.setState({ showInfo: true, detailInfo: data })
    }

    getBussType(type){
      switch (type) {
        case 0:
          return '新时代'
        case 1:
          return '诺诺'
        case 2:
          return '票通'
        default:
          return '-'
      }
    }

    filterInvoiceType(val) {
        switch (val) {
            case '004':
                return '增值税专用发票'
            case '007':
                return '增值税普通发票'
            case '025':
                return '卷式发票'
            case '026':
                return '增值税电子发票'
            case '028':
                return '电子专用发票'
            default:
                return '-'
        }
    }

    filterKplx(val) {
        switch (val) {
            case 1:
                return '待填写抬头'
            case 2:
                return '开票完成'
            case 3:
                return '开具错误'
            case 4:
                return '正数作废'
            case 5:
                return '负数发票'
            case 6:
                return '负数作废'
            case 7:
                return '空白作废'
            case 8:
                return '开票前准备'
            case 9:
                return '门店审核'
            case 10:
                return '驳回'
            case 11:
                return '商家作废二维码'
            case 20:
                return '开票中'
            case 22:
                return (
                    <span>
                        开票失败
                    </span>)
            default:
                return '-'
        }
    }

    filterTime(val) {
        if (!val) return
        return moment(typeof val === 'string' ? Number(val) : val, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
    }

    invoiceToVoid = data => {
        const { kpzdbs, invoiceType: fplxdm, fpdm, fphm } = data
        const param = { kpzdbs, fplxdm, zflx: 1, fpdm, fphm }
        this.setState({ loading: true })
        invoiceVoid(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    invoiceToReapet = data => {
        const { kpzdbs, invoiceType: fplxdm, fpdm, fphm } = data
        const param = { kpzdbs, fplxdm, zflx: 1, fpdm, fphm }
        this.setState({ loading: true })
        invoiceReapet(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    goRed = (data) => {
        this.setState({ loading: true })
        createNegativeInvoice(data).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
                this.getData()
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    paginationChange = val => {
        this.setState({
            getDataInfo: { ...this.state.getDataInfo, page: val.current, rows: val.pageSize },
            total: val.total
        }, () => this.getData())
    }

    search() {
        this.getData()
    }

    getDate = (val, key) => {
        if (!val) return
        if (key === 1) {
            return moment(val).format("YYYYMMDD000000");
        } else {
            return moment(val).format("YYYYMMDD235959");
        }
    }

    getSearchVal = val => {
        if (val.startTime) {
            val.startTime = this.getDate(val.startTime, 1)
        }
        if (val.endTime) {
            val.endTime = this.getDate(val.endTime, 2)
        }
        this.setState({ getDataInfo: { ...this.state.getDataInfo, ...val, page: 1, } }, () => this.getData())
    }

    modalDatas = (val) => {
        let { startTime, endTime, invoiceType } = val
        if (startTime) {
            startTime = this.getDate(startTime, 1)
        }
        if (endTime) {
            endTime = this.getDate(endTime, 2)
        }
        this.state.getDataInfo.startTime = startTime
        this.state.getDataInfo.endTime = endTime
        this.state.getDataInfo.invoiceType = invoiceType
    }

    onChange = value => {
        this.setState({ value });
    };

    onOk = () => {
        this.setState({ openKey: false })
    }

    changeEmail = (email) => {
        this.setState({
            paramPul: { ...this.state.paramPul, email }
        })
    }
    submitEmial = () => {
        let param = this.state.paramPul
        this.setState({ loading: true })
        pushDzfp(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    redCodes = (data) => {
        let { fpdm, fphm } = data
        let paramStatus = { fpdm, fphm }
        this.setState({ showStatus: true, paramStatus })
    }
    selectStatus = (applyReason) => {
        // console.log("123",applyReason)
        this.setState({
            paramStatus: { ...this.state.paramStatus, applyReason }
        })
    }
    statusOk = () => {
        let param = this.state.paramStatus
        redInfoPaperApply(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    getCodes = (data) => {
        let { fpdm, fphm } = data
        redInfoPaperQuery({ fpdm, fphm }).then(res => {
            if (res.code == "0000") {
                let { redInfoPaperNo, status } = res.resultObj
                if (status == 0) {
                    message.info("未处理")
                } else if (status == 1) {
                    message.info("已提交")
                } else if (status == 2) {
                    message.info("审核通过")
                    data.tzdbh = redInfoPaperNo
                    this.setState({})
                }
                else if (status == 3) {
                    message.info("审核不通过")
                }
            } else {
                message.error(res.msg)
            }
        })
    }
    render() {

        let columns = [
            {
                title: '序号',
                dataIndex: "key",
                key: 'key',
                width: 60,
            },
            {
                title: '企业名称',
                dataIndex: 'nsrmc',
                key: 'nsrmc',
                width: 200,
                render:(_,record)=>(
                  <a onClick={() => this.props.history.push({
                    pathname: `/system/errorInvoiceDetail`,
                    state: {
                      registeredNo: record.registeredNo,
                      nsrmc: record.nsrmc,
                      bussType: this.getBussType(record.businessType)
                    }
                  })}>{record.nsrmc}</a>
                )
            },
            {
                title: '纳税人识别号',
                dataIndex: 'registeredNo',
                key: 'registeredNo',
                width: 230
            },
            {
                title: '开票平台',
                dataIndex: 'businessType',
                key: 'businessType',
                width: 150,
                render:(_,record)=>(
                  <span>{this.getBussType(record.businessType)}</span>
                )
            },
          {
            title: '开票异常数量',
            dataIndex: 'errorCount',
            key: 'errorCount',
            width: 150
          }
        ];


        const { page: current, rows: pageSize } = this.state.getDataInfo

        const pagination = { current, pageSize, total: this.state.total }

        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className="menberManager" ref={this.menberManager}>
                    <PageHeader title={'异常开票监控' } />
                    <Divider />
                    <div>
                        <Table columns={columns} dataSource={this.state.tableList}  pagination={pagination} onChange={this.paginationChange} />
                    </div>
                </div>
            </Spin>
        )
    }
}

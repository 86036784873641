import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./express.less";

import imgBan from "../../../../assets/img/solution/banner9.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/16.png";
import i2 from "../../../../assets/img/solution/43.png";
import i3 from "../../../../assets/img/solution/20.png";
import i4 from "../../../../assets/img/solution/18.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";
import i7 from "../../../../assets/img/solution/13.png";

import i21 from "../../../../assets/img/solution/29.png";
import i22 from "../../../../assets/img/solution/30.png";
import i23 from "../../../../assets/img/solution/36.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";




export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer: {
				title: "我想要咨询此行业",
				phone: '400-9958-578'
			},
			active: [true, false, false, false]

		};
	}

	clickActive = (key) => {
		let list = this.state.active
		for (let i in list) {
			if (i == key) {
				list[i] = true
			} else {
				list[i] = false
			}
		}
		this.setState({ active: [...list] })
		let str = 'active' + key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};



		return (
			<div className="solution-content solution-content-9">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0] ? 'active' : ''} onClick={this.clickActive.bind(this, 0)}>行业趋势</li>
						<li className={this.state.active[1] ? 'active' : ''} onClick={this.clickActive.bind(this, 1)}>行业痛点</li>
						<li className={this.state.active[2] ? 'active' : ''} onClick={this.clickActive.bind(this, 2)}>解决方案</li>
						<li className={this.state.active[3] ? 'active' : ''} onClick={this.clickActive.bind(this, 3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>新技术驱动</span>
							<span>无人机、AI等高科技运作，跨级提升物流工作效率带动发票生态链提升</span>
						</li>
						<li style={bgImg2}>
							<span>新物流基础建设</span>
							<span>政策推动新物流基础设施建设，电子发票开具为企业间协同打好基础</span>
						</li>
						<li style={bgImg3}>
							<span>企业资源整合</span>
							<span>企业上下游企业之间沟通协作，发票管理需整合</span>
						</li>

					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>效率低</span>
							<span>开票效率低，存在手撕票、定额发票不规范的管理问题</span>
						</li>
						<li>
							<span>用量大</span>
							<span>纸质发票量使用大，综合成本高</span>
						</li>
						<li>
							<span>成本高</span>
							<span>发票领用、存放、打印、邮递、遗失成本高</span>
						</li>
						<li>
							<span>时效慢</span>
							<span>仓储运送和财务分离，同时发出运单、货、票增加运送负担，时效无法保证</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户通过酒app公众号定看店时，播开离为电子发，增写相关开需信息后交订单，等到两店时，可直提进入酒店pp信公众号自助取电子发</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>线上下单</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i2} />
								<span>线下结账</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<div className="ul_div">
							<li>
								<img alt="" src={i3} />
								<span>酒店订单系统</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i7} />
								<span>扫描二维码录入</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<li>
							<img alt="" src={i4} />
							<span>接口服务</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i6} />
							<span>发送发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>系统集成</span>
								<img alt="" src={i21} />
							</div>
							<p>快递物流公司业务系统与开票系统集成，确保开票数据与业务系统数据一致，提高开票效率，降低涉税风险</p>
						</li>
						<li>
							<div>
								<span>管控开票</span>
								<img alt="" src={i22} />
							</div>
							<p>解决快递物流公司销项开票数据无法集中管控问题，同时提升开票能力</p>
						</li>
						<li>
							<div>
								<span>数据集中</span>
								<img alt="" src={i23} />
							</div>
							<p>解决快递物流公司销项数据集中，解决进项集中获票查验、认证能力提升问题，解决供应链协同获票能力提升</p>
						</li>

					</ul>
				</section>



				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

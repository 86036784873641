import React, { Component } from "react";
import "./aboutMe.less";
import Partners from "../../../components/partners/partners";

import bgImg1 from "../../../assets/img/aboutMe/banner2.png";
import icon1 from "../../../assets/img/aboutMe/icon.png";


export default class success extends Component {
	render() {
		let bg1 = {
				height: "900px",
				backgroundSize:"100% 900px",
				backgroundRepeat: "no-repeat",
				backgroundImage: "url(" + bgImg1 + ")", //图片的路径
		}
		return (
			<div className="aboutMe-page">
				<div className="aboutMe-content1">
					<img
						src={require("../../../assets/img/aboutMe/banner.png")}
						alt=""
					/>
				</div>
				<div className="aboutMe-content2" style={bg1}>
					<h3>公司介绍</h3>
					<div className="content2-cont">
						<ul className="aboutMe-ul">
							<li>
								<span>2016</span>
								<span>营改增全面推行，成立研究涉税服务体系开发和建立</span>
							<img src={icon1} />

							</li>
							<li>
								<span>2017</span>
								<span>互联网+税务发展自主研发TCIS系统平台，为中小企业提供涉税移动服务</span>
							<img src={icon1} />

							</li>
							<li>
								<span>2018</span>
								<span>票慧云服务平台上线，为各行业提供开票解决方案</span>
							<img src={icon1} />

							</li>
							<li>
								<span>2019</span>
								<span>电子发票平台上线，为各行业提供开票解决方案</span>
							<img src={icon1} />

							</li>
							<li>
								<span>2020</span>
								<span>中税创新产品战略升级，推出财税票发展生态链，进一步完善产品和服务</span>
							<img src={icon1} />

							</li>
						</ul>
						<div className="aboutMe-p">
							<p>
							深圳市中税创新科技有限公司（简称：中税创新）成立于2016年，专注于企业财税票智能云领域行业解决方案的研发及应用，平台以互联网数字化的方式实现“票”全周期管理，应用覆盖餐饮、酒店、旅游、公共事业、物业、出行等多个行业，对接用户系统百余家，客户遍布全国各地。
							</p>
							<p>
							随着业务扩展，为了更好地打造电子发票生态，构建更加开放、灵活、多元化的平台服务。中税创新以此为切入点升级平台，衍生出财税电子发票平台、电子合同、供应链金融助贷平台、灵活用工众包平台解决方案提供商，4大领域的发票全流程产品和服务，通过发票全流程生态服务，助力企业优化繁琐流程，向社会各行业输出更优质的产品和服务。
							</p>
						</div>
					</div>
				</div>
				<div className="aboutMe-content3">
					<h3>服务体系</h3>
					<span>智能财税平台</span>
					<ul className="box-ul-1">
						<li>供应链金融助贷平台</li>
						<li>电子发票平台</li>
						<li>电子合同</li>
						<li>供应链协同平台</li>
						<li>灵活用工众包平台</li>
					</ul>
				</div>
				<div className="aboutMe-content4">
					<h3>服务客户</h3>
					<ul className="box-ul-2">
						<li>互联网</li>
						<li>金融</li>
						<li>零售</li>
						<li>餐饮</li>
						<li>物流</li>
						<li>制作业</li>
						<li>物业</li>
						<li>连锁店</li>
						<li>电中小微企业</li>
					</ul>
				</div>
				
				<Partners />
			</div>
		);
	}
}

import React, { Component } from "react"
import { Menu, Icon } from 'antd'
import './slideNav.less'
import { Link } from 'react-router-dom'
import menuList from "./slideNavData"
import emitter from '../../services/event'
import { menuGet,menuPower } from '../../api'

const { SubMenu } = Menu


export default class SlideNav extends Component {
	// {
	// 	title: '首页', // 菜单标题名称
	// 	key: '/home', // 对应的path
	// 	icon: 'home', // 图标名称
	// 	children: [], // 可能有, 也可能没有
	//   }
	//遍历加递归
	constructor(props) {
		super(props);
		this.state = {
			menuData: '',
			tree: [],
		};
	}
	getParam = (value) => {
		emitter.emit('pathValue', value);
	}

	componentDidMount() {
		this.startData()
	}
	
	startData = () => {
		let switchOpen = []
		let switchlist = []
		let switchDetails = []

		let list1 = ['新增','编辑','删除','查看']
		let list2 = ['增值税专用发票','增值税普通发票','增值税电子发票','电子专用发票','卷式发票']
		let list3 = ['红冲','查询','作废']


		// menuGet().then(res => {
		// 	if (res.code === "0000") {
		// 		this.setState({
		// 			tree: this.getData(res.resultObj)
		// 		})
		// 		console.log('all 权限',res.resultObj)
				
		// 	}
		// }) 
		// return

		// 权限管理-列表功能
		menuPower().then(res => {
			if (res.code === "0000") {
				this.setState({
					tree: this.getData(res.resultObj)
				})

				if(res.resultList.length>0){
					res.resultList.forEach(item=>{
						if(list1.includes(item.resourcesName)){
							switchOpen.push(item.resourcesUrl)
						}
						if(list2.includes(item.resourcesName)){
							switchlist.push(item.resourcesUrl.split('/').pop())
						}
						if(list3.includes(item.resourcesName)){
							switchDetails.push(item.resourcesUrl)
						}

					})
				}
				//企业管理
				switchOpen = [...new Set(switchOpen)]
				let str1 = JSON.stringify(switchOpen)
				sessionStorage.setItem('quanXian',str1)
				
				//发票类型
				switchlist = [...new Set(switchlist)]
				let str2 = JSON.stringify(switchlist)
				sessionStorage.setItem('faPiaoQx',str2)

				//发票查询操作
				switchDetails = [...new Set(switchDetails)]
				let str3 = JSON.stringify(switchDetails)
				sessionStorage.setItem('switchDetails',str3)
			}
		})
	}



	getData = (data) => {
		return data.reduce((pre, item) => {
			const str = item.data.resourcesUrl.split('/')
			if (item.children) {
				pre.push({
					title: item.data.resourcesName,
					key: item.data.resourcesUrl,
					icon: item.data.imgName,
					children: this.getData(item.children),
					refs: str[str.length - 1]
				})
			} else {
				pre.push({
					title: item.data.resourcesName,
					key: item.data.resourcesUrl,
					icon: item.data.imgName,
					refs: str[str.length - 1]
				})
			}
			return pre
		}, [])
	}
	iconUrl=(data)=>{
		let url;
		try{
			url = require(`../../assets/icons/${data}`)
		}catch{
			url = require(`../../assets/icons/moren.png`)
		}
		return url
	}

	getMenuData = (menuList) => {
		return menuList.reduce((pre, item) => {
			if (!item.children) {
				pre.push((
					<Menu.Item key={item.key} >
						<Link to={item.key} onClick={() => { this.getParam(item) }}>
							<span className="mySlides-box">
								<img src={this.iconUrl(item.icon)}/>
								<Icon type="none" />
								<span className="mySlides">{item.title}</span>
							</span>
						</Link>
					</Menu.Item>
				))
			} else {
				pre.push((
					<SubMenu
						key={item.key}
						title={
							<span className="mySlides-box">
								<img src={this.iconUrl(item.icon)} />
								<Icon type="none" />
								<span className="mySlides" >{item.title}</span>
							</span>
						}
					>
						{this.getMenuData(item.children)}
					</SubMenu>
				))
			}
			return pre
		}, [])
	}

	render() {
		return (
			<div>
				<Menu 
					mode="inline"
					theme="dark"
					mode="inline"
				>
					{
						this.getMenuData(this.state.tree)
					}
				</Menu>
			</div>

		)
	}
}
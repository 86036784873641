import React, { Component } from "react";
import Footer1 from "../../../components/webfooter1/webFooter1";
import Contact from "../../../components/contact";
import "./assistance.less";

import imgBanner from "../../../assets/img/assistance/banner.png";
import imgDH from "../../../assets/img/assistance/dh.png";
import imgbg1 from "../../../assets/img/assistance/product.png";
import imgbg2 from "../../../assets/img/assistance/bg2.png";
import imgbg3 from "../../../assets/img/assistance/bg3.png";
import imgbg4 from "../../../assets/img/assistance/service.png";
import imgbg5 from "../../../assets/img/assistance/application.png";
import imgbg6 from "../../../assets/img/assistance/bg4.png";

import b1b from "../../../assets/img/assistance/1b.png";
import b2 from "../../../assets/img/assistance/2.png";
import b3 from "../../../assets/img/assistance/3.png";

import c1 from "../../../assets/img/assistance/c1.png";
import c2 from "../../../assets/img/assistance/c2.png";
import c3 from "../../../assets/img/assistance/c3.png";
import c4 from "../../../assets/img/assistance/c4.png";
import c5 from "../../../assets/img/assistance/c5.png";

import f1 from "../../../assets/img/assistance/f1.png";
import f2 from "../../../assets/img/assistance/f2.png";
import f3 from "../../../assets/img/assistance/f3.png";

import y2 from "../../../assets/img/assistance/y2.png";
import y3 from "../../../assets/img/assistance/y3.png";
import shengtai from "../../../assets/img/assistance/shengtai.png";


export default class Assistance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			yhxx:{
				name:'',
				phone:'',
				adds:'',
				gs:'',
				zw:''
			}
		};
	}
	changes = (key,e)=>{
		let val = e.target.value
		this.state.yhxx[key] = val
		this.setState({
			yhxx:{...this.state.yhxx}
		})
	}
	resetData = () => {
		this.state.yhxx = {
			name:'',
			phone:'',
			adds:'',
			gs:'',
			zw:''
		}
		this.setState({
			yhxx:{...this.state.yhxx}
		})
	}

	render() {
		
		const bg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg1 + ")", //图片的路径
			backgroundPosition:"246px ",
		};
		const bg2 = {
			backgroundSize: '633px,591px',
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg2 + ")", //图片的路径
			backgroundPosition:"0 62% ",
		};
		const bg3 = {
			backgroundSize: '470px,411px',
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg3 + ")", //图片的路径
			backgroundPosition:"20px 74px",
			marginTop:'48px',
		};
		const bg4 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg4 + ")", //图片的路径
			backgroundPosition:"274px 0",
			paddingTop: '40px',
		};
		const bg5 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg5 + ")", //图片的路径
			backgroundPosition: "right 48px",
		};
		const bg6 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + imgbg6 + ")", //图片的路径
			backgroundPosition: "center",
		};

		const bg7 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + shengtai + ")", //图片的路径
			backgroundPosition: "0 0",
		}

		return (
			<div className="assistance_content">
				<section className="assistance_banner" >
					<img src={imgBanner} alt="" />
				</section>
				<section className="assistance_box1">
					<h3>关于金融助贷平台 <img src={imgDH} alt="" /> </h3>
					<div>
						<p>供应链金融助贷平台主要将核心企业和上下游企业联系在一起，提供灵活运用的金融产品和服务的一种融资模式。</p>
						<p>系统平台将核心企业与上下游之间的交易数据以及企业的发票数据，形成企业信用链条，与企业建立信用传递机制，解决多级供应商融资需求，把服务的主体从银行逐步发展到供应链公司、核心企业，从发展趋势看，核心企业今后将成为重要市场力量。</p>
					</div>
				</section>
				<section className="assistance_box3">
					<h3>供应链金融金融助贷平台</h3>
					<ul>
						<li>
							<img src={c1} alt="" />
							<span>发票</span>
						</li>
						<li>
							<img src={c2} alt="" />
							<span>签收</span>
						</li>
						<li>
							<img src={c3} alt="" />
							<span>拆分流转</span>
						</li>
						<li>
							<img src={c4} alt="" />
							<span>融资</span>
						</li>
						<li>
							<img src={c5} alt="" />
							<span>结算</span>
						</li>
					</ul>
				</section>
				<section className="assistance_box6">
					<ul className="assistance_ul">
						<li style={bg1}>
							<span>产品对象</span>
							<span>Product object</span>
						</li>
						<li>
							<h4>供应商</h4>
							<span>帮助供应商提前获取交易资金。</span>
							<h5>帮助供应商提前获取交易资金。</h5>
							<p>依靠贸易产生的应收账款，同时核心企业进行背书，可以进行保理、票据撮合交易等业务。</p>
						</li>
						<li>
							<h4>核心企业</h4>
							<span>贸易公司、电商平台、垂直行业细分等领域。</span>
							<h5>帮助链属快速融资，优化供应链体系。</h5>
							<p>依托核心企业的信誉，为供应链的上游下游提供融资和担保服务。</p>
						</li>
						<li>
							<h4>金融机构</h4>
							<span>保理公司、银行、担保公司、融资租赁等</span>
							<h5>帮助金融机构保障资金。</h5>
							<p>机构可以跟核心企业合作开展上下游产业链金融业务</p>
						</li>
					</ul>
					<div style={bg2}>
						<img src={b3} alt="" />
						<img src={b2} alt="" />
						<img src={b1b} alt="" />
					</div>
				</section>
				<section className="assistance_box7">
					<div style={bg3} >
						<img src={f1} alt="" />
						<img src={f3} alt="" />
						<img src={f2} alt="" />
					</div>
					<ul className="assistance_ul_right" style={bg4}>
						<li >
							<span>服务优势</span>
							<span>Service advantage</span>
						</li>
						<li>
							<h4>运营服务成熟</h4>
							<p>全方位多渠道客户服务体系，提升客户满意度</p>
						</li>
						<li>
							<h4>产品体验优异</h4>
							<p>产品功能丰富，支持个性化开发和私有化部署</p>
						</li>
						<li>
							<h4>安全有保障</h4>
							<p>交易安全、资金安全、数据安全、合规安全</p>
						</li>
					</ul>
					
				</section>
				
				<section className="assistance_box8">
					<ul className="assistance_ul_right" style={bg5}>
						<li >
							<span>应用场景</span>
							<span>Application scenario</span>
						</li>
						<li>
							<h4>金融机构</h4>
							<p>助力商业银行盘活存量大型客户授信，丰富合作产品，以供应链为依托实现中小企业批量获客</p>
						</li>
						<li>
							<h4>物流行业</h4>
							<p>产品功能丰富，支持个性化开发和私有化部署</p>
						</li>
						<li>
							<h4>其他行业</h4>
							<p>交易安全、资金安全、数据安全、合规安全</p>
						</li>
					</ul>
					<div style={bg6}>
						<img src={y3} alt="" />
						<img src={y2} alt="" />
					</div>
				</section>
				<section className="assistance_box2" style={bg7}>
					<h3>产品生态圈</h3>
					<ul>
						<li>
							<span>科技赋能，打造供应链金融助贷生态圈</span>
							<div></div>
						</li>
						<li>
							<div>上下游供应链企业</div>
							<div>融资申请/资料审核</div>
							<div>中税创新平台</div>
							<div>审核成功，放款</div>
							<div>供应链资金企业</div>
						</li>
						<li>
							<div>到期偿还融资</div>
						</li>
					</ul>
				</section>
				<div className="business-content2">
						<h3 className='content2-title'>联系我们</h3>
						<p>请留下您的联系方式，我们解决方案专家将尽快联系您。</p>
						<div className="textContent">
							<div className="left">
								<img src={require('../../../assets/img/assistance/Artwork.png')} alt=""/>
							</div>
							<div className="right">
								<div><label htmlFor='name'>称呼：</label><input placeholder="请输入您的称呼" value={this.state.name} onChange={this.changes.bind(this,'name')} id='name' type="text"/></div>
								<div><label htmlFor='phone'>电话：</label><input placeholder="请输入您的电话号码" onChange={this.changes.bind(this,'phone')} value={this.state.phone} id='phone' type="text"/></div>
								<div><label htmlFor='adds'>公司：</label><input placeholder="请输入您的公司" onChange={this.changes.bind(this,'adds')} value={this.state.adds} id='adds' type="text"/></div>
								<div><label htmlFor='gs'>行业：</label><input placeholder="请输入您的行业" onChange={this.changes.bind(this,'gs')} value={this.state.gs} id='gs' type="text"/></div>
								<div><label htmlFor='zw'>邮箱：</label><input placeholder="请输入您的邮箱" onChange={this.changes.bind(this, 'zw')} value={this.state.zw} id='zw' type="text" /></div>
								<div><button>提交</button><button onClick={this.resetData.bind(this)}>重置</button></div>
							</div>
						</div>
			   </div>
				<Footer1 />
			</div>
		);
	}
}

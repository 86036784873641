import React, { Component } from "react";
import Footer2 from "../../../components/webfooter2/webFooter2";

import "./flexible.less";

import banner from '../../../assets/img/flexible/banner.png'

import b1 from '../../../assets/img/flexible/1.png'
import b2 from '../../../assets/img/flexible/2.png'
import b3 from '../../../assets/img/flexible/3.png'
import b4 from '../../../assets/img/flexible/4.png'
import b5 from '../../../assets/img/flexible/5.png'

import bg1 from '../../../assets/img/flexible/b1.png'
import bg2 from '../../../assets/img/flexible/b2.png'


import w1 from '../../../assets/img/flexible/w0.png'
import w2 from '../../../assets/img/flexible/w1.png'
import w3 from '../../../assets/img/flexible/w2.png'
import w4 from '../../../assets/img/flexible/w3.png'
import w5 from '../../../assets/img/flexible/w4.png'
import w6 from '../../../assets/img/flexible/w5.png'




export default class Flexible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: {
                title: "助力企业开启供应链协同新体验！",
                phone: '400-9958-578'
            },
        };
    }


    render() {
        const bgImg1 = {
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(" + bg1 + ")", //图片的路径
            backgroundPosition: "93% 80%",
        };
        const bgImg2 = {
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(" + bg2 + ")", //图片的路径
            backgroundPosition: "96% 88%",
        };




        return (
            <div className="flexible_content">
                <section className="flexible_banner">
                    <img src={banner} alt="" />
                </section>
                <section className="flexible_box2">
                    <h3>产品介绍</h3>
                    <ul>
                        <li>
                            <img src={b1} />
                            <span>客招工成本高</span>
                        </li>
                        <li>
                            <img src={b2} />
                            <span>社保成本压力大</span>
                        </li>
                        <li>
                            <img src={b3} />
                            <span>一线人流动大</span>
                        </li>
                        <li>
                            <img src={b4} />
                            <span>劳务用工无发票</span>
                        </li>
                        <li>
                            <img src={b5} />
                            <span>季节性用工合规难</span>
                        </li>
                    </ul>
                </section>
                <section className="flexible_box2 flexible_box3">
                    <h3>帮助企业解决用工难题</h3>
                    <div>
                        <ul>
                            <li>
                                <div><span></span>提供财税综合服务</div>
                                <div><span></span>与隧道个人建立合作关系<span></span></div>
                            </li>
                            <li>
                                <div>客户公司</div>
                                <div>
                                    <div>支付服务费</div>
                                    <div>支付服务费</div>
                                </div>
                                <div>中税创新平台</div>
                                <div>
                                    <div>支付渠道佣金</div>
                                    <div>缴纳税款</div>
                                </div>
                                <div>
                                    自由职业者
                                </div>
                            </li>
                            <li>
                                <div><span></span>签订服务协议</div>
                                <div><span></span>签订用户协议<span></span></div>
                            </li>
                        </ul>
                        <ul>
                            <li>资金流</li>
                            <li>业务流</li>
                            <li>合同流</li>
                            <li>凭证流</li>
                        </ul>
                        <div>
                            <h5>中税创新灵活用工平台</h5>
                            <div>
                                中税创新灵活用工平台致力于为企业提供共享用工SAAS化解决方案，搭建企业与灵活用工雇佣关系桥梁，帮助共享用工企业处理财税结算与人员管理问题，有效降低企业成本、节省人力、提高效率。
                            </div>
                        </div>
                    </div>
                </section>
                <section className="flexible_box2 flexible_box4">
                    <h3>入驻平台流程</h3>
                    <div>
                        <ul>
                            <li>企业用户</li>
                            <li>入驻审核</li>
                            <li>签约</li>
                            <li>审核平台</li>
                            <li>接受服务</li>
                            <li>付款</li>
                            <li>开票</li>
                        </ul>
                        <ul>
                            <li>个人合作者</li>
                            <li>入驻审核</li>
                            <li>审核平台</li>
                            <li>开票</li>
                        </ul>
                    </div>
                </section>
                <section className="flexible_box2 flexible_box5">
                    <h3>与其他方案对比</h3>
                    <div>
                        <div style={bgImg1}>
                            <h4>灵活用工模式</h4>
                            <ul>
                                <li>
                                    <div>开具专票抵扣，大大降低运营成本</div>
                                    <div>降低企业成本，员工个税，增加员工收入</div>
                                </li>
                                <li>
                                    <div>无需个人/公司到税务局代开发票</div>
                                    <div>缩减财务手续步骤，提高工作运作效率</div>
                                </li>
                                <li>
                                    <div>合法合规，规避税务风险</div>
                                    <div>税务机关统一接口监管，四流合一保证合规</div>
                                </li>
                            </ul>
                        </div>
                        <div style={bgImg2}>
                            <h4>传统用工模式</h4>
                            <ul>
                                <li>
                                    <p>公户转多个私户，操作复杂个人开票困难，无法给企业提供发票企业找发票困难，存在税务不合理</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="flexible_box2 flexible_box6">
                    <h3>我们服务的行业</h3>
                    <ul>
                        <li>
                            <div>物流配送类</div>
                            <div>
                                <img src={w1} alt=""/>
                            </div>
                            <div>
                                <span>骑手</span>
                                <span>配送员</span>
                                <span>货车司机</span>
                            </div>
                        </li>
                        <li>
                            <div>生活服务类</div>
                            <div>
                                <img src={w2} alt=""/>
                            </div>
                            <div>
                                <span>家政</span>
                                <span>保洁</span>
                                <span>护工</span>
                            </div>
                        </li>
                        <li>
                            <div>互联网类</div>
                            <div>
                                <img src={w3} alt=""/>
                            </div>
                            <div>
                                <span>推广销售</span>
                                <span>网络主播</span>
                            </div>
                        </li>
                        <li>
                            <div>教育培训</div>
                            <div>
                                <img src={w4} alt=""/>
                            </div>
                            <div>
                                <span>讲师</span>
                                <span>教师</span>
                                <span>辅导室</span>
                            </div>
                        </li>
                        <li>
                            <div>网约车平台</div>
                            <div>
                                <img src={w5} alt=""/>
                            </div>
                            <div>
                                <span>网约车司机</span>
                                <span>代驾员</span>
                            </div>
                        </li>
                        <li>
                            <div>程序开发类</div>
                            <div>
                                <img src={w6} alt=""/>
                            </div>
                            <div>
                                <span>程序员</span>
                                <span>工程师</span>
                            </div>
                        </li>
                    </ul>
                </section>

                <Footer2 title={this.state.footer} />
            </div>
        );
    }
}

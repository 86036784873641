import React, { Component } from "react";
import { message, Spin } from "antd";
import "./index.less";
import sha1 from '../../../../utils/sha1'
import axios from "axios";
import { submitTitleUnderLine, gsDataFind, getAmouontBySecretStrUnderLine, } from "../../../../api/index.js";

let wx = window.wx

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lodding:false,
			show: false,
			toggle: true,
			orderNo: "",
			jshj: "",
			search: [],
			form: {
				fpTitle: "",  //抬头
				gfnsrsbh: "",
				gfdzdh: "",
				buyerPhone: "", //假参数
				buyerAddress: "", //假参数
				gfyhmczh: "", //mc+zh
				buyerBankName:"", //mc
				buyerBankNo:'',
				email: "",
				phone: "",
			},
			display:false,
			showBlock:false,
			param:{},
		}
		this.environmental = "micromessenger2";
		this.emailList = [];
	}

	

	componentDidMount() {
		this.init()
		//检测坏境
		var userAgent = navigator.userAgent.toLowerCase();

		if (userAgent.match(/Alipay/i) == 'alipay') {
			this.environmental = "alipay";
		} else if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
			this.environmental = "micromessenger";
			this.setState({ display:true })
			this.isWX()
		}else{
			this.setState({ display:false })
		}
	}

	init=()=>{
		let orderId = this.GetQueryString("orderId");
		let m = this.GetQueryString("m");
		let param = {parm1:orderId,parm2:m}
		this.setState({param})
		getAmouontBySecretStrUnderLine(param).then(res=>{
			if(res.code=="0000"){
				message.success(res.msg)
				let {resultObj:{amount,orderNo}} = res
				this.setState({orderNo,jshj:amount})
			}else{
				message.error(res.msg)
			}
		})
	}

	//申请发票
	writeInvoice() {
		let form = this.state.form;
		let param = this.state.param
		form.type = 1;  //固定值
		var reg1 = /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}|[a-zA-Z0-9]{20})$/;
		var reg3 = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;

		if (form.fpTitle === "") {
			message.warning("购方名称不能为空！");
			return false;
		}
		if (form.email === "") {
			message.warning("邮箱不能为空！");
			return false;
		}
		if (!reg3.test(form.email)) {
			message.warning("邮箱格式错误！");
			return false;
		}

		if (this.state.toggle) {
			if (form.gfnsrsbh === "") {
				return message.warning("购方税号不能为空!");
			}
			if (form.gfnsrsbh !== "" && !reg1.test(form.gfnsrsbh)) {
				return message.warning("购方税号格式错误!");
			}
		}else{
			form.gfnsrsbh = ""
			form.buyerPhone = ""
			form.buyerAddress = ""
			form.gfyhmczh = ""
			form.buyerBankName = "";
			form.buyerBankNo = "";
		}

		form.gfdzdh = form.buyerAddress + form.buyerPhone;
		form.gfyhmczh = form.buyerBankName+form.buyerBankNo;

		for(let key in form){
			if(form[key]&& typeof form[key]=="string" ){
				form[key] = form[key].trim()
				form[key] = (form[key]).replace(/null|undefinde/g,"")
			}
		}
		let {phone,email,fpTitle,gfnsrsbh,gfdzdh,gfyhmczh,type}=form
		let allParam = {phone,email,fpTitle,gfnsrsbh,gfdzdh,gfyhmczh,type,...param}
		if(!this.state.orderNo)return message.info('数据错误！')

		this.setState({lodding:true})
		submitTitleUnderLine(allParam).then((res) => {
			if (res.code == "0000") {
				message.success(res.msg);
				let list = [];
				res.resultList.forEach((item) => {
					list.push({
						id:item.id,
						pdfUrl:item.pdfUrl
					})
				});
				localStorage.setItem("listKp",JSON.stringify(list))
				if(this.environmental = 'micromessenger'){
					this.props.history.push({ pathname: '/source/phone/success', state: {} })
				}

			} else {
				if((res.msg).includes("税控设备无返回信息")){
					message.error("此盘无发票，请联系商家！");
				}else{
					message.error(res.msg);
				}
			}
			this.setState({lodding:false})
		});
	}
	//检索抬头
	searchInvocie(name) {
		gsDataFind({ searchName: name, token: "WxDev", }).then((res) => { 
		// axios.post(process.env.REACT_APP_BASEURL+"/registNoSearch?searchName="+name+'&token=WxDev').then((res) => {
		// 	res = res.data
			this.setState({
				search: [],
			},()=>{
				try{
					if (Array.isArray(res.object)&&res.object.length > 0) {
						this.setState({
							search: [...res.object],showBlock:true
						});
					}
				}catch{}
			});
		});
	}

	//选择购方名称
	selectOptions(id, name) {
		let form = this.state.form;
		form.gfnsrsbh = id;
		form.fpTitle = name;
		this.setState({
			form,showBlock:false
		});
	}

	inputFocus=(val)=>{
		if(!val)return
		this.searchInvocie(val)
	}
	

	inputOnBlur=()=>{
		setTimeout(()=>{
			this.setState({
				showBlock:false
			});
		},100)
	}

	//更新数据
	updateData = (e, key) => {
		let form = this.state.form;
		form[key] = e.target.value.trim();
		if(key==="gfnsrsbh"){
			form[key] = form[key].toUpperCase()
		}
		if (key === "fpTitle") {
			form.gfnsrsbh = ""
			this.searchInvocie(e.target.value);
		}
		// if (key === "email") {
		// 	this.emailSearch(e.target.value);
		// }
		this.setState({
			form,
		});
	};

	//清空数据
	deleForm=()=>{
		let form = {...this.state.form};
		for(let key in form){
			form[key]=""
		}
		this.setState({form})
	}

	//设置email
	selectEmaik(item){
		let form = this.state.form
		let email = item.props.children[1]
		form.email = email
		this.setState({
			form,
		})
		this.emailList = [];
	}
	//获取抬头
	selectInvoice() {
		this.getWx();
	}
	//验证微信环境
	isWX() {
		let param = { openId: "123" };
		axios.get(process.env.REACT_APP_BASEURL_WX+"/wxDev/getTicket", { params: param }).then((res) => {
				if (res.data.code == 0) {
					var time = Date.parse(new Date()),
						rstring = this.random_string(16),
						url =window.location.href.split("#")[0],
						ticket = res.data.map.ticket,
						str ="jsapi_ticket=" + ticket +"&noncestr=" + rstring + "&timestamp=" + time +"&url=" + url,
						str1 = sha1(str);
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: "wx20db3f2d2972db19", // 必填，公众号的唯一标识
						timestamp: time,
						beta: true,
						nonceStr: rstring,
						signature: str1,
						jsApiList: [
							// 'scanQRCode',
							"chooseInvoice",
							"chooseInvoiceTitle",
						],
					});
					wx.error(function (res) {
						axios
							.post(
								process.env.REACT_APP_BASEURL_WX+"/wxDev/reFreshTicket?openId=14555"
							)
							.then((res) => {
								if (res.data.code == "0") {
								} else {
									//window.location.reload();
								}
							})
							.catch((err) => {
								console.log("失败");
							});
					});
				}
			});
	}
	//拼接
	random_string(len) {
		var len = len || "4";
		var s = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		if (len < 1) {
			alert("请设置一个大于0的数字为该字符串的长度！");
		} else {
			var str = '',
					p = '';
			for (var i = 0; i < len; i++) {
				p = parseInt(Math.random() * 100 % 62);
				str = str + s[p];
			}
			return str;
		}
	}
	//微信抬头
	getWx() {
		let that = this.state
		let myThis = this
		wx.invoke(
			"chooseInvoiceTitle",
			{
				scene: "1",
			},
			function (res) {
				if (res.err_msg === "chooseInvoiceTitle:ok" || res.err_msg === "ok") {

					res = JSON.parse(res.choose_invoice_title_info);
					if (res.taxNumber || res.companyAddress || res.telephone || res.BankName || res.bankAccount) {
						that.form.fpTitle = res.title?res.title:"";
						that.form.gfnsrsbh = res.taxNumber?res.taxNumber:"";
						that.form.buyerPhone = res.telephone?res.telephone:"";
						that.form.buyerAddress = res.companyAddress?res.companyAddress:"";
						that.form.buyerBankName = res.BankName?res.BankName:"";
						that.form.buyerBankNo = res.bankAccount?res.bankAccount:"";
						if(!myThis.state.show){
							myThis.refs.oneCheckbox.click()
						}
						myThis.setState({toggle:true})
					} else {
						//个人
						that.form.fpTitle = res.title?res.title:"";
						that.form.gfnsrsbh = '';
						that.form.buyerPhone = '';
						that.form.buyerAddress = '';
						that.form.buyerBankName = "";
						that.form.buyerBankNo = "";
						if(myThis.state.show){
							myThis.refs.oneCheckbox.click()
						}
						myThis.setState({toggle:false})
					}
				}
			}
		);
	}
	//邮箱检索
	emailSearch(e) {
		this.emailList = [];
		var email = e;
		email = email.replace(/\s+/g, "");
		var mail = [
				"@qq.com",
				"@sina.com",
				"@163.com",
				"@gmail.com",
				"@sohu.com",
				"@qq.com",
				"@126.com",
				"@163.com",
				"@hotmail.com",
				"@yahoo.com",
			],
			idx = email.lastIndexOf("@");
		if (idx !== -1) {
			for (var i = 0; i < mail.length; i++) {
				this.emailList.push(
					<li
						key={i}
					>
						{" "}
						{email.slice(0, idx) + mail[i]}{" "}
					</li>
				);
			}
		} else {
			for (var j = 0; j < mail.length; j++) {
				this.emailList.push(
					<li
						key={j}
					>
						{" "}
						{e + mail[j]}{" "}
					</li>
				);
			}
		}
		// console.log('emailList',this.emailList);
	}
	//

	GetQueryString(kValue) {
		//当前页面的地址
		const url = window.location.href;
		var reg = /([^?&=]+)=([^?&=]+)/g,
			obj = {},
			str = url;
		url.replace(reg, function () {
			obj[arguments[1]] = arguments[2];
		});
		for (var keyName in obj) {
			if (keyName == kValue) {
				return obj[keyName];
			}
		}
	}
	render() {
		const form = this.state.form;
		return (
			<Spin spinning={this.state.lodding} size="large"  >
			<div className="soure_warp">
				<div className="center">
					<div className="logo">
						<img
							src={require("../../../../assets/img/headerLogo/header_logo.png")}
							alt=""
						/>
					</div>
					<div className="info">
						<p>增值税电子普通发票</p>
						<ul>
							<li>
								<span>订单号</span>:
								<span>{this.state.orderNo}</span>{" "}
							</li>
							<li>
								<span>开票项目</span>:
								<span>*餐饮服务*餐饮费</span>
							</li>
							<li>
								<span>开票总金额</span>:
								<span>{this.state.jshj}元</span>
							</li>
						</ul>
						<div className="wavy-line"></div>
					</div>
					<div className="form">
						<div className="invoiceBtn" >
							<button style={{display:this.state.display?'inline-block':'none'}}
								onClick={() => {
									this.selectInvoice();
								}}
							>
								+选择发票抬头
							</button>
						</div>
						<div className="type">
							<span
								onClick={() => {
									this.deleForm()
									if(this.state.show){
										this.refs.oneCheckbox.click()
									}
									this.setState({ toggle: false });
								}}
								style={{ borderBottom: !this.state.toggle ? "2px solid #1C7FF3" : "", }}
							>
								个人/其他
							</span>
							<span
								onClick={() => {
									this.deleForm()
									this.setState({ toggle: true });
									// if(!this.state.show){
									// 	this.refs.oneCheckbox.click()
									// }
								}}
								style={{ borderBottom: this.state.toggle ? "2px solid #1C7FF3" : "", }}
							>
								企业
							</span>
						</div>
						<ul>
							<li>
								<label><i>*</i>购方名称</label>
								<input autoComplete="off"
									placeholder="请输入抬头名称、智能检索"
									value={form.fpTitle}
									onChange={(e) => {
										this.updateData(e, "fpTitle");
									}}
									onBlur={ this.inputOnBlur }
									onFocus={(e)=>this.inputFocus(e.target.value)} 
								></input>
								<ul className="search" style={{ display: (this.state.showBlock&&this.state.search.length>0) ? "block" : "none" }}
								>
									{this.state.search.map((item, index) => {
										return (
											<li key={index} onClick={(e) => { this.selectOptions( item.nsrsbh, item.nsrmc ); }}
											> {item.nsrmc}
											</li>
										);
									})}
								</ul>
							</li>

							<li style={{ display: this.state.toggle ? "block" : "none", }} >
								<label><i>*</i>税号</label>
								<input autoComplete="off" placeholder="填写纳税人识别号" value={form.gfnsrsbh} onChange={(e) => { this.updateData(e, "gfnsrsbh"); }} />
							</li>

							<li className="email" >
							    <label><i>*</i>邮箱</label>
								<input autoComplete="off" placeholder="填写邮箱信息、取票更快捷" value={form.email} onChange={(e) => { this.updateData(e, "email"); }} />
								{/* <ul className="search" style={{ display: this.emailList.length > 0 ? "block" : "none", }} >
									{this.emailList.map((item, index) => {
										return (
											<li key={index} onClick={(e) => { this.selectEmaik( item ); }}> 
												{item}
											</li>
										);
									})}
								</ul> */}
							</li>
							<li>
								<label>手机号码</label>
								<input autoComplete="off" placeholder="选填、体验多种取票方式" value={form.phone}
									onChange={(e) => {
										this.updateData(e, "phone");
									}}
								></input>
							</li>
							<li className="checkbox" style={{ display: this.state.toggle ? "flex" : "none"}}>
								<input autoComplete="off"
									ref="oneCheckbox"
									id="oneCheckbox"
									type="checkbox"
									onClick={(e) => {
										this.setState({
											show: !this.state.show,
										});
									}}
									style={{verticalAlign:"middle"}}
								/>
								<label htmlFor="oneCheckbox" style={{width:'auto'}}>填写更多纳税人信息</label>
							</li>
							<ul
								style={{
									display: this.state.show ? "block" : "none",
								}}
							>
								<li>
									<label>经营地址</label>
									<input autoComplete="off"
										placeholder="选填、体验多种取票方式"
										value={form.buyerAddress=="undefined"?'':form.buyerAddress}
										onChange={(e) => {
											this.updateData(e, "buyerAddress");
										}}
									></input>
								</li>
								<li>
									<label>公司电话</label>
									<input autoComplete="off"
										placeholder="选填、体验多种取票方式"
										value={form.buyerPhone=="undefined"?'':form.buyerPhone}
										onChange={(e) => {
											this.updateData(e, "buyerPhone");
										}}
									></input>
								</li>
								<li>
									<label>开户行名称</label>
									<input autoComplete="off"
										placeholder="选填、体验多种取票方式"
										value={!form.buyerBankName?'':form.buyerBankName}
										onChange={(e) => {
											this.updateData( e, "buyerBankName" );
										}}
									></input>
								</li>
								<li>
									<label>开户行账号</label>
									<input autoComplete="off"
										placeholder="选填、体验多种取票方式"
										value={!form.buyerBankNo?'':form.buyerBankNo}
										onChange={(e) => {
											this.updateData( e, "buyerBankNo" );
										}}
									></input>
								</li>
							</ul>
						</ul>
					</div>
					<div className="btn">
						<button onClick={() => { this.writeInvoice(); }} >
							申请开票
						</button>
					</div>
				</div>
			</div>
			</Spin>
		);
	}
}

import React, { Component } from 'react'
import './business.less'
import Footer2 from '../../../components/webfooter2/webFooter2'


export default class Business extends Component {
	constructor(props) {
        super(props);
        this.state = {
			footer:{
				title: "立即加入我们吧",
				phone:'400-9958-578'
			},
      yhxx:{
				name:'',
				phone:'',
				adds:'',
				gs:'',
				zw:''
			}
    }
	}
	
	changes = (key,e)=>{
		let val = e.target.value
		this.state.yhxx[key] = val
		this.setState({
			yhxx:{...this.state.yhxx}
		})
	}
	resetData = () => {
		this.state.yhxx = {
			name:'',
			phone:'',
			adds:'',
			gs:'',
			zw:''
		}
		this.setState({
			yhxx:{...this.state.yhxx}
		})
	}
	render() {
		return (
			// 招商渠道
			<div className="business-page">
			   <div className="business-content1">
				   <img src={require('../../../assets/img/business/banner.png')} alt=""/>
			   </div>
			   <div className="business-content2">
						<h3 className='content2-title'>加入我们</h3>
						<p>请留下您的联系方式，我们解决方案专家将尽快联系您。</p>
						<div className="textContent">
							<div className="left">
								<img src={require('../../../assets/img/business/join.png')} alt=""/>
							</div>
							<div className="right">
								<div><label htmlFor='name'>称呼：</label><input placeholder="请输入您的称呼" value={this.state.name} onChange={this.changes.bind(this,'name')} id='name' type="text"/></div>
								<div><label htmlFor='phone'>电话：</label><input placeholder="请输入您的电话号码" onChange={this.changes.bind(this,'phone')} value={this.state.phone} id='phone' type="text"/></div>
								<div><label htmlFor='adds'>公司：</label><input placeholder="请输入您的公司" onChange={this.changes.bind(this,'adds')} value={this.state.adds} id='adds' type="text"/></div>
								<div><label htmlFor='gs'>行业：</label><input placeholder="请输入您的行业" onChange={this.changes.bind(this,'gs')} value={this.state.gs} id='gs' type="text"/></div>
								<div><label htmlFor='zw'>邮箱：</label><input placeholder="请输入您的邮箱" onChange={this.changes.bind(this, 'zw')} value={this.state.zw} id='zw' type="text" /></div>
								<div><button>提交</button><button onClick={this.resetData.bind(this)}>重置</button></div>
							</div>
						</div>
			   </div>
			   <div className="business-content3">
				   <div>
					   <span>渠道厚望</span>
					   <div></div>
					   <div>
						    1.有为客户提供可持续发展的能力，能为客户提供增值服务；<br/>
							2.为客户提供除财务产品与税务增值服务外还帮助企业实现税务信息化转型；<br/>
							3.为渠道培养的产品技术工程师可以给用户提供更高的服务；<br/>
							4.有志将渠道商从产品代理到集成到服务的高阶段；<br/>
							5.行业合作伙伴能够充分了解客户需求，让客户知道产品价值，并深入到行业核心用户。<br/>
						</div>
				   </div>
				   <div>
					   <span>我们的支持与服务</span>
					   <div></div>
					   <div className="fuWu">
						   <h3>专业咨询：</h3>
						   <p>在渠道和客户对接初期，售前工程师协助一起了解用户需求、梳理方案和涉及流程，让合作伙伴逐 渐步成为该领域的资深专家和顾问。</p>
						   <h3>量身定制方案：</h3>
						   <p>根据企业的需求量身定制解决方案，采取分步、循序渐进的实施方式，为企业打造最适合的税务信息化系统。</p>
						   <h3>集中培训：</h3>
						   <p>税务信息化服务专业性较强，在合作初期，我们将对合作伙伴公司进行集中的专业培训。</p>
						   <h3>质量保证：</h3>
						   <p>我们会对客户的税务系统进行定期巡检，确保系统的稳定并可靠运行，同时对于客户的电话回访，以及一些常见的税务信息化问题进行解答，对于定制相应的解决方案进行全程跟踪维护，确保系统的质量。</p>
						   <h3>雄厚的技术实力：</h3>
						   <p>我司现拥有专业的技术人才200余名，具备软件开发、信息基础平台集成、对接各种财务软件等，健全的技术支持系统遍布全国，能够快捷、及时的为各地用户提供服务。</p>
					   </div>
				   </div>
				   <div>
					   <span>我们需要的合作伙伴</span>
					   <div></div>
					   <div className="huoBan">
					   		<h3>1. 能够全心全意投入精力推广中税信息税务信息化解决方案、在拓展自己的市场带入票慧产品、自主发展地区客户，形成全网销售，我们会为合作伙伴提供相应的市场支持和技术支持。</h3>
							<h3>2. 市场支持，立体合作：</h3>
							<p>主动分享商机，互惠互利<br/>
							统一接口人，定期沟通合作计划<br/>
							市场活动经费、人力、技术支持<br/>
							支持商机拓展和项目协助
							</p>
							<h3>3. 全面渠道能力提升体系：</h3>
							<p>
							提供针对售前售后人员的培训<br/>
							建立组织保障确保培训落地<br/>
							对伙伴提供商机支持，授权资质
							</p>
							<h3>4. 营销支持：资源全方位开放，商机来源更多更广：</h3>
							<p>品牌认可，公司进行品牌活动，媒体广告以及宣传资料的支持<br/>
							产品认可，公司通过技术活动，产品评测以及第三方报告助力伙伴<br/>
							客户公关，举办客户答谢会，总部参观等活动对伙伴进行公关支持</p>
					   </div>
				   </div>
				</div>
				
				<Footer2 title={this.state.footer} />
			</div>
		)
	}
}

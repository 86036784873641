import React, { Component } from "react"
import { Button, Divider,Modal, message,Spin,Table,Radio,Checkbox,Input,DatePicker} from 'antd';
import moment from "moment"

import "./form.less"

const { RangePicker } = DatePicker;

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userObj:{
                sjlxs:1,
                fplxs:'',
                sqrqq:'',
                sqrqz:'',
                jymm:''
            },
            Jymm1:'',
            Jymm2:'',
		}
    }

    setMm=(val,key)=>{
        let obj = {}
        obj[key] = val
        this.setState({
            ...obj
        })
    }

    //时间条件
    onChangeMonth=(data,date)=>{
        let [sqrqq,sqrqz]=date
        this.setState({
            userObj:{...this.state.userObj,sqrqq,sqrqz}
        })
    }
    ycChange=(val,key)=>{
        let obj = {}
        if(key=="fplxs"&&Array.isArray(val)){
            val = val.join(',')
        }
        obj[key] = val
        this.setState({
            userObj:{...this.state.userObj,...obj}
        })
        
    }
    onOk=()=>{
        if(this.state.Jymm1 == this.state.Jymm2){
            this.state.userObj.jymm = this.state.Jymm2
        }else{
            return message.error("两次解压密码输入不一致！")
        }
        if(!this.state.userObj.fplxs)return message.error('发票类型不能为空！')
        if(!this.state.userObj.sqrqq)return message.error('申领日期不能为空！')
        if(!this.state.userObj.sqrqz)return message.error('申领日期不能为空！')
        if(!this.state.userObj.jymm)return message.error('解压密码不能为空！')
        this.props.clickOk(this.state.userObj)
    }

	render() {
        const options = [
			{ label: '进项票', value: 1 },
			{ label: '销项票', value: 2 },
        ];
        const options2 = [
			{ label: '增值税专用发票', value: '01' },
			{ label: '机动车销售统一发票', value: '03' },
			{ label: '增值税普通发票', value: '04' },
			{ label: '增值税普通发票（电子）', value: '10' },
			{ label: '增值税普通发票（卷票）', value: '11' },
			{ label: '二手车销售统一发票', value: '15' },
			{ label: '海关缴款书', value: '17' },
		];
		return (
            <ul className="myForm_box">
                <li>
                    <label htmlFor="jxs">进销项</label>：<Radio.Group id="jxs" options={options} onChange={(e)=>this.ycChange(e.target.value,"sjlxs")} value={this.state.userObj.sjlxs}  />
                </li>
                <li>
                    <label htmlFor="fplx">进销项</label>：
                    <div style={{width:380}}>
                        <Checkbox.Group id="fplx" options={options2} onChange={(e)=>this.ycChange(e,"fplxs")} />
                    </div>
                </li>
                <li>
                    <label htmlFor="MonthChange">开票日期：</label><RangePicker style={{width:300}} id="MonthChange" placeholder={['开始时间', '结束时间']} onChange={this.onChangeMonth} placeholder="选择所属期" format={['YYYY-MM-DD', 'YYYY-MM-DD']} />
                </li>
                <li>
                    <label htmlFor="jymm">解压密码</label>：<Input autoComplete="off" type="password" style={{width:300}} id="jymm" onChange={(e)=>this.setMm(e.target.value,"Jymm1")} value={this.state.Jymm1} />
                </li>
                <li>
                    <label htmlFor="prmm">确认密码</label>：<Input autoComplete="off" type="password" style={{width:300}} id="prmm" onChange={(e)=>this.setMm(e.target.value,"Jymm2")} value={this.state.Jymm2} />
                </li>
                <li>
                    <Button type="primary" onClick={this.onOk}>提交</Button>
                </li>
            </ul>
		)
	}
}
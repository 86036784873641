import React, { Component } from "react"
import { message } from 'antd'
import {register} from '../../../api/index'
import Untils from '../../../utils'
import './register.less'

export default class Login extends Component {
	form=[
		{
			name:"登录名称",
			key:'loginName',
			value:""
		},
		{
			name:"用户名",
			key:'userName',
			value:""
		},
		{
			name:"手机号",
			key:'userPhone',
			value:""
		},
		{
			name:"身份证号码",
			key:'idCard',
			value:""
		},
		{
			name:"密码",
			key:'password',
			value:""
		},
		{
			name:"确定密码",
			key:'agianPassword',
			value:""
		},
	]
	jump=()=>{
		this.props.history.push('/web/login')
	}
	commit=()=>{
		Untils.isEmpty(this.form,function(e){
			message.warning(e+"不能为空！")
			return false
		})
		const param={}
		this.form.forEach((item)=>{
			  param[item.key]=item.value||""
		})
	    if(this.form[4].value!==this.form[5].value){
			message.warning("两次密码输入不一致！")
		}else{
			register(param).then(res=>{
			    if(res.code==="0000"){
					message.success(res.msg)
					this.props.history.push('/web/login')
				}
			})
		}
	
	}
	update=()=>{

	}
	updateData=(e,index)=>{
		 this.form[index].value=e.target.value
         console.log(this.form)
	}


	render() {
		console.log(this.props)
		const {loginName,userName,userPhone,idCard,password,againPassword}=this.form
		return (
			
            <div className="web-register">
				<div className="web-register">
                      <div className="register-box">
						    
						    <div className="register-title">
								注册账号
							</div>

							{
								this.form.map((item,index)=>(
									<div className="form-list" key={index}>
	                                    <label htmlFor="">{item.name}</label>
										<input type="text" placeholder={`请输入您的${item.name}`} defaultValue={item.value} onChange={(e)=>{this.updateData(e,index)}}/>
									</div>
								))
							}
							<div className="register-commit" onClick={()=>{this.commit()}} >
							    <span >提交</span>
							</div>
							<div className="register-jump"><span>已注册</span><span onClick={()=>this.jump()}>去登陆</span></div>
					  </div>
				</div>
			</div>
		)
	}
}
import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./parkingLot.less";

import imgBan from "../../../../assets/img/solution/banner10.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/16.png";
import i2 from "../../../../assets/img/solution/43.png";
import i3 from "../../../../assets/img/solution/20.png";
import i4 from "../../../../assets/img/solution/18.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";
import i7 from "../../../../assets/img/solution/13.png";

import i21 from "../../../../assets/img/solution/21.png";
import i22 from "../../../../assets/img/solution/37.png";
import i23 from "../../../../assets/img/solution/29.png";
import i24 from "../../../../assets/img/solution/28.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";




export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer: {
				title: "我想要咨询此行业",
				phone: '400-9958-578'
			},
			active: [true, false, false, false]

		};
	}

	clickActive = (key) => {
		let list = this.state.active
		for (let i in list) {
			if (i == key) {
				list[i] = true
			} else {
				list[i] = false
			}
		}
		this.setState({ active: [...list] })
		let str = 'active' + key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};



		return (
			<div className="solution-content solution-content-10">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0] ? 'active' : ''} onClick={this.clickActive.bind(this, 0)}>行业趋势</li>
						<li className={this.state.active[1] ? 'active' : ''} onClick={this.clickActive.bind(this, 1)}>行业痛点</li>
						<li className={this.state.active[2] ? 'active' : ''} onClick={this.clickActive.bind(this, 2)}>解决方案</li>
						<li className={this.state.active[3] ? 'active' : ''} onClick={this.clickActive.bind(this, 3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>智能化</span>
							<span>智能化停车方式，提高停车效率带动停车开票难问题</span>
						</li>
						<li style={bgImg2}>
							<span>移动支付</span>
							<span>移动支付平台成熟，让下沉消费者享受电子化带来的便利</span>
						</li>
						<li style={bgImg3}>
							<span>电子化</span>
							<span>互联网+的形态演化，催生其发展电子化方式新形态</span>
						</li>

					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>形式少</span>
							<span>单次停车费用较小时，消费者难以合并开票</span>
						</li>
						<li>
							<span>无法联动</span>
							<span>无法实现发票与停车收费系统联动</span>
						</li>
						<li>
							<span>管理难</span>
							<span>发票管理难，使用不规范</span>
						</li>
						<li>
							<span>成本高</span>
							<span>使用纸质定额发票，成本高</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户消费完成，商家打印出带有二植码的小，客户日描二堆码，增写发头，手机号就者邮箱，交开票中请，系统自动开日电子发票，推送到客户邮箱</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>线上支付</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i2} />
								<span>线下下单</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<div className="ul_div">
							<li>
								<img alt="" src={i3} />
								<span>商家app/公众号</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i7} />
								<span>扫描二维码录入</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<li>
							<img alt="" src={i4} />
							<span>接口服务</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i6} />
							<span>公众号/邮箱/短信/app接收发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>实时监控</span>
								<img alt="" src={i21} />
							</div>
							<p>方便停车场系统管理实时掌握各店开票情况和发票库存情况</p>
						</li>
						<li>
							<div>
								<span>线上支付</span>
								<img alt="" src={i22} />
							</div>
							<p>解决使用线上支付平台开票获取电子发票方式</p>
						</li>
						<li>
							<div>
								<span>集成对接</span>
								<img alt="" src={i23} />
							</div>
							<p>可与停车场系统、APP等渠道集成对接</p>
						</li>
						<li>
							<div>
								<span>降低成本</span>
								<img alt="" src={i24} />
							</div>
							<p>电子发票无需邮递，降低成本</p>
						</li>

					</ul>
				</section>



				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

import React, { Component } from "react"
import './userManager.less'
import { Button,Divider,Table, Modal } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
const addData=[
	{
		type: 'SELECT',
		label: '用户信息',
		field: 'userName',
		placeholder: '请选择用户信息',
		width: "100%",
		initialValue:'0',
		list: [
            {id: '0', name: '集中托管开票员'},
            {id: '1', name: '企业开票员'}
        ]
	},
	{
		type: 'INPUT',
		label: '操作员代码',
		field: 'roleUuid',
		width: "100%",
		initialValue:'',
		placeholder: '请输入操作员代码',
	},
	{
		type: 'INPUT',
		label: '操作员名称',
		field: 'userPhone',
		placeholder: '请输入操作员名称：',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '登录口令（8～32位）',
		field: 'idCard',
		placeholder: '请输入登录口令（8～32位）',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '确认口令',
		field: 'password',
		placeholder: '请输入确认口令',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '所属纳税人名称',
		field: 'remark',
		placeholder: '请输入所属纳税人名称',
		width: "100%",
		initialValue:'',
    },
    {
		type: 'INPUT',
		label: '所属纳税人识别号',
		field: 'remark',
		placeholder: '请输入所属纳税人识别号',
		width: "100%",
		initialValue:'',
    }, 
]
export default class UserManager extends Component {
	constructor(props) {
		super(props);
		this.state={
            tableList: [],
            //修改回显数据
			updateData:[],
			isRoleVisible: false,
			flag:false,
		}
    }
    add=()=>{
		this.state.updateData.forEach(res=>{
			res.initialValue=''
		})
        this.setState({
			isRoleVisible:true,
			flag:false
		})
	}
	handleFilter=(e)=>{
    }
    handleRoleSubmit=()=>{}
    addUserDataBack=(e)=>{
		// if (Object.keys(e).length !== 0){
		//     for(let key in e){
		// 		this.state.addData[key]=e[key]
		// 	}
		// }else{
		//     return false
		// } 
	}
	render() {
		const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
            },
            {
			    title: '用户代码',
			    dataIndex: 'userName',
			    key: 'userName',
			},
			{
                title: '用户名称',
                dataIndex: 'idCard',
                key: 'idCard',
			},
			{
			    title: '用户类型',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
			    title: '企业名称',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '企业识别号',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '状态',
				dataIndex: 'x',
				key: 'x',
            },
            { 
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <div>
                        <Button type="primary" style={{marginRight: '10px'}} onClick={()=>{this.edit(record)}}>编辑</Button>
                        <Button type="danger" onClick={()=>{this.del(record)}}>删除</Button>
  
                    </div>
                  ),
              }
		  ];
		const formList = [
            {
				type: 'INPUT',
				label: '用户代码',
				field: 'fileName',
				placeholder: '请输入用户代码',
				width: "100%",
			},{
				type: 'INPUT',
				label: '用户名称',
				field: 'fileName',
				placeholder: '请输入用户名称',
				width: "100%",
			},{
				type: 'SELECT',
				label: '用户类型',
				field: '1',
				placeholder: '请选择用户类型',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"未发送"
					},
					
				]
			}, {
				type: 'SELECT',
				label: '用户状态',
				field: '2',
				placeholder: '用户状态',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"全部",					
					},
					{
						id:'1',name:"正常",	
					},
					{
						id:'2',name:"异常",
					},
					{
						id:'3',name:"未启动",
					}
					
				]
			},
		]
		return (
			<div>
				<div>
					<h2>{sessionStorage.getItem("pageTitle")||"用户管理"}</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
				<div style={{ background: '#C7E2FF'}}>
                    <Button type="link" onClick={()=>{this.add()}} style={{color: '#333333'}}><img src={require('../../../assets/icon/add.png')} style={{marginRight: '10px'}}/>创建</Button>
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>
                <Modal
                    title="创建"
                    visible={this.state.isRoleVisible}
                    onOk={this.handleRoleSubmit}
                    onCancel={()=>{
                        this.setState({
                            isRoleVisible:false
                        })
                    }}
                >
					<ModalForm formList={this.state.flag?this.state.updateData:addData} modalData={this.addUserDataBack.bind(this)}/>
				</Modal>

			</div>
		)
	}
}
import React, { Component } from "react";
import Footer1 from "../../../components/webfooter1/webFooter1";
import "./contract.less";

import imgBanner from "../../../assets/img/contract/banner.png";
import heTongGuanLi from "../../../assets/img/contract/heTongGuanLi.png";
import zhengJuFuWu from "../../../assets/img/contract/zhengJuFuWu.png";
import zaiXianBianJi from "../../../assets/img/contract/zaiXianBianJi.png";
import heTongQianShu from "../../../assets/img/contract/heTongQianShu.png";
import hangYeDingZhi from "../../../assets/img/contract/hangYeDingZhi.png";

// import beijingKuai from "../../../assets/img/contract/beijingKuai.png";
import tuPian from "../../../assets/img/contract/tuPian.png";


export default class Contract extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}
	render() {

		return (
			<div className="web_content">
				<section className="contract_1">
					<img src={imgBanner} />
				</section>
				<div className="web_content_box">
					<section className="contract_2">
						<h3>电子合同产品功能介绍</h3>
						<ul>
							<li>
								<img src={heTongGuanLi} />
								<span>合同管理</span>
								<div>对合同的查阅、下载，合同信息管理</div>
							</li>
							<li>
								<img src={zhengJuFuWu} />
								<span>证据服务</span>
								<div>证据保全、司法鉴定等法律公正处理</div>
							</li>
							<li>
								<img src={zaiXianBianJi} />
								<span>在线编辑</span>
								<div>签约合同编辑、确认</div>
							</li>
							<li>
								<img src={heTongQianShu} />
								<span>合同在线签署</span>
								<div>实名认证、在线签署、签署确认</div>
							</li>
							<li>
								<img src={hangYeDingZhi} />
								<span>行业定制</span>
								<div>根据企业行业，定制专属合同</div>
							</li>
						</ul>
					</section>
					<section  className="contract_3">
						<h3>签署流程</h3>
						<ul>
							<li>
								<div>注册账号</div>
								<span></span>
								<span></span>
								<span>1</span>
							</li>
							<li>
								<div>实名认证</div>
								<span></span>
								<span></span>
								<span>2</span>
							</li>
							<li>
								<div>创建合同</div>
								<span></span>
								<span></span>
								<span>3</span>
							</li>
							<li>
								<div>发起签约</div>
								<span></span>
								<span></span>
								<span>4</span>
							</li>
							<li>
								<div>双方在线签署</div>
								<span></span>
								<span></span>
								<span>5</span>
							</li>
						</ul>
					</section>
					<section className="contract_4">
						<h3>电子合同价值</h3>
						<div>
							<div>
								<img src={tuPian} />
							</div>
							<ul>
								<li>数字化管理大量合同</li>
								<li>防范业务过程中用章风险</li>
								<li>实现业务的电子签约</li>
								<li>降低业务运营成本</li>
								<li>具有法律效率的电子证据</li>
								<li>与企业需求服务系统定制对接</li>
								<li>降低合同管理成本</li>
							</ul>
						</div>
					</section>
					<section className="contract_5">
						<h3>广泛运用于多个行业</h3>
						<ul>
							<li>
								<span>金融</span>
								<hr/>
								<div>降低法律风险、提升风控水平</div>
							</li>
							<li>
								<span>教育培训</span>
								<hr/>
								<div>在线签署、降本增效、安全可控</div>
							</li>
							<li>
								<span>人力资源</span>
								<hr/>
								<div>无纸化办公、降本增效</div>
							</li>
							<li>
								<span>物流</span>
								<hr/>
								<div>实现上下游交易电子化、安全环保</div>
							</li>
							<li>
								<span>供应链</span>
								<hr/>
								<div>缩短交易周期提高运营管理效率</div>
							</li>
							<li>
								<span>电商</span>
								<hr/>
								<div>全程电子化、更加高效协作方式</div>
							</li>
						</ul>
					</section>
				</div>
				<Footer1/>
			</div>
		);
	}
}

import React from 'react'
import { BackTop } from 'antd';
import './webFooter.less'

export default class WebFooter extends React.Component {


	render() {
		return (
			<div className='web-footer-last'>
				<BackTop>
					<div className="ant-back-top-inner">
						<img src={require('../../assets/img/logo/toUp.png')} alt=""  />
					</div>
				</BackTop>
				<div className="footer-top-box">
					<div className="footer-top">
						<div className="foot-left">
							<div className="logo">
								<img src={require('../../assets/img/logo/logo_png.png')} alt="" />
								&nbsp;&nbsp;&nbsp;
								<span>中税创新</span>
							</div>
							<div>
								<div>
									<p>
										联系电话：0755-86546860(7*24小时)
									</p>
									<p>
										联系邮箱：customerservice@bjzsxx.com
									</p>
								</div>
								<div>
									<p>
										公司名称：深圳市中税创新科技有限公司
									</p>
									<p>
										公司地址：深圳市南山区粤兴二道6号武汉大学深圳产学研大楼B210
									</p>
								</div>
							</div>
						</div>

						<div className="foot-right">
							<div>
								<img src={require('../../assets/img/logo/gfwb.png')} alt="" />

								<span>官方微博</span>
							</div>
							<div>
								<img src={require('../../assets/img/logo/phgzh.png')} alt="" />

								<span>票慧公众号</span>
							</div>
						</div>
					</div>
				</div>
				<div className="myHr"></div>
				<div className="foot-bottom">
					中税创新&nbsp;&nbsp;&nbsp;版权所有&nbsp;&nbsp;&nbsp;Copyright©2021, bjzs All Rights Reserved&nbsp;<a href="http://beian.miit.gov.cn" target="_block">粤ICP备19148303号</a>
				</div>
			</div>
		);
	}
}

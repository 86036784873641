import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, DatePicker, message} from 'antd'
import Utils from '../../utils/index.js';

import "./index.less"
const FormItem = Form.Item;
const Option = Select.Option;

class FilterForm extends React.Component{

    handleFilterSubmit = ()=>{
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.filterSubmit(fieldsValue);
        // message.success('操作成功')
    }

    

    reset = ()=>{
        this.props.form.resetFields();
    }

    initFormList = ()=>{
		
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const formItemList = [];
        if (formList && formList.length>0){
            formList.forEach((item,i)=>{
                let label = item.label;
				let field = item.field;
                let field1 = item.field1;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                let disabled = item.disabled||""
                if (item.type == '城市') {
                    
                    const city = <FormItem label="SELECT" key={field}>
                        {
                            getFieldDecorator('city',{
                                initialValue:'0'
                            })(
                                <Select
                                    style={{width:80}}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList([{ id: '0', name: '全部' }, { id: '1', name: '北京' }, { id: '2', name: '上海' }, { id: '3', name: '天津' }, { id: '4', name: '杭州' }])}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(city)
                }else if (item.type == '时间查询'){
                    const begin_time = <FormItem label={item.type} key={field}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss"/>
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field1}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                }else if(item.type === 'INPUT'){
                    const INPUT = <FormItem label={label} key={field} >
                        {
                            getFieldDecorator(field,{
                                initialValue: initialValue
                            })(
                                <Input type="text" style={{ width: width }} disabled={disabled?disabled:false}  autoComplete="off" placeholder={placeholder} />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                }else if (item.type === 'AUTOSELECT') {
                    const SELECT = <FormItem label={label} key={field} >
                            {
                                getFieldDecorator(field, {
                                    initialValue: initialValue,
                                })(
                                    <Select
                                        showSearch
                                        initialValue=""
                                        style={{ width: width }}
                                        optionFilterProp="children"
                                        placeholder={placeholder}
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                    >
                                        {
                                            placeholder==="请选择当前模板名称"?(Utils.getOptionList2(item.list)):(Utils.getOptionList(item.list))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'SELECT') {
                    const SELECT = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Select
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator([field], {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>;
                    formItemList.push(CHECKBOX)
                } else if (item.type == 'DATE') {
                    const Date = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator([field])(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(Date)
                }else if(item.type==="DATEDAY"){
                    const Date = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator([field])(
                                <DatePicker placeholder={placeholder} format="YYYY-MM-DD" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(Date)
                }
            })
        }
        return formItemList;
    }
    render(){
        let fieldsValue = this.props.form.getFieldsValue();
        let name="";
        try{
            this.props.modalDatas(fieldsValue);
        }catch{
            // console.log("")
        }
        try{
            name = this.props.btnName
        }catch{}

        return (
            <Form layout="inline">
                { this.initFormList() }
                <FormItem>
                    <Button  type="primary" style={{ margin: '0 20px' }} onClick={this.handleFilterSubmit}>{name?name:'查询'}</Button>
                    <Button onClick={this.reset}>重置</Button>
                </FormItem>
            </Form>
        );
    }
}
export default Form.create({})(FilterForm);
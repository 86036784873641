import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor } from "./store";

import md5 from "js-md5";
React.Component.prototype.$md5 = md5;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

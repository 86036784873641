import React from 'react'
import './webFooter2.less'
import { Modal, Button, Icon, Input, Select } from 'antd';

import bgFooter from './../../assets/img/footers/footer2.png'


const { Option } = Select;
const provinceData = ['请选择省份', "北京", "上海", "广东", "江苏", "浙江", "重庆", "安徽", "福建", "甘肃", "广西",
	"贵州", "海南", "河北", "黑龙江", "河南", "湖北", "湖南", "江西", "吉林", "辽宁", "内蒙古",
	"宁夏", "青海", "山东", "山西", "陕西", "四川", "天津", "新疆", "西藏", "云南", "香港",
	"澳门", "台湾"];

const cityData = {
	'请选择省份': ['请选择城市'],
	"北京": ["东城", "西城", "朝阳", "丰台", "石景山", "海淀", "门头沟", "房山", "通州", "顺义", "昌平", "大兴", "平谷", "怀柔", "密云", "延庆"],
	"上海": ["崇明", "黄浦", "卢湾", "徐汇", "长宁", "静安", "普陀", "闸北", "虹口", "杨浦", "闵行", "宝山", "嘉定", "浦东", "金山", "松江", "青浦", "南汇", "奉贤"],
	"广东": ["广州", "深圳", "珠海", "东莞", "中山", "佛山", "惠州", "河源", "潮州", "江门", "揭阳", "茂名", "梅州", "清远", "汕头", "汕尾", "韶关", "顺德", "阳江", "云浮", "湛江", "肇庆"],
	"江苏": ["南京", "常熟", "常州", "海门", "淮安", "江都", "江阴", "昆山", "连云港", "南通", "启东", "沭阳", "宿迁", "苏州", "太仓", "泰州", "同里", "无锡", "徐州", "盐城", "宜兴", "仪征", "张家港", "镇江", "周庄"],
	"浙江": ["杭州", "安吉", "慈溪", "定海", "奉化", "海盐", "黄岩", "湖州", "嘉兴", "金华", "临安", "临海", "丽水", "宁波", "瓯海", "平湖", "千岛湖", "衢州", "江山", "瑞安", "绍兴", "嵊州", "台州", "温岭", "温州", "余姚", "舟山"],
	"重庆": ["万州", "涪陵", "渝中", "大渡口", "江北", "沙坪坝", "九龙坡", "南岸", "北碚", "万盛", "双桥", "渝北", "巴南", "黔江", "长寿", "綦江", "潼南", "铜梁", "大足", "荣昌", "璧山", "梁平", "城口", "丰都", "垫江", "武隆", "忠县", "开县", "云阳", "奉节", "巫山", "巫溪", "石柱", "秀山", "酉阳", "彭水", "江津", "合川", "永川", "南川"],
	"安徽": ["合肥", "安庆", "蚌埠", "亳州", "巢湖", "滁州", "阜阳", "贵池", "淮北", "淮南", "黄山", "九华山", "六安", "马鞍山", "宿州", "铜陵", "屯溪", "芜湖", "宣城"],
	"福建": ["福州", "厦门", "泉州", "漳州", "龙岩", "南平", "宁德", "莆田", "三明"],
	"甘肃": ["兰州", "白银", "定西", "敦煌", "甘南", "金昌", "酒泉", "临夏", "平凉", "天水", "武都", "武威", "西峰", "张掖"],
	"广西": ["南宁", "百色", "北海", "桂林", "防城港", "贵港", "河池", "贺州", "柳州", "钦州", "梧州", "玉林"],
	"贵州": ["贵阳", "安顺", "毕节", "都匀", "凯里", "六盘水", "铜仁", "兴义", "玉屏", "遵义"],
	"海南": ["海口", "儋县", "陵水", "琼海", "三亚", "通什", "万宁"],
	"河北": ["石家庄", "保定", "北戴河", "沧州", "承德", "丰润", "邯郸", "衡水", "廊坊", "南戴河", "秦皇岛", "唐山", "新城", "邢台", "张家口"],
	"黑龙江": ["哈尔滨", "北安", "大庆", "大兴安岭", "鹤岗", "黑河", "佳木斯", "鸡西", "牡丹江", "齐齐哈尔", "七台河", "双鸭山", "绥化", "伊春"],
	"河南": ["郑州", "安阳", "鹤壁", "潢川", "焦作", "济源", "开封", "漯河", "洛阳", "南阳", "平顶山", "濮阳", "三门峡", "商丘", "新乡", "信阳", "许昌", "周口", "驻马店"],
	"湖北": ["武汉", "恩施", "鄂州", "黄冈", "黄石", "荆门", "荆州", "潜江", "十堰", "随州", "武穴", "仙桃", "咸宁", "襄阳", "襄樊", "孝感", "宜昌"],
	"湖南": ["长沙", "常德", "郴州", "衡阳", "怀化", "吉首", "娄底", "邵阳", "湘潭", "益阳", "岳阳", "永州", "张家界", "株洲"],
	"江西": ["南昌", "抚州", "赣州", "吉安", "景德镇", "井冈山", "九江", "庐山", "萍乡", "上饶", "新余", "宜春", "鹰潭"],
	"吉林": ["长春", "吉林", "白城", "白山", "珲春", "辽源", "梅河", "四平", "松原", "通化", "延吉"],
	"辽宁": ["沈阳", "鞍山", "本溪", "朝阳", "大连", "丹东", "抚顺", "阜新", "葫芦岛", "锦州", "辽阳", "盘锦", "铁岭", "营口"],
	"内蒙古": ["呼和浩特", "阿拉善盟", "包头", "赤峰", "东胜", "海拉尔", "集宁", "临河", "通辽", "乌海", "乌兰浩特", "锡林浩特"],
	"宁夏": ["银川", "固源", "石嘴山", "吴忠"],
	"青海": ["西宁", "德令哈", "格尔木", "共和", "海东", "海晏", "玛沁", "同仁", "玉树"],
	"山东": ["济南", "滨州", "德州", "东营", "菏泽", "济宁", "莱芜", "聊城", "临沂", "蓬莱", "青岛", "曲阜", "日照", "泰安", "潍坊", "威海", "烟台", "枣庄", "淄博"],
	"山西": ["太原", "长治", "大同", "侯马", "晋城", "离石", "临汾", "宁武", "朔州", "沂州", "阳泉", "榆次", "运城"],
	"陕西": ["西安", "安康", "宝鸡", "汉中", "渭南", "商州", "绥德", "铜川", "咸阳", "延安", "榆林"],
	"四川": ["成都", "巴中", "达川", "德阳", "都江堰", "峨眉山", "涪陵", "广安", "广元", "九寨沟", "康定", "乐山", "泸州", "马尔康", "绵阳", "眉山", "南充", "内江", "攀枝花", "遂宁", "汶川", "西昌", "雅安", "宜宾", "自贡", "资阳"],
	"天津": ["天津", "和平", "东丽", "河东", "西青", "河西", "津南", "南开", "北辰", "河北", "武清", "红桥", "塘沽", "汉沽", "大港", "宁河", "静海", "宝坻", "蓟县"],
	"新疆": ["乌鲁木齐", "阿克苏", "阿勒泰", "阿图什", "博乐", "昌吉", "东山", "哈密", "和田", "喀什", "克拉玛依", "库车", "库尔勒", "奎屯", "石河子", "塔城", "吐鲁番", "伊宁"],
	"西藏": ["拉萨", "阿里", "昌都", "林芝", "那曲", "日喀则", "山南"],
	"云南": ["昆明", "大理", "保山", "楚雄", "东川", "个旧", "景洪", "开远", "临沧", "丽江", "六库", "潞西", "曲靖", "思茅", "文山", "西双版纳", "玉溪", "中甸", "昭通"],
	"香港": ["香港", "九龙", "新界"],
	"澳门": ["澳门"],
	"台湾": ["台北", "基隆", "台南", "台中", "高雄", "屏东", "南投", "云林", "新竹", "彰化", "苗栗", "嘉义", "花莲", "桃园", "宜兰", "台东", "金门", "马祖", "澎湖"],
};


export default class WebHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			cities: cityData[provinceData[0]],
			secondCity: cityData[provinceData[0]][0],
			shengFen: '',
			city: ''
		}
	}

	//省份
	handleProvinceChange = value => {
		// console.log(value)
		this.setState({
			cities: cityData[value],
			secondCity: cityData[value][0],
			shengFen: value,
		});
	};
	//城市
	onSecondCityChange = value => {
		// console.log(value)
		this.setState({
			secondCity: value,
			city: value,
		});
	};


	showModal = () => {
		this.setState({ visible: true })
	}
	changeValue = (key, e) => {
		console.log(key, e.target.value)
	}
	closeModal = () => {
		this.setState({ visible: false })
	}

	render() {
		const { cities } = this.state;
		return (
			<div className="footer2_content">
				<div className="footer2-img">
					<img src={bgFooter} alt=""/>
				</div>
				<div className="text">
					<span>{this.props.title.title || ""}</span>
					<span style={{ display: this.props.title.phone ? 'block' : 'none' }}>{this.props.title.phone}</span>
					<div className="span" onClick={this.showModal}>立即咨询</div>
				</div>

				<Modal className="modalClass"
					visible={this.state.visible}
					onCancel={() => this.setState({ visible: false })}
					footer={null}
					destroyOnClose={false}
					width={750}
					// closeIcon={<Icon type="close-circle" theme="filled" style={{ color: "#fff" }} />}
				>
					<h3>免费产品咨询</h3>
					<div>
						<p>填写完成会有专门服务人员联系您；另中创平台非常注重您的隐私，您在互联网上发送的所有个人信息都是加密的，不能被第三方读取</p>
						<div>
							<label htmlFor="name01">您的姓名：</label><Input id="name01" placeholder="您的真实姓名" onChange={(e) => { this.changeValue('name', e) }} />
						</div>
						<div>
							<label htmlFor="name011">您的手机：</label><Input id="name011" placeholder="您的11位手机号" onChange={(e) => { this.changeValue('phone', e) }} />
						</div>
						<div>
							<label htmlFor="name012">电子邮箱：</label><Input id="name012" placeholder="请输入您的电子邮箱" onChange={(e) => { this.changeValue('email', e) }} />
						</div>
						<div>
							<label htmlFor="name013">公司名称：</label><Input id="name013" placeholder="请输入您的公司名称" onChange={(e) => { this.changeValue('company', e) }} />
						</div>
						<div >
							<label >公司地址：</label>
							<div className="jilanSelect">
								<Select
									defaultValue={provinceData[0]}
									style={{ width: 244 }}
									onChange={this.handleProvinceChange}
								>
									{provinceData.map(province => (
										<Option key={province}>{province}</Option>
									))}
								</Select>
								<Select
									style={{ width: 244 }}
									value={this.state.secondCity}
									onChange={this.onSecondCityChange}
								>
									{cities.map(city => (
										<Option key={city}>{city}</Option>
									))}
								</Select>
							</div>
						</div>
						<div>
							<label htmlFor="name014">咨询项目：</label><Input id="name014" placeholder="输入您资讯项目" onChange={(e) => { this.changeValue('zxxm', e) }} />
						</div>
						{/* <div>
							<label htmlFor="name015">其他描述：</label><Input id="name015" placeholder="Basic usage" onChange={(e) => { this.changeValue('qtms', e) }} />
						</div> */}
						<div>
							<Button onClick={ this.closeModal }>提交</Button>
							<Button onClick={ this.closeModal } >取消</Button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

import React, { Component } from "react";
import "./contact.less";
import { Button,Input} from "antd";

import lianxi from "../../assets/img/assistance/lianxi.png";


const { TextArea } = Input;
export default class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
    change = (e,key)=>{
		let obj = {}
		obj[key] = e.target.value
		this.setState({...obj})
	}
	submit=()=>{
		console.log(this.state)
	}
	render() {
		const bg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + lianxi + ")", //图片的路径
			backgroundPosition: "78% 280px",
		}
		return (
			<div className="contact_content" style={bg1}>
				<h3>联系我们</h3>
				<h4>
					请留下您的联系方式和问题，我们解决方案专家将尽快联系您。
				</h4>
				<ul>
					<li>
						<label htmlFor="chenghu">称呼：</label>
						<Input id="chenghu" onChange={(e)=>this.change(e,'chenghu')} />
					</li>
					<li>
						<label htmlFor="gongsi">公司：</label>
						<Input id="gongsi" onChange={(e)=>this.change(e,'gongsi')} />
					</li>
					<li>
						<label htmlFor="dianhua">电话：</label>
						<Input id="dianhua" onChange={(e)=>this.change(e,'dianhua')} />
					</li>
					
					<li>
						<label htmlFor="email">邮箱：</label>
						<Input id="email" onChange={(e)=>this.change(e,'email')} />
					</li>
					<li>
						<label htmlFor="beizhu">备注：（您想咨询的行业）</label>
						<TextArea id="beizhu" onChange={(e)=>this.change(e,'beizhu')} />
					</li>
				</ul>
				<Button type="primary" className="contact_submit" onClick={this.submit}>查询</Button>
			</div>
		);
	}
}

import React, { Component } from "react"
import './ensureState.less'
import { Button,Divider,Table } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state={
			tableList: []
		}
	}
	handleFilter=(e)=>{
	}
	render() {
		const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
            },
            {
                title: '上传操作',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '上传状态',
                dataIndex: 'userPhone',
                key: 'userPhone',
            },
            {
                title: '纳税人名称',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: '纳税人识别号',
                dataIndex: 'userPhone',
                key: 'userPhone',
            },
            {
			    title: '税控盘编号',
			    dataIndex: 'userName',
			    key: 'userName',
			},
            {
			    title: '盘组设备名称',
			    dataIndex: 'userName',
			    key: 'userName',
			},
			{
                title: '服务地址或域名',
                dataIndex: 'idCard',
                key: 'idCard',
			},
			{
			    title: '端口号',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
			    title: '发票上传服务地址',
				dataIndex: 'x',
				key: 'x',
			},
			{
			    title: '统一受理地址',
				dataIndex: 'qi',
				key: 'qi',
			},
			{
			    title: '统一受理端口号',
				dataIndex: 'pan',
				key: 'pan',
			},
			{
			    title: '省份',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: '板式生成地址',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '板式生成端口号',
				dataIndex: 'remark',
				key: 'remark',
            },
			{ 
			  title: '操作',
			  dataIndex: 'operation',
			  key: 'operation',
			  render: (text, record) => (
				  <div>
					  <Button type="primary" style={{marginRight: "10px"}} onClick={()=>{this.edit(record)}}>编辑</Button>
					  <Button type="danger" onClick={()=>{this.del(record)}}>删除</Button>

				  </div>
				),
			}
		  ];
		const formList = [
			{
				type: 'SELECT',
				label: '盘组设备名称',
				field: '1',
				placeholder: '请选择盘组设备名称',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"未发送"
					},
					
				]
			}, {
				type: 'INPUT',
				label: '税控盘编号',
				field: 'fileName',
				placeholder: '请输入税控盘编号',
				width: "100%",
			},{
				type: 'INPUT',
				label: '服务端口号',
				field: 'fileName',
				placeholder: '请输入服务端口号',
				width: "100%",
			}
		]
		return (
			<div>
				<div>
					<h2>税控盘状态</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
				<div style={{padding:"12px 37px", background: '#C7E2FF'}}>
					<span>共计 {} 个</span>
					<span style={{margin: '0 60px'}}>失败 {} 个</span>
					<span>成功 {} 个</span>
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>

			</div>
		)
	}
}
const menuList=[
    {
        title:'企业控制台',
        key:'/system/desk',
        icon:'icon-desktop',
		refs:"desk",
    },
    {
        title:'合同管理',
        key:'/system/ContractManagement',
        icon:'icon-hetong5',
        children:[
            {
                title:'所有合同',
                key:'/system/allContract',
                icon:'icon-hetong5',
				refs:"allContract",
            },
            {
                title:'发起签约',
                key:'/system/signContract',
                icon:'icon-hetong5',
				        refs:"signContract",
            },{
                title:'发送签约',
                key:'/system/sendContract',
                icon:'icon-Send-Mail',
				refs:"sendContract",
            },{
                title:'文件详情',
                key:'/system/fileDetail',
                icon:'icon-filesearch',
				refs:"fileDetail",
            },{
                title:'合同签署',
                key:'/system/contractSign',
                icon:'icon-hetong7',
				refs:"contractSign",
            },{
                title:'批量签署',
                key:'/system/batchContract',
                icon:'icon-more',
				refs:"batchContract",
            }
        ]
    },
    {
        title:'基础管理',
        key:'/system/enterpriseMagager',
        icon:'icon-jichuguanli',
        children:[
            {
                title:'账户管理',
                key:'/system/AccountManager',
                icon:'icon-account1',
                refs:"AccountManager",
                children:[
                    {
                        title:'成员管理',
                        key:'/system/MemberManager',
                        icon:'icon-account2',
                        refs:"MemberManager",
                    },
                    {
                        title:'角色管理',
                        key:'/system/roleManager',
                        icon:'icon-add-account',
                        refs:"roleManager",
                    }
                ]
            },{
                title:'充值管理',
                key:'/system/rechargeManager',
                icon:'icon-account3',
                refs:"rechargeManager",
                children:[
                    {
                        title:'充值记录',
                        key:'/system/rechargeRecord',
                        icon:'icon-account3',
                        refs:"rechargeRecord",
                    },
                    {
                        title:'使用记录',
                        key:'/system/userRecord',
                        icon:'icon-faqiqianyue',
                        refs:"userRecord",
                    }
                ]
            },{
                title:'印章管理',
                key:'/system/sealManager',
                icon:'icon-contract1',
				refs:"sealManager",
            }

        ]
    },
    {
        title:'企业管理',
        key:'/system/company',
        icon:'icon-gongsi',
        children:[
            {
                title:'菜单管理',
                key:'/system/menuManager',
                icon:'icon-icon-quanxianxg',
                refs:"menu",
            }
        ]
    }
]
export default menuList

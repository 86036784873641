import React from 'react'
import './partners.less'

import Rectangle1 from "./../../assets/img/home/Rectangle1.png"
import Rectangle2 from "./../../assets/img/home/Rectangle2.png"
import Rectangle3 from "./../../assets/img/home/Rectangle3.png"
import Rectangle4 from "./../../assets/img/home/Rectangle4.png"
import Rectangle5 from "./../../assets/img/home/Rectangle5.png"
import Rectangle6 from "./../../assets/img/home/Rectangle6.png"
import Rectangle7 from "./../../assets/img/home/Rectangle7.png"
import Rectangle8 from "./../../assets/img/home/Rectangle8.png"
import Rectangle9 from "./../../assets/img/home/Rectangle9.png"
import Rectangle10 from "./../../assets/img/home/Rectangle10.png"
import Rectangle11 from "./../../assets/img/home/Rectangle11.png"
import Rectangle12 from "./../../assets/img/home/Rectangle12.png"

export default class Partners extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	imgList=[
		{url:Rectangle1,},
		{url:Rectangle2,},
		{url:Rectangle3,},
		{url:Rectangle4,},
		{url:Rectangle5,},
		{url:Rectangle6,},
		{url:Rectangle7,},
		{url:Rectangle8,},
		{url:Rectangle9,},
		{url:Rectangle10,},
		{url:Rectangle11,},
		{url:Rectangle12,},
	]

	render() {
		return (
            <div className="home-content7">
					<div className="title">战略合作伙伴</div>
					<div className="content7-box">
						{
							this.imgList.map((item,index)=>{
								return <img className="content7-box-img" key={index} src={item.url} alt="" />
							})
						}
					</div>

				</div>
			);
	}
}

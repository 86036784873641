import React, {Component} from "react"
import {Button, Divider, message, Popconfirm, Select, Spin, Table, Tooltip, TreeSelect,PageHeader } from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import BaseForm from '../../../../components/baseForm/baseForm'
// import ModalForm from '../../../components/modal/modal'
import {
  createNegativeInvoice,
  invoiceReapet,
  invoiceVoid,
  pushDzfp,
  queryInvoiceByPage,
  redInfoPaperApply,
  redInfoPaperQuery
} from '../../../../api'
import moment from 'moment'
import utils from "../../../../utils/index"
import './invoiceQuery.less'

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;



let treeData = []

export default class WritingState extends Component {
    constructor(props) {
        super(props);
        this.menberManager = React.createRef()
        let st = this.props.location.state;
        if(!st) {
          window.location.href = '/system/errorInvoice'
          return
        }
        this.state = {
            switchDetails: false,
            invoiceVoids: false,
            tableList: [],
            getDataInfo: {
                fpdm: '',
                fphm: '',
                jqbh: '',
                minAmount: '',
                maxAmount: '',
                startTime: '',
                endTime: '',
                biller: '',
                buyerName: '',
                invoiceType: '',
                slv: '',
                orderNo: '',
                nsrsbh: this.props.location.state.registeredNo,
                status: '',
                error: true,
                page: 1,
                rows: 10
            },
            tableList2: [],
            total: 1,
            xq: {},
            showInfo: false,
            detailInfo: '',
            loading: false,
            openKey: false,
            value: [],
            showEmail: false,
            paramPul: {},
            showStatus: false,
            paramStatus: {},
            selectedRows:[],
            statusList: [
                { id: 0, name: "已抵扣" },
                { id: 1, name: "未抵扣" },
                { id: 2, name: "销方申请" },
            ],
            openRepair: false,
        }
    }

    componentDidMount() {
        this.getData()
        let lists = JSON.parse(sessionStorage.getItem('switchDetails')) || [];
        let switchDetails, invoiceVoids;
        try {
            switchDetails = lists.includes("createNegaticeInvoice")
        } catch {
            switchDetails = false
        }
        try {
            invoiceVoids = lists.includes("copyAndReport/invoiceVoid")
        } catch {
            invoiceVoids = false
        }
        this.setState({ switchDetails, invoiceVoids })
    }

    getData() {
        const { getDataInfo: param } = this.state
        this.setState({ loading: true })
        queryInvoiceByPage(param).then(res => {
            if (res.code === '0000') {
                const { resultList: tableList } = res
                tableList.forEach((element, index) => {
                    element.key = index + 1
                });

                const { currentPage: page, pageSize: rows, totalCount: total } = res.pager
                this.setState({
                    tableList,
                    getDataInfo: { ...this.state.getDataInfo, page, rows },
                    total
                })
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    //推送
    pushInvoice = data => {
        let { fpdm, fphm, gfkhyx: email } = data
        let paramPul = { fpdm, fphm, email }
        this.setState({ showEmail: true, paramPul })
    }


    //导出
    exports = () => {
        let { startTime, endTime, invoiceType, fpdm, fphm, buyerName, nsrsbh, orderNo } = this.state.getDataInfo

        if (!startTime) {
            return message.error('开票起始日期,不能为空')
        }
        if (!endTime) {
            return message.error('开票结束时间,不能为空')
        }

        // exportSuccessInvoices({startTime, endTime, invoiceType,fpdm,fphm,buyerName,nsrsbh,orderNo}).then(res=>{
        //     console.log(res)
        //         utils.downLoad(res,"票据列表")
        // })
        // return

        let token = sessionStorage.getItem("token")
        let urlLocation = window.location.href
        let baseUrl = ''

        if (urlLocation.includes(process.env.REACT_APP_SEARCH)) {
            baseUrl = process.env.REACT_APP_KPBASE_PRO
        } else {
            baseUrl = process.env.REACT_APP_KPBASE
        }

        let url = baseUrl + `/simpleInvoice/exportSuccessInvoices?startTime=${startTime}&endTime=${endTime}&invoiceType=${invoiceType}&token=${token}&fpdm=${fpdm}&fphm=${fphm}&buyerName=${buyerName}&nsrsbh=${nsrsbh}&orderNo=${orderNo}`;
        utils.aDownLoad(url)
    }


    getInfo = data => {
        this.setState({ showInfo: true, detailInfo: data })
    }

    filterInvoiceType(val) {
        switch (val) {
            case '004':
                return '增值税专用发票'
            case '007':
                return '增值税普通发票'
            case '025':
                return '卷式发票'
            case '026':
                return '增值税电子发票'
            case '028':
                return '电子专用发票'
            default:
                return '-'
        }
    }

    filterKplx(val) {
        switch (val) {
            case 1:
                return '待填写抬头'
            case 2:
                return '开票完成'
            case 3:
                return '开具错误'
            case 4:
                return '正数作废'
            case 5:
                return '负数发票'
            case 6:
                return '负数作废'
            case 7:
                return '空白作废'
            case 8:
                return '开票前准备'
            case 9:
                return '门店审核'
            case 10:
                return '驳回'
            case 11:
                return '商家作废二维码'
            case 20:
                return '开票中'
            case 22:
                return (
                    <span>
                        开票失败
                    </span>)
            default:
                return '-'
        }
    }

    filterTime(val) {
        if (!val) return
        return moment(typeof val === 'string' ? Number(val) : val, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
    }

    invoiceToVoid = data => {
        const { kpzdbs, invoiceType: fplxdm, fpdm, fphm } = data
        const param = { kpzdbs, fplxdm, zflx: 1, fpdm, fphm }
        this.setState({ loading: true })
        invoiceVoid(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    invoiceToReapet = data => {
        const { xhdwsbh,orderNo } = data
        const param = { orderNos:[orderNo],salerTaxNum:xhdwsbh }
        this.setState({ loading: true })
        invoiceReapet(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.getData()
            this.setState({ loading: false })
        })
    }
    invoiceToReapetBatch = ()=>{

      const param = { salerTaxNum: this.state.getDataInfo.nsrsbh,orderNos: this.state.selectedRows }
      this.setState({ loading: true })
      invoiceReapet(param).then(res => {
        if (res.code == "0000") {
          message.success(res.msg)
        } else {
          message.warning(res.msg)
        }
        this.getData()
        this.setState({ loading: false })
      })
    }
    goRed = (data) => {
        this.setState({ loading: true })
        createNegativeInvoice(data).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
                this.getData()
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    paginationChange = val => {
        this.setState({
            getDataInfo: { ...this.state.getDataInfo, page: val.current, rows: val.pageSize },
            total: val.total
        }, () => this.getData())
    }

    search() {
        this.getData()
    }

    getDate = (val, key) => {
        if (!val) return
        if (key === 1) {
            return moment(val).format("YYYYMMDD000000");
        } else {
            return moment(val).format("YYYYMMDD235959");
        }
    }

    getSearchVal = val => {
        if (val.startTime) {
            val.startTime = this.getDate(val.startTime, 1)
        }
        if (val.endTime) {
            val.endTime = this.getDate(val.endTime, 2)
        }
        this.setState({ getDataInfo: { ...this.state.getDataInfo, ...val, page: 1, } }, () => this.getData())
    }

    modalDatas = (val) => {
        let { startTime, endTime, invoiceType } = val
        if (startTime) {
            startTime = this.getDate(startTime, 1)
        }
        if (endTime) {
            endTime = this.getDate(endTime, 2)
        }
        this.state.getDataInfo.startTime = startTime
        this.state.getDataInfo.endTime = endTime
        this.state.getDataInfo.invoiceType = invoiceType
    }

    onChange = value => {
        this.setState({ value });
    };

    onOk = () => {
        this.setState({ openKey: false })
    }

    changeEmail = (email) => {
        this.setState({
            paramPul: { ...this.state.paramPul, email }
        })
    }
    submitEmial = () => {
        let param = this.state.paramPul
        this.setState({ loading: true })
        pushDzfp(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    redCodes = (data) => {
        let { fpdm, fphm } = data
        let paramStatus = { fpdm, fphm }
        this.setState({ showStatus: true, paramStatus })
    }
    selectStatus = (applyReason) => {
        // console.log("123",applyReason)
        this.setState({
            paramStatus: { ...this.state.paramStatus, applyReason }
        })
    }
    statusOk = () => {
        let param = this.state.paramStatus
        redInfoPaperApply(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    getCodes = (data) => {
        let { fpdm, fphm } = data
        redInfoPaperQuery({ fpdm, fphm }).then(res => {
            if (res.code == "0000") {
                let { redInfoPaperNo, status } = res.resultObj
                if (status == 0) {
                    message.info("未处理")
                } else if (status == 1) {
                    message.info("已提交")
                } else if (status == 2) {
                    message.info("审核通过")
                    data.tzdbh = redInfoPaperNo
                    this.setState({})
                }
                else if (status == 3) {
                    message.info("审核不通过")
                }
            } else {
                message.error(res.msg)
            }
        })
    }
    render() {
        let state = this.props.location.state;
        let columns = [
            {
                title: '序号',
                dataIndex: "key",
                key: 'key',
                width: 60,
            },
            {
                title: '订单号',
                dataIndex: 'orderNo',
                key: 'orderNo',
                width: 200
            },
            // {
            //     title: '销货单位名称',
            //     dataIndex: 'xhdwmc',
            //     key: 'xhdwmc',
            //     width: 230
            // },
            {
                title: '发票代码/发票号码',
                dataIndex: 'fpdm',
                key: 'fpdm',
                width: 150,
                render:(_,record)=>(
                    <div>
                        <span>{record.fpdm}</span><br/>
                        <span>{record.fphm}</span>
                    </div>
                )
            },
            // {
            //     title: '发票号码',
            //     dataIndex: 'fphm',
            //     key: 'fphm',
            //     width: 100,
            // },
            {
                title: '购货单位名称',
                dataIndex: 'ghdwmc',
                key: 'ghdwmc',
                width: 230
            },
            {
                title: '含税金额',
                dataIndex: 'jshj',
                key: 'jshj',
                width: 100
            },
            {
                title: '开票日期',
                dataIndex: 'kprq',
                key: 'kprq',
                render: (text, record) => <span>{this.filterTime(text)}</span>,
                width: 140,
            },
            {
                title: '开票状态',
                dataIndex: 'status',
                key: 'status',
                width: 140,
                render: (text, record) => <span>{this.filterKplx(record.status)}{record.status==22?
                    <Tooltip placement="right" getTooltipContainer={() => this.menberManager.current}
                        overlayClassName="errorMagTooltip" title={record.errorMsg}>
                        <QuestionCircleOutlined className="errorIcon" /></Tooltip>:null }</span>
            },
            {
              title: '失败原因',
              dataIndex: 'errorMsg',
              key: 'errorMsg',
              width: 140
            },
            {
                title: '发票类型',
                dataIndex: 'invoiceType',
                key: 'invoiceType',
                render: (text, record) => <span>{this.filterInvoiceType(record.invoiceType)}</span>,
                width: 170
            },
            {
                title: '销货单位识别号',
                dataIndex: 'xhdwsbh',
                key: 'xhdwsbh',
                width: 210
            },
            // {
            //     title: '开票类型',
            //     dataIndex: 'status',
            //     key: 'status',
            //     render: (text, record) => <span>{this.filterKplx(record.status)}</span>,
            //     width: 120
            // },

            {
                title: '购货单位识别号',
                dataIndex: 'ghdwsbh',
                key: 'ghdwsbh',
                width: 190
            },

            {
                title: '机器编号',
                dataIndex: 'kpzdbs',
                key: 'kpzdbs',
                width: 130
            },
            {
                title: '发票请求流水号',
                dataIndex: 'fpqqlsh',
                key: 'fpqqlsh',
                width: 230
            },
            {
                title: '原发票代码',
                dataIndex: 'yfpdm',
                key: 'yfpdm',
                width: 130
            },
            {
                title: '原发票号码',
                dataIndex: 'yfphm',
                key: 'yfphm',
                width: 130
            },
            {
                title: '原pdf发票链接',
                dataIndex: 'ypdfurl',
                key: 'ypdfurl',
                width: 150
            },
            {
                title: '原ofd发票链接',
                dataIndex: 'yofdurl',
                key: 'yofdurl',
                width: 150
            },
            {
                title: '作废人',
                dataIndex: 'invalidUser',
                key: 'invalidUser',
                width: 90
            },
            {
                title: '作废时间',
                dataIndex: 'invalidTime',
                key: 'invalidTime',
                width: 170,
            },
            {
                title: '负数发票开票日期',
                dataIndex: 'negativeKprq',
                key: 'negativeKprq',
                width: 170,
            },
            {
                title: '信息表编号',
                dataIndex: 'tzdbh',
                key: 'tzdbh',
                width: 120,
            },
            {
                title: '购方客户电话',
                dataIndex: 'gfkhdh',
                key: 'gfkhdh',
                width: 120,
            },
            {
                title: '购方客户邮箱',
                dataIndex: 'gfkhyx',
                key: 'gfkhyx',
                width: 200,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                width: 190,
                align: 'center',
                render: (_, record) => {
                    if (record.status === 2) {
                        return <div className="operation-buttons">
                            <Button type="link" onClick={() => {
                                this.getInfo(record)
                            }}>详情</Button>
                            <Button type="link"  onClick={() => this.pushInvoice(record)}>推送</Button>
                            <Popconfirm title='确认冲红吗' onConfirm={() => this.goRed(record)}>
                                <Button type="link" >冲红</Button>
                            </Popconfirm>
                            <Popconfirm title='确认作废吗' onConfirm={() => this.invoiceToVoid(record)}>
                                <Button type="link"  className='invoice-button'>作废</Button>
                            </Popconfirm>
                        </div>
                    }
                    if (record.status === 22) {
                        return <div>
                            <Popconfirm title='确认重新开票吗' onConfirm={() => this.invoiceToReapet(record)}><Button type="link" >重新开票</Button></Popconfirm>
                        </div>
                    }
                },
            }
        ];

        ((columns) => {
            let list = []
            columns.forEach(item => {
                list.push({
                    value: item.key,
                    ...item
                })
            })
            let lastItem = list.pop()
            treeData = list
            let num = columns.length

            if (this.state.value.length == 0) {
                return false
            } else {
                let values = []
                let value = this.state.value
                value.forEach((item, index) => {
                    // if(item=="operation"){
                    //     value.splice(index,1)
                    //     value.push("operation")
                    // }
                    if (item == "key" && index != 0) {
                        value.splice(index, 1)
                        value.unshift("key")
                    }
                })

                for (let key of this.state.value) {
                    columns.forEach(item => {
                        if (item.key == key) {
                            values.push(item)
                        }
                    })
                }
                columns.splice(0, num, ...values, lastItem)
            }
        })(columns);

        const { page: current, rows: pageSize } = this.state.getDataInfo

        const pagination = { current, pageSize, total: this.state.total }

        const invoiceTypeSelectValue = [
            { id: '', name: '请选择发票类型' },
            { id: '025', name: '卷式发票' },
            { id: '028', name: '电子专用发票' },
            { id: '004', name: '增值税专用发票' },
            { id: '007', name: '增值税普通发票' },
            { id: '026', name: '增值税电子发票' }
        ]


        const baseFormData = [
            { type: 'SELECT', label: '发票类型', field: 'invoiceType', placeholder: '', width: '128%', initialValue: '', list: invoiceTypeSelectValue },
            { type: 'INPUT', label: '发票代码', field: 'fpdm', placeholder: '请输入发票代码', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '发票号码', field: 'fphm', placeholder: '请输入发票号码', width: '100%', initialValue: '' },
        ]

        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className="menberManager" ref={this.menberManager}>
                  <PageHeader
                    onBack={() => this.props.history.push("/system/errorInvoice")}
                    title={'异常发票列表（' +state.nsrmc + "-" + state.bussType +'）' }></PageHeader>
                    <Divider />
                  <div style={{ padding: "10px 0", }} className="baseForm" >
                    <BaseForm formList={baseFormData} style={{ width: '1600px' }} filterSubmit={this.getSearchVal} modalDatas={this.modalDatas} />
                    <Popconfirm title='确认重新开票吗' onConfirm={() => this.invoiceToReapetBatch()}><Button type="primary" className="primary3" >批量重开</Button></Popconfirm>
                  </div>

                    {/*<div style={{ padding: "10px 0", }} className="baseForm">*/}
                    {/*    <BaseForm formList={baseFormData} style={{ width: '1600px' }} filterSubmit={this.getSearchVal} modalDatas={this.modalDatas} />*/}
                    {/*    <Button type='primary' className="primary3" onClick={() => { this.setState({ showSl: true, restSls: "" }) }}>批量重开</Button>*/}
                    {/*</div>*/}
                    <div>
                        <Table columns={columns} rowSelection={this.rowSelection} dataSource={this.state.tableList} scroll={{ x: this.state.tableList.length * 100, y: 800 }} pagination={pagination} onChange={this.paginationChange} />
                    </div>
                </div>
            </Spin>
        )
    }
    rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let rows = selectedRows.map(item => {
          return item.orderNo
        })
        this.setState({
          selectedRows: [...rows]
        })
      },
    };
}

import React from "react";
import { Select, message } from "antd";
import moment from "moment";
const Option = Select.Option;
export default {
	formateDate(time) {
		if (!time) return "";
		let date = new Date(time);
		return (
			date.getFullYear() +
			"-" +
			(date.getMonth() + 1) +
			"-" +
			date.getDate() +
			" " +
			date.getHours() +
			":" +
			date.getMinutes() +
			":" +
			date.getSeconds()
		);
	},
	pagination(data, callback) {
		return {
			onChange: (current) => {
				callback(current);
			},
			current: data.result.page,
			pageSize: data.result.page_size,
			total: data.result.total_count,
			showTotal: () => {
				return `共${data.result.total_count}条`;
			},
			showQuickJumper: true,
		};
	},
	getOptionList(data) {
		if (!data) {
			return [];
		}

		let options = []; //[<Option value="0" key="all_key">全部</Option>];
		Array.isArray(data)&&data.forEach((item, index) => {
			options.push(
				<Option value={item.id} key={index}>
					{item.name}
				</Option>
			);
		});
		return options;
	},
	getOptionList2(data) {
		if (!data) {
			return [];
		}
		let options = [];
		data.forEach((item, index) => {
			options.push(
				<Option value={item} key={index}>
					{item}
				</Option>
			);
		});
		return options;
	},

	getFpdm(data) {
		let list = [];
		for (let e of data) {
			if (e == "004") {
				list.push({ id: e, name: "增值税专用发票" });
			} else if (e == "007") {
				list.push({ id: e, name: "增值税普通发票" });
			} else if (e == "025") {
				list.push({ id: e, name: "卷式发票" });
			} else if (e == "026") {
				list.push({ id: e, name: "增值税电子发票" });
			} else if (e == "028") {
				list.push({ id: e, name: "电子专用发票" });
			} else if (e == "005") {
				list.push({ id: e, name: "机动车发票" });
			} else if (e == "006") {
				list.push({ id: e, name: "二手车销售统一发票" });
			}
		}
		return list;
    },
    
    //发票类型选择
	myFaPiaoType(val) {
		let faPiao = {
			"004": "增值税专用发票",
			"007": "增值税普通发票",
			"025": "卷式发票",
			"026": "增值税电子发票",
			"028": "电子专用发票",
			"81":"全电专用发票",
			"82":"全电普通发票"
		};
		return faPiao[val] || "";
	},

	updateSelectedItem(selectedRowKeys, selectedItem, selectedIds) {
		if (selectedIds) {
			this.setState({
				selectedRowKeys,
				selectedItem,
				selectedIds,
			});
		} else {
			this.setState({
				selectedRowKeys,
				selectedItem,
			});
		}
	},
	// isEmpty(data){
	//    for(var i in data){
	//       if(data[i]===""||data[i]==="undefined"){
	//         return  message.warning("不能为空")

	//       }
	//    }
	// },
	isEmpty(data, callback) {
		for (var i = 0; i < data.length; i++) {
			if (callback) {
				if (data[i].value === "") {
					return callback(data[i].name);
				}
			}
		}
	},
	//重新组合相同数组
	dataDeal(data) {
		let listArr = [];
		data.forEach(function (value, index) {
			if (value.length == 0) {
				return false;
			}
			let obj = [];
			for (let i = 0; i < listArr.length; i++) {
				// 对比相同的字段key，相同放入对应的数组
				if (listArr[i][0].id == value.id) {
					listArr[i].push(value);
					return;
				}
			}
			// 第一次对比没有参照，放入参照
			obj.push(value);
			listArr.push(obj);
		});
		return listArr;
	},
	//对象数组去重
	uniqObjInArray(objarray) {
		let len = objarray.length;
		let tempJson = {};
		let res = [];
		for (let i = 0; i < len; i++) {
			//取出每一个对象
			tempJson[JSON.stringify(objarray[i])] = true;
		}
		let keyItems = Object.keys(tempJson);
		for (let j = 0; j < keyItems.length; j++) {
			res.push(JSON.parse(keyItems[j]));
		}
		return res;
	},
	//合同类型
	contractType(item) {
		switch (item) {
			case 0:
				return "未发送";
			case 1:
				return "已发送";
			case 2:
				return "已完成";
			case 3:
				return "已撤销";
			case 4:
				return "已拒绝";
			case 5:
				return "已过期";
		}
	},
	//时间格式化
	moment(value, type = "YYYY-MM-DD HH:mm:ss") {
		return value ? moment(value).format(type) : "";
	},
	//组件禁用日期
	disabledDate(current, type = true) {
		if (type) {
			return current && current > moment().endOf("day");
		} else {
			return current && current > moment().startOf("day");
		}
	},
	
	//下载流
	downLoad(res, name = "") {
		try {
			if (res.status >= 400) {
				return message.error(res.msg);
			}
		} catch {}
		let blob = new Blob([res], { type: "application/vnd.ms-excel" });
		let blobUrl = window.URL.createObjectURL(blob);
		const aElement = document.createElement("a");
		document.body.appendChild(aElement);
		aElement.style.display = "none";
		aElement.href = blobUrl;
		aElement.download = name;
		aElement.click();
		document.body.removeChild(aElement);
		window.URL.revokeObjectURL(blobUrl);
	},
	//a标签下载 静态资源
	aDownLoad(url, name = "") {
		let aElement = document.createElement("a");
		aElement.style.display = "none";
		aElement.href = url;
		aElement.setAttribute("download", name);
		document.body.appendChild(aElement);
		aElement.click();
		document.body.removeChild(aElement);
	},

	png64DownLoad(data = "") {
		if (!data.includes(";base64,")) {
			data = "data:image/png;base64," + data;
		}
		let blob = this.base64ToBlob(data);
		let url = URL.createObjectURL(blob);
		this.aDownLoad(url);
	},

	//base64转blob
	base64ToBlob(code) {
		let parts = code.split(";base64,");
		let contentType = parts[0].split(":")[1];
		let raw = window.atob(parts[1]);
		let rawLength = raw.length;

		let uInt8Array = new Uint8Array(rawLength);

		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = raw.charCodeAt(i);
		}
		return new Blob([uInt8Array], { type: contentType });
	},

	resetList(obj, resultList){
		if(!Array.isArray(obj))return obj
		for (let item of obj) {
			if (!item.children) {
				for (let e of resultList) {
					if (e.parentResourcesNo === item.data.resourcesNo) {
						if (!item.children) {
							item.children = [];
						}
						item.children.push({data:e,children:null});
					}
				}
			} else {
				this.resetList(item.children, resultList);
			}
		}
		return obj;
	},
	regEmail(email){
		let reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
		return reg.test(email)
	},
	regPhone(phone){
		let reg = /^1[0-9]{10}$/;
		return reg.test(phone)
	}
};

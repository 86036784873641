import React, { Component } from "react"
import {Button,Table,Drawer,message} from 'antd'
import Utils from "../../../utils/index.js"
import {Divider} from "antd"
import {contractDetail,smsRemind} from '../../../api'
// import { Document, Page } from 'react-pdf';
import {PDFObject} from "react-pdfobject"
//import ETable from '../../../components/eTable/eTable'
import './fileDetail.less'
import thunk from "redux-thunk"




export default class PowerStation extends Component {
	constructor(props) {
		super(props);
		this.state={
			contractName:"",
			contractSerial:"",
			sponsorName:'',
			createTime:"",
			contractType:"",
			contractState:"",
			contractTime:"",
			placement:"top",
			visible: false,
			pdfUrl:"",
			pdfTitle:"",
			numPages: null,
			pageNumber: 1,
			tableList:[]
		}
	
	}

	componentDidMount(){
		this.getData()
	}
	sendCode=(e)=>{

		smsRemind({phone:e.phone,serial:e.contractSerial}).then(res=>{
			if(res.code===0){
				message.success("提醒成功,请注意接收短信！")
			}else{
				message.success(res.message)
			}
		})
	}
	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	  }
	getData=()=>{
		contractDetail({contractSerial:this.props.location.state.order}).then(res=>{
			if(res.code===0){
				const data=res.map.list[0]	
				this.setState({
						contractName:data.contractName,
						contractSerial:data.contractSerial,
						sponsorName:data.sponsorName,
						createTime:data.createTime,
						contractType:data.contractType===0?"对公合同":"对私合同",
						contractState:data.contractType===0?"未签":data.contractType===1?"已签":"拒绝",
						contractTime:data.contractTime,
						pdfUrl:data.contractUrl,
						pdfTitle:data.contractName
				})    
				data.ecContractOrderDetails.forEach(res=>{
					const dom={
						key:res.datailsId,
						userName:res.datailsOperator,
						status:res.datailsStatus===0?"未签":res.datailsStatus===1?"已签":"拒绝",
						date:res.createTime,
						detail:res.datailsIdentity,
						phone:res.datailsPhone,
						contractSerial:res.contractSerial
					}
						this.setState({
							tableList:[...this.state.tableList,dom]
						})
				})
				
			  }
			})
	}
	onClose=()=>{
		this.setState({
			visible:!this.state.visible
		})
	}
	uploadDown=()=>{
		    let a = document.createElement("a");
			a.href = this.state.pdfUrl;
			a.download = this.state.pdfTitle;
			a.setAttribute('target','_blank');
			document.body.appendChild(a);
			a.click();
			a.remove();
	}

	render() {
	   const columns=[
			{
				title: '用户',
				dataIndex: 'userName',
				key: 'userName',
			  },
			  {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
			  },
			  {
				title: '时间',
				dataIndex: 'date',
				key: 'date',
				render: (text, record) => (
					<div>

					   {record.status==="未签"?<Button onClick={()=>this.sendCode(record)}>提醒</Button>:record.date}
					</div>
				  ),
			  },
			  {
				title: '详情',
				dataIndex: 'detail',
				key: 'detail',
			  }
		]
		const that=this.state
		return (
			<div className='file-detail'>
			   <div className="file-header">
			       <h1>文件详情</h1>
				   <Divider/>
			   </div>
			    <div className="file-info">	
					<div className="file-info-basic">
						<ul className="file-info-left">
						    <h1>合同信息:<span>{that.contractName}</span></h1>
							<li>合同编号:<span> {that.contractSerial}</span></li>
							<li>发起方:<span>{that.sponsorName}</span></li>
							<li>发起时间:<span>{that.createTime}</span></li>
							<li>合同类型:<span>{that.contractType}</span></li>
						</ul>
						<ul className="file-info-right">
							<li>合同状态: <span>{that.contractState}</span> </li>
							<li>签署截至日期:<span>{that.contractTime}</span> </li>
							<li>
								<Button type='primary'>签署</Button><Button type='primary' onClick={()=>{this.onClose()}}>查看详情</Button>
							    <Button type='primary'>撤销</Button><Button type='primary' onClick={()=>{this.uploadDown()}}>下载合同</Button>
							</li>
							
						</ul>
					</div>
				</div>
			    <div>
			    <div className="file-content">
                   <h1>签约方</h1>
				   <Table columns={columns} dataSource={this.state.tableList} />
				</div>
			   </div>
			   <div className="drawer" >
			   <Drawer
				title={this.state.pdfTitle}
				placement={this.state.placement}
				closable={false}
				onClose={this.onClose}
				visible={this.state.visible}
                height="100vh"
				closable={true}
				>
					  <PDFObject url={this.state.pdfUrl} height="100vh"/>
				</	Drawer>
				 
			   </div>
			</div>
		)
	}
}
import React, { Component } from "react"
import './enterpriseCertification.less'
import { Button,Divider, } from "antd"
import {companyInfo} from '../../../api/index'






export default class PowerStation extends Component {
	constructor(props) {
		super(props);
		this.state={}
	}
	startData=()=>{
		companyInfo({id:localStorage.getItem("userID")}).then(res=>{

		})
	}
	render() {
		return (
			<div className="certification">
				<div className="certification-step">
					<div className="certification-title">
						<p>
							企业基本信息
						</p>
					</div>
					<div className="certification-concent">
						<ul>
							<li>公司名称:<span>通过企业认证后显示企业名称</span></li>
							<li>创建时间:<span>2020-03-09</span></li>
							<li>企业实名:<span>未实名</span></li>
						</ul>
						<div className='btn'>
							<Button type='primary' onClick={()=>{this.props.history.push("/system/Authentication")}}>立即认证</Button>
						</div>
						
					</div>
				</div>
			    <div className="certification-step">
					<div className="certification-title">
						<p>
						设置企业通知邮箱（用于通知企业剩余合同可用份数，防止影响合同无法发送）
						</p>
					</div>
					<div className="certification-concent">
					 <div>
						<p>暂无通知邮箱</p>
					 </div>
					 <div className='btn'>
					    <Button type="primary">新增通知邮箱</Button>
					 </div>
				</div>
				 {/* <div className="certification-title">
					 <p>
						 设置企业头像
					 </p>
				 </div>
				 <div className="certification-concent">
					 <div>
						 <img src="" alt=""/>
					 </div>
					 <ul>
						 <li>温馨提示</li>
						 <li>成为企业成员对外展示的头像</li>
						 <li>图片长宽比例建议为1:1</li>
						 <li>上传图片仅支持JPG、BMP、PNG图片文件，且文件小于1M</li>
					 </ul>
					
				 </div>
				 <div className="certification-title">
					 <p>
						 设置管理员
					 </p>
				 </div>
				 <div className="certification-concent">
					 <div>
						<p>柱子</p>
					 </div>
					 <div className='btn'>
					    <Button type="primary">更换管理员</Button>
					 </div>	
					
				 </div> */}
				
					 	
					
				 </div>
			</div>
		)
	}
}
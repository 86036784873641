import React, { Component } from "react";
import { Route, Switch, Redirect } from 'react-router-dom'
import PrintInfo from './printInfo/printInfo'

export default class Print extends Component {

	render() {	
		return (
			<Switch>
				<Route path='/print/printInfo' component={PrintInfo} />
				<Redirect to='/print/printInfo' />
			</Switch>
		)
	}
}

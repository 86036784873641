import React from 'react'
import { Link } from 'react-router-dom'
import './webHeader.less'



export default class WebHeader extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
			navList:[false,false,false,false,false,false],
			urlObj:{
				'home':0,
				'assistance':1,
				'coordination':1,
				'flexible':1,
				'electronic':1,
				'contract':1,
				'check':1,
				'solution':2,
				'success':3,
				'information':4,
				'aboutMe':5,
				'service':5,
				'business':5,
			}
        }
    }
	jump = (e) => {
		if (e === "login") {
			this.props.props.history.push('/web/login')
		} else {
			this.props.props.history.push('/web/register')
		}
	}
	componentDidMount(){
		document.title = "深圳市中税创新科技有限公司"
		let baseUrl = window.location.href
		if(process.env.NODE_ENV==="production"){
		   if(baseUrl.startsWith("http:")){
			   window.location.href = baseUrl.replace('http:',"https:")
		   }
	   }
		let url = baseUrl.split('#')[1]
		let key = url.split('/')[2]
		this.state.navList[this.state.urlObj[key]]=true
		this.setState({})
	}
	doClick = (val)=>{
		let list = this.state.navList
		for(let i in list){
			list[i] = false
		}
		list[val] = true
		this.setState({})
	}
	render() {
		return (
			<div className='web-header'>
				<header className="header">
					<nav className="header-top">

						<div className="logo">
							<img src={require('../../assets/img/logo/logo.png')} alt="" />
							<span>中税创新</span>
						</div>

						<ul className='header-menu'>
							<li onClick={this.doClick.bind(this,0)} >
								<Link to='/web/home'>
									<span className={this.state.navList[0]?'active':null}>首页</span>
								</Link>
							</li>
							<li>
								<span className={this.state.navList[1]?'active':null}>产品线</span>
								<div  className='drop-menu'>
									<ul className='csp-box'>
										{/* <li className="left-title">
										</li> */}
										<li className="right-li" onClick={this.doClick.bind(this, 1)}>
											<div>
												<span>票</span>
												<Link to='/web/electronic'>电子发票</Link>
												<Link to='/web/income'>进项管理</Link>
												<Link to='/web/check'>发票查验</Link>
											</div>
											<div>
												<span>财</span>
												<Link to='/web/assistance'>金融助贷</Link>
												<Link to='/web/coordination'>供应链协同</Link>
												<Link to='/web/contract'>电子合同</Link>
											</div>
											<div>
												<span>税</span>
												<Link to='/web/flexible'>灵活用工</Link>
											</div>
										</li>
									</ul>
								</div>
							</li>
							<li >
								<span className={this.state.navList[2]?'active':null}>解决方案</span>
                <ul  onClick={this.doClick.bind(this,2)} className='drop-menu allLi' style={{left:0,width:110}}>
									<li><Link to='/web/solution/chain'>连锁行业</Link></li>
									<li><Link to='/web/solution/enterprise'>中小微企业</Link></li>
									<li><Link to='/web/solution/financial'>金融助贷</Link></li>
									<li><Link to='/web/solution/restaurant'>餐饮行业</Link></li>
									<li><Link to='/web/solution/market'>商超行业</Link></li>
									<li><Link to='/web/solution/property'>物业行业</Link></li>
									<li><Link to='/web/solution/onlineRetailers'>电商行业</Link></li>
									<li><Link to='/web/solution/hotel'>酒店行业</Link></li>
									<li><Link to='/web/solution/express'>快递行业</Link></li>
									<li><Link to='/web/solution/parkingLot'>停车场行业</Link></li>
								</ul>
							</li>
							<li  onClick={this.doClick.bind(this,3)} ><Link to='/web/success' className={this.state.navList[3]?'active':null}>成功案例</Link></li>
							<li  onClick={this.doClick.bind(this,4)} ><Link to='/web/information' className={this.state.navList[4]?'active':null}>资讯中心</Link></li>
							<li  onClick={this.doClick.bind(this,5)}>
								<Link to='/web/aboutMe' className={this.state.navList[5]?'active':null}>关于我们</Link>
								<ul className='drop-menu allLi' style={{left:-10,width:130}}>
									<li><Link to='/web/aboutMe'>了解中税创新</Link></li>
									<li><Link to='/web/service'>服务与支持</Link></li>
									<li><Link to='/web/business'>渠道招商</Link></li>
								</ul>
							</li>
						</ul>

						<div className="header-btn" onClick={() => { this.jump("login") }}>
							<span className='login' >登录</span>
						</div>
					</nav>
				</header>
			</div>
		);
	}
}

import React, { Component } from "react"
import './rechargeRecord.less'

export default class PowerStation extends Component {
	constructor(props) {
		super(props);
		this.state={}
	}
	render() {
		return (
			<div>
			   <p>充值记录</p>
			</div>
		)
	}
}
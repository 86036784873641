import React, { Component } from "react"
import './writeBackMonitoring.less'
import { Button, Divider, Table, message, Modal,Spin,Popconfirm } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ControlPanel from "../../../components/controlPanel/controlPanel"
import {  copyAndReport ,writeBackListenData} from '../../../api'

const { confirm } = Modal;


export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//修改回显数据
			updateData: [],
            tableList: [],
            tableList2:[],
            //税控盘编号
			deviceNo:'',
			fplxdmList:[],
			//发票编号
			fplxdm:'',
            isRoleVisible:false,
			getDataInfo: {
				nsrsbh: '',
				nsrmc: '',
				plateNo: '',
				page: 1,
				rows: 10
			},
			total:0,
			loading:false
		}
	}

	// 跳转确认
	jumpCopy = (e) => {
		let data = {
			jqbh: e.jqbh,
			fplxdm: this.state.fplxdm
		}
		this.setState({loading:true})
		copyAndReport(data).then(res => {
			if(res.code=="0000"){
				if(res.resultObj.returncode=="0"){
					message.success(res.msg)
					this.props.history.push({ pathname: '/system/setReport', state: {} })
				}else{
					Modal.error({
						title: '错误提示',
						content: res.resultObj.returnmsg,
						// okText:"确定"
					});
				}
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
		
	}
	//查询
	handleFilter = e => {
		this.setState({ fplxdm: e.fplxdm,getDataInfo:{...this.state.getDataInfo,page:1}}, () => {
			this.getData()
		})
	}

	getData() {
		const param = {
			jqbh:this.state.deviceNo,
			fplxdm:this.state.fplxdm,
		}
		if(param.jqbh==""){
			return message.error('控税盘编号未选择!')
		}
		if(param.fplxdm=="请选择发票类型"){
			return message.error('发票类型未选择!')
		}
		
		this.setState({loading:true})
		writeBackListenData(param).then(res=>{
				if(res.code=="0000"){
					message.success(res.msg)
					const { resultList: tableList } = res
					this.setState({
						tableList,
						// total:res.pager.totalCount,
					})
				}else{
					message.error(res.msg||"数据错误！")
				}
				this.setState({loading:false})

		})

	}

	setPage=(val)=>{
		this.state.getDataInfo.page = val
		this.getData()
    }

    handleRoleSubmit = () => { }


	getInfos = (val)=>{
		// console.log(val.fplxdm)
		this.setState({
			deviceNo:val.deviceNo,
			fplxdmList:val.fplxdm
		})
	}

	render() {
		const columns = [
			{
				title: '序号',
				//   dataIndex: '',
				//   key: '',
				render: (text, record, index) => (
					<span>
						{index + 1}
					</span>
				),
			},

			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},
			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				render: (text, record) => (
					<Popconfirm
						title="是否抄报"
						onConfirm={() => { this.jumpCopy(record) }}
						onCancel={()=>{}}
						okText="确定"
						cancelText="取消"
					>
						<Button type="primary">网上抄报</Button>
					</Popconfirm>
				),
			}
		];
		const formList = [
			 {
				type: 'SELECT',
				label: '发票类型',
				field: 'fplxdm',
				placeholder: '',
				width: "110%",
				initialValue:'请选择发票类型',
				list:this.state.fplxdmList
			},
        ]

		return (
			<Spin  spinning={this.state.loading} size="large" >
			<div className="menberManager">
				<div className="menberManager-title">
					<h2>{sessionStorage.getItem("pageTitle")||"反写监控"}</h2>
				</div>
				<Divider />
                <div style={{ padding: "10px 0", display: 'flex', alignItems: 'center' }}>
					<ControlPanel getInfo={(val)=>{this.getInfos(val)}}/>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} btnName="清卡" />
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList}  pagination={{
							// onChange: this.setPage,
							// total: this.state.total,
							// current:this.state.getDataInfo.page
						}} />
				</div>
			</div>
			</Spin>
		)
	}
}
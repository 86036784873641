import React, { Component } from "react"
import { Table, Button, Divider, Modal, message} from 'antd'
import { roleGet, addRoleAll, delRole, updateRole, detailRole,menuPower,menuGet} from '../../../api/index'
import utils from "../../../utils"
import BaseForm from '../../../components/baseForm/baseForm'
import moment from "moment"
import ModalForm from '../../../components/modal/modal'
import './roleManager.less'


//新增 编辑表单数据
const addRoleData = [
	{
		type: 'INPUT',
		label: '角色名称',
		field: 'roleName',
		placeholder: '请输入角色名称',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'SELECT',
		label: '是否禁用',
		field: 'status',
		width: "100%",
		initialValue: '1',
		list: [
			{
				id: '-1', name: "禁用"
			},
			{
				id: '0', name: "删除"
			},
			{
				id: '1', name: "启用"
			}
		]
	},
	{
		type: 'INPUT',
		label: '备注',
		field: 'remark',
		placeholder: '请输入备注信息',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'TREE',
		label: '权限',
		field: 'resourcesNoList',
		placeholder: '请输入备注信息',
		width: "100%",
		initialValue: '',
	},
]

export default class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isRoleVisible: false,
			flag: false,
			myTitle: '',
			pager:{
				pagerNo:1,
			},
			total:1,
		};
		//表格数据
		this.tableList = []
		//弹窗数据
		this.modalData = {}
		//表单查询数据
		this.formData = {}
		//树数据
		this.treeData = []

		//提交新增数据
		this.addRoleDataAll = {}

		//回显数据 权限树
		this.updateRoleData = addRoleData
		
		this.parList = []
	}
	componentDidMount() {
		
		this.startTable()
		
		// menuPower().then(res => {
		// 	if (res.code === "0000") {
		// 		let myList = utils.resetList(res.resultObj,res.resultList)
		// 		this.isLeaf(myList)
		// 		this.treeData = this.getData(myList)
		// 	}
		// })
		// return
		//所有权限
		menuGet().then(res => {
			if (res.code === "0000") {
				this.treeData = this.getData(res.resultObj)
				this.isLeaf(res.resultObj)
			}
		})

	}
	getData = (data) => {
		return data.reduce((pre, item) => {
			if (item.children) {
				pre.push({
					title: item.data.resourcesName,
					key: item.data.resourcesUrl,
					value: item.data.resourcesNo,
					icon: item.data.imgName,
					children: this.getData(item.children)
				})
			} else {
				pre.push({
					title: item.data.resourcesName,
					key: item.data.resourcesUrl,
					value: item.data.resourcesNo,
					icon: item.data.imgName,
				})
			}
			return pre
		}, [])
	}

	isLeaf=(dataList)=>{
		dataList.forEach(item=>{
			if(!item.children){
				// this.parList.push(item.data.resourcesNo)
			}else{
				this.parList.push(item.data.resourcesNo)
				this.isLeaf(item.children)
			}
		})
	}

	 renderTime=(date)=> {
		 return moment(date).format("YYYY-MM-DD HH:mm:ss")
	  }
	  
	setPage=(val)=>{
		// console.log(val)
		this.setState({
			pager:{...this.state.pager,pagerNo:val}
		},()=>{
			this.startTable()
		})
	}

	startTable = () => {
		roleGet({page:this.state.pager,...this.formData}).then(res => {
			if (res.code === "0000") {
				this.tableList = []
				res.resultList.map((item, index) => {
					let updateTime = this.renderTime(item.updateTime)
					let createTime = this.renderTime(item.createTime)
					this.tableList.push({
						key: item.id,
						roleName: item.roleName,
						createBy: item.createBy,
						createTime: createTime,
						updateBy: item.updateBy,
						updateTime: updateTime,
					})
				})
				this.setState({
					total:res.pager.totalCount
				})
				// this.forceUpdate()
			}
		})
	}


	handleFilter = (e) => {
		this.formData = e
		this.state.pager = {pagerNo:1}
		this.startTable()
	}
	//角色详情
	detailData = (id) => {
		
		// let node = [100102,100103,100104,10010403,100106,10010603,10010602,10010601,1001060103,1001060201]
		let node = this.parList
		detailRole({ id }).then(res => {
			if (res.code === "0000") {
				this.updateRoleData.forEach((item, index) => {
					switch (item.field) {
						case "roleName":
							item.initialValue = res.resultObj.roleName;
							break;
						case "status":
							item.initialValue = String(res.resultObj.status);
							break;
						case "resourcesNoList":
							//节点数据
							let lists = res.resultObj.resourcesNoList;
							let nodes = [];
							lists.forEach(item=>{
								if(!node.includes(item)){
									nodes.push(item)
								}
							})
							item.initialValue = nodes;
							break;
						case "remark":
							item.initialValue = res.resultObj.remark;
							break;
					}
					this.forceUpdate()
					this.setState({
						isRoleVisible: true,
						flag: true
					})
				})
			}
		})
	}
	edit = (e) => {
		this.setState({ myTitle: '编辑角色' })
		this.addRoleDataAll["id"] = e.key
		this.detailData(e.key)
	}
	del = (e) => {
		delRole({ id: e.key }).then(res => {
			if (res.code === "0000") {
				message.success(res.msg)
				this.startTable()
			} else {
				message.warning(res.msg)
			}
		})
	}
	addRole = () => {
		this.updateRoleData.forEach(res => {
			res.initialValue = ''
		})
		this.updateRoleData[3].initialValue = ''
		this.setState({
			isRoleVisible: true,
			flag: false,
			myTitle: '新增角色'
		})

	}
	handleRoleSubmit = (e) => {
		this.addRoleDataAll["name"]["resourcesNoList"] = this.addRoleDataAll["resourcesNoList"] || this.updateRoleData[3].initialValue

		if (this.state.flag) {  //修改
			// console.log('测试',this.addRoleDataAll["name"])
			this.addRoleDataAll["name"]["id"] = this.addRoleDataAll["id"]
			updateRole(this.addRoleDataAll["name"]).then(res => {
				if (res.code === "0000") {
					this.setState({
						isRoleVisible: false,
					})
					message.success(res.msg)
					this.startTable()
				}
			})
		} else {  //新增
			addRoleAll(this.addRoleDataAll["name"]).then(res => {
				if (res.code === "0000") {
					this.setState({
						isRoleVisible: false,
					})
					this.startTable()
				}
				message.success(res.msg)
			})
		}
	}
	getform = (e) => {
		this.modalData = e
	}
	addRoleData = (e) => {
		this.addRoleDataAll["name"] = e
	}
	//选择树节点
	treeSelect = (e) => {
		this.addRoleDataAll["resourcesNoList"] = e
	}
	render() {
		const formList = [
			{
				type: 'INPUT',
				label: '角色名称',
				field: 'roleName',
				placeholder: '请输入角色名称',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '创建人',
				field: 'createBy',
				placeholder: '请输入创建人',
				width: "100%",
			}, 
			{
				type: 'DATE',
				label: '请选择创建时间',
				field: 'createTime',
				placeholder: '请输入日期',
			}
		]
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				render:(text,recode,index)=>index+1
			},
			{
				title: '角色名称',
				dataIndex: 'roleName',
				key: 'roleName',
			},
			{
				title: '创建人',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
				title: '创建时间',
				dataIndex: 'createTime',
				key: 'createTime',
			},
			{
				title: '最近更新人',
				dataIndex: 'updateBy',
				key: 'updateBy',
			},
			{
				title: '最近更新时间',
				dataIndex: 'updateTime',
				key: 'updateTime',
			},
			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				render: (text, record) => (
					<div>
						<Button type="primary" style={{ marginRight: 10 }} onClick={() => { this.edit(record) }}>编辑</Button>
						<Button type="danger" onClick={() => { this.del(record) }}>删除</Button>
					</div>
				),
			}
		];

		return (
			<div className='role-manager'>
				<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"角色管理"}</h2>
				<Divider />
				<div className="role-serach">
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
					<Button type="primary" onClick={() => { this.addRole() }}>新增</Button>
				</div>
				<div className="role-content">
					<Table dataSource={this.tableList} columns={columns} pagination={{
						onChange:this.setPage,
						total:this.state.total,
						current:this.state.pager.pagerNo
					}}  />
				</div>
				{
					this.state.isRoleVisible?(<Modal
						title={this.state.myTitle}
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={() => {
							this.setState({
								isRoleVisible: false
							})
						}}
					>
						<ModalForm formList={this.state.flag ? this.updateRoleData : addRoleData} treeData={this.treeData} TreeSelect={this.treeSelect.bind(this)} TreeinitialValue={this.updateRoleData[3].initialValue} modalData={this.addRoleData.bind(this)} />
					</Modal>):null
				}
				
			</div>
		)
	}
}



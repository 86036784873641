import React, { Component } from "react"
import {Divider,Timeline,Upload,Button,message,Spin,Icon,Input,Select,DatePicker,Switch } from 'antd';
import axios from "axios"
import moment from 'moment';
import {selectCustomer,selectSign,fileUploadUrl} from "../../../api/index"
import './signContract.less'

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const nextMoths=moment(new Date()).add(1,'months').format(dateFormat); 
export default class Customer extends Component {
		constructor(props) {
			 super(props);
			 this.personalInfo=[
				 {  
					id:"01",
					index:1,
					account:"2226656566",
					name:"柱子",
					company:"北京中税",
					type:"1",
				 },
				 {
					id:"02",
					index:3,
					account:"2226656566",
					name:"柱子2",
					company:"北京中税2",
					type:"2"
				 }
			 ]
			 this.commitData=[]
			 this.state = {
				fileList:[
				],
				loading:false,
				form:{
					title:"",
					date:nextMoths,
					department:"0",
					isPublick:0
				}
			  }
		}
	    beforeUpload = (file) => {
           if(file.type!=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
				message.warning('请上传docx格式文件!')
				return false
		   }else{
				this.setState({
					loading:true
				})
		   }
		}
		onChange(info) {
     
			console.log(info)
		}
		commit=()=>{
			const param={
				sponsorId:"123456",
				sponsorName:"柱子",
				contractUrl:this.state.fileList[0].url,
				contractName:this.state.fileList[0].name,
				contractSerial:this.state.fileList[0].order,
				contractTime:this.state.form.date,
				departmentId:this.state.form.department,
				contractType:String(this.state.form.isPublick),
				signtoryJson:this.personalInfo.map((item)=>{
                    return {
						datailsIndex:item.id,
						datailsPhone:item.account,
				 		datailsEmail:item.account,
				 		datailsType:item.type,
				 		datailsName:item.company,
				 		datailsIdentity:item.cardId,
				 		datailsOperator:item.name,
				 		createBy:"zhuzi",
				 		updateBy:"zhuzi"
					}
				}),
				signtoryNum:String(this.personalInfo.length),
				createBy:"zhuzi",
				updateBy:"zhuzi"
			  }	  
			  selectCustomer(param).then(res=>{
				  if(res.code===0){
					  this.props.history.push({pathname:"/system/sendContract",state:{order:res.object}})
				  }
			  })
		}
		updateData=(e,index,key)=>{
			this.personalInfo[index][key]=e.target.value
		}
		addData=(e)=>{
            if(e===1){
                   this.personalInfo.unshift({
					id:"",
					index:"",
					account:"",
					name:"",
					company:"",
					type:"1"
				   })
			}else{
				this.personalInfo.unshift({
					id:"",
					index:"",
					account:"",
					name:"",
					company:"",
					type:"2"
				   })
			}
			this.forceUpdate()
		}
		del=(index)=>{
			this.personalInfo.splice(index,1)
			this.forceUpdate()
		}
		enter=(index)=>{
			
		}

	render() {
		
		const {title,date,department}=this.state.form
		const that=this
		const props = {
			name: "file",
			onChange: this.handleChange,
			multiple:true,
			customRequest: info => {	
				const formData = new FormData();
				formData.append('file', info.file);
				axios.post(
					fileUploadUrl,
					formData,
					{ headers: {'token':sessionStorage.getItem("token")}}
					).then(res=>{
						this.setState({
							loading:false
						})
						if(res.data.code===0){
							const dom={
								uid: '-1',
								name: res.data.map.fileNames,
								status: 'done',
								url: res.data.map.url,
								order: res.data.map.order
							}
							that.setState({
								fileList:[...that.state.fileList,dom],
								form:{...that.state.form,title:res.data.map.fileNames}
							})				 
						}else{
						    message.error(res.data.message)
						}
					}).catch(err=>{
						this.setState({
							loading:false
						})
						message.error("上传失败！")
					})
			}
		  };
		return (
			<div className="sign-contract">
				<div className="sign-contract-title">
					<h2>添加文件和签约信息</h2>
					<Divider />
				</div>
				<Timeline>
					<Timeline.Item>
						<section className="step1">
							<div className="step1-title">
								<span>第一步:</span><span>确认发起人</span>
							</div>
							<div className="send-people">
								<p>北京中慈创新科技有限公司</p>
							</div>
						</section>
				    </Timeline.Item>
					<Timeline.Item>
					  <Spin spinning={this.state.loading}>
						<section className="step2">
							<div className="step2-title">
								<span>第二步:</span><span>上传文件</span>
								<Divider />
							</div>
							<div className="upload">
							<Upload {...props} fileList={this.state.fileList} beforeUpload={this.beforeUpload}>
								<Button className='upload-btn'>
								  <Icon type="upload" style={{fontSize:20}} /> 上传本地文件
								</Button>
							</Upload>
							<div className='search-btn'>
								 <div>
									 <label htmlFor="">合同标题</label>
									 <Input placeholder="" value={title}/>
								 </div>
								 <div>
									 <label htmlFor="">签署截至日期</label>
									 <DatePicker defaultValue={moment(date, dateFormat)} format={dateFormat} />
								 </div>
								 <div>
								    <label htmlFor="">合同所属部门</label>
									<Select defaultValue={department} style={{ width: 140 }} >
									  <Option value="0">研发</Option>
									  <Option value="1">后勤</Option>
									</Select>
								 </div>
							</div>
							</div>
						</section>
						</Spin>
					</Timeline.Item>
					<Timeline.Item>
						<section className="step3">
							<div className="step3-title">
								<span>第二步:</span><span>添加签约方信息</span>
								<Divider />
							</div>
							<div className="step3-switch">
								<div>
							      <Switch defaultChecked />
								  <label htmlFor="">顺序签署</label>	  
								</div>
								<div>
							      <Switch defaultChecked />
								  <label htmlFor="">发送文件(自己不签署，仅需对方签署)</label>
								
								</div>
							    <div>
								    <Icon type="form" />
									<span>联系人通讯录</span>	
								</div>
								<div>
								    <Icon type="form" />
									<span>签署顺序</span>	
								</div>
							</div>
						    <div className="step3-form">
                                {
									this.personalInfo.map((item,index)=>(
										<li key={index} onMouseEnter={()=>{this.enter(index)}}>
										<div><Input placeholder="default size"  defaultValue={item.index} onChange={(e)=>{this.updateData(e,index,'index')}} /></div>
										<div>
											<label htmlFor="">账号:</label>
											<Input placeholder="default size"   defaultValue={item.account} onChange={(e)=>{this.updateData(e,index,'account')}}/>
										</div> 
										<div>
									        <label htmlFor="">签约主体:{item.type==="1"?"个人":"公司"}</label>
											<Input placeholder="default size"   defaultValue={item.company} onChange={(e)=>{this.updateData(e,index,'company')}}/>
										</div>
										<div>
											<label htmlFor="">经办人:</label>
											<Input placeholder="default size"   defaultValue={item.name} onChange={(e)=>{this.updateData(e,index,'name')}}/>
										</div>
										<div style={{display:(item.type==="1"?"block":"none")}}>
											<label htmlFor="">身份证:</label>
											<Input placeholder="default size"   defaultValue={item.name} onChange={(e)=>{this.updateData(e,index,'name')}}/>
										</div>
                                        <div style={{position:"absolute",right:20}} onClick={()=>{this.del(index)}}>
										    <Icon type="delete" className='animated bounce'  style={{fontSize:20}} />
										</div>
									</li>
									
									))
								}
								
							</div>
							<div className="form-commit" >
							   <Button type="primary" icon="plus" onClick={()=>{this.addData(1)}}>添加签署企业</Button> <Button icon="plus" onClick={()=>{this.addData(2)}} type="primary">添加签署个人</Button>
							</div>
						</section>
					</Timeline.Item>
					<Timeline.Item style={{marginTop:40}}>
					    <Button type="primary"  icon="loading" onClick={()=>{this.commit()}}>提交申请</Button> 
					</Timeline.Item>
				</Timeline>
			</div>
			
		)
	}
}
import React, { Component } from "react"
import {  message, Spin,Divider, Row,Col,Descriptions } from 'antd'
import ControlPanel from "../../../components/controlPanel/controlPanel"
import BaseForm from '../../../components/baseForm/baseForm'
import { listenManageinfoQuery,getNsrmcType } from '../../../api/index'
import moment from "moment"
import './monitorInfo.less'


const ysList = [
	"kfpzsj",
	"zxbsrq",
	"bsqsrq",
	"bszzrq",
	"scjzrq",
	"dqsz"
	]

export default class MonitorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			info:{
				kpzdbs:'',
				fplxdm:''
			},
			infoList:[],
			selectList:[],
			showInfo:{},
			switchOpen:0,
		};


	}
	componentDidMount() { }

	getData=()=>{
		let param = this.state.info
		if(param.kpzdbs == ""){
			return message.error('请选择控税盘编号！')
		}
		if(param.fplxdm == "请选择类型状态"){
			return message.error('请选择类型状态')
		}
		this.setState({loading:true})
		if(!param.fplxdm){
			console.log(param)
			getNsrmcType({kpzdbs:param.kpzdbs}).then(res=>{
				this.setState({loading:false,switchOpen:1})
				if(res.code=="0000"){
					message.success(res.msg)
					let showInfo = res.resultObj
					this.setState({showInfo})
				}else{
					message.error(res.msg)
				}
			})
		}else{
			listenManageinfoQuery(param).then(res=>{
				if(res.code=="0000"){
					let showInfo = res.resultObj.body.returndata
					for(let key of ysList){
						showInfo[key] = this.restDate(showInfo[key])
					}
					this.setState({showInfo})
				}else{
					message.error(res.msg)
				}
				this.setState({loading:false,switchOpen:2})
			})
		}
	}

	getInfo=(data)=>{
		let {deviceNo:kpzdbs,fplxdm:selectList} = data
		this.setState({
			info:{
				...this.state.info,kpzdbs
			},
			selectList:[{id: "", name: "税控盘信息查询"},...selectList]
		})
	}

	handleFilter = e => {
		this.setState({
			info:{
				...this.state.info,...e
			}
		},()=>{
			this.getData()
		})
    }
    

	restList = (val) => {
		switch (val) {
			case "004":
				return "增值税专用发票"
			case "007":
				return "增值税普通发票"
			case "025":
				return "卷式发票"
			case "026":
				return "增值税电子发票"
			case "028":
				return "电子专用发票"
			default:
				break;
		}
	}

	restDate=(date)=>{
		if(date.length>6){
			if(date.length>8){
				return moment(date,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
			}
			return moment(date,"YYYYMMDD").format("YYYY-MM-DD")
		}
		return date
	}

	render() {
		
		const formList = [
			{
				type: 'SELECT',
				label: '类型状态',
				field: 'fplxdm',
				placeholder: '',
                width: "100%",
                initialValue: '请选择类型状态',
                list: this.state.selectList
			}
        ]
		return (
			<Spin spinning={this.state.loading} size="large" >
				<div>
					<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"状态查询"}</h2>
				</div>
				<Divider />
				<div className="box-b01">
					<ControlPanel getInfo={this.getInfo} />
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
				</div>
				<br/>
				{
					(this.state.switchOpen==1?(<Descriptions bordered={true} column={2} className="box-b02">
					<Descriptions.Item label="控税设备编号">{this.state.showInfo.jqbh}</Descriptions.Item>
					<Descriptions.Item label="发票类型">{this.state.showInfo.fplxdm}</Descriptions.Item>
					<Descriptions.Item label="纳税人名称">{this.state.showInfo.nsrmc}</Descriptions.Item>
					<Descriptions.Item label="机柜名称">{this.state.showInfo.jgmc}</Descriptions.Item>
					<Descriptions.Item label="纳税人类型">{this.state.showInfo.nsrlx}</Descriptions.Item>
					<Descriptions.Item label="税控盘类型">{this.state.showInfo.skplx}</Descriptions.Item>
				</Descriptions>):(<Descriptions bordered={true} column={2} className="box-b02">
					<Descriptions.Item label="控税设备编号">{this.state.showInfo.jqbh}</Descriptions.Item>
					<Descriptions.Item label="发票类型">{this.restList(this.state.showInfo.fplxdm)}</Descriptions.Item>
					<Descriptions.Item label="开票截止时间">{this.state.showInfo.kfpzsj}</Descriptions.Item>
					<Descriptions.Item label="数据报送起始日期">{this.state.showInfo.bsqsrq}</Descriptions.Item>
					<Descriptions.Item label="数据报送终止日期">{this.state.showInfo.bszzrq}</Descriptions.Item>
					<Descriptions.Item label="单张发票开票金额限额">{this.state.showInfo.dzkpxe}</Descriptions.Item>
					<Descriptions.Item label="正数票累计金额限额">{this.state.showInfo.zsljxe}</Descriptions.Item>
					<Descriptions.Item label="负数票累计金额限额">{this.state.showInfo.fsljxe}</Descriptions.Item>
					<Descriptions.Item label="负数发票标志">{this.state.showInfo.fsfpbz}</Descriptions.Item>
					<Descriptions.Item label="负数发票限定天数">{this.state.showInfo.fsfpts}</Descriptions.Item>
					<Descriptions.Item label="最新报税日期">{this.state.showInfo.zxbsrq}</Descriptions.Item>
					<Descriptions.Item label="当前时钟">{this.state.showInfo.dqsz}</Descriptions.Item>
					<Descriptions.Item label="工作状态">{this.state.showInfo.gzzt}</Descriptions.Item>
					<Descriptions.Item label="上传截止日期">{this.state.showInfo.scjzrq}</Descriptions.Item>
					<Descriptions.Item label="离线开票时长">{this.state.showInfo.lxkpsc}</Descriptions.Item>
					<Descriptions.Item label="离线开票张数">{this.state.showInfo.lxkpzs}</Descriptions.Item>
					<Descriptions.Item label="离线正数累计金额">{this.state.showInfo.lxzsljje}</Descriptions.Item>
					<Descriptions.Item label="离线负数累计金额">{this.state.showInfo.lxfsljje}</Descriptions.Item>
					<Descriptions.Item label="离线扩展信息">{this.state.showInfo.lxkzxx}</Descriptions.Item>
				</Descriptions>))
					
				}
			</Spin>
		)
	}
}


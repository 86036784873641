import React from 'react'
import { Input, Select, Form, Button, TreeSelect, Row, Col, Checkbox, Radio, DatePicker, AutoComplete } from 'antd'

import Utils from '../../utils/index.js';
const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_PARENT, SHOW_ALL } = TreeSelect;


class Modal extends React.Component {

    state = {
        value: [],
    };
    onSelect = (value, node, extra)=>{ }
    
    onChange = (value) => {
        // console.log('onChange ', value);
        this.setState({ value });
        const str = JSON.stringify(value)

        const list = JSON.parse(str)
        let list2 = []
        for (var item of list) {
            let str = String(item)
            let len = String(item).length
            if (len > 6) {
                let nStr = str.substr(0, len - 2)
                let nNum = Number(nStr)
                if (!list.includes(nNum)) {
                    //所有
                    list.push(nNum)
                    //半选中的父节点
                    list2.push(nNum)
                }
            }
        }
        
        this.props.TreeSelect(list);
    };

    // reset = ()=>{
    //     this.props.form.resetFields();
    // }


    componentDidMount() {
        // this.props.resets("this.reset")
    }

    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const treeData = this.props.treeData;
        const formItemList = [];
        const tProps = {
            treeData,
            value: this.state.value,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_ALL,
            searchPlaceholder: '请选择',
            onSelect: this.onSelect,
            style: {
                width: '100%',
            },
        };

        if (this.props.TreeinitialValue) {
            this.state.value = this.props.TreeinitialValue
        }
        if (formList && formList.length > 0) {
            formList.forEach((item, i) => {
                let label = item.label;
                let field = item.field;
                let help = item.help || '';

                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                if (item.type == '城市') {

                    const city = <FormItem label="城市" key={field}>
                        {
                            getFieldDecorator('city', {
                                initialValue: '0'
                            })(
                                <Select
                                    style={{ width: 80 }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList([{ id: '0', name: '全部' }, { id: '1', name: '北京' }, { id: '2', name: '上海' }, { id: '3', name: '天津' }, { id: '4', name: '杭州' }])}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(city)
                } else if (item.type == '时间查询') {
                    const begin_time = <FormItem label="订单时间" key={field}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type === 'INPUT') {
                    const INPUT = <Col span={12} key={field}>
                        <FormItem label={label} help={help?help:undefined} >
                            {
                                getFieldDecorator(field, {
                                    initialValue: initialValue,
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `${label}必填，不能为空`,
                                        },
                                    ],
                                })(
                                    <Input type={placeholder == "请输入密码" ? "password" : (placeholder == "请输入单价" ? "number" : "text")} style={{ width: width }} placeholder={placeholder} autoComplete="off" />
                                )
                            }
                        </FormItem>
                        
                    </Col>;
                    formItemList.push(INPUT)
                } else if (item.type === 'AUTOSELECT') {
                    const SELECT = <Col span={12} key={field}>
                        <FormItem label={label} >
                            {
                                getFieldDecorator(field, {
                                    initialValue: initialValue,
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `${label}必填，不能为空`,
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        initialValue=""
                                        style={{ width: width }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                    >
                                        <Option value="" >{placeholder}</Option>
                                        {
                                            placeholder==="请选择当前模板名称"?(Utils.getOptionList2(item.list)):(Utils.getOptionList(item.list))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                    </Col>;
                    formItemList.push(SELECT)
                } else if (item.type === 'SELECT') {
                    const SELECT = <Col span={12} key={field}><FormItem label={label} >
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue,
                                rules: [
                                    {
                                        required: item.required, //true | false
                                        message: `${label}必填，不能为空`,
                                    },
                                ],
                            })(
                                <Select
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }


                    </FormItem>
                    </Col>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <Col span={12} key={field}> <FormItem label={label} >
                        {
                            getFieldDecorator([field], {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>
                    </Col>
                    formItemList.push(CHECKBOX)
                } else if (item.type == 'DATE') {
                    const Date = <Col span={12} key={field}><FormItem label={label} >
                        {
                            getFieldDecorator([field])(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>
                    </Col>;
                    formItemList.push(Date)
                } else if (item.type == 'TREE') {
                    const Date = <Col span={12} key={field}><FormItem label={label} >
                        {
                            <TreeSelect {...tProps} />
                        }
                    </FormItem>
                    </Col>;
                    formItemList.push(Date)
                }
            })
        }
        return formItemList;
    }


    render() {
        //console.log(this.props.TreeinitialValue)
        const { getFieldDecorator } = this.props.form;
        let setWidth;
        try {
            setWidth = this.props.setWidth
        } catch{
            setWidth = false
        }
        const formItemLayout = setWidth ? {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        } : {}
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.modalData(fieldsValue);

        return (
            <Form {...formItemLayout} >
                <Row gutter={24}>
                    {this.initFormList()}
                </Row>

            </Form>
        );
    }
}
export default Form.create({})(Modal);
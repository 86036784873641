import React, { Component } from "react"
import { Button, Divider, Table, Modal, Form, Input, message, Spin, Select, DatePicker, } from 'antd';
import { selectByPage } from '../../../api'
import moment from "moment"
import './auditLog.less'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default class TicketsOnline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            //查询状态
            param: {
                type: 1,
                eventModule: 1,
            },
            doAble: true,
            current: 1,
            total: 0,
            tableList: [],
            showDetails: false,
            showDetails2: false,
            data:""
        }
        this.listType = [
            { value: 1, name: "登陆" },
            { value: 2, name: "新增" },
            { value: 3, name: "修改" },
            { value: 4, name: "删除" },
        ]
        this.listEventModule = [
            { value: 1, name: "登陆" },
            { value: 2, name: "企业管理" },
        ]
    }

    getValue(key, val) {
        let obj = {}
        obj[key] = val
        this.setState({ param: { ...this.state.param, ...obj } }, () => {
            this.abled()
        })

    }
    handSubmit = () => {
        // console.log(this.state.param)
        this.getData()
    }

    getData = (page = 1) => {
        let param = this.state.param
        param.page = page
        if (!param.type) return message.error('操作类型未选择！')
        if (!param.eventModule) return message.error('模块未选择！')
        this.setState({ loading: true })
        selectByPage(param).then(res => {
            if (res.code === "0000") {
                message.success(res.msg)
                let tableList = res.resultList
                let total = res.pager.totalCount
                tableList.forEach((item, index) => {
                    item.key = index + 1
                    item.showDitails = ''
                    if (item.type == 1) {
                        try {
                            item.showDatails = JSON.parse(item.addValue)
                        } catch {
                            item.showDatails = item.addValue
                        }
                        item.showXx = { loginName: item.showDatails.loginName, userName: item.showDatails.userName }
                    }
                    else if (item.type == 2) {
                        try {
                            item.showDatails = JSON.parse(item.addValue)
                        } catch {
                            item.showDatails = item.addValue
                        }
                        item.showXx = { loginName: item.showDatails.companyName, userName: item.showDatails.registeredNo }
                    }
                    else if (item.type == 3) {
                        try {
                            // beforeValue和afterValue
                            item.beforeValue = JSON.parse(item.beforeValue)
                            item.afterValue = JSON.parse(item.afterValue)
                        } catch {
                            item.beforeValue = item.beforeValue
                            item.afterValue = item.afterValue
                        }
                        item.showXx = { loginName: item.beforeValue.companyName, userName: item.beforeValue.registeredNo }
                    } else if (item.type == 4) {
                        try {
                            item.showDatails = JSON.parse(item.deleteValue)
                        } catch {
                            item.showDatails = item.deleteValue
                        }
                        item.showXx = { loginName: item.showDatails.companyName, userName: item.showDatails.registeredNo }
                    }
                })
                this.setState({ tableList, total, current: page })
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    
    abled = () => {
        if (this.state.param.type == 1) {
            if (this.state.param.eventModule != 1) {
                this.setState({ param: { ...this.state.param, eventModule: 1 } })
            }
        } else {
            if (this.state.param.eventModule == 1) {
                this.setState({ param: { ...this.state.param, eventModule: 2 } })
            }
        }
    }

    setPage = (val) => {
        this.getData(val)
    }

    types = (val, list = []) => {
        let str = "";
        list.forEach(item => {
            if (item.value == val) {
                str = item.name
            }
        })
        return str
    }
    details = (data) => {
        if (data.type != 1) {
            this.setState({ showDetails: true,data })
        }
        if (data.type == 3) {
            this.setState({ showDetails2: true })
            // console.log(data.beforeValue, data.afterValue)
        } else {
            this.setState({ showDetails2: false })
            // console.log(data.showDatails)
        }
    }

    restTable = () => {
        let data = this.state.data
        if (this.state.showDetails2) {
            return (
                <ul className="datails_two">
                    <li className="datails_title"> <span></span>&nbsp;&nbsp;<span>更改前</span><span>更改后</span> </li>
                    <li><span>公司名称</span>：<span>{data.beforeValue.companyName}</span><span>{data.afterValue.companyName}</span> </li>
                    <li><span>公司详细地址</span>：<span>{data.beforeValue.address}</span><span>{data.afterValue.address}</span> </li>
                    <li><span>纳税人识别号</span>：<span>{data.beforeValue.registeredNo}</span><span>{data.afterValue.registeredNo}</span> </li>
                    <li><span>开户行名称</span>：<span>{data.beforeValue.bankName}</span><span>{data.afterValue.bankName}</span> </li>
                    <li><span>开户行账号</span>：<span>{data.beforeValue.bankAccount}</span><span>{data.afterValue.bankAccount}</span> </li>
                    <li><span>手机号</span>：<span>{data.beforeValue.phone}</span><span>{data.afterValue.phone}</span> </li>
                    <li><span>联系人</span>：<span>{data.beforeValue.legalName}</span><span>{data.afterValue.legalName}</span> </li>
                    <li><span>联系电话</span>：<span>{data.beforeValue.contactPhone}</span><span>{data.afterValue.contactPhone}</span> </li>
                    <li><span>邮箱</span>：<span>{data.beforeValue.email}</span><span>{data.afterValue.email}</span> </li>
                    <li><span>当前模板名称</span>：<span>{data.beforeValue.param1}</span><span>{data.afterValue.param1}</span> </li>
                    <li><span>收款人</span>：<span>{data.beforeValue.payee}</span><span>{data.afterValue.payee}</span> </li>
                    <li><span>复核人</span>：<span>{data.beforeValue.reviewer}</span><span>{data.afterValue.reviewer}</span> </li>
                    <li><span>开票人</span>：<span>{data.beforeValue.biller}</span><span>{data.afterValue.biller}</span> </li>
                    <li><span>公章</span>：<span><img src={data.beforeValue.param2} alt="图片" /></span><span><img src={data.afterValue.param2} alt="图片" /></span> </li>
                </ul>
            )
        } else {
            return (
                <ul className="datails_two">
                    <li><span>公司名称</span>：<span>{data.showDatails.companyName}</span></li>
                    <li><span>公司详细地址</span>：<span>{data.showDatails.address}</span> </li>
                    <li><span>纳税人识别号</span>：<span>{data.showDatails.registeredNo}</span> </li>
                    <li><span>开户行名称</span>：<span>{data.showDatails.bankName}</span> </li>
                    <li><span>开户行账号</span>：<span>{data.showDatails.bankAccount}</span> </li>
                    <li><span>手机号</span>：<span>{data.showDatails.phone}</span> </li>
                    <li><span>联系人</span>：<span>{data.showDatails.legalName}</span> </li>
                    <li><span>联系电话</span>：<span>{data.showDatails.contactPhone}</span></li>
                    <li><span>邮箱</span>：<span>{data.showDatails.email}</span> </li>
                    <li><span>当前模板名称</span>：<span>{data.showDatails.param1}</span></li>
                    <li><span>收款人</span>：<span>{data.showDatails.payee}</span></li>
                    <li><span>复核人</span>：<span>{data.showDatails.reviewer}</span> </li>
                    <li><span>开票人</span>：<span>{data.showDatails.biller}</span> </li>
                    <li><span>公章</span>：<span><img src={data.showDatails.param2} alt="图片" /></span> </li>
                </ul>
            )
        }
    }

    restZtxx = (text, key) => {
        return (<div className="log_ztxx">
            <span>{key==1?'用户名':"公司名称"}：{text.userName}</span>
            {
                key==1?null:<span>{key==1?'公司名称':"纳税人识别号"}：{text.loginName}</span>
            }
            
        </div>)
    }


    changeVal=(val)=>{
        return this.state.param.type>=val?false:true
    }


    render() {

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
            },
            {
                title: '类型',
                dataIndex: 'type',
                render: (text) => this.types(text, this.listType)
            },
            {
                title: '模块',
                dataIndex: 'eventModule',
                render: (text) => this.types(text, this.listEventModule)
            },
            {
                title: '主题信息',
                dataIndex: 'showXx',
                render: (text, codes) => this.restZtxx(text, codes.type)
            },
            {
                title: '时间',
                dataIndex: 'createTime',
                render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                title: '详情',
                render: (text, record, index) => (<div>
                    {
                        <Button type="primary" disabled={record.type == 1 ? true : false} onClick={() => { this.details(record) }}>详情</Button>
                    }
                </div>)
            },
        ];


        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className="auditLog_box" >
                    <div>
                        <h2 style={{ color: "#666", fontWeight: 700, letterSpacing: "1px", }} >{sessionStorage.getItem("pageTitle")||"审计日志"}</h2>
                    </div>
                    <Divider />
                    <div className="shenLing-box">
                        <Form layout="inline">
                            <FormItem label='操作类型' style={{ padding: "10px 0" }}>
                                <Select defaultValue="请选择类型" value={this.state.param.type} style={{ width: 140 }} onChange={(e) => this.getValue("type", e)}>
                                    {
                                        this.listType.map((item, index) => {
                                            return <Option value={item.value} key={index} >{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                            <FormItem label='模块' style={{ padding: "10px 0" }}>
                                <Select defaultValue="请选择模块" style={{ width: 140 }} value={this.state.param.eventModule} onChange={(e) => this.getValue("eventModule", e)}>
                                    <Option value={1} disabled={this.changeVal(1)}>登陆</Option>
                                    <Option value={2} disabled={this.changeVal(2)}>企业管理</Option>
                                </Select>
                            </FormItem>
                            <FormItem style={{ padding: "10px 0" }}>
                                <Button type="primary" onClick={this.handSubmit}>查询</Button>
                            </FormItem>
                        </Form>
                    </div>

                    <div>
                        <Table columns={columns} dataSource={this.state.tableList} pagination={{
                            onChange: this.setPage,
                            total: this.state.total,
                            current: this.state.current
                        }} />
                    </div>

                    {
                        this.state.showDetails ? (
                        <Modal title="详情" width="800px" visible={this.state.showDetails} footer={null} onOk={() => { this.setState({ showDetails: false, }) }} onCancel={() => { this.setState({ showDetails: false }) }}>
                            {
                                this.restTable()
                            }
                        </Modal>) : null
                    }
                </div>

            </Spin>
        )
    }
}
import React, { Component } from "react"
import { Table, Button,Input,Select} from 'antd';
import {selectSponsorId} from '../../../api'
import Utils from "../../../utils"
import './batchContract.less'
const { Option } = Select;

const columns = [
	{
	  title: '文件名称',
	  dataIndex: 'fileName',
	  key: 'fileName',
	},
	{
	  title: '发件人',
	  dataIndex: 'sendMen',
	  key: 'sendMen',
	},
	{
	  title: '收件人',
	  dataIndex: 'address',
	  key: 'address',
	},
	{
	  title: '发起时间',
	  dataIndex: 'createTime',
	  key: 'createTime',
	},
	{
	  title: '状态',
	  dataIndex: 'status',
	  key: 'status',
	},
	{ 
	  title: '操作',
	  dataIndex: 'operation',
	  key: 'operation',
	  render: (text, record) => (
		  <div>
			 <Select defaultValue="lucy" style={{ width: 120 }} onChange={()=>{this.handleChange()}}>
				<Option value="jack">财务专用章</Option>
				<Option value="lucy">合同专用章</Option>
			</Select>
		  </div>
		),
	}
  ];
  
  const data = [];
  for (let i = 0; i < 46; i++) {
	data.push({
	  key: i,
	  name: `Edward King ${i}`,
	  age: 32,
	  address: `London, Park Lane no. ${i}`,
	});
  }



export default class BatchContract extends Component {
	 state = {
		selectedRowKeys: [], // Check here to configure the default column
		loading: false,
		tableList:[]
	  };
	  componentDidMount(){
             this.getData()
	  }

	  getData=()=>{
		const param={
			sponsorId:"",
			type:"",
			stime:"",
			etime:"",
			contractName:"",
			sponsorName:"",
			datailsName:"",
			contractState:""
		}
		selectSponsorId(param).then(res=>{
			this.state.tableList=[]
			const result=res.map.list
			result.forEach((item)=>{
			  const dom={
				  key:item.id,
				  fileName:item.contractName,
				  sendMen:item.sponsorName,
				  address:item.ecContractOrderDetails.reduce((pre,list)=>{return list.datailsName?pre+list.datailsName+'/':""},""),
				  createTime:item.createTime,
				  status:Utils.contractType(item.contractState),
			  }
			  this.state.tableList.push(dom)
			 })
			 this.forceUpdate()
		})
	  }
	  handleChange=()=>{

	  }
	  start = () => {
		this.setState({ loading: true });
		// ajax request after empty completing
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 1000);
	  };
	
	  onSelectChange = selectedRowKeys => {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	  };
	  render() {
		const { loading, selectedRowKeys } = this.state;
		const rowSelection = {
		selectedRowKeys,
		onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		return (
			<div className="batch-contract">
				<div className="seal">
					<h3>选择印章</h3>
					<div>
						<div style={{ marginBottom: 16 }}>
						<Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
							Reload
						</Button>
						<span style={{ marginLeft: 8 }}>
							{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
						</span>
						</div>
						<Table rowSelection={rowSelection} columns={columns} dataSource={this.state.tableList} />
					</div>
				</div>
				<div className="rules">
                    <h3>签约校验</h3>
					<div className="rules-phone">
						<label htmlFor="">手机号</label><Input></Input>
					</div>
					<div className="rules-code">
						<label htmlFor="">验证码</label><Button type="primary">发送</Button>
					</div>
				</div>
			</div>
		)
	}
}
import React, { Component } from "react"
import { Button, Divider,Modal, message,Spin,Table,Radio, Input,DatePicker} from 'antd';
import { authToken,checkInventory,oneClickStatistics,revocationStatistics,checkInventoryDerive} from '../../../api'
import moment from "moment"
import utils from "../../../utils"
import store from "../../../store"
import action from "../../../actions/action"
import "./statistics.less"

const { MonthPicker } = DatePicker;

export default class Statistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
            val:0,
			visibleLogin:false,
			loading:false,
            tableList:[],
            tableList1:[],
            paramObj:{
                tjyf:'',
                nsrsbh:""
            },
            user:{
				nsrsbh:"",
				Passwor:'',
				pin:'',
            },
            nsrsbh:"",
            ableCx:false,
		}
    }
    componentDidMount(){
        let nsrsbh = store.getState().web.nsrsbh
        if(!nsrsbh){
            this.setState({visibleLogin:true})
        }else{
            this.setState({nsrsbh,user:{...this.state.user,nsrsbh},paramObj:{...this.state.paramObj,nsrsbh}})
        }
    }

    userChange=(val,key)=>{
		let obj = {}
		obj[key] = val
		this.setState({user:{...this.state.user,...obj}})
	}

    qieHuan=(val)=>{
        this.setState({val})
    }
	
	handleCancel = e => {
		this.setState({
		  visibleLogin:false
		});
	};

    onChangeMonth=(data,tjyf)=>{
        this.setState({
            paramObj:{...this.state.paramObj,tjyf}
        })
    }

    disabledDate=(current)=> {
        return current && current > moment().subtract(1,"M").startOf('day')
    }

    getMothen=()=>{
        return moment().subtract(1,"M").format("YYYY年M月")
    }

    getMonth2=()=>{
        return moment().subtract(1,"M").format("YYYYMM")
    }

    getData=(val)=>{
        let param = this.state.paramObj
        param.nsrsbh = this.state.nsrsbh
        if(!param.nsrsbh){
            this.setState({visibleLogin:true})
            return message.error('未认证！')
        }
        if(!param.tjyf&&val!=1){
            return message.error("未选择月份")
        }
        if(val==1){
            param.tjyf= this.getMonth2()
        }
        this.setState({loading:true})
        checkInventory(param).then(res=>{
            if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
				this.setState({visibleLogin:true,loading:false})
				return message.error(res.msg)
            }
            if(res.code=="0000"){
                try{
                    if(res.resultObj.statusCode=="200"){
                        message.success(res.msg)
                        let tableList = res.resultObj.data
                        if(Array.isArray(tableList)){
                            tableList.forEach((item,index)=>{
                                item.key = index+1
                            })
                        }
                        if(val==2){
                            this.setState({tableList})
                        }else if(val==1){
                            this.setState({tableList2:tableList})
                        }
                        store.dispatch(action('nsrsbh',param.nsrsbh))
                    }else{
                        message.error(res.resultObj.message)
                    }
                }catch{
                    console.log("err")
                }
            }else{
                message.error(res.msg)
            }
            

			this.setState({loading:false})
        })
    }

    doAble=(val)=>{
        // let dates = new Date()
        let param = {
            tjyf:this.getMonth2(),
            nsrsbh:this.state.user.nsrsbh
        }
        if(!param.nsrsbh){
            this.setState({visibleLogin:true})
            return message.error('未认证！')
        }
        this.setState({loading:true})
        if(val===1){
            oneClickStatistics(param).then(res=>{
                if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
                    this.setState({visibleLogin:true,loading:false})
                    return message.error(res.msg)
                }
                if(res.code=="0000"){
                    try{
                        if(res.resultObj.statusCode=="200"){
                            message.info(res.resultObj.message)
                            store.dispatch(action('nsrsbh',param.nsrsbh))
                        }
                    }catch{
                        console.log('err')
                    }
                }else{
                    message.error(res.msg)
                }
                
                this.setState({loading:false})
            })
        }else if(val===2){
            revocationStatistics(param).then(res=>{
                if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
                    this.setState({visibleLogin:true,loading:false})
                    return message.error(res.msg)
                }
                if(res.code=="0000"){
                    try{
                        if(res.resultObj.statusCode=="200"){
                            message.success(res.resultObj.message)
                            store.dispatch(action('nsrsbh',param.nsrsbh))
                        }
                    }catch{
                        console.log('err')
                    }
                }else{
                    message.error(res.msg)
                }
                this.setState({loading:false})
            })
        }
    }

	
	clickLogin=()=>{
        let param = this.state.user
		if(!param.nsrsbh){
			return message.error('纳税人识别号不能为空！')
		}
		if(!param.pin){
			return message.error('盘的证书口令不能为空！')
		}
		this.setState({loading:true})
		authToken(param).then(res=>{
            if(res.code=="0000"){
				try{
					if(res.resultObj.statusCode=="200"){
						message.success(res.resultObj.message)
						this.setState({visibleLogin:false,nsrsbh:param.nsrsbh},()=>{
							store.dispatch(action('nsrsbh',param.nsrsbh))
						})
					}else{
						message.error(res.resultObj.message)
					}
				}catch{
					console.log('数据错误')
				}
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
    }
    
    onChangePage=()=>{}

    downLoad=()=>{
        let param = this.state.paramObj
        if(!param.tjyf)return message.error('未选择月份！')
        // console.log(param)
        this.setState({loading:true})
        checkInventoryDerive(param).then(res=>{
            this.setState({loading:false})
            utils.downLoad(res,param.tjyf)
        })
    }

	render() {
		
		const modal_label = {width:120,textAlignLast:"justify"}
		const modal_style = {display:"flex",justifyContent:"start",alignItems:"center",marginBottom:10}
        const modal_span = {color:'red',verticalAlign:'middle'}
        
        const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '发票代码',
				dataIndex: 'fpdm',
				key: 'fpdm',
			},
			{
				title: '发票号码',
				dataIndex: 'fphm',
				key: 'fphm',
				
			},
			{
				title: '开票日期',
				dataIndex: 'kprq',
				key: 'kprq',
			},
			{
				title: '销方名称',
				dataIndex: 'xfmc',
				key: 'xfmc',
			},
			{
				title: '金额',
				dataIndex: 'je',
				key: 'je',
            },
            {
				title: '税额',
				dataIndex: 'se',
				key: 'se',
            },
            {
				title: '有效税额',
				dataIndex: 'yxse',
				key: 'yxse',
				render:(text,record)=><Input autoComplete="off" value={record.se}/>
            },
            {
				title: '勾选日期',
				dataIndex: 'qrrq',
				key: 'qrrq',
            },
            {
				title: '发票类型',
				dataIndex: 'fplx',
				key: 'fplx',
            },
            {
				title: '用途',
				dataIndex: 'fpyt',
				key: 'fpyt',
            },
            {
				title: '发票状态',
				dataIndex: 'fpzt',
				key: 'fpzt',
            },
            {
				title: '管理状态',
				dataIndex: 'glzt',
				key: 'glzt',
            },
           
        ];
		
		return (
			<Spin spinning={this.state.loading} size="large" >
				<div className="statistics_box">
					<div>
						<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"数据统计"}</h2>
					</div>
					<Divider />
					<div className="qieHuan_box">
                        <Button type={this.state.val==0?"primary":"default"} onClick={()=>this.qieHuan(0)}>当前期数数据统计</Button>
                        <Button type={this.state.val==1?"primary":"default"} style={{margin:"0 10px 0"}} onClick={()=>this.qieHuan(1)}>历史期数数据统计</Button>
                    </div>
					<section style={{display:this.state.val==0?"block":'none'}} className="section_1_box">
                        <label>税款所属期：<span>{this.getMothen()}</span></label>
                        <Button type="primary" onClick={()=>this.getData(1)}>统计查询</Button>
                        <Button type="primary" onClick={()=>this.doAble(1)}>一键统计</Button>
                        <Button type="primary" onClick={()=>this.doAble(2)}>一键撤销</Button>
                        <br/>
                        <br/>
                        <Table columns={columns} dataSource={this.state.tableList2} rowSelection={this.rowSelection}   pagination={{onChange: this.onChangePage, total: this.state.pages}} />
                    </section>

					<section style={{display:this.state.val==1?"block":'none'}} className="section_2_box">
                        <label htmlFor="MonthChange">税款所属期：</label><MonthPicker id="MonthChange" disabledDate={this.disabledDate} onChange={this.onChangeMonth} placeholder="选择所属期" format={'YYYYMM'} />
                        
                        <Button type="primary" onClick={()=>this.getData(2)}>统计查询</Button>
                        <Button type="primary" onClick={()=>this.downLoad()}>导出</Button>
                        
                        <br/>
                        <br/>
                        <Table columns={columns} dataSource={this.state.tableList} rowSelection={this.rowSelection}   pagination={{onChange: this.onChangePage, total: this.state.pages}} />
                    </section>

					{
						this.state.visibleLogin?(
						<Modal title="登录认证"  visible={this.state.visibleLogin} onOk={this.clickLogin} onCancel={this.handleCancel} destroyOnClose={true} >
								<div style={modal_style}>
									<label htmlFor="yc_nsrsbh1" style={modal_label}><span style={modal_span}>*</span>纳税人识别号</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_nsrsbh1" onChange={(e)=>this.userChange(e.target.value,"nsrsbh")} value={this.state.user.nsrsbh} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_name1" style={modal_label}><span style={modal_span}>*</span>盘的证书口令</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_name1" onChange={(e)=>this.userChange(e.target.value,"pin")} value={this.state.user.pin} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_psd1" style={modal_label}>二级密码</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_psd1" onChange={(e)=>this.userChange(e.target.value,"Passwor")} value={this.state.user.Passwor} />
								</div>
						</Modal>):null
					}

				</div>
				
			</Spin>
		)
	}
}
import React, { Component } from "react"
import './companyInfo.less'
import { Button, Divider, Table, Modal, message, Spin,Radio,Select,Popconfirm} from 'antd';
import { querySysEnterpriseList, sysEnterpriseDelete, sysEnterpriseAdd, sysEnterpriseUpdate, getBelongEnterprisesByUser,getLowerRolesByUser } from '../../../api'
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
import Upload from '../../../components/upload/upload'
import utils from '../../../utils'

const { Option } = Select;

let qiYe = []
let list = []

const addData = [

	{
		type: 'INPUT',
		label: '公司名称',
		field: 'companyName',
		placeholder: '请输入公司名称',
		width: "100%",
		initialValue: '',
		required:true
	},

	{
		type: 'INPUT',
		label: '联系人',
		field: 'legalName',
		placeholder: '请输入联系人',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '手机号',
		field: 'phone',
		placeholder: '请输入手机号',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '公众号APPID',
		field: 'wxAppid',
		placeholder: '请输入公众号APPID',
		width: "100%",
		initialValue: '',
		// required:true
	},
	{
		type: 'INPUT',
		label: '开户行名称',
		field: 'bankName',
		placeholder: '请输入开户行名称',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '开户行账号',
		field: 'bankAccount',
		placeholder: '请输入开户行账号',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '联系电话',
		field: 'contactPhone',
		placeholder: '请输入联系电话',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '纳税人识别号',
		field: 'registeredNo',
		placeholder: '请输入纳税人识别号',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '邮箱',
		field: 'email',
		placeholder: '请输入邮箱',
		width: "100%",
		initialValue: '',
		help:<span>接收余票不足等报警推送信息</span>
	},
	{
		type: 'INPUT',
		label: '开票人',
		field: 'biller',
		placeholder: '请输入开票人',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '复核人',
		field: 'reviewer',
		placeholder: '请输入复核人',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '收款人',
		field: 'payee',
		placeholder: '请输入收款人',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'INPUT',
		label: '公司详细地址',
		field: 'address',
		placeholder: '请输入公司详细地址',
		width: "100%",
		initialValue: '',
		required:true
	},
	{
		type: 'AUTOSELECT',
		label: '当前模板名称',
		field: 'param1',
		placeholder: '请选择当前模板名称',
		width: "100%",
		list:['上海', '云南', '内蒙', '北京', '厦门', '吉林', '四川', '大连', '天津', '宁夏', '宁波', '安徽', '山东', '山西', '广东', '广西', '新疆', '江苏', '江西', '河北', '河南', '浙江', '深圳', '湖南', '湖北', '澳门', '甘肃', '福建', '西藏', '贵州', '辽宁', '重庆', '陕西', '青岛', '青海', '黑龙'],
		required:true
	},
	{
		type: 'AUTOSELECT',
		label: '上级企业名称',
		field: 'parentNo',
		placeholder: '请选择上级企业名称',
		width: "100%",
		initialValue: '',
		list: qiYe,
		required:true
	},

]


export default class CompanyInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rolList:[],
			value:2,
			showValue:true,
			roleUuid:"",
			total: 1,
			tableList: [],
			//修改回显数据
			updateData: [],
			isRoleVisible: false,
			myTtle: '',
			id: '',
			companyAdd: false,
			companyUpdate: false,
			companyDelete: false,
			base: '',
			page: {
				pageNo: 1,
				pageSize: 10
			},
			param2: '',
			last: {
				type: 'AUTOSELECT',
				label: '上级企业名称',
				field: 'parentNo',
				placeholder: '请选择上级企业名称',
				width: "100%",
				initialValue: '',
				list: qiYe,
				required:true
			},
			showDetails: false,
			details: {},
			loading: false,
			//查询条件
			e:{},
			current:1,
			// 元素个数
			openLength: 15,
		}
		this.contents = {
			// "id": "",
			// 'address': "",
			// 'bankAccount': "",
			// 'bankName': "",
			// 'biller': "",
			// 'companyName': "",
			// 'contactPhone': "",
			// 'email': "",
			// 'legalName': "",
			// 'payee': "",
			// 'phone': "",
			// 'parentNo':"",
			// 'registeredNo': "",
			// 'reviewer': "",
			// 'idcardFront': "",
			// 'idcardReverse': "",
			// 'businessLicense': "",
		}
	}

	setBase = (base) => {
		this.setState({
			base
		})
	}
	componentWillMount(){
		getLowerRolesByUser().then(res=>{
			if(res.code==="0000"){
				let rolList = res.resultList
				this.setState({rolList})
			}
		})
	}

	componentDidMount() {
		let list = JSON.parse(sessionStorage.getItem('quanXian')) || []

		if (list.includes('companyAdd')) {
			this.setState({
				companyAdd: true
			})
		}
		if (list.includes('companyUpdate')) {
			this.setState({
				companyUpdate: true
			})
		}
		if (list.includes('companyDelete')) {
			this.setState({
				companyDelete: true
			})
		}

		this.getData()
	}

	componentWillUnmount() {
		list.splice(0, list.length)
		qiYe.splice(0, qiYe.length)
	}

	getUser = () => {
		let mList = []
		getBelongEnterprisesByUser().then(res => {
			if (res.code == "0000") {
				mList = res.resultObj.kids || []
				mList.forEach((item, index) => {
					item.key = index + 1
					let orgNo = item.orgNo
					if (!list.includes(orgNo)) {
						list.push(orgNo)
						qiYe.push({
							id: orgNo,
							name: item.companyName
						})
					}
				})
			}
		})
	}

	setPage = (pageNo) => {
		this.setState({
			page: { ...this.state.page, pageNo},
			current:pageNo
		}, () => {
			this.getData()
		})

	}

	getData = () => {
		this.getUser()
		this.setState({ loading: true })
		querySysEnterpriseList({ page: this.state.page,...this.state.e}).then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				let total = 1
				if (res.pager) {
					// 总条数
					total = res.pager.totalCount
				}

				tableList.forEach((item, index) => {
					item.key = index + 1
				})
				this.setState({
					tableList,
					total,
				})
			}
			this.setState({ loading: false })
		})
	}

	handleFilter = (e) => {
		//查询条件e
		this.setState({e,page:{pageNo: 1},current:1},()=>this.getData())
	}

	add = () => {

		if (addData.length < this.state.openLength) {
			addData.push(this.state.last)
		}

		for (let item of addData) {
			item.initialValue = ''
		}

		this.setState({
			isRoleVisible: true,
			myTtle: '创建',
			param2: '',
			roleUuid:'',
			showValue:true,
		})
	}

	edit = (data) => {
		if (addData.length == this.state.openLength) {
			addData.pop()
		}
		for (let key in data) {
			for (let item of addData) {
				if (item.field == key) {
					item.initialValue = data[key]
					this.contents[key] = data[key]
				}
			}
		}
		this.state.id = data.id
		this.setState({
			isRoleVisible: true,
			myTtle: '编辑',
			param2: data.param2, //图片路径
			roleUuid:"",
			showValue:false,
			value:2,
		})
	}
	addUserDataBack = (e) => {
		for (let key in e) {
			this.contents[key] = e[key]
		}
	}

	del = (data, index) => {
		let id = data.id
		this.setState({loading:true})
		sysEnterpriseDelete({ id }).then(res => {
			if (res.code == "0000") {
				message.success(res.msg)
				this.state.tableList.splice(index, 1)
				this.setState({})
			} else {
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}

	//必填 数据校验
	verification = () => {
		let lists = [
			{
				field: 'companyName',
				title: '公司名称',
			},
			{
				field: 'phone',
				title: '手机号',
			},
			{
				field: 'bankName',
				title: '开户行名称',
			},
			{
				field: 'bankAccount',
				title: '开户行账号',
			},
			{
				field: 'contactPhone',
				title: '联系电话',
			},
			{
				field: 'registeredNo',
				title: '纳税人识别号',
			},
			{
				field: 'biller',
				title: '开票人',
			},
			{
				field: 'reviewer',
				title: '复核人',
			},
			{
				field: 'payee',
				title: '收款人',
			},
			{
				field: 'address',
				title: '公司详细地址',
			},
			{
				field: 'param1',
				title: '当前模板名称',
			},
		]

		for (let item of lists) {
			let key = item.field
			if(key==="phone"&&!utils.regPhone(this.contents[key])){
				return item
			}
			if (!this.contents[key]) {
				return item
			}
		}

	}

	handleRoleSubmit = () => {
		let {value,roleUuid} = this.state
		if(value==1 && !roleUuid){
			return message.error('未选择角色！')
		}else if(value!=1){
			roleUuid = ""
		}
		//图片 base64编码
		let param2 = this.state.base
		if(param2&&!(param2.includes('/png')||param2.includes('/jpg')||param2.includes('/jpeg')||param2.includes('/gif')))return message.error("图片格式为(.png / .jpg / .jpeg / .gif)")

		let obj = this.verification()
		if (obj) {
			return message.error(`${obj.title}为必填项，或格式错误！`)
		}

		if (this.state.myTtle == '创建') {
			if (!this.contents.parentNo) {
				return message.error('上级企业必填，不能为空！')
			}
			if (!param2) {
				return message.error('公章不能为空！')
			}
			this.contents.roleUuid = roleUuid
			this.contents.param2 = param2
			this.setState({ loading: true })
			sysEnterpriseAdd(this.contents).then(res => {
				if (res.code == "0000") {
					message.success(res.msg)
					this.contents = {}
					this.setState({
						isRoleVisible: false,
						base: '',
					})
					this.getData()
					for (let key in this.contents) {
						this.contents[key] = ''
					}
				} else {
					message.error(res.msg)
				}
				this.setState({ loading: false })
			})
		} else {
			this.contents.id = this.state.id
			this.contents.param2 = param2
			this.setState({ loading: true })
			sysEnterpriseUpdate(this.contents).then(res => {
				if (res.code == '0000') {
					message.success(res.msg)
					this.setState({
						isRoleVisible: false,
						base: '',
					})
					this.getData()
					for (let key in this.contents) {
						this.contents[key] = ''
					}
				} else {
					message.error(res.msg)
				}
				this.setState({ loading: false })
			})
		}
	}

	details = (e) => {
		this.setState({ showDetails: true,details: e})
	}

	onChangeRadio=(e)=>{
		this.setState({
			value: e.target.value,
		});
	}

	onChangeSelect = (roleUuid)=>{
		this.setState({roleUuid})
	}

	render() {
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '公司名称',
				dataIndex: 'companyName',
				key: 'companyName',
				width:220,
			},
			{
				title: '纳税人识别号',
				dataIndex: 'registeredNo',
				key: 'registeredNo',
			},
			{
				title: '上级企业名称',
				dataIndex: 'parentName',
				key: 'parentName',
				width:220,
			},
			{
				title: '创建时间',
				dataIndex: 'createTime',
				key: 'createTime',
			},
			{
				title: '手机号',
				dataIndex: 'phone',
				key: 'phone',
			},
			{
				title: '公众号APPID',
				dataIndex: 'wxAppid',
				key: 'wxAppid',
			},
			{
				title: '联系人',
				dataIndex: 'legalName',
				key: 'legalName',
			},
			{
				title: '邮箱',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: '当前模板名称',
				dataIndex: 'param1',
				key: 'param1',
			},

			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				width:240,
				render: (text, record, index) => (
					<div>
						<Button type="primary" onClick={() => { this.details(record) }}>详情</Button>
						{
							this.state.companyUpdate?(<Button type="primary" style={{ marginLeft:10 }} onClick={() => { this.edit(record, index) }}>编辑</Button>):null
						}
						{
							this.state.companyDelete?(<Popconfirm title='确认删除吗' onConfirm={() => this.del(record, index)}>
									<Button type="danger"style={{ marginLeft:10 }} >删除</Button>
                        		</Popconfirm>):null
						}
					</div>
				),
			}
		];
		const formList = [
			{
				type: 'INPUT',
				label: '公司名称/税号',
				field: 'registeredNo',
				placeholder: '',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '手机号/联系人',
				field: 'phone',
				placeholder: '',
				width: "100%",
			},

		]
		return (
			<Spin spinning={this.state.loading} size="large"  >
				<div>
					<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}}>{sessionStorage.getItem("pageTitle")||"企业管理"}</h2>
				</div>
				<Divider />
				<div className="companyInfo-header">
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
					<Button type="primary" onClick={this.add} disabled={this.state.companyAdd ? false : true} style={{ display: this.state.companyAdd ? "inline-block" : 'none' }}>新增</Button>
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList}
						pagination={{
							onChange: this.setPage,
							total: this.state.total,
							current:this.state.current
						}} />
				</div>
				{
					this.state.isRoleVisible ? (<Modal
						destroyOnClose={true}
						width="800px"
						title={this.state.myTtle}
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={() => {
							this.setState({
								isRoleVisible: false,
								base: '',
							})
						}}
					>
						<ModalForm formList={addData} modalData={this.addUserDataBack.bind(this)} setWidth={true} />
						<div className="boxx">
							<div>
								<span>上传发票章：</span>
								<Upload setBase={this.setBase} url={this.state.param2+`?time=${new Date().getTime()}`} />
							</div>
							{
								this.state.showValue?(<div className="boxx_last">
								<Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
									<span style={{width:116,display:"inline-block",textAlign:'right'}}>是否创建账号：</span>
									<Radio value={1}>是</Radio>
									<Radio value={2}>否</Radio>
								</Radio.Group>
								<div >
									{this.state.value==1?(<>
										<span style={{width:116,display:"inline-block",textAlign:'right'}}>请选择：</span>
										<Select defaultValue={this.state.roleUuid} style={{ width: 200 }} onChange={this.onChangeSelect} >
											{
												this.state.rolList.map((item,index)=>{
													return <Option value={item.uuid} key={index+1}>{item.roleName}</Option>
												})
											}
										</Select>
									</>):null}
								</div>
							</div>):null
							}
							
						</div>
					</Modal>) : null
				}
				<Modal title="详情" visible={this.state.showDetails} onCancel={() => this.setState({ showDetails: false })}  className="companyInfoDetails" onOk={() => this.setState({ showDetails: false })}>
					<div className="details"><span>公司名称</span>：{this.state.details.companyName}</div>
					<div className="details"><span>上级企业名称</span>：{this.state.details.parentName}</div>
					<div className="details"><span>创建时间</span>：{this.state.details.createTime}</div>
					<div className="details"><span>纳税人识别号</span>：{this.state.details.registeredNo}</div>
					<div className="details"><span>手机号</span>：{this.state.details.phone}</div>
					<div className="details"><span>联系人</span>：{this.state.details.legalName}</div>
					<div className="details"><span>邮箱</span>：{this.state.details.email}</div>
					<div className="details"><span>当前模板名称</span>：{this.state.details.param1}</div>
				</Modal>
			</Spin>
		)
	}
}
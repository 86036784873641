import React, { Component } from "react"
import { Table, Divider, Icon } from 'antd';
import './desk.less'
import { selectSponsorIdCount } from '../../../api'
import Utils from '../../../utils'
const IconFont = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/font_1572614_vfq37bi2yx.js',
}); //记得更新




export default class Desk extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type1: "0",
			type2: "0",
			type3: "0",
			type4: "0",
			tableList: []
		}
		this.tableList = []
	}
	componentDidMount() {
		this.getData()
	}
	getData = () => {
		return
		selectSponsorIdCount({ sponsorId: localStorage.getItem("userID") }).then(res => {
			const result = res.map.list
			const result1 = res.map.list1
			if (result.length === 0) return false
			this.setState({
				type1: result[0].sum,
				type2: result[1].sum,
			})
			result1.forEach((item) => {
				const dom = {
					key: item.id,
					fileName: item.contractName,
					sendMen: item.sponsorName,
					address: item.ecContractOrderDetails.reduce((pre, list) => { return list.datailsName ? pre + list.datailsName + '/' : "" }, ""),
					time: item.createTime,
					status: Utils.contractType(item.contractState),
				}
				this.setState({
					tableList: [...this.state.tableList, dom]
				})
			})
		})
	}
	sign = () => {
		this.props.history.push('/system/signContract')
	}

	render() {

		const columns = [
			{
				title: '文件名称',
				dataIndex: 'fileName',
				key: 'fileName',
			},
			{
				title: '发件人',
				dataIndex: 'sendMen',
				key: 'sendMen',
			},
			{
				title: '收件人',
				dataIndex: 'address',
				key: 'address',
			},
			{
				title: '发起时间',
				dataIndex: 'time',
				key: 'time',
			},
			{
				title: '状态',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				render: () => (
					<span>
						<a href='www.baidu.com'>详情</a>
						<Divider type="vertical" />
						<a href='www.baidu.com'>下载</a>
					</span>
				),
			}
		];

		return (
			<div className="control-Desk">
				{/* <section>
				 <div className="send-sign clearfix">
					 <span onClick={()=>{this.sign()}}>发起签约</span>
				 </div>
				 <div className="contract-number clearfix">
					 <p>可发:对公合同<span>0</span>份 | 对私合同<span>2</span>份 | <span>立刻充值</span></p>
				 </div>
				 <div className="contract-type">
					<li><div className='contract-img1'><IconFont type="icon-account" style={{ fontSize: '30px', color: '#fff' }} /></div><p>{this.state.type1}</p><p>未发送</p></li>
					<li><div className='contract-img2'><IconFont type="icon-add-account" style={{ fontSize: '30px', color: '#fff' }} /></div><p>{this.state.type2}</p><p>已发送</p></li>
					<li><div className='contract-img3'><IconFont type="icon-templatedefault" style={{ fontSize: '30px', color: '#fff' }} /></div><p>{this.state.type3}</p><p>已完成</p></li>
					<li><div className='contract-img4'><IconFont type="icon-delete" style={{ fontSize: '30px', color: '#fff' }} /></div><p>{this.state.type4}</p><p>已过期</p></li>
				 </div>
			  </section>
			  <section>
				  <div className="near-file">
				  <p>最近文件</p>
				  <Table dataSource={this.state.tableList} columns={columns} />;
				  </div>
			  </section> */}
				<img className="myImg" src={require('../../../assets/img/logo/logo@2x.png')} alt="" />
				<span className="mySpan">
					深圳市中税创新科技有限公司
			  </span>
			</div>
		)
	}
}
import React, { Component } from "react"
import { Tree,Button,Form,Modal,Input,Select, message} from 'antd'
import TreeData from '../../../components/slideNav/slideNavData'
import ModalForm from '../../../components/modal/modal'
import {menuGet,addMenu,delMenu,updateMenu,selectMenu} from '../../../api/index'
import './menuManager.less'
const FormItem = Form.Item;
const Option = Select.Option;
const { TreeNode } = Tree;
  const addMenuData=[
	  
	{
		type: 'INPUT',
		label: '菜单名',
		field: 'resourcesName',
		placeholder: '请输入菜单名',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '跳转路径',
		field: 'resourcesUrl',
		placeholder: '请输入跳转路径',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: 'icon路径',
		field: 'imgName',
		placeholder: '请输入icon路径',
		width: "100%",
		initialValue:'',
	},
    {
		type: 'SELECT',
		label: '菜单类型',
		field: 'resourcesType',
		width: "100%",
		initialValue:'1',
		list:[
			{
			  id:'1',name:"模块"
			},
			{
			  id:'2',name:"菜单"
			},
			{
			  id:'3',name:"功能"
			}
		]
	},
	{
		type: 'SELECT',
		label: '状态',
		field: 'status',
		width: "100%",
		initialValue:'1',
		list:[
			{
			  id:'-1',name:"禁用"
			},
			{
			  id:'0',name:"删除"
			},
			{
			  id:'1',name:"启用"
			}
		]
	},
	
   
]
export default class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedKeys: [],
			autoExpandParent: true,
			checkedKeys: [],
			selectedKeys: [],
			getTree:[],
			isRoleVisible:false,
			flag:false,
			selectID:"",
			myTitle:''
		  };
		  //添加数据
		  this.addData={}
		  //还原数据
		  this.reduction=addMenuData
          //修改数据
		  this.updateDate={}
		
	}
      componentDidMount(){
		  this.startData()
	  }
	  startData=()=>{
		menuGet().then(res=>{
			if(res.code==="0000"){
				
			   this.setState({
				   getTree:this.getData(res.resultObj)
			   })
			}
	    })
	  }
	  getData=(data)=>{
		return data.reduce((pre,item)=>{
			if(item.children){
				pre.push({
					title:item.data.resourcesName,
					key:item.data.resourcesNo+","+item.data.id+','+item.data.parentResourcesNo,
					icon:item.data.imgName,
					children:this.getData(item.children)
				})
			}else{
				pre.push({
					title:item.data.resourcesName,
					key:item.data.resourcesNo+","+item.data.id+','+item.data.parentResourcesNo,
					icon:item.data.imgName,
				})
			}
			return pre
		},[])
	  }
	  renderTreeNodes = data =>
	  data.map(item => {
		if (item.children) {
		  return (
			<TreeNode title={item.title} key={item.key} dataRef={item}>
			  {this.renderTreeNodes(item.children)}
			</TreeNode>
		  );
		}
		return <TreeNode key={item.key} {...item} />;
	  });
	  onExpand = expandedKeys => {
		//if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		this.setState({
		  expandedKeys,
		  autoExpandParent: false,
		});
	  };
	  onCheck = checkedKeys => {
		this.setState({ checkedKeys });
		//console.log(checkedKeys)
	  };
	
	  onSelect = (selectedKeys, info) => {
		console.log(selectedKeys)
		this.setState({ selectedKeys});
	  };
	  addMenu=(myTitle)=>{
		// addMenuData
		addMenuData.forEach(item=>{
			if(item.type=="INPUT"){
				item.initialValue = ''
			}
		})
		this.setState({isRoleVisible:true,myTitle,flag:false})
	  }
	  delMenu=()=>{
		if(this.state.selectedKeys.length=="0"){
			message.warning("请先选择需要删除的菜单")
			return false
		}
		delMenu({id:this.state.selectedKeys[0].split(',')[1]}).then(res=>{
			if(res.code==="0000"){
				message.success(res.msg)
				this.startData()
			}
		})
	  }
	  updateMenu=(myTitle)=>{
		  if(this.state.selectedKeys.length=="0"){
			  message.warning("请先选择需要修改的菜单")
			  return false
			}
		this.setState({myTitle})

		selectMenu({id:this.state.selectedKeys[0].split(',')[1]}).then(res=>{
			if(res.code==="0000"){
				this.reduction.forEach((item,index)=>{

					switch(item.field){
						case "resourcesName":
						item.initialValue=res.resultObj.resourcesName;
						break;
						case "resourcesUrl":
						item.initialValue=String(res.resultObj.resourcesUrl);
						break;
						case "imgName":
						item.initialValue=res.resultObj.imgName;
						break;
						case "status":
						item.initialValue=String(res.resultObj.status);
						break;
						case "resourcesType":
						item.initialValue=String(res.resultObj.resourcesType);
						break;
					}
					this.forceUpdate()
					this.setState({
						isRoleVisible:true,
						flag:true
					})
				})
				console.log(this.reduction)
			} 
		})
		
	  }
	 
	  //添加菜单
	  handleRoleSubmit=(e)=>{
	
		this.addData.id=this.state.selectedKeys[0].split(',')[1]
		// console.log(this.addData)
		if(this.state.flag){
			this.addData.parentResourcesNo=this.state.selectedKeys[0].split(',')[2]
			updateMenu(this.addData).then(res=>{
				if(res.code==="0000"){
					this.setState({
						isRoleVisible:false,
						flag:false
					})
					this.startData()
					message.success(res.msg)
				}else{
					message.warning(res.msg)
				}
			})
		}else{
			this.addData.parentResourcesNo=this.state.selectedKeys[0].split(',')[0]
			addMenu(this.addData).then(res=>{
				if(res.code==="0000"){
					this.setState({
						isRoleVisible:false
					})
					this.startData()
					message.success(res.msg)
				}else{
					message.warning(res.msg)
				}
			})
		}
      
	  }
	  addMenuDataBack=(e)=>{
		this.addData=e
	  }


	
	render() {	
		const treeMenu=[
			{
				title:"全部",
				key:"1001",
				children:this.state.getTree
			}
		]
		return (
			<div className='menu-manager'>
			   <p>{sessionStorage.getItem("pageTitle")||"菜单栏管理"}</p>
			   <div className="menu-box">
			   <div style={{border:"1px solid #eee",width:"20%",height:"100vh"}}>
			   <Tree
					
					onExpand={this.onExpand}
					expandedKeys={this.state.expandedKeys}
					autoExpandParent={this.state.autoExpandParent}
					onCheck={this.onCheck}
					checkedKeys={this.state.checkedKeys}
					onSelect={this.onSelect}
					selectedKeys={this.state.selectedKeys}
				>
					{this.renderTreeNodes(treeMenu)}
				</Tree>
			   </div>
			   <div className='menu-update'>
				   <div className="menu-btn">
				       <Button type="primary" onClick={()=>this.addMenu("添加菜单")}>添加菜单</Button>
					   <Button type="primary" onClick={()=>this.updateMenu("修改菜单")}>修改菜单</Button>
					   <Button type="primary" onClick={()=>this.delMenu()}>删除菜单</Button>
				   </div>
			        
			   </div>
			   </div>
			   {
				   this.state.isRoleVisible?(<Modal
					title={this.state.myTitle}
					visible={this.state.isRoleVisible}
					onOk={this.handleRoleSubmit}
					onCancel={()=>{
						this.setState({
							isRoleVisible:false
						})
					}}
				>
				<ModalForm formList={this.flag?this.reduction:addMenuData} modalData={this.addMenuDataBack.bind(this)}/>
			</Modal>):null
			   }
			   
			</div>
		)
	}
}


import React, { Component } from "react"
import './setReport.less'
import { Button, Divider, Table, message, Modal,Spin,Select} from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ControlPanel from "../../../components/controlPanel/controlPanel"
import { queryTaxPlateByPage, copyAndReport, listenManageinfoQuery,writeBackListenData } from '../../../api'
import utils from "../../../utils"
import moment from "moment"


const formList = [
	{
	   type: 'INPUT',
	   label: '纳税人识别号',
	   field: 'nsrsbh',
	   placeholder: '请输入纳税人识别号',
	   initialValue:"",
	   width: "100%",
   }, {
	   type: 'INPUT',
	   label: '纳税人名称',
	   field: 'nsrmc',
	   placeholder: '请输入纳税人名称',
	   initialValue:"",
	   width: "100%",
   },
]

const ysList = [
	"kfpzsj",
	"zxbsrq",
	"bsqsrq",
	"bszzrq",
	"scjzrq",
	"dqsz"
	]

export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//修改回显数据
			updateData: [],
			isRoleVisible: false,
			flag: false,
			tableList: [],
			getDataInfo: {
				nsrsbh: '',
				nsrmc: '',
				plateNo: '',
				page: 1,
				rows: 10
			},
			total:1,
			loading:false,
			fpdm:"",
			showTable:false,
			tableData:[],
			copyData:[],
			selectedRowKeys:[],
		}
	}

	resetFpdm=(data,index)=>{
		data = data.split(",")
		let defaultValue = data[0]
		data = utils.getFpdm(data)
		this.state.tableList[index].valFpdm = defaultValue

		return (<Select defaultValue={defaultValue} style={{ width: 150 }} onChange={(valFpdm)=>{this.selectHandle(valFpdm,index)}}>{utils.getOptionList(data)}</Select>)
	}

	selectHandle=(valFpdm,index)=>{
		this.state.tableList[index].valFpdm = valFpdm
	}
	restDate=(date)=>{
		if(date.length>6){
			if(date.length>8){
				return moment(date,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
			}
			return moment(date,"YYYYMMDD").format("YYYY-MM-DD")
		}
		return date
	}
	// 上报汇总
	jumpCopy = (e) => {
		this.setState({tableData:[],selectedRowKeys:[],copyData:[]})
		let fplxdm = e.fplxdm.split(",")
		fplxdm.pop()
		fplxdm.forEach((item,indexs)=>{
			let param = {
				kpzdbs:e.jqbh,
				fplxdm:item
			}
			this.setState({loading:true})
			listenManageinfoQuery(param).then(res=>{
				if(res.code=="0000"){
					let showInfo = res.resultObj.body.returndata
					for(let key of ysList){
						showInfo[key] = this.restDate(showInfo[key])
					}
					if(res.resultObj.body.returnmsg=="成功"){
						showInfo.key = indexs
						this.state.tableData.push(showInfo)
						let keys = this.state.tableData.map((e,i)=>{
							return i
						})
						this.setState({showTable:true,selectedRowKeys:keys})
					}else{
						this.setState({showTable:false})
					}
				}else{
					message.error(res.msg)
				}
				this.setState({loading:false})
			})
		})
	}

	getList=()=>{
		let data=[]
		let selectedRowKeys = this.state.selectedRowKeys
		if(selectedRowKeys.length>0){
			selectedRowKeys.forEach(item=>{
				data.push(this.state.tableData[item])
			})
		}
		return data
	}

	clearCopy = () => {
		let data=this.getList()
		if(data.length==0)return message.error('数据缺失！')
		data.forEach(item=>{
			let data = {
				jqbh:item.jqbh,
				fplxdm: item.fplxdm
			}
			this.setState({loading:true})
			writeBackListenData(data).then(res => {
				if(res.code=="0000"){
					if(res.resultObj.returncode=="0"){
						message.success(res.msg)
					}else{
						Modal.error({
							title: '错误提示',
							content: utils.myFaPiaoType(item.fplxdm) + "  " +res.resultObj.returnmsg,
						});
					}
				}else{
					message.error(res.msg)
				}
				this.setState({loading:false})
			})
		})
		
	}
	
	copyInter=()=>{
		let data=this.getList()
		if(data.length==0)return message.error('数据缺失！')
		data.forEach(item=>{
			let data = {
				jqbh:item.jqbh,
				fplxdm: item.fplxdm
			}
			this.setState({loading:true})
			copyAndReport(data).then(res => {
				if(res.code=="0000"){
					if(res.resultObj.returncode=="0"){
						message.success(res.msg)
						this.getData()
					}else{
						Modal.error({
							title: '错误提示',
							content: utils.myFaPiaoType(item.fplxdm) + "  " + res.resultObj.returnmsg,
						});
					}
				}else{
					message.error(res.msg)
				}
				this.setState({loading:false})
			})
		})
		
	}

	handleFilter = e => {
		this.setState({ getDataInfo: { ...this.state.getDataInfo, ...e,page:1 } }, () => {
			this.getData()
			formList.forEach(item=>{
				item.initialValue = ""
			})
		})
	}

	componentDidMount() {
		this.getData()
	}

	setPage=(val)=>{
		this.state.getDataInfo.page = val
		this.getData()
	}

	getData() {
		const param = {
			...this.state.getDataInfo
		}
		this.setState({loading:true})
		queryTaxPlateByPage(param).then(res => {
			const { resultList: tableList } = res
			let total;
			try{
				total = res.pager.totalCount
				tableList.forEach((item,index)=>{
					item.key = index+1
				})
			}catch{
				total=1
			}

			this.setState({
				tableList,
				total,
				loading:false
			})
		})
	}


	getInfos = (val)=>{
		formList.forEach(item=>{
			item.initialValue = val[item.field]
		})
		this.setState({ getDataInfo: { ...this.state.getDataInfo, ...val } })
	}

	resetFp=(text)=>{
		return utils.myFaPiaoType(text)
	}

	onSelectChange = (selectedRowKeys, selectedRows) => {
		this.setState({ selectedRowKeys,copyData:selectedRows });
	  };

	render() {
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
			},
			// {
			// 	title: '发票类型',
			// 	dataIndex: 'fplxdm',
			// 	render: (text, record, index) => this.resetFpdm(text,index),
			// },
			{
				title: '操作',
				dataIndex: 'operations',
				render: (text, record, index) => (
						<Button type="primary" onClick={() => { this.jumpCopy(record)}}>汇总上传</Button>
				),
			}
		];
		
		let tableCoumns =[
			{
				title: '控税设备编号',
				dataIndex: 'jqbh',
			},
			{
				title: '发票类型代码',
				dataIndex: 'fplxdm',
				render: (text) => this.resetFp(text),
			},
			{
				title: '开票截止时间',
				dataIndex: 'kfpzsj',
			},
			{
				title: '数据报送起始日期',
				dataIndex: 'bsqsrq',
			},
			{
				title: '数据报送终止日期',
				dataIndex: 'bszzrq',
			},
			{
				title: '最新报税日期',
				dataIndex: 'zxbsrq',
			},
			
		]
		const rowSelection = {
			selectedRowKeys:this.state.selectedRowKeys,
			onChange: this.onSelectChange,
			
		  };

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div className="setReport_box">
					<div className="setReport_menberManager_title">
						<h2>{sessionStorage.getItem("pageTitle")||"抄报反写"}</h2>
					</div>
					<Divider />
					<div style={{ padding: "10px 0",display:'flex',justifyContent:"start",alignItems:'center' }}>
						<ControlPanel getInfo={(val)=>{this.getInfos(val)}}/>
						<BaseForm formList={formList} filterSubmit={this.handleFilter} />
					</div>
					<div style={{ background: '#C7E2FF' }}>
						
					</div>
					<div>
						<Table columns={columns} dataSource={this.state.tableList}  pagination={{
								onChange: this.setPage,
								total: this.state.total,
								current:this.state.getDataInfo.page
							}} />
					</div>
					{
						this.state.showTable?(
							<Modal title="监控管理数据" width="1200px" visible={this.state.showTable} footer={null} onCancel={() => { this.setState({ showTable: false }) }}>
                            <Table columns={tableCoumns} rowSelection={rowSelection} dataSource={this.state.tableData}  pagination={{hideOnSinglePage:true}}/>
							<div className="setRepot_model_button" >
								<Button type="primary" loading={this.state.loading} onClick={this.copyInter} >上报汇总</Button>
								<Button type="primary" loading={this.state.loading} onClick={this.clearCopy} >反写</Button>
							</div>
                        </Modal>
						):null
					}
				</div>
			</Spin>
		)
	}
}
import React, { Component } from "react"
import './deviceManagement.less'
import { Button,Divider,Table, message,Modal } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
const typeList = [
	{
		id:'0',name:"100口主板盘组"
	},{
		id:'1',name:"税控服务器（500）"
	},{
		id:'2',name:"单Ukey终端"
	},{
		id:'3',name:"32口主板盘组"
	},{
		id:'4',name:"32口USB盘组"
	},{
		id:'5',name:"40口Ukey盘组"
	},{
		id:'6',name:"84口UKey盘组"
	},	
]
const addData=[
	{
		type: 'INPUT',
		label: '盘组名称',
		field: 'loginName',
		placeholder: '请输入盘组名称',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'SELECT',
		label: '盘组类型',
		field: 'userName',
		placeholder: '请选择盘组类型',
		width: "100%",
		initialValue:'0',
		list: typeList
	},
	{
		type: 'INPUT',
		label: '服务地址或域名',
		field: 'roleUuid',
		width: "100%",
		initialValue:'',
		placeholder: '请输入服务地址或域名',
	},
	{
		type: 'INPUT',
		label: '服务端口号',
		field: 'userPhone',
		placeholder: '请输入服务端口号',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '发票上传服务地址',
		field: 'idCard',
		placeholder: '请输入发票上传服务地址',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '所属企业',
		field: 'password',
		placeholder: '请输入所属企业',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '备注',
		field: 'remark',
		placeholder: '请输入备注',
		width: "100%",
		initialValue:'',
	},
   
]
export default class DeviceManagement extends Component {
	constructor(props) {
		super(props);
		this.state={
			//修改回显数据
			updateData:[],
			isRoleVisible: false,
			flag:false,
			tableList: []
		}
	}
	add=()=>{
		this.state.updateData.forEach(res=>{
			res.initialValue=''
		})
        this.setState({
			isRoleVisible:true,
			flag:false
		})
	}
	edit=(e)=>{
	}
	del=(e)=>{
	}
	handleFilter=(e)=>{
	}
	addUserDataBack=(e)=>{
		// if (Object.keys(e).length !== 0){
		//     for(let key in e){
		// 		this.state.addData[key]=e[key]
		// 	}
		// }else{
		//     return false
		// } 
	}
	handleRoleSubmit=()=>{}
	render() {
		const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
			},
			{
			  title: '盘组设备名称',
			  dataIndex: 'userName',
			  key: 'userName',
			},
			{
			  title: '盘组类型',
			  dataIndex: 'userPhone',
			  key: 'userPhone',
			},
			{
			  title: '服务地址或域名',
			  dataIndex: 'idCard',
			  key: 'idCard',
			},
			{
			    title: '端口号',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
			    title: '发票上传地址',
				dataIndex: 'x',
				key: 'x',
			},
			{
			    title: '所属企业',
				dataIndex: 'qi',
				key: 'qi',
			},
			{
			    title: '盘数',
				dataIndex: 'pan',
				key: 'pan',
			},
			{
			    title: '添加时间',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: '备注',
				dataIndex: 'remark',
				key: 'remark',
			},
			{ 
			  title: '操作',
			  dataIndex: 'operation',
			  key: 'operation',
			  render: (text, record) => (
				  <div>
					  <Button type="primary" onClick={()=>{this.edit(record)}}>编辑</Button>
					  <Button type="primary" style={{margin: "0 10px"}}>更新</Button>
					  <Button type="danger" onClick={()=>{this.del(record)}}>删除</Button>
				  </div>
				),
			}
		  ];
		const formList = [
			{
				type: 'SELECT',
				label: '盘组设备名称',
				field: '1',
				placeholder: '请选择盘组设备名称',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"未发送"
					},
					
				]
			}, {
				type: 'SELECT',
				label: '盘组类型',
				field: 'userName',
				placeholder: '请选择盘组类型',
				width: "100%",
				initialValue:'0',
				list: typeList
			},{
				type: '时间查询',
				label: '请选择时间',
				field: 'begin',
				field1: 'end',
				placeholder: '请选择日期',
			}
		]
		return (
			<div className="menberManager">
				<div className="menberManager-title">
					<h2>盘组设备管理</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
				</div>
				<div style={{ background: '#C7E2FF'}}>
                    <Button type="link" onClick={()=>{this.add()}} style={{color: '#333333'}}><img src={require('../../../assets/icon/add.png')} style={{marginRight: '10px'}}/>添加</Button>
                    <Button type="link" style={{color: '#333333',margin:"0 20px"}}><img src={require('../../../assets/icon/set-code.png')} style={{marginRight: '10px'}}/>设置注册码</Button>
                    <Button type="link" style={{color: '#333333'}}><img src={require('../../../assets/icon/mac.png')} style={{marginRight: '10px'}}/>查询MAC</Button>
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>
				<Modal
						title="添加盘组设备"
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={()=>{
							this.setState({
								isRoleVisible:false
							})
						}}
					>
					<ModalForm formList={this.state.flag?this.state.updateData:addData} modalData={this.addUserDataBack.bind(this)}/>
				</Modal>

			</div>
		)
	}
}
import React, { Component } from "react";
import Footer1 from "../../../components/webfooter1/webFooter1";
import "./check.less";

import imgBanner from "../../../assets/img/check/banner.png";
import bgImg2 from "../../../assets/img/check/bg2.png";
import icon1 from "../../../assets/img/check/icon1.png";

import c1 from "../../../assets/img/check/c1.png";
import c2 from "../../../assets/img/check/c2.png";
import c3 from "../../../assets/img/check/c3.png";
import c4 from "../../../assets/img/check/c4.png";

export default class Check extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitData: {},
		};
	}
	inputs = (key, e) => {
		let val = e.target.value;
		let obj = {};
		obj[key] = val;
		this.setState({
			submitData: { ...this.state.submitData, ...obj },
		});
	};

	render() {
		const bg2 = {
			width: "290px",
			height: "258px",
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bgImg2 + ")", //图片的路径
		};

		return (
			<div className="electronic_2-content">
				<section className="electronic-1">
					<img alt="" src={imgBanner} />
				</section>
				<section className="electronic-2">
					{/* 选项1 */}
					<div className="switchOpen">
							<h3>
								增值税发票查验平台
							</h3>
							<div className="textContent">
								<div>
									<img src={bgImg2}/>
								</div>
								<div>
									<ul className="form-ul">
										<li>
											<label htmlFor="dm">
											票种类型：
											</label>
											<input
												id="dm"
												placeholder="请输入票种类型"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm1">
												发票代码：
											</label>
											<input
												id="dm1"
												placeholder="请输入发票代码"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm2">
												发票号码：
											</label>
											<input
												id="dm2"
												placeholder="请输入发票号码"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm3">
												开票日期：
											</label>
											<input
												id="dm3"
												placeholder="请输入开票日期"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<button>查询</button>
											<button>重置</button>
										</li>
									</ul>
								</div>
							</div>
					</div>
				</section>
				<section className="electronic-3">
					<h3>查验亮点</h3>
					<ul>
						<li>
							<div>
								<span>快速查验</span>
								<img src={c1} alt="" />
							</div>
							<div>快速反馈查验结果，快速准确</div>
						</li>
						<li>
							<div>
								<span>智能识别</span>
								<img src={c2} alt="" />
							</div>
							<div>
								可采用OCR识别、拍照、手工录入等结合图片智能识别技术
							</div>
						</li>
						<li>
							<div>
								<span>结果准确</span>
								<img src={c3} alt="" />
							</div>
							<div>
								与税局的最新查验结果保持一致发票均为真实发票，从根本上杜绝假发票
							</div>
						</li>
						<li>
							<div>
								<span>票种全</span>
								<img src={c4} alt="" />
							</div>
							<div>减少假发票带来的税务、审计风险</div>
						</li>
					</ul>
				</section>
				<Footer1 />
			</div>
		);
	}
}

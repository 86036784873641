import React, { Component } from "react"
import { Button, Divider, message,Spin } from 'antd';
import ControlPanel from "../../../components/controlPanel/controlPanel"
import { invoiceChoose } from '../../../api'

export default class SumStatistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			kpzdbs:''
		}
	}

	getInfo =(data)=>{
		let {deviceNo:kpzdbs} = data
		// console.log(data)
		this.setState({kpzdbs})
	}
	click=()=>{
		let {kpzdbs} = this.state
		this.setState({loading:true})
		invoiceChoose({kpzdbs}).then(res=>{
			this.setState({loading:false})
			if(res.code==="0000"){
				let {resultObj} = res
				window.open(resultObj,"_blank")
			}else{
				message.error(res.msg)
			}
		})
	}
	render() {
		
		return (
			<Spin spinning={this.state.loading} size="large" >
				<div>
					<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"认证勾选"}</h2>
				</div>
				<Divider />
				<div style={{ padding: "10px 0",display:'flex',justifyContent:"start",alignItems:"center" }}>
					<ControlPanel getInfo={this.getInfo} />
					<Button type="primary" onClick={this.click} >查询</Button>
				</div>
			</Spin>
		)
	}
}
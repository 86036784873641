import React, { Component } from "react";
import { Button, Input, Collapse, message, Spin } from "antd";
import "./index.less";
import sha1 from "../../../utils/sha1";
import axios from "axios";
import { commitInvoiceAudit, gsDataFind } from "../../../api";

var wx = window.wx;

const { Panel } = Collapse;

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			block: false,
			openCk: false,
			yhsj: {
				ghdwmc: "", //购方名称
				ghdwsbh: "", // 购方税号
				jshj: "", //金额
				ghdwdzdh: "", // 地址 + 电话
				ghdwyhzh: "", // 开户行名称    +账号
				gfkhyx: "", // 邮箱
				gfkhdh: "", //手机号
				shopScanId: "", //门店id
			},
			adds: "", //地址
			phone: "", //电话
			khName: "", //开户行名
			khNumber: "", //开户行号
			lodding: false,
			search: [],
			showBlock: false,
			shopScanId:"",
		};
		this.environmental = "";
		this.emailList = [];
	}

	componentDidMount() {
		let shopScanId = this.GetQueryString("id")
		this.setState({
			yhsj: {
				shopScanId,
			},
			shopScanId,
		});

		//检测环境
		var userAgent = navigator.userAgent.toLowerCase();

		if (userAgent.match(/alipay/i) == 'alipay') {
			this.environmental = "alipay";
		} else if (userAgent.indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined') {
			this.environmental = "micromessenger";
			this.isWX()
		}
	}

	inputOnBlur=()=>{
		setTimeout(()=>{
			this.setState({
				showBlock:false
			});
		},100)
	}

	inputFocus=(val)=>{
		if(!val)return
		this.searchInvocie(val)
	}

	searchInvocie(name) {
		gsDataFind({ searchName: name, token: "WxDev",}).then((res) => {
		// axios.post(process.env.REACT_APP_BASEURL+"/registNoSearch?searchName="+name+'&token=WxDev').then((res) => {
		// 	res = res.data
			this.setState({
				search: [],
			}, () => {
				try{
					if(Array.isArray(res.object)&&res.object.length > 0) {
						this.setState({
							search: [...res.object],showBlock:true
						});
					}
				}catch{}
			});
		});
	}
	//选择购方名称
	selectOptions(ghdwsbh, ghdwmc) {
		let obj = {ghdwsbh, ghdwmc}

		this.setState({
			showBlock: false,yhsj:{...this.state.yhsj,...obj}
		});
	}

	// 截取地址中参数 
	GetQueryString(kValue) {
		//当前页面的地址
		const url = window.location.href;
		var reg = /([^?&=]+)=([^?&=]+)/g,
			obj = {},
			str = url;
		url.replace(reg, function () {
			obj[arguments[1]] = arguments[2];
		});
		for (var keyName in obj) {
			if (keyName == kValue) {
				return obj[keyName];
			}
		}
	}

	//get 输入数据
	getOnChange = (key, e) => {

		let val = e.target.value.trim() //去除两边的空格

		if(key==='ghdwsbh'){
			val = val.toUpperCase()
		}

		if (key == 'adds' || key == 'phone') {
			this.state[key] = val
			if (key == 'adds') {
				this.state.yhsj.ghdwdzdh = val + " " + this.state.phone
			} else {
				this.state.yhsj.ghdwdzdh = this.state.adds + " " + val
			}

		} else if (key == 'khName' || key == 'khNumber') {
			this.state[key] = val
			if (key == 'khName') {
				this.state.yhsj.ghdwyhzh = val + " " + this.state.khNumber
			} else {
				this.state.yhsj.ghdwyhzh = this.state.khName + " " + val
			}
		} else {
			this.state.yhsj[key] = val
		}

		if (key === "ghdwmc") {
			this.state.yhsj.ghdwsbh=""
			this.searchInvocie(val);
		}

		this.setState({
			yhsj: {
				...this.state.yhsj,
			},
		});
	}

	//清空数据
	deleForm=()=>{
		let yhsj = {...this.state.yhsj};
		for(let key in yhsj){
			yhsj[key]=""
		}
		this.setState({yhsj})
	}

	//提交数据开票
	submitAll = () => {
		let param = this.state.yhsj;
		param.shopScanId=this.state.shopScanId

		if (!param['ghdwmc']) {
			message.error("发票抬头名称,不能为空");
			return;
		} else if (!param['ghdwsbh'] && !this.state.block) {
			message.error("购方税号,不能为空");
			return;
		} else if (!param['jshj']) {
			message.error("金额,不能为空");
			return;
		} else if (!param['gfkhyx']) {
			message.error("邮箱号,不能为空");
			return;
		} else if (!param["gfkhdh"]) {
			message.error("手机号,不能为空");
			return;
		}

		let reg1 = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
		let reg2 = /^1[3456789]\d{9}$/;

		if (!reg2.test(param.gfkhdh)) {
			message.error("手机号格式错误！");
			return;
		} else if (!reg1.test(param.gfkhyx)) {
			message.error("邮箱号格式错误！");
			return;
		}

		for (let item in param) {
			if (param[item] && typeof param[item]=="string") {
				param[item] = param[item].trim()
				param[item] = (param[item]).replace(/null|undefinde/g,"")
			}
		}

		this.setState({ lodding: true});
		commitInvoiceAudit(param).then((res) => {
			if (res.code === "0000") {
				message.success(res.msg);
				localStorage.setItem("step_pdfUrl","")
				localStorage.setItem("step_id","")
				this.props.history.push({ pathname: '/takeInvoice/success' });
			} else {
				if((res.msg).includes("税控设备无返回信息")){
					message.error("此盘无发票，请联系商家！");
				}else{
					message.error(res.msg);
				}
			}
			this.setState({
				lodding: false,
			});
		});
	};

	selectInvoice = () => {
		this.getWx()
	};
	//检测维修
	isWX() {
		let param = { openId: "123" };
		axios
			.get(process.env.REACT_APP_BASEURL_WX+"/wxDev/getTicket", { params: param })
			.then((res) => {
				if (res.data.code == 0) {
					var time = Date.parse(new Date()),
						rstring = this.random_string(16),
						url = window.location.href.split("#")[0],
						ticket = res.data.map.ticket,
						str = "jsapi_ticket=" + ticket + "&noncestr=" + rstring + "&timestamp=" + time + "&url=" + url,
						str1 = sha1(str);
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: "wx20db3f2d2972db19", // 必填，公众号的唯一标识
						timestamp: time,
						beta: true,
						nonceStr: rstring,
						signature: str1,
						jsApiList: [
							// 'scanQRCode',
							"chooseInvoice",
							"chooseInvoiceTitle",
						],
					});
					wx.error(function (res) {
						axios
							.post(
								process.env.REACT_APP_BASEURL_WX+"/wxDev/reFreshTicket?openId=14555"
							)
							.then((res) => {
								if (res.data.code == "0") {
								} else {
									//window.location.reload();
								}
							})
							.catch((err) => {
								console.log("失败");
							});
					});
				}
			});
	}
	// 拼接
	random_string(len) {
		var len = len || "4";
		var s = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		if (len < 1) {
			alert("请设置一个大于0的数字为该字符串的长度！");
		} else {
			var str = "",
				p = "";
			for (var i = 0; i < len; i++) {
				p = parseInt((Math.random() * 100) % 62);
				str = str + s[p];
			}
			return str;
		}
	}


	//微信抬头
	getWx() {
		var that = this.state;
		var myThis = this
		wx.invoke(
			"chooseInvoiceTitle",
			{
				scene: "1",
			},
			function (res) {
				if (res.err_msg === "chooseInvoiceTitle:ok" || res.err_msg === "ok") {
					res = JSON.parse(res.choose_invoice_title_info);
					let block = ''

					if (res.companyAddress || res.taxNumber || res.telephone !== "" || res.bankName !== "" || res.bankAccount !== "") {
						block = false;
						that.yhsj.ghdwmc = res.title?res.title:""
						that.yhsj.ghdwsbh = res.taxNumber?res.taxNumber:""
						that.adds = res.companyAddress?res.companyAddress:""
						that.phone = res.telephone?res.telephone:""
						that.khName = res.bankName?res.bankName:""
						that.khNumber = res.bankAccount?res.bankAccount:""
						myThis.setState({ openCk: true })

					} else {
						block = true;
						that.yhsj.ghdwmc = res.title?res.title:""
						that.yhsj.ghdwsbh = ""
						that.adds = ''
						that.phone = ''
						that.khName = ''
						that.khNumber = ''
					}
					myThis.setState({ block })
				}
			}
		);


	}

	render() {
		return (
			<Spin size="large" spinning={this.state.lodding} >
				<div className="billing_content">
					<div className="selectBlock">
						<Button
							className={this.state.block ? "active" : "noActive"}
							onClick={() => {
								this.deleForm()
								this.setState({ block: true, openCk: false });
							}}
							style={{ marginRight: "5px" }}
						>
							个人
					</Button>
						<Button
							className={this.state.block ? "noActive" : "active"}
							onClick={() => {
								this.deleForm()
								this.setState({ block: false, openCk: true });
							}}
						>
							企业
					</Button>
					</div>
					<div className="contentsInput">
						<div>
							<label htmlFor="ghdwmc">
								<span>*</span>购方名称
							</label>
							<Input autoComplete="off" id="ghdwmc" placeholder="请输入发票抬头名称" onBlur={ this.inputOnBlur } onFocus={(e)=>this.inputFocus(e.target.value)} onChange={this.getOnChange.bind(this, 'ghdwmc')} value={this.state.yhsj.ghdwmc}  />
							<ul className="search" style={{ display: (this.state.showBlock && this.state.search.length > 0) ? "block" : "none" }} >
								{this.state.search.map((item, index) => {
									return (
										<li key={index} onClick={(e) => { this.selectOptions(item.nsrsbh, item.nsrmc); }}
										> {item.nsrmc}
										</li>
									);
								})}
							</ul>
							<Button className="dingwei" onClick={this.selectInvoice}>
								选取抬头
						</Button>
						</div>
						<div >
						{/* <div style={{display:this.state.block?'none':'block'}}> */}
							<label htmlFor="ghdwsbh">
								{this.state.block ? (
									<span>&nbsp;</span>
								) : (
										<span>*</span>
									)}
								购方税号
						</label>
							<Input autoComplete="off"
								id="ghdwsbh"
								placeholder="请输入购方税号"
								onChange={this.getOnChange.bind(this, 'ghdwsbh')}
								value={this.state.yhsj.ghdwsbh}
							/>
						</div>
						<div>
							<label htmlFor="jshj">
								<span>*</span>发票金额
						</label>
							<Input autoComplete="off"
								id="jshj"
								placeholder="请输入发票金额"
								onChange={this.getOnChange.bind(this, 'jshj')}
								value={this.state.yhsj.jshj}
							/>
						</div>
						<div style={{ border: 0, paddingBottom: 0 }}>
						{/* <div style={{ border: 0, paddingBottom: 0,display:this.state.block?'none':'block' }}> */}
							<Collapse
								className="collapse"
								bordered={false}
								defaultActiveKey=""
								expandIconPosition="right"
								activeKey={this.state.openCk ? "1" : 0}
								onChange={() => {
									let open = this.state.openCk;
									this.setState({
										openCk: !open,
									});
								}}
							>
								{/* <Panel key="1" header='&nbsp;&nbsp;&nbsp;备注'> */}
								<Panel key="1">
									<div style={{ paddingLeft: 3 }} className="billing_bz">
										<div>
											<label>备注</label>
										</div>
										<div>
											<label htmlFor="adds" className="myLabel">经营地址</label>
											<Input autoComplete="off"
												id="adds"
												placeholder="请输入经营地址"
												onChange={this.getOnChange.bind(this, 'adds')}
												value={this.state.adds}
											/>
										</div>
										<div>
											<label htmlFor="phone" className="myLabel">公司电话</label>
											<Input autoComplete="off"
												id="phone"
												placeholder="请输入公司电话"
												onChange={this.getOnChange.bind(this, 'phone')}
												value={this.state.phone}
											/>
										</div>
										<div>
											<label htmlFor="khName" className="myLabel">
												开户行名称
										</label>
											<Input autoComplete="off"
												id="khName"
												placeholder="请输入开户行名称"
												onChange={this.getOnChange.bind(this, 'khName')}
												value={this.state.khName}
											/>
										</div>
										<div>
											<label htmlFor="khNumber" className="myLabel">
												开户行账号
										</label>
											<Input autoComplete="off"
												id="khNumber"
												placeholder="请输入开户行账号"
												onChange={this.getOnChange.bind(this, 'khNumber')}
												value={this.state.khNumber}
											/>
										</div>
									</div>
								</Panel>
							</Collapse>
						</div>
						<div>
							<label htmlFor="gfkhyx">
								<span>*</span>收票邮箱
						</label>
							<Input autoComplete="off"
								id="gfkhyx"
								placeholder="请输入收票邮箱"
								onChange={this.getOnChange.bind(this, 'gfkhyx')}
								value={this.state.yhsj.gfkhyx}
							/>
						</div>
						<div style={{ border: "0" }}>
							<label htmlFor="gfkhdh">
								<span>*</span>收票手机
						</label>
							<Input autoComplete="off"
								id="gfkhdh"
								placeholder="请输入收票手机"
								onChange={this.getOnChange.bind(this, 'gfkhdh')}
								value={this.state.yhsj.gfkhdh}
							/>
						</div>
					</div>
					<div className="submitButton">
						<Button onClick={this.submitAll}>提交申请</Button>
					</div>
				</div>
			</Spin>
		);
	}
}

import React, { Component } from "react";
import Footer2 from "../../../components/webfooter2/webFooter2";

import "./electronic.less";

import imgBanner from "../../../assets/img/electronic/banner.png";
import bgImg2 from "../../../assets/img/electronic/bg2.png";
import icon1 from "../../../assets/img/electronic/icon1.png";
import icon2 from "../../../assets/img/electronic/icon2.png";
import icon3 from "../../../assets/img/electronic/icon3.png";
import icon4 from "../../../assets/img/electronic/icon4.png";
import icon5 from "../../../assets/img/electronic/icon5.png";
import icon6 from "../../../assets/img/electronic/icon6.png";
import icon7 from "../../../assets/img/electronic/icon7.png";
import icon8 from "../../../assets/img/electronic/icon8.png";
import icon9 from "../../../assets/img/electronic/icon9.png";
import icon11 from "../../../assets/img/electronic/icon11.png";
import icon12 from "../../../assets/img/electronic/icon12.png";
import icon13 from "../../../assets/img/electronic/icon13.png";

import icon21 from "../../../assets/img/electronic/icon21.png";
import icon22 from "../../../assets/img/electronic/icon22.png";
import icon23 from "../../../assets/img/electronic/icon23.png";

import icon31 from "../../../assets/img/electronic/icon31.png";
import icon32 from "../../../assets/img/electronic/icon32.png";
import icon33 from "../../../assets/img/electronic/icon33.png";

import i1 from "../../../assets/img/electronic/1.png";
import i2 from "../../../assets/img/electronic/2.png";
import i3 from "../../../assets/img/electronic/3.png";
import i4 from "../../../assets/img/electronic/4.png";
import i5 from "../../../assets/img/electronic/5.png";

import ls1 from "../../../assets/img/electronic/ls1.png";
import ls2 from "../../../assets/img/electronic/ls2.png";
import ls3 from "../../../assets/img/electronic/ls3.png";

import bz1 from "../../../assets/img/electronic/bz1.png";
import bz2 from "../../../assets/img/electronic/bz2.png";
import bz3 from "../../../assets/img/electronic/bz3.png";
import bz4 from "../../../assets/img/electronic/bz4.png";
import bz5 from "../../../assets/img/electronic/bz5.png";
import bz6 from "../../../assets/img/electronic/bz6.png";
import bz7 from "../../../assets/img/electronic/bz7.png";
import bz8 from "../../../assets/img/electronic/bz8.png";
import bz33 from "../../../assets/img/electronic/bz33.png";

import sy1 from "../../../assets/img/electronic/sy1.png";
import sy2 from "../../../assets/img/electronic/sy2.png";

import gd1 from "../../../assets/img/electronic/gd1.png";
import gd2 from "../../../assets/img/electronic/gd2.png";
import gd3 from "../../../assets/img/electronic/gd3.png";

import kp1 from "../../../assets/img/electronic/kp1.png";
import kp2 from "../../../assets/img/electronic/kp2.png";
import kp3 from "../../../assets/img/electronic/kp3.png";

export default class Electronic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer: {
				title: "助力企业开启发票新体验",
				phone: "",
			},
			switchOpen: [true, false, false],
			switchLiOpen: [true, false, false, false],
			submitData: {},
		};
	}
	componentDidMount(){
		// 找到锚点
		let anchorElement = document.body;
		// 如果对应id的锚点存在，就跳转到锚点
		if (anchorElement) {
			window.scrollTo(0, anchorElement.offsetTop);
		}
	}
	//切换
	click = (val) => {
		let list = this.state.switchOpen;
		for (let i in list) {
			list[i] = false;
		}
		list[val] = true;
		this.setState({
			switchOpen: [...list],
		});
	};
	liClick = (val) => {
		let list = this.state.switchLiOpen;
		for (let i in list) {
			list[i] = false;
		}
		list[val] = true;
		this.setState({
			switchLiOpen: [...list],
		});
	};

	inputs = (key, e) => {
		let val = e.target.value;
		let obj = {};
		obj[key] = val;
		this.setState({
			submitData: { ...this.state.submitData, ...obj },
		});
	};

	render() {
		const bg2 = {
			width: "290px",
			height: "258px",
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bgImg2 + ")", //图片的路径
		};

		return (
			<div className="electronic-content">
				<section className="electronic-1">
					<img alt="" src={imgBanner} />
				</section>
				<section className="electronic-2">
					{/* 导航 */}
					<ul className="electronic-box">
						<li
							className={
								this.state.switchOpen[0] ? "active" : "noActive"
							}
							onClick={this.click.bind(this, 0)}
						>
							产品功能
						</li>
						<li
							className={
								this.state.switchOpen[1] ? "active" : "noActive"
							}
							onClick={this.click.bind(this, 1)}
						>
							平台服务
						</li>
						<li
							className={
								this.state.switchOpen[2] ? "active" : "noActive"
							}
							onClick={this.click.bind(this, 2)}
						>
							开票场景
						</li>
					</ul>

					{/* 选项1 */}
					<div
						className="switchOpen-0"
						style={{
							display: this.state.switchOpen[0]
								? "block"
								: "none",
						}}
					>
						<div>
							<h3>票慧云电子发票一体化平台</h3>
							<div>
							增值税发票管理平台是中税创新旗下电子发票管理平台。为满足不同行业电子发票管理需求，致力于为企业用户提供多种开票方式，全面兼容多种税控终端，包括金税盘、税控盘、UKEY、新型税控服务器等，为企业提供全方位的服务平台。
							</div>
						</div>
						{/* 第二个div */}
						{/* <div>
							<h3>
								<img alt="" src={icon1} />
								<span>发票查验</span>
							</h3>
							<div>
								<div>
									<ul className="form-ul">
										<li>
											<label htmlFor="dm">
												发票代码：
											</label>
											<input
												id="dm"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm1">
												发票号码：
											</label>
											<input
												id="dm1"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm2">
												验证码：
											</label>
											<input
												id="dm2"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm3">
												开票日期：
											</label>
											<input
												id="dm3"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<label htmlFor="dm4">
												开票金额：
											</label>
											<input
												id="dm4"
												onChange={this.inputs.bind(
													this,
													"key"
												)}
												type="text"
											/>
										</li>
										<li>
											<span>查询</span>
										</li>
									</ul>
								</div>

								<div style={bg2}>
									<span>
										电子发票可追溯来源，真假，报销信息，实现发票信息全程流通。
									</span>
								</div>
							</div>
						</div> */}

						<ul>
							<li>
								<div>
									<img alt="" src={icon2} />
									<span>智能赋码</span>
								</div>
								<div>
									自动识别发票录入抬头信息或者根据商品名称，自动匹配商品编码及商品的关键信息。
								</div>
							</li>
							<li>
								<div>
									<img alt="" src={icon3} />
									<span>发票批量开具</span>
								</div>
								<div>
									平台支持企业用户批量开具电子发票，满足用户批量开具需求。
								</div>
							</li>
							<li>
								<div>
									<img alt="" src={icon13} />
									<span>发票查询</span>
								</div>
								<div>
									电子发票可追溯来源，真假，报销信息，实现发票信息全程流通。
								</div>
							</li>
							<li>
								<div>
									<img alt="" src={icon4} />
									<span>发票冲红</span>
								</div>
								<div>
									平台在线操作，更快更便捷的实现发票作废冲红。
								</div>
							</li>
							<li>
								<div>
									<img alt="" src={icon5} />
									<span>开票统计</span>
								</div>
								<div>
									随时随地明确发票开具统计和计算，真实可信。
								</div>
							</li>
							
							<li>
								<div>
									<img alt="" src={icon6} />
									<span>接收方式</span>
								</div>
								<div>短信、邮箱、微信卡包、公众号。</div>
							</li>
						</ul>
					</div>

					{/* 选项2 */}
					<div
						className="switchOpen-1"
						style={{
							display: this.state.switchOpen[1]
								? "block"
								: "none",
						}}
					>
						{/* 1 */}
						<div>
							<div>租赁服务器+票慧云平台</div>
							<div>适用于中小企业，低成本，托管省心</div>
							<div className="left-right">
								<div>
									<ul className="left-box">
										<li>优势</li>
										<li>
											<span></span>
											用户申请方便快捷，一步到位
										</li>
										<li>
											<span></span>
											无需维护设备，机房云端数据存储，全网覆盖
										</li>
										<li>
											<span></span>
											多种开票方式（WEB端/公众号、扫码开票等）
										</li>
										<li>
											<span></span>
											多种收票方式（网站下载/短信通知/邮件接收等）
										</li>
									</ul>
								</div>
								<div>
									<ul className="right-box">
										<li>
											<span></span>
											<span>流程</span>
										</li>
										<li>
											<span>注册账号，签署合作协议</span>
											<span>
												在平台上注册账号并签署合作协议/合同
											</span>
											<img alt="" src={i1} />
										</li>
										<li>
											<span>申请电子发票</span>
											<span>
												向企业所在地的税务机关提交必要的申请资料，包括：营业执照、税务登记证、经办人身份证、票种核定单、发票领用簿等
											</span>
											<img alt="" src={i2} />
										</li>
										<li>
											<span>
												部署服务器，调试开票接口
											</span>
											<span>
												部署服务器，调试电子发票开票接口，实现电子发票开具
											</span>
											<img alt="" src={i3} />
										</li>
										<li>
											<span>成功接入</span>
											<img alt="" src={i4} />
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* 2 */}
						<div>
							<div>购买服务器+票慧云平台</div>
							<div>适用能自管服务器的大中型企业</div>
							<div className="left-right">
								<div>
									<ul className="left-box">
										<li>优势</li>
										<li>
											<span></span>
											企业功能定制（根据企业需求和特点开发定制功能）
										</li>
										<li>
											<span></span>
											开票并发量高（系统通过软硬件层面的负载均衡机制，为客户提供最大支持）
										</li>
										<li>
											<span></span>
											多种开票方式（WEB端/公众号、扫码开票等）
										</li>
										<li>
											<span></span>
											多种收票方式（网站下载/短信通知/邮件接收等）
										</li>
									</ul>
								</div>
								<div>
									<ul className="right-box">
										<li>
											<span></span>
											<span>流程</span>
										</li>
										<li>
											<span>注册账号，签署合作协议</span>
											<span>
												在平台上注册账号并签署合作协议/合同
											</span>
											<img alt="" src={i1} />
										</li>
										<li>
											<span>申请电子发票</span>
											<span>
												向企业所在地的税务机关提交必要的申请资料，包括：营业执照、税务登记证、经办人身份证、票种核定单、发票领用簿等
											</span>
											<img alt="" src={i2} />
										</li>
										<li>
											<span>
												部署服务器，调试开票接口
											</span>
											<span>
												部署服务器，调试电子发票开票接口，实现电子发票开具
											</span>
											<img alt="" src={i3} />
										</li>
										<li>
											<span>成功接入</span>
											<img alt="" src={i4} />
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* 3 */}
						<div>
							<div>购买服务器+自建平台</div>
							<div>适用有自建平台、有开发能力的大型企业</div>
							<div className="left-right">
								<div>
									<ul className="left-box">
										<li>优势</li>
										<li>
											<span></span>
											企业功能定制（可根据企业需求，企业业务特点，开发定制功能）
										</li>
										<li>
											<span></span>
											企业发票数据自行存储管理（自主存储，管理数据）
										</li>
										<li>
											<span></span>
											多种开票方式（WEB端/公众号、扫码开票等）
										</li>
										<li>
											<span></span>
											多种收票方式（网站下载/短信通知/邮件接收等）
										</li>
									</ul>
								</div>
								<div>
									<ul className="right-box">
										<li>
											<span></span>
											<span>流程</span>
										</li>
										<li>
											<span>注册账号，签署合作协议</span>
											<span>
												在平台上注册账号并签署合作协议/合同
											</span>
											<img alt="" src={i1} />
										</li>
										<li>
											<span>申请电子发票</span>
											<span>
												向企业所在地的税务机关提交必要的申请资料，包括：营业执照、税务登记证、经办人身份证、票种核定单、发票领用簿等
											</span>
											<img alt="" src={i2} />
										</li>
										<li>
											<span>
												部署服务器，调试开票接口
											</span>
											<span>
												部署服务器，调试电子发票开票接口，实现电子发票开具
											</span>
											<img alt="" src={i3} />
										</li>
										<li>
											<span>成功接入</span>
											<img alt="" src={i4} />
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					{/* 选项3 */}
					<div
						className="switchOpen-2"
						style={{
							display: this.state.switchOpen[2]
								? "block"
								: "none",
						}}
					>
						{/* 导航 */}
						<ul className="slide-switch">
							<li className="">场景展示</li>
							<li  
								className={
									this.state.switchLiOpen[0] ? "liActive" : ""
								}
								onClick={this.liClick.bind(this, 0)}
							>
								扫码开票
							</li>
							<li
								className={
									this.state.switchLiOpen[1] ? "liActive" : ""
								}
								onClick={this.liClick.bind(this, 1)}
							>
								系统对接开票
							</li>
							<li
								className={
									this.state.switchLiOpen[2] ? "liActive" : ""
								}
								onClick={this.liClick.bind(this, 2)}
							>
								固定扫码开票
							</li>
							<li
								className={
									this.state.switchLiOpen[3] ? "liActive" : ""
								}
								onClick={this.liClick.bind(this, 3)}
							>
								网页开票
							</li>
						</ul>
						{/* 1 */}
						<div
							className="slide-switch-1"
							style={{
								display: this.state.switchLiOpen[0]
									? "block"
									: "none",
							}}
						>
							<div>
								<h3>场景描述</h3>
								<div>
									消费者可扫一扫小票上二维码，成功提交信息即可秒开电子发票；该电子发票可插入微信卡包/票慧公众号，也可发送到邮箱，手机短信中。
								</div>
							</div>
							<div>
								<h3>适用行业</h3>
								<div>
									商超连锁、餐饮连锁、酒店、交通出行等线下下单（支付）的企业。
								</div>
								<div>
									<img alt="" src={ls1} />
									<img alt="" src={ls2} />
									<img alt="" src={ls3} />
								</div>
							</div>
							<div>
								<h3>流程设计</h3>
								<div>
									<div>
										<img alt="" src={bz1} />
										<div>商户结算并打印小票</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz2} />
										<div>用户扫描小票二维码</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz3} />
										<div>提交申请开票</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz4} />
										<div>开票成功</div>
									</div>
								</div>
							</div>
							<div>
								<h3>场景优势</h3>
								<ul>
									<li>
										<div>
											<img alt="" src={icon7} />
											<span>全自动开票</span>
										</div>
										<div>
											自助扫码开票无需工作人员手工开票，减轻工作人员负担。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon8} />
											<span>开票零等待</span>
										</div>
										<div>
											提升抬头输入的准确性，不易出错，提高发票开具的效率。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon9} />
											<span>提升满意度</span>
										</div>
										<div>
											电子发票可一扫开具，无需排队等待，不丢失、不破损，提高客户满意度。
										</div>
									</li>
								</ul>
							</div>
						</div>
						{/* 2 */}
						<div
							className="slide-switch-1 slide-switch-2"
							style={{
								display: this.state.switchLiOpen[1]
									? "block"
									: "none",
							}}
						>
							<div>
								<h3>场景描述</h3>
								<div>
									票慧云构建开放平台，提供发票相关的API接口与各企业的业务系统进行对接，帮助企业完成发票相关的业务。
								</div>
							</div>

							<div>
								<h3>适用行业</h3>
								<div>
									已有ERP系统、订单管理系统的电商等其他平台企业。
								</div>
								<div>
									<img alt="" src={sy1} />
									<img alt="" src={sy2} />
								</div>
							</div>

							<div>
								<h3>流程设计</h3>
								<div>
									<div>
										<img alt="" src={bz5} />
										<div>ERP\订单系统接口</div>
									</div>

									<img alt="" src={i5} />

									<div>
										<img alt="" src={bz6} />
										<div>调用APU等</div>
									</div>

									<img alt="" src={i5} />

									<div>
										<img alt="" src={bz7} />
										<div>集成开票</div>
									</div>
								</div>
							</div>
							<div>
								<h3>场景优势</h3>
								<ul>
									<li>
										<div>
											<img alt="" src={icon11} />
											<span>接入方便</span>
										</div>
										<div>
											提供多种类型的测试账号与数据，极大提高了开发者接入的效率与开发质量。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon12} />
											<span>多场景服务</span>
										</div>
										<div>
											多样化选择，满足税控服务器、税控盘、移动开票等多设备开票与服务。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon13} />
											<span>抬头模糊查询</span>
										</div>
										<div>
											支持公司抬头模糊查询，实时数据更新，准确性高达98%。
										</div>
									</li>
								</ul>
							</div>
						</div>
						{/* 3 */}
						<div
							className="slide-switch-1 slide-switch-3"
							style={{
								display: this.state.switchLiOpen[2]
									? "block"
									: "none",
							}}
						>
							<div>
								<h3>场景描述</h3>
								<div>
									商户登录发票通平台生成开票二维码，消费者扫码提交开票信息，商户确认并补全开票项目、金额等信息，开具电子发票并推送给消费者。
								</div>
							</div>

							<div>
								<h3>解决问题</h3>
								<div>
									<img alt="" src={gd1} />
									<img alt="" src={gd2} />
									<img alt="" src={gd3} />
								</div>
							</div>

							<div>
								<h3>流程设计</h3>
								<div>
									<div>
										<img alt="" src={bz8} />
										<div>商户结算并打印小票</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz2} />
										<div>消费者进行扫码</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz3} />
										<div>开票信息显示</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz4} />
										<div>开票成功</div>
									</div>
								</div>
							</div>

							<div>
								<h3>场景优势</h3>
								<ul>
									<li>
										<div>
											<img alt="" src={icon21} />
											<span>开票零等待</span>
										</div>
										<div>
											消费者完成扫固定二维码即可离开，随时随地开发票。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon22} />
											<span>开票零成本</span>
										</div>
										<div>无需添加硬件成本、人工成本。</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon23} />
											<span>提高工作效率</span>
										</div>
										<div>
											自助扫码开票无需工作人员手工开票，减轻工作人员负担。
										</div>
									</li>
								</ul>
							</div>
						</div>
						{/* 4 */}
						<div
							className="slide-switch-1 slide-switch-4"
							style={{
								display: this.state.switchLiOpen[3]
									? "block"
									: "none",
							}}
						>
							{/* 1 */}
							<div>
								<h3>场景描述</h3>
								<div>
									商户登录票慧云官网企业版输入开票信息，或消费者扫码提交抬头，商户补全开票信息，开票并推送。
								</div>
							</div>
							{/* 2 */}
							<div>
								<h3>解决问题</h3>
								<div>
									<img alt="" src={kp1} />
									<img alt="" src={kp2} />
									<img alt="" src={kp3} />
								</div>
							</div>
							{/* 3 */}
							<div>
								<h3>流程设计</h3>
								<div>
									<div>
										<img alt="" src={bz33} />
										<div>企业人员进入网页开票页面</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz3} />
										<div>
											录入发票信息确认无误后开出发票
										</div>
									</div>
									<img alt="" src={i5} />
									<div>
										<img alt="" src={bz4} />
										<div>开票成功</div>
									</div>
								</div>
							</div>
							{/* 4 */}
							<div>
								<h3>场景优势</h3>
								<ul>
									<li>
										<div>
											<img alt="" src={icon31} />
											<span>更省</span>
										</div>
										<div>
											电票自动生成，节约人员和纸票成本。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon32} />
											<span>新体验</span>
										</div>
										<div>
											非连锁企业也能使用，享有与更高效同样的开票体验。
										</div>
									</li>
									<li>
										<div>
											<img alt="" src={icon33} />
											<span>新形象</span>
										</div>
										<div>
											支持公司抬头模糊查询，实时数据更新，准确性高达98%。
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

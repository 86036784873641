import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import BillingStep from './billing'
export default class  KaiPiao extends Component {
    componentDidMount() {
        //this.props.history.push({ pathname: '/ KaiPiao/step1', state: {  KaiPiaoNumber: '222' } })
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/Billing' component={ BillingStep} />
                    <Redirect to='/Billing' />
                </Switch>

            </div>
        )
    }
}

import React, { Component } from "react"
import { Button,Divider,Table, Modal, Form, Input, Row, Col } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
const FormItem = Form.Item;
const FormList = Form.List;

const addData=[
	{
		type: 'SELECT',
		label: '盘组设备名称',
		field: 'userName',
		placeholder: '请选择盘组设备名称',
		width: "100%",
		initialValue:'0',
		list: [
            {id: '0', name: '集中托管开票员'},
            {id: '1', name: '企业开票员'}
        ]
	},
	{
		type: 'INPUT',
		label: '税控盘编号',
		field: 'roleUuid',
		width: "100%",
		initialValue:'',
		placeholder: '请输入税控盘编号',
	},
	{
		type: 'INPUT',
		label: '纳税人识别号',
		field: 'userPhone',
		placeholder: '请输入纳税人识别号',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '纳税人名称',
		field: 'idCard',
		placeholder: '请输入纳税人名称',
		width: "100%",
		initialValue:'',
	}
]

export default class SystemManager extends Component {
	constructor(props) {
		super(props);
		this.state={
            tableList: [],
            tableList2: [],
            //修改回显数据
			updateData:[],
			isRoleVisible: false,
            flag:false,
            bankList: [{}],
            collectionList: [{}],
            reviewList: [{}]
		}
    }
    add=()=>{
		this.state.updateData.forEach(res=>{
			res.initialValue=''
		})
        this.setState({
			isRoleVisible:true,
			flag:false
		})
	}
    handleRoleSubmit=()=>{}
    addUserDataBack=(e)=>{
		// if (Object.keys(e).length !== 0){
		//     for(let key in e){
		// 		this.state.addData[key]=e[key]
		// 	}
		// }else{
		//     return false
		// } 
    }
    addOrDelList=(name, type, index)=> {
        let arr = this.state[name]
        if(type == 'add') {
            arr.push({})
            this.setState({[name]:  arr})
        } else {
            arr.splice(index, 1)
            this.setState({[name]:  arr})
        }  
    }
	render() {
        const columns = [
            {
                title: '序号',
              //   dataIndex: '',
              //   key: '',
                render: (text, record, index) => (
                  <span>
                      {index+1}
                  </span>
                ),
            },
            {
			    title: '纳税人识别号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '税控盘编号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '开户行及账号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '销方地址电话',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '操作',
			    dataIndex: 'userName',
			    key: 'userName',
			},
        ];
		const formList = [
            {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'fileName',
				placeholder: '请输入纳税人识别号',
				width: "100%",
            },
            {
				type: 'INPUT',
				label: '地址电话',
				field: 'fileName',
				placeholder: '请输入地址电话',
				width: "100%",
            },
            {
				type: 'INPUT',
				label: '开户行及账号',
				field: 'fileName',
				placeholder: '请输入开户行及账号',
				width: "100%",
            },
        ]
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 10 },
        };
		return (
			<div>
				<div>
					<h2>{sessionStorage.getItem("pageTitle")||"开票单位信息"}</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0", display: 'flex', alignItems: 'center'}}>
                    <BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
				<div style={{ background: '#C7E2FF'}}>
                    <Button type="link" onClick={()=>{this.add()}} style={{color: '#333333'}}><img src={require('../../../assets/icon/add.png')} style={{marginRight: '10px'}}/>添加</Button>
                    <Button type="link" style={{color: '#333333',margin:"0 20px"}}><img src={require('../../../assets/icon/import.png')} style={{marginRight: '10px'}}/>批量导入</Button>
                    <Button type="link" style={{color: '#333333'}}><img src={require('../../../assets/icon/import2.png')} style={{marginRight: '10px'}}/>模版导入</Button>
                </div>

				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>
                <Modal
                    width={820}
                    title="开票单位信息维护"
                    visible={this.state.isRoleVisible}
                    onOk={this.handleRoleSubmit}
                    onCancel={()=>{
                        this.setState({
                            isRoleVisible:false
                        })
                    }}
                >
                    <div style={{background: '#C7E2FF'}}>
                        <Form {...layout}>
                            <FormItem label='纳税人姓名'>
                                <Input type="text"  placeholder='请输入纳税人姓名' />
                            </FormItem>
                            <FormItem label='纳税人识别号'>
                                <Input type="text"  placeholder='请输入纳税人识别号' />
                            </FormItem>
                            <FormItem label='税控盘编号'>
                                <Input type="text"  placeholder='请输入税控盘编号' />
                            </FormItem>
                            <FormItem label='销方地址'>
                                <Input type="text"  placeholder='请输入销方地址' />
                            </FormItem>
                            <FormItem label='销方电话'>
                                <Input type="text"  placeholder='请输入销方电话' />
                            </FormItem>
                            <FormItem label='开户行及账号' {...{labelCol: { span: 4 },wrapperCol: { span: 20 },}}>
                                {
                                    this.state.bankList.map((item, index) => {
                                        return (
                                            <Row gutter={20} justify="start">
                                                <Col span={10}>
                                                    <Input type="text"  placeholder='开户行名称' />
                                                </Col>
                                                <Col span={10}>
                                                    <Input type="text"  placeholder='银行账号' />
            
                                                </Col>
                                                {
                                                    !index ? (
                                                        <img src={require('../../../assets/icon/sum.png')} alt="" onClick={() => this.addOrDelList('bankList', 'add')}/>
                                                    ): 
                                                    (
                                                        <img src={require('../../../assets/icon/sub.png')} alt="" onClick={() =>  this.addOrDelList('bankList', 'del', index)}/>
                                                    )
                                                }  
                                            </Row> 
                                            )
                                    })
                                }
                            </FormItem>
                            <FormItem label='收款人' {...{labelCol: { span: 4 },wrapperCol: { span: 20 },}}>
                                {
                                    this.state.collectionList.map((item, index) => {
                                        return (
                                            <Row gutter={20} justify="start">
                                                <Col span={10}>
                                                    <Input type="text"  placeholder='收款人' />
                                                </Col>

                                                {
                                                    !index ? (
                                                        <img src={require('../../../assets/icon/sum.png')} alt="" onClick={() => this.addOrDelList('collectionList', 'add')}/>
                                                    ): 
                                                    (
                                                        <img src={require('../../../assets/icon/sub.png')} alt="" onClick={() =>  this.addOrDelList('collectionList', 'del', index)}/>
                                                    )
                                                }  
                                            </Row> 
                                            )
                                    })
                                }
                            </FormItem>
                        
                            <FormItem label='复核人' {...{labelCol: { span: 4 },wrapperCol: { span: 20 },}}>
                                {
                                    this.state.reviewList.map((item, index) => {
                                        return (
                                            <Row gutter={20} justify="start">
                                                <Col span={10}>
                                                    <Input type="text"  placeholder='复核人' />
                                                </Col>
                                                {
                                                    !index ? (
                                                        <img src={require('../../../assets/icon/sum.png')} alt="" onClick={() => this.addOrDelList('reviewList', 'add')}/>
                                                    ): 
                                                    (
                                                        <img src={require('../../../assets/icon/sub.png')} alt="" onClick={() =>  this.addOrDelList('reviewList', 'del', index)}/>
                                                    )
                                                }  
                                            </Row> 
                                            )
                                    })
                                }
                            </FormItem>
                        </Form>
                    </div>
                    
                </Modal>

			</div>
		)
	}
}
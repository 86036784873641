import React, { Component } from "react"
import './goodsNo.less'
import { Button, Divider, Table, message, Modal, Tree, Input, Spin } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal.fork'
import { queryGoodsNoByPage, addGoodsNo, updateGoodsNo, deleteGoodsNo, queryFirstLevelPublicGoodsNo, queryPublicGoodsNoByPage, queryPublicCategoryGoodsLevelList,querySysEnterpriseList } from '../../../api'
import { UnorderedListOutlined } from '@ant-design/icons'

const { TreeNode } = Tree
const { Search } = Input

let slSelectVal = [
    {id:'0.13', name: '13%' },
    {id:'0.09', name: '9%' },
    {id:'0.06', name: '6%' },
    {id:'0.05', name: '5%' },
    {id:'0.03', name: '3%' },
    {id:'0.02', name: '2%' },
    {id:'0.015', name: '1.5%' },
    {id:'0.01', name: '1%' },
    {id:'0.00', name: '0%' },
]


const yhzcbsSelectVal = [
    { id: '0', name: '否' },
    { id: '1', name: '是' },
]

const lslbsSelectVal = [
    { id: '1', name: '出口免税和其他免税优惠政策（免税）' },
    { id: '2', name: '不征增值税（不征税）' },
    { id: '3', name: '普通零税率（0%）' },
]

let addData = [
    {
        type: 'INPUT',
        label: '商品名称',
        field: 'spmc',
        placeholder: '请输入商品名称',
        width: "100%",
        initialValue: '',
    },
    {
        type: 'SELECT',
        label: '税率',
        field: 'sl',
        placeholder: '请输入盘组名称',
        width: "100%",
        initialValue: '13%',
        list: slSelectVal
    },
    {
        type: 'INPUT',
        label: '税收分类编码',
        field: 'spbm',
        placeholder: '请输入税收分类编码',
        width: "100%",
        initialValue: '',
        abled: true,
        inputProps: {
            addonAfter: <UnorderedListOutlined />
        }
    },
    {
        type: 'INPUT',
        label: '税收分类名称',
        field: 'spsm',
        placeholder: '请输入税收分类名称',
        width: "100%",
        abled: true,
        initialValue: '',
    },
    {
        type: 'INPUT',
        label: '单价',
        field: 'unitPrice',
        placeholder: '请输入单价',
        width: "100%",
        initialValue: '',
    },
    {
        type: 'INPUT',
        label: '计量单位',
        field: 'dw',
        placeholder: '请输入计量单位',
        width: "100%",
        initialValue: '',
    },
    {
        type: 'INPUT',
        label: '规格型号',
        field: 'ggxh',
        placeholder: '请输入规格型号',
        width: "100%",
        initialValue: '',
    },
    {
        type: 'SELECT',
        label: '享受优惠政策',
        field: 'yhzcbs',
        placeholder: '请输入享受优惠政策',
        width: "100%",
        initialValue: '0',
        list: yhzcbsSelectVal
    }
]

const hasYhzc = [
    {
        type: 'SELECT',
        label: '优惠政策类型',
        field: 'lslbs',
        placeholder: '请输入优惠政策类型',
        width: "100%",
        initialValue: '1',
        list: lslbsSelectVal
    },
    // {
    //     type: 'INPUT',
    //     label: '增值税特殊管理',
    //     field: 'zzstsgl',
    //     placeholder: '请输入增值税特殊管理',
    //     width: "100%",
    //     initialValue: '',
    // },

]

let editOrAddFormData = {}


export default class GoodsNo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showEditOrAddDialog: false,
            doAddOrBj:false,
            tableList: [],
            getDataInfo: {
                spmc: '',
                spbm: '',
                nsrsbh:'',
                page: 1,
                rows: 10
            },
            addData: [],
            resetForm: false,
            showSpbmModal: false,
            treeData: [],
            treeChildrens: [],
            spbmSelectTableData: [],
            total: 1,
            loading:false,
            obj:{}, //商品编码
            totalModal:0,
            pageModal:1,
            goodsNo:"",
            name:"",
            id:"",
            list2:[]
        }
        this.myLength=8;
    }


    handleFilter = e => {

        this.setState({ getDataInfo: { ...this.state.getDataInfo, ...e,page:1} }, () => {
            this.getData()
        })
    }

    //改变数据
    getEditOrAddData = e => {
        this.changeEditOrAddForm(e)
        for (let item in e) {
            editOrAddFormData[item] = e[item]
        }
    }

    changeEditOrAddForm = data => {
        const hasFindedLslbs = addData.find(item => item.field === 'lslbs')
        if (data.yhzcbs === '1' && !hasFindedLslbs) {
            hasYhzc.forEach(item => {
                addData.push(item)
            })
        } else if (data.yhzcbs === '0' && hasFindedLslbs) {
            // addData.splice(addData.length - 2, 2)
            addData.pop()
        }
    }

    componentDidMount() {
        this.getData()
        this.getList()
    }
    
    getList = () => {
		let list2 = []
		querySysEnterpriseList().then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				tableList.forEach((item, index) => {
					list2.push({
						id: item.registeredNo,
						name: item.companyName,
					})
				})
                this.setState({list2})
			}
		})
	}

    getData() {
        const param = {
            ...this.state.getDataInfo
        }
        this.setState({loading:true})
        queryGoodsNoByPage(param).then(res => {
            if (res.code === '0000') {
                const { resultList: tableList } = res
                const total = res.pager.totalCount
                tableList.forEach((item, index) => {
                    item.key = index + 1
                    if (parseFloat(item.sl) && item.sl.includes(".") && !item.sl.includes("%")) {
                        item.sl = parseFloat(item.sl) * 100 + "%"
                    }else if(item.sl == 0){
                        item.sl = "0%"
                    }
                })
                this.setState({
                    tableList,
                    total
                })
            } else {
                message.error(res.msg)
            }
            this.setState({loading:false})
        })
    }
    // 编辑入口
    edit = data => {
        const editNeedShowData = ['spmc', 'spbm', 'ggxh', 'dw', 'sl', 'unitPrice', 'yhzcbs', 'lslbs', 'zzstsgl', 'spsm']
        this.changeEditOrAddForm(data)
        for (let key of editNeedShowData) {
            for (let item of addData) {
                if (item.field === key) {
                    item.initialValue = data[key]
                    editOrAddFormData[key] = data[key]
                }
            }
        }
        // this.state.id = data.id
        this.setState({ showEditOrAddDialog: true,doAddOrBj:false,id:data.id})
    }

    del = data => {
        const id = data.id
        deleteGoodsNo(id).then(res => {
            if (res.code === '0000') {
                message.success(res.msg)
                this.getData()
                return
            }
            message.error(res.msg)
        })
    }

    addNew = () => {
        if (addData.length >this.myLength) {
            addData.splice(addData.length-1, 1)
        }

        // 重置数据
        this.resetEditOrAddFormData()

        this.setState({
            showEditOrAddDialog: true,
            doAddOrBj:true
        })
    }


    //提交
    enterEditOrAdd = async () => {

        // editOrAddFormData.sl = (parseInt(editOrAddFormData.sl) / 100).toFixed(3)
        if (editOrAddFormData.sl == "NaN") {
            return
        }
        if (editOrAddFormData.yhzcbs == 1) {
            editOrAddFormData.sl = '0.00'
        }
        // else{
        //     editOrAddFormData.zzstsgl = ''
        // }
  
        let param = {
            ...editOrAddFormData
        }

        // if(this.state.obj.spbm){
        //     param = {...param,...this.state.obj}
        // }

        if(typeof param.sl=="string" && param.sl.includes("%")){
            param.sl = (parseFloat(param.sl)/100).toString()
        }
  
        // if (param.yhzcbs == 1 && (!param.zzstsgl )) {
        //     message.error('增值税特殊管理,不能为空！')
        //     return
        // }

        let res;
        this.setState({loading:true})
        if (this.state.doAddOrBj) {
            res = await addGoodsNo(param)
        } else {
            param.id = this.state.id
            res = await updateGoodsNo(param)
        }
        if(res.code=="0000"){
            message.success(res.msg)
            this.setState({ showEditOrAddDialog: false })
        }else{
            message.error(res.msg)
        }

        this.setState({loading:false,obj:{}})
        this.getData()

        this.setState({ resetForm: false })
        for (let key in editOrAddFormData) {
            editOrAddFormData[key] = ''
        }
    }

    resetEditOrAddFormData() {
        this.setState({ resetForm: true,obj:{} })
        for (let item of addData) {
            switch (item.field) {
                case 'sl':
                    item.initialValue = '13%'
                    continue
                case 'yhzcbs':
                    item.initialValue = '0'
                    continue
                case 'lslbs':
                    item.initialValue = '1'
                    continue
                default:
                    item.initialValue = ''
                    continue
            }
        }
        for (let key in editOrAddFormData) {
            delete editOrAddFormData[key]
        }
    }

    showSelect = () => {
        queryFirstLevelPublicGoodsNo().then(res => {
            if (res.code === '0000') {
                let { resultList: treeData } = res
                treeData.forEach(item => {
                    item.title = item.mc
                    item.key = item.bm
                    // item.children = []
                })
                this.setState({
                    treeData
                })
            }
            this.setState({
                showSpbmModal: true
            })
        })
    }

    queryTreeChilrenNode = param => {
        let childrenNode = []
        return queryPublicCategoryGoodsLevelList(param).then(res => {
            if (res.code === '0000') {
                let { resultList } = res
                childrenNode = resultList
            } else {
                message.warning('该分类下没有子节点')
            }
            return childrenNode
        })
    }
    // tree结构
    onLoadData = treeNode => {
        const { bm } = treeNode.props
        const param = { goodsNo: bm }
        const { treeData } = this.state;
        treeData.forEach(item => {
            item.key = item.bm
            item.isLeaf = false
        })
        this.setState({
            treeData: [...treeData],
        });

        return new Promise(resolve => {
            if (treeNode.props.children) {
                resolve();
                return;
            }
            this.queryTreeChilrenNode(param).then(children => {
                let treeChildrens
                if (children && children.length !== 0) {
                    treeChildrens = children.map(item => {
                        let obj = { ...item }
                        obj.title = item.mc
                        obj.key = item.bm
                        return obj
                    })
                } else {
                    resolve();
                    return;
                }
                setTimeout(() => {
                    treeNode.props.dataRef.children = treeChildrens;
                    this.setState({
                        treeData: [...this.state.treeData],
                    });
                    resolve();
                }, 10);
            })

        })
    };

    renderTreeNodes = data => {
        return data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} dataRef={item} />;
        });
    }

    setPageModal=(val)=>{
        this.state.pageModal = val
        this.getTabelData()
    }

    getTabelData = (val,key) => {
        if(Array.isArray(val)&&val&&key!=2){
            this.state.name=""
            this.state.goodsNo = val[0]
            this.state.pageModal = 1
        }else if(!Array.isArray(val)&&val&&key==2){
            this.state.name=val
            this.state.goodsNo = ""
            this.state.pageModal = 1
        }
        const param = {
            name: this.state.name,
            goodsNo: this.state.goodsNo,
            page: this.state.pageModal,
        }
        queryPublicGoodsNoByPage(param).then(res => {
            if (res.code === '0000') {
                const { resultList: spbmSelectTableData, pager:{totalCount:totalModal}} = res
                try{
                    if(Array.isArray(spbmSelectTableData)&&spbmSelectTableData.length>0){
                        spbmSelectTableData.forEach((item,index)=>{
                            item.key = index+1
                        })
                    }
                }catch{}
                this.setState({ spbmSelectTableData,totalModal})
            }
        })
    }

    // getSearchData = val =>{
    //     console.log(val)
    // }
    setPage = (val) => {
        this.setState({
            getDataInfo: { ...this.state.getDataInfo, page: val }
        }, () => {
            this.getData()
        })
    }


    render() {
        const formList = [
            {
                type: 'INPUT',
                label: '商品名称',
                field: 'spmc',
                placeholder: '请输入纳税人名称',
                width: "100%",
            },
            {
                type: 'INPUT',
                label: '商品编码',
                field: 'spbm',
                placeholder: '请输入商品编码',
                width: "100%",
            },
            {
                type: 'AUTOSELECT',
                label: '公司名称',
                field: 'nsrsbh',
                placeholder: '请选择公司名称',
                width: "300px",
                list:this.state.list2
            },
        ]

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
            },
            {
                title: '商品名称',
                dataIndex: 'spmc'
            },
            {
                title: '税率',
                dataIndex: 'sl'
            },
            {
                title: '税收分类编码',
                dataIndex: 'spbm'
            },
            {
                title: '税收分类名称',
                dataIndex: 'spsm'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <div>
                        <Button type="primary" style={{ marginRight: "10px" }} onClick={() => { this.edit(record) }}>编辑</Button>
                        <Button type="danger" onClick={() => { this.del(record) }}>删除</Button>
                    </div>
                ),
            }
        ]

        let spbmSelectTableColumns = [
            {
                title: '序号',
                dataIndex: 'key',
                width:60,
            },
            { 
                title: '编码', 
                dataIndex: 'hbbm',
            },
            { 
                title: '名称', 
                dataIndex: 'mc',
                ellipsis: true,
            },
           
            { 
                title: '说明', 
                dataIndex: 'sm',
                ellipsis: true,
            },
            { 
                title: '增值税税率', 
                dataIndex: 'slv',
                ellipsis: true,
            },
            // { 
            //     title: '增值税特殊管理', 
            //     dataIndex: 'zzstsgl',
            //     ellipsis: true,
            // },
            { 
                title: '增值税政策依据', 
                dataIndex: 'zzstsyj',
                ellipsis: true,
            },
            { 
                title: '增值税特殊内容代码', 
                dataIndex: 'zzstsnrdm',
                ellipsis: true,
            },

            { 
                title: '消费税管理', 
                dataIndex: 'xfsgl',
                ellipsis: true,
            },
            { 
                title: '消费税政策依据', 
                dataIndex: 'xfszcyj',
                ellipsis: true,
            },
            { 
                title: '消费税特殊内容代码', 
                dataIndex: 'xfstsnrdm',
                ellipsis: true,
            },
            { 
                title: '关键字', 
                dataIndex: 'gjz',
                ellipsis: true,
            },
            { 
                title: '是否汇总项', 
                dataIndex: 'hzx',
                ellipsis: true,
            },

            { 
                title: '统计局编码或国民行业代码', 
                dataIndex: 'tjjbm',
                ellipsis: true,
            },
            { 
                title: '海关进出口商品品目', 
                dataIndex: 'hgjcksppm',
                ellipsis: true,
            },
            { 
                title: '版本号', 
                dataIndex: 'bbh',
                ellipsis: true,
            },

            { 
                title: '可用状态', 
                dataIndex: 'kyzt',
                ellipsis: true,
            },
            { 
                title: '启用时间', 
                dataIndex: 'qysj',
                ellipsis: true,
            },
            { 
                title: '过渡期截止时间', 
                dataIndex: 'gdqjzsj',
                ellipsis: true,
            },

            { 
                title: '上级商品编码', 
                dataIndex: 'sjbm',
                ellipsis: true,
            },
            { 
                title: '汇总项', 
                dataIndex: 'wj',
                ellipsis: true,
            },
            { 
                title: '是否隐藏', 
                dataIndex: 'ishide',
                ellipsis: true,
            },
            { 
                title: '享受优惠政策',
                dataIndex: 'yhzc',
                ellipsis: true,
            },
            { 
                title: '优惠政策类型', 
                dataIndex: 'yhzcmc',
                ellipsis: true,
            },
            { 
                title: '简称', 
                dataIndex: 'flbmjc',
                ellipsis: true,
            },
            
        ]

        const tabelRowEvent = record => {
            return {
                onDoubleClick: e => {
                    if(record.hzx==='Y'){
                        Modal.warning({
                            title: '提示',
                            content: (
                              <div>
                                  可用状态值为：Y，该状态不可用！！！
                              </div>
                            ),
                            onOk() {},
                          });
                        return
                    }
                    const idx = addData.findIndex(i => i.field === 'spbm')
                    addData[idx].initialValue = record.hbbm
                    const ids = addData.findIndex(i => i.field === 'spsm')
                    addData[ids].initialValue = record.flbmjc

                    editOrAddFormData.spbm = record.hbbm
                    editOrAddFormData.spsm = record.flbmjc
                    let obj = {}
                    obj.spbm = record.hbbm
                    obj.spsm = record.flbmjc
                    this.setState({ showSpbmModal: false,obj })
                }
            }
        }

        return (
            <Spin  spinning={this.state.loading} size="large"  delay={500} >
            <div className="menberManager">
                <div className="menberManager-title">
                    <h2>{sessionStorage.getItem("pageTitle")||"商品编码"}</h2>
                </div>
                <Divider />
                <div className='menberManager-box' style={{ padding: "10px 0" }}>
                    <BaseForm formList={formList} filterSubmit={this.handleFilter} />
                    <Button type="primary" style={{marginLeft:"35px"}} onClick={this.addNew}>新增</Button>
                </div>

                <div>
                    <Table columns={columns} dataSource={this.state.tableList} pagination={{
                        onChange: this.setPage,
                        total: this.state.total,
                        current:this.state.getDataInfo.page,
                    }} />
                </div>
                {
                    this.state.showEditOrAddDialog ?
                        (<Modal
                            title="新增/编辑 商品编码"
                            visible={this.state.showEditOrAddDialog}
                            onOk={this.enterEditOrAdd}
                            onCancel={() => {
                                this.setState({
                                    showEditOrAddDialog: false,
                                })
                                this.resetEditOrAddFormData()
                            }}
                            getContainer={false}
                            maskClosable={false}
                        >
                            <ModalForm formList={addData} modalData={this.getEditOrAddData}  myLength={this.myLength} resetForm={this.state.resetForm} addonAfterClickEvent={this.showSelect} />
                        </Modal>) : null
                }

                {
                    this.state.showSpbmModal ?
                        (<Modal visible={this.state.showSpbmModal} title='税收分类编码管理' onCancel={() => this.setState({ showSpbmModal: false })} width='1200px' className='select-table' getContainer={false} maskClosable={false}>
                        <div className='spbm-container'>
                            <div className='left-tree'>
                                <Tree loadData={this.onLoadData} onSelect={this.getTabelData} height={200} >{this.renderTreeNodes(this.state.treeData)}</Tree>
                            </div>
                            <div className='right-table'>
                                <Search style={{ width: 200, marginBottom: 20 }} onSearch={(e)=>this.getTabelData(e,2)} />
                                <div style={{display:"block"}}>
                                <Table columns={spbmSelectTableColumns} dataSource={this.state.spbmSelectTableData} scroll={{ x: spbmSelectTableColumns.length*180 }} 
                                scrollToFirstRowOnChange={true}
                                pagination={{
                                    onChange: this.setPageModal,
                                    total: this.state.totalModal,
                                    current:this.state.pageModal,
                                }}
                                onRow={tabelRowEvent}
                                 />
                                </div>
                            </div>
                        </div>
                    </Modal>) : null
                }
                
            </div>
            </Spin>
        )
    }
}
import React, {Component} from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import './App.less';
import 'antd/dist/antd.css';

import Admin from './view/system/enter'
import Web from './view/websites/enter'
import Invoice from './view/invoice'
import Source from './view/invoice/source'
import Billing from './view/billing'
import Print from './view/print'

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/web' component={Web} ></Route>
          <Route path='/system' component={Admin} ></Route>
          <Route path='/takeInvoice' component={Invoice} ></Route>
          <Route path='/source' component={Source} ></Route>
          <Route path='/Billing' component={Billing} ></Route>
          <Route path='/print' component={Print} ></Route>
          <Redirect from="/" to="/web" />
        </Switch>
      </Router>
    );
  }
}

export default App;

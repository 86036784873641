import React, { Component } from 'react'
import './success.less'
import Footer2 from '../../../components/webfooter2/webFooter2'

import banner from "../../../assets/img/success/banner.png"

import img1 from '../../../assets/img/success/fec.png'
import img2 from '../../../assets/img/success/qycw.png'
import img3 from '../../../assets/img/success/jxgl.png'
import img4 from '../../../assets/img/success/xxglpt.png'

export default class success extends Component {
	constructor(props) {
        super(props);
        this.state = {
			footer:{
				title: "立即开启新体验吧",
				phone:'400-9958-578'
			},
        }
	}
	
	render() {
		return (
			<div className="success-page">
			     <div className="success-content1">
					 <img src={banner} alt=""/>
				 </div>
				 <div className="success-content2">
					 <ul className='content2-list'>
						 <li>
							 <img src={img1} alt=""/>
							 <span>餐饮</span>
							 <p>消费即开票，值得餐饮人拥有的好帮手！</p>
						 </li>
						 <li>
							 <img src={img2} alt=""/>
							 <span>企业财务</span>
							 <p>企业财务如何面对电子发票挑战？</p>
						 </li>
						 <li>
							 <img src={img3} alt=""/>
							 <span>进项平台管理</span>
							 <p>【进项平台】企业电子发票轻松管理</p>
						 </li>
						 <li>
							 <img src={img4} alt=""/>
							 <span>销项平台管理</span>
							 <p>【销项平台】中小企业电子发票轻松管理</p>
						 </li>
						 
					 </ul>
				 </div>
				 <Footer2 title={this.state.footer} />
			</div>
		)
	}
}

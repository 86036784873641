import React, { Component } from "react"
import { Modal } from 'antd'
import './headerBar.less'
import store from "../../store"
import action from "../../actions/action"
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
const { confirm } = Modal;


export default class HeaderBar extends Component {
	constructor() {
		super();
		this.state = {
			locale: zhCN,
			isHover: false,
			userName: '',
			userName2: '',
		};

	}
	componentWillMount() {
		let car =  store.getState()
		this.setState({
			userName:car.web.userName
		})
	}
	changeLocale = (value) => {
		this.props.languageToggle(value)
	}
	setHover = (flag) => {
		this.setState({ isHover: flag })
	}
	outLogin = () => {
		const that = this.props
		confirm({
			title: '是否确定退出登录',
			content: '',
			onOk() {
				sessionStorage.clear();
				localStorage.clear();
				store.dispatch(action("clearWeb",""))
				store.dispatch(action("clearSystem",""))
				that.props.history.push("/web/home")
			},
			onCancel() { },
		});
	}

	restPassword = ()=>{
		this.props.resetMm(true)
	}
	
	render() {

		return (
			<header className="header-nav">

				<span className="enter">
					用户名称：{this.state.userName}
				</span>
				{/* <span className="enter">
					修改用户名
			  </span>*/}
				<span className="enter" onClick={() => { this.restPassword() }}>
					修改密码
			  	</span>
				<span className="enter" onClick={() => { this.outLogin() }} >
					退出
			  	</span>

			</header>
		)
	}
}
import React, { Component } from "react"
import { Button, Form,Input,Divider, Table, message, Modal,Spin, Select,Popconfirm,Icon} from 'antd';
import { getTaxStatusList,insertTaxStatus,updateTaxStatus,deleteTaxStatus, listenManageinfoQuery,getBelongEnterprisesByUser,syncAllTaxPlate } from '../../../api/index'
import BaseForm from '../../../components/baseForm/baseForm'
import utils from '../../../utils'
import './ukeyInventory.less'

const { Option } = Select;
const { TextArea } = Input;

export default class UkeyInventory extends Component {
	constructor(props) {
		super(props);
		this.state = {
            loading:false,
            isRoleVisible: false,
            myTitle:true,
            tableList:[],
            param:{
                page:1,
            },
            formData:{
                taxExist:"0"
            }, //回显数据
            showInfo:{},
            list:[],
            userList:[],
            jqbhList:[],
        }
        this.list = [
            {id:"1",name:"Ukey-01"},
            {id:"2",name:"Ukey-02"},
            {id:"3",name:"Ukey-03"},
            {id:"6",name:"Ukey-04"},
            {id:"4",name:"税盘-01"},
            {id:"5",name:"税盘-02"},
            {id:"7",name:"税盘-03"},
        ]
    }
    componentWillMount(){
        this.getUser()
    }

	componentDidMount() {	
        this.getData()
    }

    //获取数据
    getData = () => {
        let param = this.state.param;
        console.log(param)
        this.setState({loading:true})
        getTaxStatusList(param).then(res=>{
            let list = []
            if(res.code==="0000"){
                message.success(res.msg)
                let tableList = res.resultList
                Array.isArray(tableList)&&tableList.forEach((item,index)=>{
                    item.key = index+1
                    list.push(item.jqbh)
                })
                this.setState({tableList,total:res.pager.totalCount})
            }else{
                this.setState({tableList:[],total:0})
                message.error(res.msg)
            }
            this.setState({loading:false,list},()=>{
                this.getStatus()
            })
        })
    }
    //条件查询
	handleFilter = (param) => {
        this.setState({param:{...param,page:1}},()=>{
            this.getData()
        })
    }
    //同步数据
    modalDatas=(e)=>{
		this.state.param = {...this.state.param,...e}
    }

    onChangePage=(page)=>{
        this.setState({param:{...this.state.param,page}},()=>{
            this.getData()
        })
    }
    //新增
    add = ()=>{
        this.setState({myTitle:true,isRoleVisible:true,formData:{taxExist:0}})
    }
    //编辑
    edit = (formData)=>{
        this.setState({myTitle:false,isRoleVisible:true,formData})
    }
    //提交
    handleRoleSubmit=()=>{
        let param = this.state.formData
        let {nsrsbh,jqbh,taxGroup} = param
        if(!nsrsbh)return message.error('纳税人名称不能为空!')
        if(!jqbh)return message.error('机器编号不能为空!')
        if(!taxGroup)return message.error('盘组不能为空!')
        this.setState({loading:true})
        if(this.state.myTitle){
            //添加
            insertTaxStatus(param).then(res=>{
                if(res.code==="0000"){
                    message.success(res.msg)
                    this.getData()
                }else{
                    message.error(res.msg)
                }
                this.setState({loading:false})
            })
        }else{
            //编辑
            updateTaxStatus(param).then(res=>{
                if(res.code==="0000"){
                    message.success(res.msg)
                    this.getData()
                }else{
                    message.error(res.msg)
                }
                this.setState({loading:false})
            })
        }
        this.setState({ isRoleVisible: false });
    }


    del=(id)=>{
        this.setState({loading:true})
        deleteTaxStatus({id}).then(res=>{
            if(res.code==="0000"){
                message.success(res.msg)
                this.getData()
            }else{
                message.error(res.msg)
            }
            this.setState({loading:false})
        })
    }

    getStatus=()=>{
        let list = this.state.list
        list.forEach((kpzdbs,index)=>{
            listenManageinfoQuery({kpzdbs,fplxdm:"026"}).then(res=>{
                if(res.code==="0000"){
                    this.state.tableList[index].gzzt = '成功！'
                }else{
                    this.state.tableList[index].gzzt = '失败！'
                    this.state.tableList[index].xinxi = res.msg
                }
                this.setState({})
            })
            
        })
    }

    renderSpan=(text)=>{
        if(!text)return
        if(text.includes('成功')){
            return <span style={{color:'green'}}><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{marginRight:8}} />{text}</span>
        }else{
            return <span style={{color:'red'}}><Icon type="exclamation-circle" theme="twoTone" twoToneColor="red" style={{marginRight:8}} />{text}</span>
        }
    }

    getUser = () => {
        let userList = []
		getBelongEnterprisesByUser().then(res => {
			if (res.code == "0000") {
                let mList = res.resultObj.kids
                Array.isArray(mList)&&mList.forEach((item, index) => {
                    let len = userList.filter(e=>e.id==item.registeredNo).length
                    if(len==0){
                        userList.push({
                            id:item.registeredNo,
                            name:item.companyName,
                            key:index+1
                        })
                    }
				})
            }
            this.setState({userList})
		})
    }

    changeFileNsrsbh=(e)=>{
        let obj={}
        obj["nsrsbh"] = e
        let object = this.state.userList.filter(item=>e==item.id)[0]
        syncAllTaxPlate(obj).then(res=>{
            this.state.formData.jqbh = ""
            if(res.code==="0000"){
                this.setState({jqbhList:res.resultList})
            }else{
                message.error(res.msg)
                this.setState({jqbhList:[]})
            }
        })
        this.setState({formData:{...this.state.formData,...obj,nsrmc:object.name}})
    }
    changeFileJqbh=(e)=>{
        let obj={}
        obj["jqbh"] = e
        this.setState({formData:{...this.state.formData,...obj}})
    }

    changeFileTaxGroup=(e)=>{
        let obj={}
        obj["taxGroup"] = e
        this.setState({formData:{...this.state.formData,...obj}})
    }
    changeFileTaxExist=(e)=>{
        let obj={}
        obj["taxExist"] = e
        this.setState({formData:{...this.state.formData,...obj}})
    }
    
    changeInput=(val,key)=>{
        let obj={}
        obj[key] = val
        this.setState({formData:{...this.state.formData,...obj}})
    }

    renderGroup=(id)=>{
        let name 
        try{
            name = this.list.filter(item=>item.id==id)[0].name
        }catch{
            name=""
        }
        return name
    }
    
	render() {
        let that = this;
        
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
			},
			{
				title: '企业名称',
				dataIndex: 'nsrmc',
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
			},
			{
				title: '税盘编号',
				dataIndex: 'jqbh',
			},
			{
				title: '所在盘组',
                dataIndex: 'taxGroup',
                render:(text)=>this.renderGroup(text)
            },
           
            {
				title: '是否在库',
                dataIndex: 'taxExist',
                render:(text)=><>
                    {text==0?<span>在库</span>:<span>寄出</span>}
                </>
            },
            {
				title: '在库单号',
				dataIndex: 'orderNo',
            },
            {
				title: '处理时间',
				dataIndex: 'updateTime',
            },
            {
				title: '备注',
				dataIndex: 'bz',
            },
            {
				title: '税盘状态',
                dataIndex: 'gzzt',
                render:(text)=>this.renderSpan(text)
            },
            {
				title: '错误信息',
                dataIndex: 'xinxi',
                width:120
            },
			{
				title: '操作',
				dataIndex: 'operation',
				render: (text, record) => (<><Button type="primary" style={{ marginRight: 10 }} onClick={() => {this.edit(record)}}>修改</Button><Popconfirm title='确定删除吗' onConfirm={() => this.del(record.id)}>
                <Button type="primary" style={{ marginRight: 10 }} >删除</Button>
            </Popconfirm></>),
			}
        ];
        
        const formList = [
			{
				type: 'INPUT',
				label: '企业名称',
				field: 'nsrmc',
				placeholder: '请输入企业名称',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'nsrsbh',
				placeholder: '请输入纳税人识别号',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '税盘编号',
				field: 'jqbh',
				placeholder: '请输入税盘编号',
				width: "100%",
			},
			{
				type: 'SELECT',
				label: '所在盘组',
				field: 'taxGroup',
                placeholder: '请输入所属企业',
                list:this.list,
                width:100
			},
        ]
        
        let formItemLayout ={
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            }

		return (
            <Spin spinning={this.state.loading} size="large" >
			<div className="ukeyInventory" >
				<h2>UKEY税盘库存管理</h2>
				<Divider />
                <div className="ukeyInventory_centent"  >
                    <div className="ukeyInventory_form" style={{ padding: "10px 0" }}>
                        <BaseForm formList={formList} filterSubmit={this.handleFilter} modalDatas={this.modalDatas} />
                        <Button type="primary" style={{margin:"0 10px 0 50px"}} onClick={this.add}>新增</Button>
                    </div>
                    
                    <div className="ukeyInventory_table">
                        <Table columns={columns} dataSource={this.state.tableList} 
                        pagination={{
                            onChange: this.onChangePage, 
                            total:this.state.total,
                            current:this.state.param.page
                            }} />
                    </div>

                     {
                        this.state.isRoleVisible?(<Modal
                            width={700}
                            title={this.state.myTitle?'库存管理新增':'库存管理编辑'}
                            visible={this.state.isRoleVisible}
                            onOk={this.handleRoleSubmit}
                            onCancel={() => {
                                this.setState({
                                    isRoleVisible: false
                                })
                            }}
                        >
                        <div>
                        <Form {...formItemLayout} >
                            <Form.Item label="纳税人名称">
                                <Select
                                    showSearch
                                    onChange={this.changeFileNsrsbh}
                                    placeholder="请选择纳税人名称"
                                    optionFilterProp="children"
                                    defaultValue={this.state.formData.nsrsbh}
                                    filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!this.state.myTitle}
                                >
                                    {
                                        that.state.userList.map((e,i)=>{
                                            return <Option value={e.id} key={i}>{e.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="机器编号">
                                <Select onChange={this.changeFileJqbh}  value={this.state.formData.jqbh} disabled={!this.state.myTitle}>
                                    {
                                        Array.isArray(that.state.jqbhList)&&that.state.jqbhList.map((e,i)=>{
                                            return <Option value={e.jqbh} key={i}>{e.jqbh}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="盘组">
                                <Select onChange={this.changeFileTaxGroup} value={this.state.formData.taxGroup}>
                                    {
                                        that.list.map((e,i)=>{
                                            return <Option value={e.id} key={i}>{e.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="是否在库">
                                <Select onChange={this.changeFileTaxExist} value={this.state.formData.taxExist}>
                                    <Option value={0} >在库</Option>
                                    <Option value={1} >寄出</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="订单号">
                                <Input allowClear onChange={(e)=>this.changeInput(e.target.value,'orderNo')} value={this.state.formData.orderNo} />
                            </Form.Item>
                            <Form.Item label="备注">
                                <TextArea onChange={(e)=>this.changeInput(e.target.value,'bz')} autoSize={{minRows: 4}} value={this.state.formData.bz} />
                            </Form.Item>
                            </Form>
                        </div>
                        </Modal>):null
                    }
                    
                </div>

			</div>
            </Spin>
		)
	}
}
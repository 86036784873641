import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Scan1 from './scan'
import ScanwxForWeiQian from './scanwxForWeiQian'
import Success from "./success"
export default class Invoice extends Component {
    componentDidMount() {
        //this.props.history.push({ pathname: '/invoice/step1', state: { invoiceNumber: '222' } })
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/source/phone/scan' component={Scan1} />
                    <Route path='/source/phone/scanwxForWeiQian' component={ScanwxForWeiQian} />
                    <Route path='/source/phone/success' component={Success} />
                    <Redirect to='/source/phone/scan' />
                </Switch>

            </div>
        )
    }
}

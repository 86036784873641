import React, { Component } from 'react'
import { Table, Modal, Input } from 'antd'
import './selectTable.less'

const { Search } = Input


export default class SelectTable extends Component{
    constructor(props){
        super(props)
    }

    render(){
        const tabelRowEvent = record => {
			return {
				onDoubleClick: e =>{ 
					this.props.isSelect(record)
				}
			}
		}
        const { showModal, columns, tableData } = this.props
        return(
            <Modal visible={showModal} onCancel={this.props.closeModal} style={{padding:'20px'}} width='800px' className='select-table' getContainer={false} maskClosable={false}> 
                <Search onSearch={val => this.props.getData(val)} style={{width:200,marginBottom:20}} />
                <Table columns={columns} dataSource={tableData} onRow={tabelRowEvent} className='cant-select' />
            </Modal>
        )
    }
}
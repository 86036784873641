import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./onlineRetailers.less";

import imgBan from "../../../../assets/img/solution/banner7.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/11.png";
import i2 from "../../../../assets/img/solution/26.png";
import i3 from "../../../../assets/img/solution/20.png";
import i4 from "../../../../assets/img/solution/18.png";
import i5 from "../../../../assets/img/solution/14.png";
import i6 from "../../../../assets/img/solution/15.png";

import i21 from "../../../../assets/img/solution/27.png";
import i22 from "../../../../assets/img/solution/4.png";
import i23 from "../../../../assets/img/solution/28.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";




export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer: {
				title: "我想要咨询此行业",
				phone: '400-9958-578'
			},
			active: [true, false, false, false]

		};
	}

	clickActive = (key) => {
		let list = this.state.active
		for (let i in list) {
			if (i == key) {
				list[i] = true
			} else {
				list[i] = false
			}
		}
		this.setState({ active: [...list] })
		let str = 'active' + key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};



		return (
			<div className="solution-content solution-content-7">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0] ? 'active' : ''} onClick={this.clickActive.bind(this, 0)}>行业趋势</li>
						<li className={this.state.active[1] ? 'active' : ''} onClick={this.clickActive.bind(this, 1)}>行业痛点</li>
						<li className={this.state.active[2] ? 'active' : ''} onClick={this.clickActive.bind(this, 2)}>解决方案</li>
						<li className={this.state.active[3] ? 'active' : ''} onClick={this.clickActive.bind(this, 3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>政策方面</span>
							<span>《电子商务法》要求电子商务服务应当依法出具发票等购货凭证</span>
						</li>
						<li style={bgImg2}>
							<span>交易方面</span>
							<span>线上交易方式的变化带来发票开具形式 </span>
						</li>
						<li style={bgImg3}>
							<span>新营销渠道</span>
							<span>商家注重通过线上营销增加消费粘性，电子发票成为新的渠道</span>
						</li>

					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>难处理</span>
							<span>电商企业跨平台经营，发票统一归集处理难度大</span>
						</li>
						<li>
							<span>综合成本高</span>
							<span>纸票打印耗费纸张和油墨，综合成本高</span>
						</li>
						<li>
							<span>人工成本高</span>
							<span>电商企业交易大，发票开具量大，货物与发票需要人工匹配，人工成本高</span>
						</li>
						<li>
							<span>物流成本高</span>
							<span>货票分离邮寄，物流成本高</span>
						</li>
					</ul>
				</section>

				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户在线下单遇电子发票，写指头信息，系概自动生成电子发票，可在公众号/订单理中看发下</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i1} />
								<span>下单付款</span>
								<img alt="" src={nextImg} />
							</li>
							<li>
								<img alt="" src={i2} />
								<span>录入发票抬头</span>
								<img alt="" src={nextImg} />
							</li>
						</div>
						<li>
							<img alt="" src={i3} />
							<span>电商系统</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i4} />
							<span>接口服务</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i5} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg} />
						</li>
						<li>
							<img alt="" src={i6} />
							<span>发送发票</span>
						</li>
					</ul>
				</section>


				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>自动开票</span>
								<img alt="" src={i21} />
							</div>
							<p>自动化开具，减少人工流程</p>
						</li>
						<li>
							<div>
								<span>避免纠纷</span>
								<img alt="" src={i22} />
							</div>
							<p>避免消费者因丢失纸质发票产生退货或者售后维修的纠纷</p>
						</li>
						<li>
							<div>
								<span>降低成本</span>
								<img alt="" src={i23} />
							</div>
							<p>电子化开具，不用邮寄，降低商家快递成本</p>
						</li>

					</ul>
				</section>



				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

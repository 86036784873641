import { message } from 'antd';

export default  class Verification{
    verificationAddInput(inputList) {
        // console.log(this)
        let verificationFail = false;
        for(let item of inputList){
            const nowItem = this.state.addData[Object.keys(item)[0]];
            if (nowItem === "") {
                message.info(`${item[Object.keys(item)[0]]}不能为空`);
                verificationFail = true;
                return false;
            }
        }
    }
    updateTable(newParam){
        const data= Object.assign({},this.state.param,newParam)
		this.setState({param:data},()=>{
            this.startTable(data)
		})
    }
}


import React, { Component } from "react"
import { Button,Divider,Table, message,Modal,Spin } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal.fork'
import {queryByPage,buyerManageAdd,buyerManageDelete,buyerManageUpdata} from '../../../api'
import "./ticketCompany.less"

let updateAndAddDataPsw = [
	{type: 'INPUT',label: '购方名称',field: 'buyerName',placeholder: '请输入购方名称',width: "100%",},
	{type: 'INPUT',label: '购方税号',field: 'buyerRegiestNo',placeholder: '请输入购方税号',width: "100%",},
	{type: 'INPUT',label: '购方地址',field: 'buyerAddress',placeholder: '请输入购方地址',width: "100%",},
	{type: 'INPUT',label: '购方电话',field: 'buyerPhone',placeholder: '请输入购方电话',width: "100%",},
	{type: 'INPUT',label: '购方银行名称',field: 'buyerBankName',placeholder: '请输入购方银行名称',width: "100%",},
	{type: 'INPUT',label: '购方银行账号',field: 'buyerBankNo',placeholder: '请输入购方银行账号',width: "100%",}
]

export default class TicketCompany extends Component {
	constructor(props) {
		super(props);
		this.state={
			loading:false,
			//修改回显数据
			updateData:[
                {
                    type: 'INPUT',
                    label: '原名称',
                    field: 'loginName',
                    placeholder: '请输入原名称',
                    width: "100%",
                    initialValue:'',
                },
                {
                    type: 'INPUT',
                    label: '',
                    field: 'loginName',
                    placeholder: '请输入新名称',
                    width: "100%",
                    initialValue:'',
                },
            ],
            isRoleVisible: false,
            isPswVisible: false,
			flag:false,
			tableList: [],
			pager:{
				page:1,
				rows:10
			},
			total:1
		}
	}
	// 存储数据
	addAndUpdataFormData = {}
	add=()=>{
		this.state.updateData.forEach(res=>{
			res.initialValue=''
		})
		updateAndAddDataPsw.forEach(res=>{
			res.initialValue=''
		})

		for(let key in this.addAndUpdataFormData){
			delete this.addAndUpdataFormData[key]
		}

        this.setState({
			isPswVisible:true,
			flag:false
		})
	}

	edit = e => {
		// console.log('bj',e)
		for(let item of updateAndAddDataPsw){
			for(let key in e){
				if(item.field == key){
					item.initialValue = e[key]
					this.addAndUpdataFormData[key]= e[key]
				}
			}
		}
		this.addAndUpdataFormData.id = e.id

		console.log("this.addAndUpdataFormData",this.addAndUpdataFormData)
		this.setState({isPswVisible:true})
		// this.setState({isPswVisible:true},()=>{
		// 	for(let i of updateAndAddDataPsw){
		// 		i.initialValue = e[i.field]
		// 	}
		// 	const {id,buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo} = e
		// 	this.addAndUpdataFormData = {id,buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo}
		// 	this.forceUpdate()
		// })
	}
	del = e =>{
		const { id } = e
		this.setState({loading:true})
		buyerManageDelete(id).then(res=>{
			if(res.code === '0000'){
				message.success(res.msg)
				this.getData()
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}
	searchInfo = {}
	handleFilter=(e)=>{
		this.searchInfo = e
		this.state.pager.page = 1
		this.getData()
	}
	// 改变数据
	addUserDataBack=(e)=>{
		// console.log('gb',e)
		// this.addAndUpdataFormData = {...e}
		for(let key in e){
			this.addAndUpdataFormData[key] = e[key]
		}
		// console.log(this.addAndUpdataFormData)
	}
	//提交
	handleRoleSubmit = async () => {
		// console.log("updata",this.addAndUpdataFormData)
		let length = Object.keys(this.addAndUpdataFormData).length
		if(length==0){
			message.info('数据未修改，无需提交！')
			return this.setState({
				isPswVisible:false
			})
		}
		// return
		let res;
		if(this.addAndUpdataFormData.id){
			res = await buyerManageUpdata(this.addAndUpdataFormData)
		}else{
			res = await buyerManageAdd(this.addAndUpdataFormData)
		}
		if(res.code === '0000'){
			message.success(res.msg)
			return this.setState({
				isPswVisible:false
			},()=>this.getData())
		}else{
			return message.error(res.msg)
		}
		
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		const param = {
			...this.searchInfo,
			...this.state.pager
		}
		this.setState({loading:true})
		queryByPage(param).then(res=>{
			if(res.code === '0000'){
				const {resultList:tableList} = res
				tableList.forEach((item,index)=>{
					item.key = index+1
				})
				this.setState({
					tableList,
					total:res.pager.totalCount
				})
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}

	setPage=(val)=>{
		this.state.pager.page = val
		this.getData()
	}

	render() {
		const columns = [
			{
			  title: '序号',
			  dataIndex: 'key',
            },
			{
			  title: '购方纳税人识别号',
			  dataIndex: 'buyerRegiestNo',
			},
			{
			  title: '购方纳税人名称',
			  dataIndex: 'buyerName',
			},
			{
			  title: '购方地址',
			  dataIndex: 'buyerAddress',
			},
			{
				title: '购方电话',
				dataIndex: 'buyerPhone',
			  },
			{
			    title: '购方银行名称',
				dataIndex: 'buyerBankName',
			},
			{
			    title: '购方银行账户',
				dataIndex: 'buyerBankNo',
			},
			{ 
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <div>
                        <Button type="primary" onClick={()=>{this.edit(record)}} >编辑</Button>
                        {/* <Button type="primary" style={{margin: "0 10px"}}>更新</Button> */}
                        <Button type="danger" onClick={()=>{this.del(record)}} style={{margin: "0 0 0 10px"}}>删除</Button>
                    </div>
                  ),
            },
		  ];
		const formList = [
			{
				type: 'INPUT',
				label: '纳税人名称',
				field: 'buyerName',
				placeholder: '请输入纳税人名称',
				width: "100%",
            },
            {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'buyerRegiestNo',
				placeholder: '请输入简码',
				width: "100%",
            },
		]
		
		return (
			<Spin  spinning={this.state.loading} size="large" >
			<div>
				<div>
					<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"购票单位信息"}</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}} className="ticketCompany_header">
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
					<Button type="primary" onClick={()=>{this.add()}} >新增</Button>
				</div>
				<div style={{ background: '#C7E2FF'}}></div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} 
						pagination={{
							onChange: this.setPage,
							total: this.state.total,
							current:this.state.pager.page
						}} />
				</div>
				{/* <Modal
						title="修改用户名2"
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={()=>{
							this.setState({
								isRoleVisible:false
							})
						}}
					>
					<ModalForm formList={this.state.updateData} modalData={this.addUserDataBack.bind(this)}/>
				</Modal> */}

				{
					this.state.isPswVisible?(<Modal
						title="新增/编辑"
						visible={this.state.isPswVisible}
						onOk={this.handleRoleSubmit}
						onCancel={()=>{
							this.setState({
								isPswVisible:false
							})
						}}
						getContainer={false} maskClosable={false}
					>
					<ModalForm formList={updateAndAddDataPsw} modalData={this.addUserDataBack.bind(this)}/>
				</Modal>):null
				}
			</div>
			</Spin>
		)
	}
}
import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./chain.less";

import imgBan1 from "../../../../assets/img/solution/banner1.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/1.png";
import i2 from "../../../../assets/img/solution/2.png";
import i3 from "../../../../assets/img/solution/3.png";
import i4 from "../../../../assets/img/solution/4.png";

import i11 from "../../../../assets/img/solution/11.png";
import i12 from "../../../../assets/img/solution/12.png";
import i13 from "../../../../assets/img/solution/13.png";
import i14 from "../../../../assets/img/solution/14.png";
import i15 from "../../../../assets/img/solution/15.png";

import i21 from "../../../../assets/img/solution/21.png";
import i22 from "../../../../assets/img/solution/22.png";
import i23 from "../../../../assets/img/solution/23.png";
import i24 from "../../../../assets/img/solution/24.png";

import bg1 from "../../../../assets/img/solution/bg1.png";
import bg2 from "../../../../assets/img/solution/bg2.png";
import bg3 from "../../../../assets/img/solution/bg3.png";





export default class Chain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer:{
				title: "我想要咨询此行业",
				phone:'400-9958-578'
			},
			active:[true,false,false,false]
			
		};
	}
	
	clickActive=(key)=>{
		let list  = this.state.active
		for(let i in list){
			if(i==key){
				list[i] = true
			}else{
				list[i] = false
			}
		}
		this.setState({active:[...list]})
		let str = 'active'+key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};
		

		return (
			<div className="solution-content solution-content-1">

				<section className="solution-1">
					<img alt="" src={imgBan1} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0]?'active':''} onClick={this.clickActive.bind(this,0)}>行业趋势</li>
						<li className={this.state.active[1]?'active':''} onClick={this.clickActive.bind(this,1)}>行业痛点</li>
						<li className={this.state.active[2]?'active':''} onClick={this.clickActive.bind(this,2)}>解决方案</li>
						<li className={this.state.active[3]?'active':''} onClick={this.clickActive.bind(this,3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul className="resetBox">
						<li style={bgImg1}>
							<span>连锁化发展，需要建立票据体系化标准，实现数据化管理</span>
						</li>
						<li  style={bgImg2}>
							<span>管好供应链，优化成本结构，提升利润率</span>
						</li>
						<li  style={bgImg3}>
							<span>运营精细化管理，提升效率用户粘粘度高</span>
						</li>
					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<div>开票时间长，体验差且各门店邮寄发票成本高且易丢失</div>
							<img alt="" src={i1} />
							<span>成本高</span>
						</li>
						<li>
							<div>供应商结算信息存在差异，开票信息准确率低、获票周期长,无法实现当月“应抵尽抵”</div>
							<img alt="" src={i2} />
							<span>信息差</span>
						</li>
						<li>
							<div>门店分布不同区域，各发票管理存在信息差，失控风险高</div>
							<img alt="" src={i3} />
							<span>风险高</span>
						</li>
						<li>
							<div>销售和发票无法实时汇总，数据难统一带来经营风险</div>
							<img alt="" src={i4} />
							<span>数据难</span>
						</li>
					</ul>
				</section>
				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户结账，商家打印出带有二维码的小票，客户扫描二维码，填写发票抬头、手机号或者邮箱，提交开票申请，系统自动开具电子发票，推送到客户邮箱。</div>
					<ul>
						<li>
							<img alt="" src={i11} />
							<span>客户结账</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i12} />
							<span>商家打印小票(含二维码)</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i13} />
							<span>扫码申请开票</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i14} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i15} />
							<span>推送电子发票</span>
						</li>
					</ul>
				</section>
				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>发票智能化管理</span>
								<img alt="" src={i21} />
							</div>
							<p>全流程的发票电子化，降低各项成本（人工/硬件设备），发票管理效率同步提升</p>
						</li>
						<li>
							<div>
								<span>业务财税票一体化</span>
								<img alt="" src={i22} />
							</div>
							<p>针对性的连锁化方案实现在平台上的全票种管理，便于总部管理</p>
						</li>
						<li>
							<div>
								<span>内部管理标准化</span>
								<img alt="" src={i23} />
							</div>
							<p>统一发票数据源，简化财务人员各环节工作压力，提升管理效能</p>
						</li>
						<li>
							<div>
								<span>税务风险降低</span>
								<img alt="" src={i24} />
							</div>
							<p>电子化票据统一管理，避免因发票遗失造成的税务风险</p>
						</li>
					</ul>
				</section>
				
				

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

import React from "react";
import "antd/dist/antd.css";
import "./index.less";
import { Upload, Icon, Modal } from "antd";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
let fileList = []

export default class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    fileList: '',
    opens:true
  };

  componentDidMount() {
    fileList = this.getUrl()
    this.setState({
      fileList
    })
  }

  componentWillUpdate() {
    if(!this.state.opens)return
    this.state.fileList = this.getUrl()
  }
  
  getUrl=()=>{
    let url = this.props.url
    if (!url.includes("/"))return
    let name = url.split('/').pop()
    let list = [{
      uid: '-1',
      name,
      status: 'done',
      url,
    }]
    return list
  }


  handleCancel = () => {
    this.setState({ previewVisible: false });
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };



  stopUpLoad = (fileList) => {
    getBase64(fileList).then(res => {
      this.props.setBase(res)
      }
    )
    return false
  }

  setBase = () => {
    let list = this.state.fileList
    if (list.length == 0) return;
    let bt64 = list[0].thumbUrl
    this.props.setBase(bt64)
  }

  handleChange = ({ fileList }) => {
    this.setState({ fileList,opens:false }, () => {
      this.setBase()
    });

  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          // action="http://www.baidu"
          beforeUpload={this.stopUpLoad.bind(this)}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {
            Array.isArray(fileList)?(fileList.length >= 1 ? null : uploadButton):uploadButton
          }
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

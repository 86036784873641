import React, { Component } from "react";
import "./aboutMe2.less";
import Partners from "../../../components/partners/partners";


import banner from '../../../assets/img/aboutMe/banner1.png';
import banner3 from '../../../assets/img/aboutMe/banner3.png';

import iconImg1 from '../../../assets/img/aboutMe/tuoGuan.png';
import iconImg2 from '../../../assets/img/aboutMe/guanLi.png';
import iconImg3 from '../../../assets/img/aboutMe/daiLi.png';
import iconImg4 from '../../../assets/img/aboutMe/jianCe.png';
import iconImg5 from '../../../assets/img/aboutMe/qingXi.png';
import iconImg6 from '../../../assets/img/aboutMe/youHua.png';


export default class success extends Component {
	render() {
		return (
			<div className="aboutMe-page2">
				<div className="aboutMe2-content1">
					<img src={banner} alt="" />
				</div>
				
				<div className="aboutMe2-content2">
					<h3>在这里提供数据必备所需的一切</h3>
					<ul className="box-ul-1">
						<li>
							<div>依托于中税电信级机房设施，为用户提供服务器托管服务</div>
							<img src={iconImg1}/>
							<span>主机托管</span>
						</li>
						<li>
							<div>专业机房监控系统提供全智能化管理，保证通风、恒温、恒湿</div>
							<img src={iconImg2}/>
							<span>智能管理</span>
						</li>
						<li>
							<div>得到用户授权下，为用户托管服务器提供代管代维服务器</div>
							<img src={iconImg3}/>
							<span>代管代维</span>
						</li>
						<li>
							<div>7*24小时实时监控网络环境，及时发现处理网络安全事件</div>
							<img src={iconImg4}/>
							<span>入侵检测</span>
						</li>
						<li>
							<div>对遭受网络攻击的托管服务器，提供清洗服务，过滤攻击流量，保障用户服务器正常流量</div>
							<img src={iconImg5}/>
							<span>流量清洗</span>
						</li>
						<li>
							<div>根据用户需求，优化路由选择，改善用户访问体验</div>
							<img src={iconImg6}/>
							<span>路由优化</span>
						</li>
						
					</ul>
				</div>
				
				<div className="aboutMe2-content3">
					<h3>平台管理服务体系</h3>
					<div>建立完善的技术支持与售后服务机构，协助企业办理电子发票开通、税务等事宜，提供售后、财务咨询等服务。</div>
					<div>
						<img src={banner3}/>
					</div>
					<div>
						<span>地址位于深圳，业务遍布全国各地</span> <br/>
						<span>中税创新承诺：7*24*365全天候为您竭诚服务</span><br/>
						<span>电话热线：400-9958-578</span>
					</div>
				</div>
				
				<Partners />
			</div>
		);
	}
}

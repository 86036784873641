import React, { Component } from 'react'
import {Steps,Button, message} from 'antd';
import {companyregister,companyCode} from '../../../api'
import './Authentication.less'



const { Step } = Steps



export default class Authentication extends Component {
   state={
     phone:"",email:"",loginName:"",name:"",registeredNo:"",address:"",authCode:"",checkCode:""
   }
   step=0

   register=()=>{
     const list=[
         {value:"loginName",msg:"公司名称"},
         {value:"email",msg:"邮箱"}, 
         {value:"name",msg:"法定代表人"},
         {value:"registeredNo",msg:"纳税人识别号"},
         {value:"address",msg:"公司地址"},
         {value:"phone",msg:"手机号"},
         {value:"authCode",msg:"验证码"},
        ]
     for(let key in  list){
         if(this.state[list[key].value]===""){
             return message.warning(`${list[key].msg}不能为空！`)
         }
     }  
     companyregister(this.state).then(res=>{
         if(res.code===0){
             this.step=1
         }
     })
   }
   inputValue=(e,name)=>{
       for(let key in this.state){
           if(key===name){
               this.state[key]=e.target.value
           }
       }
       console.log(this.state)
   }
   sendCode=()=>{
    const reg=/^1[3456789]\d{9}$/
    if(this.state.phone===""|| !reg.test(this.state.phone)) return  message.warning("请正确输入手机号！")
    companyCode({phone:this.state.phone}).then(res=>{
        if(res.code===0){
            this.state.checkCode=res.object
            message.success("请注意接收短信！");
        }
    })
   }
    render() {

        return (
            <div className="authentication">
                <div className="header">
                    <h1>企业注册</h1>
                </div>
                <Steps current={this.step} style={{marginTop:"20px",width:"50%"}}>
                    <Step title="填写账户信息"/>
                    <Step title="填写企业主体信息" />
                </Steps>
                <div className="content" style={{display:this.step===0?"block":"none"}}>
                    <ul>
                        <li>
                            <label htmlFor="">公司名称:</label><input type="text" placeholder="请输入公司名称" onChange={(e)=>{this.inputValue(e,"loginName")}}/>
                        </li>
                        <li>
                            <label htmlFor="">邮箱:</label><input type="text" placeholder="请输入邮箱" onChange={(e)=>{this.inputValue(e,"email")}}/>
                        </li>
                        <li>
                            <label htmlFor="">法定代表人:</label><input type="text" placeholder="请输入法定代表人" onChange={(e)=>{this.inputValue(e,"name")}}/>
                        </li>
                        <li>
                            <label htmlFor="">纳税人识别号:</label><input type="text" placeholder="请输入统一社会信用代码/工商注册号(纳税人识别号)" onChange={(e)=>{this.inputValue(e,"registeredNo")}}/>
                        </li>
                        <li>
                            <label htmlFor="">公司地址:</label><input type="text" placeholder="请输入公司地址" onChange={(e)=>{this.inputValue(e,"address")}}/>
                        </li>
                        <li>
                            <label htmlFor="">手机号:</label><input type="text" placeholder="请输入手机号" onChange={(e)=>{this.inputValue(e,"phone")}}/>
                        </li>
                        <li>
                            <label htmlFor="">验证码:</label><input type="text" placeholder="请输入验证码" onChange={(e)=>{this.inputValue(e,"authCode")}} style={{width:"12%"}}/><Button type="primary" onClick={()=>{this.sendCode()}} style={{width:"7%"}}>获取验证码</Button>
                        </li>
                        <li>
                            <label htmlFor=""></label> <Button type="primary" block onClick={()=>{this.register()}}>提交</Button>
                        </li>
                    </ul>
                </div>
                <div style={{display:this.step===1?"block":"none"}}>
                        
                </div>
            </div>
        )
    }
}



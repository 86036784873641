import React, { Component } from "react"
// import './writeBackMonitoring.less'
import { Button, Divider, Table, message, Modal,Input, Icon  } from 'antd';
import { queryTaxPlateByNsrsbh } from '../../api'
import Highlighter from 'react-highlight-words';
const { confirm } = Modal;

export default class ControlPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//修改回显数据
			tableList2: [],
			//税控盘编号
			deviceNo: '',

			isRoleVisible: false,
			info: {
				nsrsbh: '',
				deviceNo: '',
				nsrmc: ''
			}

		}
	}

	componentDidMount() { }

	setPage = (val) => { }

	handleRoleSubmit = () => { }

	add = () => {
		queryTaxPlateByNsrsbh().then(res => {
			const { resultObj: tableList2 } = res
			tableList2.forEach((item,index) => {
				item.key = index+1
			});
			if (tableList2 && tableList2.length !== 0){
				return this.setState({ tableList2, isRoleVisible: true })
			}
			message.error('获取税盘列表失败')
		})
	}

	restList = (val) => {
		if(val==="004"){
			return { id: '004', name: "增值税专用发票" }
		}
		if(val==="007"){
			return { id: '007', name: "增值税普通发票" }
		}
		if(val==="026"){
			return { id: '026', name: "增值税电子发票" }
		}
		if(val==="028"){
			return { id: '028', name: "电子专用发票" }
		}
		if(val==="025"){
			return { id: '025', name: "卷式发票" }
		}
		if(val==="005"){
			return { id: '005', name: "机动车发票" }
		}
		if(val==="006"){
			return { id: '006', name: "二手车销售统一发票" }
		}

	}
	
	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={node => {
				this.searchInput = node;
			  }}
			  placeholder={`查询 纳税人名称`}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
			  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon="search"
			  size="small"
			  style={{ width: 90, marginRight: 8 }}
			>
			  查询
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </div>
		),
		filterIcon: filtered => (
		  <Icon type="search" style={{ color: filtered ? '#1890ff' : "#000" }} />
		),
		onFilter: (value, record) =>
		  record[dataIndex]
			.toString()
			.toLowerCase()
			.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
		  if (visible) {
			setTimeout(() => this.searchInput.select());
		  }
		},
		render: text =>
		  this.state.searchedColumn === dataIndex ? (
			<Highlighter
			  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			  searchWords={[this.state.searchText]}
			  autoEscape
			  textToHighlight={text.toString()}
			/>
		  ) : (
			text
		  ),
	  });
	
	  handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	  };
	
	  handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	  };
	
	render() {

		const columns2 = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
				...this.getColumnSearchProps('nsrmc'),
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},
		];
		const tabelRowEvent = record => {
			return {
				onDoubleClick: e => {
					let fplxdm = (record.fplxdm).split(',').map(item=>{
						return this.restList(item)
					})
					// if(record.fplxdm.endsWith(',')){

					// }
					fplxdm.pop()
					this.setState({
						deviceNo: record.jqbh, isRoleVisible: false, info: {
							deviceNo: record.jqbh,
							nsrmc: record.nsrmc,
							nsrsbh: record.nsrsbh,
							fplxdm
						}
					}, () => {
						this.props.getInfo(this.state.info)
					})
				}
			}
		}

		return (
			<div className="menberManager">

				<div style={{ padding: "10px 0", display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 15 }}>
						<span>税控盘编号：</span>
						<Button onDoubleClick={() => this.add()}>{this.state.info.deviceNo === '' ? '双击选择税控盘' : this.state.info.deviceNo}</Button>
					</div>

				</div>

				{
					this.state.isRoleVisible ? (<Modal
						width={820}
						title="双击选择税控设备"
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={() => {
							this.setState({
								isRoleVisible: false
							})
						}}
					>
						<Table columns={columns2} dataSource={this.state.tableList2} onRow={tabelRowEvent} pagination={{ hideOnSinglePage: true }} />
					</Modal>) : null
				}

			</div>
		)
	}
}
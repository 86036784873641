import React, { Component } from "react"
import { Button, Divider,Modal, message,Spin,Table,Radio,Select,Input,DatePicker} from 'antd';
import { authToken,queryApplyClick,applyClickInvoice,downloadClickInvoice} from '../../../api'
import moment from "moment"
import store from "../../../store"
import action from "../../../actions/action"
import utils from "../../../utils"
import MyForm from "./form"
import "./invoiceDownload.less"

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class Statistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visibleLogin:false,
			visible2:false,
			loading:false,
            tableList:[], 
            tableList2:[], 
            paramObj:{
                nsrsbh:"",
                sqrqq:"", //申请日期起（yyyy-mm-dd）
                sqrqz:"", //申请日期止（yyyy-mm-dd）
                sjlxs:"0", //进销项（  1 进项票2 销项票）
                fplxs:"", //01 增值税专用发票03 机动车销售统一发票04 增值税普通发票 10 增值税普通发票（电子） 11增值税普通发票（卷票） 15  二手车销售统一发票 17海关缴款书
            },
            user:{
				nsrsbh:"",
				Passwor:'',
				pin:'',
            },
            nsrsbh:"",
            ableCx:false,
		}
    }

    componentDidMount(){
        let nsrsbh = store.getState().web.nsrsbh
        if(!nsrsbh){
            this.setState({visibleLogin:true})
        }else{
            this.setState({nsrsbh,user:{...this.state.user,nsrsbh}})
        }
    }

	//改变数据
    ycChange=(val,key)=>{
        let obj = {}
		obj[key] = val
		this.setState({paramObj:{...this.state.paramObj,...obj}},()=>{
			// console.log(this.state.paramObj)
		})
    }

    //时间条件
    onChangeMonth=(data,date)=>{
        let [sqrqq,sqrqz]=date
        this.setState({
            paramObj:{...this.state.paramObj,sqrqq,sqrqz}
        })
    }

    disabledDate=(current)=> {
        return current && current > moment().endOf('day')
    }

    //获取数据
    getData=(val)=>{
        let param = this.state.paramObj
		param.nsrsbh = this.state.nsrsbh
        if(!param.nsrsbh){
			this.setState({visibleLogin:true})
            return message.error('未认证！')
		}
		this.setState({loading:true})
		queryApplyClick(param).then(res=>{
			if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
				this.setState({visibleLogin:true,loading:false})
				return message.error(res.msg)
			}
			if(res.code=="0000"){
				message.success(res.msg)
				let tableList = res.resultObj.data
				this.setState({tableList})
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
    }

	//认证
	userChange=(val,key)=>{
		let obj = {}
		obj[key] = val
		this.setState({user:{...this.state.user,...obj}})
	}
	
	handleCancel = () => {
		this.setState({
		  visibleLogin:false
		});
	};

	//登录认证
	clickLogin=()=>{
        let param = this.state.user
		if(!param.nsrsbh){
			return message.error('纳税人识别号不能为空！')
		}
		if(!param.pin){
			return message.error('盘的证书口令不能为空！')
		}
		this.setState({loading:true})
		authToken(param).then(res=>{
			if(res.code=="0000"){
				try{
					if(res.resultObj.statusCode=="200"){
						message.success(res.resultObj.message)
						this.setState({visibleLogin:false,nsrsbh:param.nsrsbh},()=>{
							store.dispatch(action('nsrsbh',param.nsrsbh))
						})
					}else{
						message.error(res.resultObj.message)
					}
				}catch{
					message.error(res.msg)
				}
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
    }
    
				
    onChangePage=(val)=>{
		console.log(val)
	}
	
	clickOk=(param)=>{
		param.nsrsbh = this.state.nsrsbh
		if(!param.nsrsbh){
			this.setState({visibleLogin:true})
            return message.error('未认证！')
		}
		this.setState({loading:true})
		applyClickInvoice(param).then(res=>{
			if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
				this.setState({visibleLogin:true,loading:false})
				return message.error(res.msg)
			}
			if(res.code=="0000"){
				message.success(res.msg)
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false,visible2:false})
		})
	}
	
	dowmLoad=(data)=>{
		let {jxx:sjlx,sqrq,fplx} = data
		let nsrsbh = this.state.nsrsbh
		this.setState({loading:true})
		downloadClickInvoice({nsrsbh,sjlx, sqrq,fplx}).then(res=>{
			if(res.code=="80009"||res.code=="40303"||res.code=="40403"||res.code=="40301"||res.code=="40409"||(res.msg).includes("会话已失效")){
				this.setState({visibleLogin:true,loading:false})
				return message.error(res.msg)
			}
			if(res.code=="0000"){
				message.success(res.msg)
				try{
					let url = res.resultObj.files[0]
					utils.aDownLoad(url)
				}catch{
					console.log('数据错误')
				}
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}

	render() {
		
		const modal_label = {width:120,textAlignLast:"justify"}
		const modal_style = {display:"flex",justifyContent:"start",alignItems:"center",marginBottom:10}
		const modal_span = {color:'red',verticalAlign:'middle'}
		
		const options2 = [
			{ label: '全部', value: '' },
			{ label: '增值税专用发票', value: '01' },
			{ label: '机动车销售统一发票', value: '03' },
			{ label: '增值税普通发票', value: '04' },
			{ label: '增值税普通发票(电子)', value: '10' },
			{ label: '增值税普通发票(卷票)', value: '11' },
			{ label: '二手车销售统一发票', value: '15' },
			{ label: '海关缴款书', value: '17' },
		];
        
        const columns = [
			{
				title: '序号',
				dataIndex: 'xh',
			},
			{
				title: '申请时间',
				dataIndex: 'sqrq',
			},
			{
				title: '发票类型',
				dataIndex: 'fplx',
			},
			{
				title: '开票（填发）日期起',
				dataIndex: 'kprqq',
            },
            {
				title: '开票（填发）日期止',
				dataIndex: 'kprqz',
			},
			{
				title: '进销项',
				dataIndex: 'jxx',
			},
			{
				title: '处理状态',
				dataIndex: 'clzt',
            },
            {
				title: '下载',
				render:(text,code)=>{
					return <Button type="primary" disabled={code.clzt=="处理完成"?false:true} onClick={()=>this.dowmLoad(code)}>下载</Button>
				}
            },
           
		];
		
		
		
		return (
			<Spin spinning={this.state.loading} size="large" >
				<div className="invoiceDownload_box">
					<div>
						<h2 style={{color: "#666", fontWeight: 700, letterSpacing: "1px",}} >{sessionStorage.getItem("pageTitle")||"发票下载"}</h2>
					</div>
					<Divider />
					
                    <ul className="setLabel">
                        <li>
                            <label htmlFor="MonthChange">申请日期：</label><RangePicker id="MonthChange" placeholder={['开始时间', '结束时间']} onChange={this.onChangeMonth} placeholder="选择所属期" format={['YYYY-MM-DD', 'YYYY-MM-DD']} />
                        </li>
                        <li>
                            <label htmlFor="yc_fpdm">发票类型：</label>
							<Select style={{width:180}} placeholder="请选择发票类型" id="yc_fpdm" onChange={(val)=>this.ycChange(val,"fplxs")} >
								
      							{
									  options2.map((item,index)=>{
									  	return <Option value={item.value} key={index+1}>{item.label}</Option>
									  })
								  }
                            </Select>
                        </li>
                        <li>
                            <label htmlFor="yc_kprq">进销项：</label>
							<Select style={{width:100}} defaultValue={"0"} placeholder="请选择进销项" id="yc_kprq" onChange={(val)=>this.ycChange(val,"sjlxs")} >
								<Option value="0">全部</Option>
      							<Option value="1">进项票</Option>
      							<Option value="2">销项票</Option>
                            </Select>
                        </li>
                        
                        <li>
                            <Button type="primary" style={{marginRight:10}} onClick={this.getData} >查询</Button>
                            <Button type="primary" style={{marginRight:10}} onClick={()=>this.setState({visible2:true})} >申请</Button>
                        </li>
                    </ul>
                    <Table columns={columns} dataSource={this.state.tableList} rowSelection={this.rowSelection}   pagination={{onChange: this.onChangePage, total: this.state.pages}} />

                    <div>
                        <h3 style={{color:"#666",fontWeight:"bold",marginTop:10}}>备注</h3>
                        <ul style={{color:"#999"}}>
                            <li>1、同一个开票月份的每一类发票每24小时申请下载请求不超过1次；</li>
                            <li>2、当前开票月份的每一类发票最多申请5次发票下载，历史开票月份的每一类发票每30天可申请一次发票下载；</li>
                            <li>3、提交的申请会在24小时内被处理；</li>
                            <li>4、可以下载7天内申请且处理完成的发票和海关缴款书。</li>
                        </ul>
                    </div>
					{
						this.state.visibleLogin?(
						<Modal title="登录认证"  visible={this.state.visibleLogin} onOk={this.clickLogin} onCancel={this.handleCancel} destroyOnClose={true} >
								<div style={modal_style}>
									<label htmlFor="yc_nsrsbh1" style={modal_label}><span style={modal_span}>*</span>纳税人识别号</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_nsrsbh1" onChange={(e)=>this.userChange(e.target.value,"nsrsbh")} value={this.state.user.nsrsbh} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_name1" style={modal_label}><span style={modal_span}>*</span>盘的证书口令</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_name1" onChange={(e)=>this.userChange(e.target.value,"pin")} value={this.state.user.pin} />
								</div>
								<div style={modal_style}>
									<label htmlFor="yc_psd1" style={modal_label}>二级密码</label>:&nbsp;&nbsp;<Input autoComplete="off" id="yc_psd1" onChange={(e)=>this.userChange(e.target.value,"Passwor")} value={this.state.user.Passwor} />
								</div>
						</Modal>):null
					}
                    
                    {
						this.state.visible2?(
						<Modal title="申请" footer={false}  visible={this.state.visible2} onOk={()=>{}} onCancel={()=>this.setState({visible2:false})} destroyOnClose={true} >
								<MyForm clickOk={this.clickOk}/>
						</Modal>):null
					}
				</div>
				
			</Spin>
		)
	}
}
import React, { Component } from "react";
import "./income.less";
import Footer1 from "../../../components/webfooter1/webFooter1";

import banner from "../../../assets/img/income/banner.png";
import b1 from "../../../assets/img/income/b1.png";
import b2 from "../../../assets/img/income/b2.png";
import b3 from "../../../assets/img/income/b3.png";
import b4 from "../../../assets/img/income/b4.png";
import b5 from "../../../assets/img/income/b5.png";
import b6 from "../../../assets/img/income/b6.png";

import i1 from "../../../assets/img/income/i1.png";
import i2 from "../../../assets/img/income/i2.png";
import i3 from "../../../assets/img/income/i3.png";

import bg1 from "../../../assets/img/income/bg1.png";
import bg2 from "../../../assets/img/income/bg2.png";

export default class Income extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		// 找到锚点
		let anchorElement = document.body;
		// 如果对应id的锚点存在，就跳转到锚点
		if (anchorElement) {
			window.scrollTo(0, anchorElement.offsetTop);
		}
	}

	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
			backgroundPosition: "85% 85%",
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
			backgroundPosition: "90% 90%",
		};
		return (
			<div className="incomeBox">
				<div>
					<img src={banner} alt="" />
				</div>
				<div className="incomeBoxTwo">
					<h3>产品亮点</h3>
					<ul>
						<li>
							<div>票据采集</div>
							<img src={b1} alt="" />
							<div>
								<span>OCR识别</span>
								<span>底账下载</span>
								<span>扫码枪扫码</span>
								<span>发票文件导入</span>
							</div>
						</li>
						<li>
							<div>票据种类</div>
							<img src={b2} alt="" />
							<div>
								<span>专用发票</span>
								<span>普通发票</span>
								<span>电子发票</span>
							</div>
						</li>
						<li>
							<div>发票查验</div>
							<img src={b3} alt="" />
							<div>
								<span>查验提交</span>
								<span>结果查询</span>
							</div>
						</li>
						<li>
							<div>发票认证</div>
							<img src={b4} alt="" />
							<div>
								<span>勾选认证</span>
								<span>认证提交</span>
								<span>查询统计</span>
								<span>清单打印</span>
							</div>
						</li>
						<li>
							<div>电子发票</div>
							<img src={b5} alt="" />
							<div>
								<span>发票处理</span>
								<span>发票重复预警</span>
								<span>提醒</span>
								<span>台账管理</span>
							</div>
						</li>
						<li>
							<div>系统设置</div>
							<img src={b6} alt="" />
							<div>
								<span>进项设置</span>
								<span>发票管理</span>
							</div>
						</li>
					</ul>
				</div>

				<div className="incomeBoxThree">
					<h3>客户收益</h3>
					<ul>
						<li>
							<div>
								<span>发票全周期管理</span>
								<img src={i1} alt="" />
							</div>
							<div>
								开具、收票、报销、入账、抵扣、归档等全生命周期管理
							</div>
						</li>
						<li>
							<div>
								<span>节约成本提升效率</span>
								<img src={i2} alt="" />
							</div>
							<div>
								自动认证、查验识别率高达98%以上，提高了企业工作效率，降低了人工成本
							</div>
						</li>
						<li>
							<div>
								<span>加强管理降低风险</span>
								<img src={i3} alt="" />
							</div>
							<div>
								系统票池内票房真实真实的票证，从根本上杜绝假票
							</div>
						</li>
						<li>
							<div>
								<span>决策分析加强管理</span>
								<img src={i1} alt="" />
							</div>
							<div>
								进项发票进行集中管理，实现财务共享，方便决策者加强管理
							</div>
						</li>
					</ul>
				</div>

				<div className="incomeBoxFour">
					<h3>与其他方案对比</h3>
					<div>
						<div style={bgImg1}>
							<div>中税进项管理</div>
							<ul>
								<li>
									<span></span>支持扫描枪二维码录入；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>发票查验不需要输入验证码；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>可以防止电子发票重复报销；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>支持发票查验数据EXCEL表格导出；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>
									已认证发票状态变化支持批量查询；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>支持抵扣期到期提醒。
								</li>
							</ul>
						</div>
						<div style={bgImg2}>
							<div>传统行业</div>
							<ul>
								<li>
									<span></span>只能手工录入发票信息；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>发票查验需要输入验证码；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>不支持发票报销管理；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>不支持发票查验数据导出；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>
									已认证发票状态变化仅支持单票查询；
								</li>
							</ul>
							<ul>
								<li>
									<span></span>不支持抵扣期到期提醒；
								</li>
							</ul>
						</div>
					</div>
				</div>
				{/* 底部footer1 */}
				<Footer1 />
			</div>
		);
	}
}

import React, { Component } from "react"
import './RemainingTickets.less'
import { Button, Divider, Table, message, Modal,Input, Icon ,Spin } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import { invoiceBuyInfoQuery,queryTaxPlateByNsrsbh } from '../../../api'
import utils from "../../../utils";
import Highlighter from 'react-highlight-words';

const { confirm } = Modal;


export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false,
            tableList: [],
            tableList2: [],
            tableList3: [],
			getDataInfo: {
				fplxdm: '',
				kpzdbs: '', //控税盘编号
                lgqxx: '',
                nsrsbh: '',
				// page: 1,
				// rows: 10
            },
			deviceNo:'', //控税盘编号获取
			fplxdm:[],
			isRoleVisible:false,
			xiangQingAbled:false
		}
	}

	handleFilter = e => {
		if(e.fplxdm =="请选择发票类型")return message.error('发票类型不能为空！')
		if(e.nsrsbh =="")return message.error('纳税人识别号不能为空！')
		this.setState({ getDataInfo: { ...this.state.getDataInfo, ...e } }, () => {
			this.getData()
		})
    }

	componentDidMount() {}

    add = () => {
		queryTaxPlateByNsrsbh().then(res => {
			const { resultObj: tableList2 } = res
			if (tableList2 && tableList2.length !== 0){
				tableList2.forEach((item,index)=>{
					item.key = index+1
				})
				return this.setState({ tableList2, isRoleVisible: true })
			}
			message.error('获取税盘列表失败')
		})
	}

	getData() {
		const param = {
			...this.state.getDataInfo
		}
		this.setState({loading:true})
		invoiceBuyInfoQuery(param).then(res => {
			this.setState({loading:false})
            if(res.code=="0000"){
				message.success(res.msg)
                const {jqbhxl} = res.resultObj
                this.setState({
                    tableList:jqbhxl
                })
            }else{
                message.error(res.msg)
            }
			
		})
	}
	//xq
	xiangQing = (val)=>{
		let list = val.group
		list.forEach(element => {
			let str = element.lgrq
			if(str.length==8){
				let year = str.substr(0,4)
				let month = str.substr(4,2)
				let day = str.substr(6,2)

				let st = `${year}-${month}-${day}`
				element.lgrq = st
			}
		});
		this.setState({
			tableList3:list,
			xiangQingAbled: true,
		})
	}
	handleRoleSubmit =()=>{
		this.setState({
			isRoleVisible: false,
			xiangQingAbled: false,
		})
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={node => {
				this.searchInput = node;
			  }}
			  placeholder={`查询 纳税人名称`}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
			  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon="search"
			  size="small"
			  style={{ width: 90, marginRight: 8 }}
			>
			  查询
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </div>
		),
		filterIcon: filtered => (
		  <Icon type="search" style={{ color: filtered ? '#1890ff' : "#000" }} />
		),
		onFilter: (value, record) =>
		  record[dataIndex]
			.toString()
			.toLowerCase()
			.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
		  if (visible) {
			setTimeout(() => this.searchInput.select());
		  }
		},
		render: text =>
		  this.state.searchedColumn === dataIndex ? (
			<Highlighter
			  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			  searchWords={[this.state.searchText]}
			  autoEscape
			  textToHighlight={text.toString()}
			/>
		  ) : (
			text
		  ),
	  });
	
	  handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	  };
	
	  handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	  };
	


	render() {
		const columns = [
			{
				title: '序号',
				// dataIndex: 'key',
				// key: 'key',
				render: (text, record, index) => (
					<span>
						{index + 1}
					</span>
				),
			},
			{
				title: '编码',
				dataIndex: 'errorcode',
			},
			{
				title: '信息提示',
				dataIndex: 'errormsg',
			},
			{
				title: '税盘编号',
				dataIndex: 'jqbh',
            },
            {
				title: '当前未开发票代码',
				dataIndex: 'dqfpdm',
            },
            {
				title: '当前未开发票号码',
				dataIndex: 'dqfphm',
            },
            {
				title: '总剩余份数',
				dataIndex: 'zsyfs',
			},
			{
				title:'操作',
				dataIndex: 'operation',
				render:(text, record, index) => (
					<div>
                        <Button type="primary" disabled={record.errorcode=='0000'?false:true} onClick={() => { this.xiangQing(record.lgxx) }}>详情</Button>
                    </div>
				),
			}
			
		];
		const columns3 = [
			{
				title: '序号',
				//   dataIndex: 'key',
				//   key: '',
				render: (text, record, index) => (
					<span>
						{index + 1}
					</span>
				),
			},
			{
				title: '发票代码',
				dataIndex: 'fpdm',
				key: 'fpdm',
            },
            {
				title: '起始号码',
				dataIndex: 'qshm',
				key: 'qshm',
            },
            {
				title: '终止号码',
				dataIndex: 'zzhm',
				key: 'zzhm',
            },
            {
				title: '发票份数',
				dataIndex: 'fpfs',
				key: 'fpfs',
            },
            {
				title: '剩余份数',
				dataIndex: 'syfs',
				key: 'syfs',
            },
            {
				title: '日期',
				dataIndex: 'lgrq',
				key: 'lgrq',
			},
			{
				title: '领购人员',
				dataIndex: 'lgry',
				key: 'lgry',
			},
			
			
		];
		
        const selectList2 = [
            {id: '1', name: "有剩余份数号段"},
            {id: '', name: "所有号段"},
        ]
		const formList = [
			{
				type: 'SELECT',
				label: '发票类型代码',
				field: 'fplxdm',
				placeholder: '',
                width: "170px",
                initialValue: '请选择发票类型',
                list: this.state.fplxdm
			},  {
				type: 'INPUT',
				label: '纳税人名称',
				field: 'nsrmc',
                placeholder: '纳税人名称',
                initialValue: this.state.getDataInfo.nsrmc,
				width: "100%",
				disabled:true,
			}, {
				type: 'SELECT',
				label: '领购信息',
				field: 'lgqxx',
                placeholder: '',
                initialValue: '1',
                width: "140px",
                list: selectList2
			},
			
        ]
        
        const columns2 = [
			{
				title: '序号',
				dataIndex: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
				...this.getColumnSearchProps('nsrmc'),
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},
			
        ];

        const tabelRowEvent = record => {
			return {
				onDoubleClick: e => {
					let fplxdm = utils.getFpdm(record.fplxdm.split(','))
					this.setState({ deviceNo: record.jqbh,fplxdm,getDataInfo:{...this.state.getDataInfo,kpzdbs:record.jqbh,nsrsbh:record.nsrsbh,nsrmc:record.nsrmc}, isRoleVisible: false }, () => {
						// this.getData()
					})
				}
			}
		}

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div className="menberManager">
					<div className="menberManager-title">
						<h2>{sessionStorage.getItem("pageTitle")||"库存查询"}</h2>
					</div>
					<Divider />
					<div className="myKsp">
						<div >
							<span>税控盘编号：</span>
							<Button onDoubleClick={() => this.add()}>{this.state.deviceNo === '' ? '双击选择税控盘' : this.state.deviceNo}</Button>
						</div>
						<BaseForm formList={formList} style={{width:400}} filterSubmit={this.handleFilter} resetBtn={false} />
					</div>
					<div>
						<Table columns={columns} dataSource={this.state.tableList}  />
					</div>
					<Modal
						width={820}
						title="选择税控设备"
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={this.handleRoleSubmit}
					>
						<Table columns={columns2} dataSource={this.state.tableList2} onRow={tabelRowEvent} pagination={{hideOnSinglePage:true}} className='cant-select' />
					</Modal>

					<Modal
						width={820}
						title="详情"
						visible={this.state.xiangQingAbled}
						onOk={this.handleRoleSubmit}
						onCancel={this.handleRoleSubmit}
					>
						<Table columns={columns3} dataSource={this.state.tableList3} pagination={{hideOnSinglePage:true}} className='cant-select' />
					</Modal>
				</div>
			</Spin>
		)
	}
}
import React, { Component } from "react"
import { Button,Icon,message,Layout} from 'antd';
import Utils from '../../../utils'
import {selectSign,sign} from '../../../api/index'
import './sendContract.less'

// import PDF from 'react-pdf-js'
// import  Pdf1 from "../../../assets/pdf/05.pdf"

const { Header, Footer, Sider, Content } = Layout;

const dragStyle={
    display: "flex",
    alignItems: "center",
    padding: "5px 20px ",
    backgroundColor: "#1DA57A",
    borderRadius:" 4px",
    color:"#fff",
    cursor: "pointer",
    position:"relative"
}


export default class DistrictManager extends Component {
	constructor(props) {
       
		super(props);
		this.state={
			isCheck:false,
			width:"0px",
			height:"0px",
			page:"02",
            listData:[],
            serial:"",
            selectID:""
		}
        this.pageList=[[],[],[],[],[],[],[],[],[],[],[]]
        this.dragList=[{
            type:"seal",
            pageX:44,
            pageY:66,
            isShow:"none"
        }]
        this.index=0
        this.isMove=false
        this.target=''
        this.order=this.props.location.state.order.serial
        this.imgCss={
            backgroundImage:`url(https://invoicefileservice.bjzsxx.com/ec_contract/2019/12/24/20191224120001887/201912241200018871.jpg)`,
            heigth:300
        }
	}
   componentDidMount(){       
    this.getContract() 
    }

    componentWillUpdate(){
    }
    //组件更新完成
    componentDidUpdate(){
           
    }
    sendContract=()=>{
        const  data=this.dragList.slice(1,this.dragList.length)
        const obj=[],listObj=[];
        data.forEach((item,index)=>{
            const url_picture=[],time_picture=[]
            if(item.type=="seal"){
                url_picture.push({
                    path:"91440300319797090X.png",
                    page:Number(item.page)+1,
                    x:item.pageX.replace("px",""),
                    y:item.pageY.replace("px","")
                    
                })
            }else{
                time_picture.push({
                    path:"91440300319797090X.png",
                    page:Number(item.page)+1,
                    x:item.pageX.replace("px",""),
                    y:item.pageY.replace("px","")
                })   
            }
            obj.push({
                id:item.id,
                url_picture:url_picture,
                time_picture:time_picture
            })       
        })

       const list=this.oldDataRule(obj)
       const param={
         OrderResult:JSON.stringify(list),
         serial:this.order
       }
       sign(param).then(res=>{
          if(res.code===0){
              this.props.history.push({pathname:"/system/allContract"})
          }
       })
    }
    oldDataRule=(oldDataRule)=>{
        let newData = []
        let newObj = []
        oldDataRule.forEach((el, i) => {
        if (!newObj[el.id]) {
            console.log(el)
            newData.push(el);
            newObj[el.id] = true;
        } else {
            newData.forEach(el => {
            if (el.id === oldDataRule[i].id) {
                el.time_picture = el.time_picture.concat(oldDataRule[i].time_picture);
                el.url_picture = el.url_picture.concat(oldDataRule[i].url_picture);
                // el.citys = [...el.citys, ...oldDataRule[i].citys]; // es6语法
            }
            })
        }
        })
        return newData
    }
    //获取合同
    getContract=()=>{
        selectSign({serial:this.order}).then(res=>{
                res.map.list.forEach((item)=>{
                this.setState({
                    listData:[...this.state.listData,{name:item.datailsOperator,
                    check:false,ID:item.datailsId}]
                })
            }) 
        })
    }
	check=(e,id)=>{
		   const {listData}=this.state
		   listData.map((item,index)=>{
			   if(index===e){
				 listData[index].check=!listData[index].check
			   }else{
				listData[index].check=false
			   }
           })
           this.setState({
              selectID:id
           })
		    this.forceUpdate()
	}
	nextClick=(e)=>{

    }
    
    
    //拖拽元素开始
	dragStart=(ev,id)=>{
        if(this.state.selectID===""){
            message.warning("请先选择签署方！")
            return false
        }
        this.target=ev.target
        if(ev.target.parentNode.className==="sign-position"){
            this.isMove=false
        }else{
            this.isMove=true
            
        }
        ev.dataTransfer.setData("Text",ev.target.className.split(" ")[0]);
    }
    //拖拽元素移动中
	dragOver=(e)=>{

		e.preventDefault();
    }
 
    //拖拽元素进入目标区域
	drop=(ev)=>{
        this.page=ev.target.getAttribute("page")
        var data=ev.dataTransfer.getData("Text")
        var left=ev.target.parentNode.parentNode.getBoundingClientRect().left;
        var top=ev.target.parentNode.parentNode.getBoundingClientRect().top;
        var dom=document.getElementsByClassName(data)[0]

        if(!this.isMove && dom){
            this.dragList.push({
                type:data,
                pageY:(ev.pageY-top-(dom.clientHeight/2))+"px",
                pageX:(ev.pageX-left-(dom.clientWidth/2))+"px",
                isShow:"block",
                page:this.page,
                id:this.state.selectID
            })
            this.pageList[this.page].push({
                type:data,
                pageY:(ev.pageY-top-(dom.clientHeight/2))+"px",
                pageX:(ev.pageX-left-(dom.clientWidth/2))+"px",
                isShow:"block",
                page:this.page,
                id:this.state.selectID
            })
        }else if(dom){
            var move=this.target
            move.style.position="absolute"
            move.style.top=(ev.pageY-top-(dom.clientHeight/2))+"px"
            move.style.left=(ev.pageX-left-(dom.clientWidth/2))+"px"
        }
        this.forceUpdate()
    }
    onMouseOver=(e)=>{
        
    }
    del=(index,id)=>{
        this.pageList[index].splice(id,1)
        this.forceUpdate()
    }
    addDrag=(dom,i)=>{
        if(dom.page===i){
            return dom.map((item,index)=>(
                                                        
                <div  className="seal btn"    draggable="true" style={{position:"absolute",top:item.pageY,left:item.pageX,display:item.isShow}}   onMouseOver={(e)=>{this.onMouseOver(e) }}   onDragStart={(e)=>{this.dragStart(e)}} >
                    <Icon type="edit"/>
                    <span>
                        {item.type==="seal"?"签章位置":"日期位置"}
                    </span> 
                    <Icon type="close-circle" onClick={(e)=>{this.del(index)}}  style={{position:"absolute",top:-14,right:-14,color:"#1DA57A",display:item.isShow}} />
                </div>
          ))
        }else{
            return false
        }
    }
	render() {
		return (
			<div className="send-contract" >
                <div className="header" >
                    <div className="send-contract-title">
                        <h2>劳动合同</h2> <Button type="primary" onClick={()=>{this.sendContract()}}>签署并发送</Button>
                    </div>
                </div>
				<div className="body">
                    <div className="left">
                    <div className="aside-step1">
                          <div className="aside-title">
							  <h1>第一步: <span>选择签署方</span></h1>
							  <div className='list'>
                                  {
									  this.state.listData.map((item,index)=>(
									  <div key={index} onClick={()=>{this.check(index,item.ID)}}><p>{item.name}</p><span style={{display:item.check?"block":"none"}}> <Icon type="check"  /> </span></div>
									  ))
								  }
							  </div>
						  </div>
						 
                          </div>
                          <div className="aside-step2">
                            <div className="aside-title">
                                <h1>第二步: <span>选择签章拖动到选定位置</span></h1>
                            </div>
                            <div className="aside-content" >
                                <div className="sign-position">
                                    <div  className="seal btn" draggable="true" style={dragStyle} onDragStart={(e)=>{this.dragStart(e)}} >
                                        <Icon type="edit"/>
                                        <span>
                                             签章位置
                                        </span> 
                                        <Icon type="close-circle" onClick={(e)=>{this.del(e)}}  style={{position:"absolute",top:-14,right:-14,color:"#1DA57A",display:"none"}} />
                                    </div>
                                    
                                </div>
                                <div className="sign-position">
                                    <div className="date btn" draggable="true" style={dragStyle}  onDragStart={(e)=>{this.dragStart(e)}}>
                                        <Icon type="schedule"/>
                                        <span>
                                        日期位置
                                        </span>
                                        <Icon type="close-circle" onClick={(e)=>{this.del(e)}}  style={{position:"absolute",top:-14,right:-14,color:"#1DA57A",display:"none"}} /> 
                                    </div>
                                    
                                </div>
                            </div>
                          </div>
                    </div>
                    <div className="center">
                              <ul>
                                  {
                                      this.pageList.map((item,index)=>(
                                          <li key={index} > 
                                              <a name={index} onDrop={(e)=>{this.drop(e)}} onDragOver={(e)=>{this.dragOver(e)}} >
                                                  
                                                  <img page={index} src={'https://invoicefileservice.bjzsxx.com/ec_contract/2019/12/24/20191224120001887/20191224120001887/'+index+'.jpg'} alt=""/>
                                              </a>
                                              {  
                                                item.map((dom,i)=>(
                                                        
                                                <div  className="seal btn"  key={i}   draggable="true" style={{position:"absolute",top:dom.pageY,left:dom.pageX,display:dom.isShow}}   onMouseOver={(e)=>{this.onMouseOver(e) }}   onDragStart={(e)=>{this.dragStart(e)}} >
                                                    <Icon type="edit"/>
                                                    <span>
                                                        {item.type==="seal"?"签章位置":"日期位置"}
                                                    </span> 
                                                    <Icon type="close-circle" onClick={(e)=>{this.del(index,i)}}  style={{position:"absolute",top:-14,right:-14,color:"#1DA57A",display:dom.isShow}} />
                                                </div>
                                                ))
                                              }

                                          </li>
                                      ))
                                  }
                              </ul>
					</div>
                    <div className="right">
                        <div className="title">
						   文档
						</div>
						<div className="doc">
							<p>劳动合同</p><span><Icon type="down" /> </span>
						</div>
						<p className='page'>页数:18页</p>
						<ul className="pageList">
                             {
                                this.pageList.map((item,index)=>(
                                    <li  key={index}> 
                                        <a href={'#'+index}>
                                            <img src={'https://invoicefileservice.bjzsxx.com/ec_contract/2019/12/24/20191224120001887/20191224120001887/'+index+'.jpg'} alt=""/>
                                        </a>
                                    </li>
                                ))
                            }
						</ul>
                    </div>
                </div>
			    

			</div>

        
		)
	}
}
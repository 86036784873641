import React, { Component } from "react";
import { Input, message } from "antd";
import { officialLogin, verificationCode } from "../../../api/index";
import store from "../../../store";
import action from "../../../actions/action";
// import myJs from 'crypto-js';
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
// import PropsTyoe from "./propsType";

import "./login.less";

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: "pwd",
			form: {
				loginName: "",
				password: "",
				code: "",
			},
			identifyCode: "",
			open: true,
		};
	}
	componentDidMount() {
		this.getCode();
	}

	toggle = (active) => {
		this.setState({
			active
		});
	};
	go = () => {
		this.props.history.push("/web/register");
	};
	commit = () => {
		let param = {...this.state.form};

		param.loginName = Base64.stringify(Utf8.parse(param.loginName))
		param.password = Base64.stringify(Utf8.parse(param.password))
		
		officialLogin(param).then((res) => {
			if (res.code === "0000") {
				message.success(res.msg);

				store.dispatch(action("userName", res.resultObj.userName));
				store.dispatch(action("firstLogin", res.resultObj.firstLogin));
				store.dispatch(action("lastUpdateTime", res.resultObj.lastUpdateTime));
				store.dispatch(action("isLogin", true));

				sessionStorage.setItem("token", res.resultObj.token);
				sessionStorage.setItem("registeredNo", res.resultObj.registNo);
				localStorage.setItem("userID", res.resultObj.memberId);

				this.props.history.push("/system/desk");
			} else if(res.code=="9907") {
				message.error(res.msg);
				this.getCode();
			}else{
				message.error(res.msg);
			}
		});
	};
	
	updateData = (e, key) => {
		let obj = {};
		obj[key] = e.target.value;
		this.setState({ form: { ...this.state.form, ...obj } });
	};

	onkeydown = (e) => {
		if (e.keyCode === 13) {
			this.commit();
		}
	};

	getCode = () => {
		if (!this.state.open) return message.warning("请不要频繁刷新验证码！！！");
		this.setState({form:{...this.state.form,code:""}})
		this.setState({ open: false }, () => {
			//操作
			verificationCode().then(res=>{
				if(res.code=="0000"){
					let identifyCode = "data:image/jpg;base64,"+res.resultObj
					this.setState({identifyCode},()=>{
						let timeId = setTimeout(() => {
							this.setState({ open: true }, () => {
								clearTimeout(timeId);
							});
						}, 500);
					})
				}else{
					return message.error(res.msg)
				}
			})
			
		});
	};

	render() {
		
		return (
            <div className="web-login">
				<div className="web-content">
                      <div className="login-box">
						  <div className="login-type">
                             <span onClick={()=>this.toggle("pwd")}   style={{color:this.state.active==="pwd"?"#333":"#ddd",}}>密码登录</span>
						  </div>
						  <div className="login-pwd" style={{display:this.state.active==="pwd"?"block":"none"}}  onKeyDown={(e)=>this.onkeydown(e)}>
							  <Input type="text" placeholder='请输入登录名' autoComplete="off" value={this.state.form.loginName} onChange={(e)=>{this.updateData(e,'loginName')}} />
							  <Input type="password" placeholder='请输入密码' autoComplete="off" value={this.state.form.password} onChange={(e)=>{this.updateData(e,'password')}} />
							  <div>
							  	<Input type="text" placeholder='请输入验证码' autoComplete="off" value={this.state.form.code} onChange={(e)=>{this.updateData(e,'code')}} />
								<div>
									<img src={this.state.identifyCode} onClick={this.getCode} title="点击刷新" alt="验证码"/>
							  		{/* <PropsTyoe identifyCode={(this.state.identifyCode).toString()} onClick={this.getCode} /> */}
								</div>
							  </div>
						  </div>
						  <div className="login-commit" onClick={()=>{this.commit()}} >
		                        <span >登录</span>
						  </div>
					  </div>
				</div>
			</div>
		)
	}
}



import React, { Component } from "react"
import { Button, TreeSelect, Divider, Layout, Table, message, Modal,Pagination } from 'antd';
import { userGet, delUser, addUser, updateUser, roleGet, detailUser, querySysEnterpriseList,getRolesByUser,exportUserList} from '../../../api/index'
import Verification from '../../../utils/verification'
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
import utils from '../../../utils'
import './MemberManager.less'
const { Sider, Content } = Layout;
const { TreeNode } = TreeSelect;

let list = []	
let list2 = []	
let roleData = []
let companyList = []

const addUserData = [
	{
		type: 'AUTOSELECT',
		label: '公司名称',
		field: 'entNo',
		placeholder: '请选择公司名称',
		width: "100%",
		initialValue: '',
		list:companyList,
		// required:true
	},
	{
		type: 'INPUT',
		label: '登录名',
		field: 'loginName',
		placeholder: '请输入登录名',
		width: "100%",
		initialValue: '',
	},
	
	{
		type: 'INPUT',
		label: '用户名',
		field: 'userName',
		placeholder: '请输入用户名',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'SELECT',
		label: '角色',
		field: 'roleUuid',
		width: "100%",
		initialValue: '',
		placeholder: '请选择角色',
		list: roleData
	},
	{
		type: 'INPUT',
		label: '手机号',
		field: 'userPhone',
		placeholder: '请输入手机号',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '身份证号码',
		field: 'idCard',
		placeholder: '请输入身份证号码',
		width: "100%",
		initialValue: '',
	},
	{
		type: 'INPUT',
		label: '密码',
		field: 'password',
		placeholder: '请输入密码',
		width: "100%",
		initialValue: '',
	},
	

]
export default class MemberManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			isRoleVisible: false,
			flag: false,
			tableList: [],
			//用户数据
			addData: {
				loginName: "",
				userName: "",
				roleUuid: "",
				userPhone: "",
				idCard: "",
				password: "",
				id: "",
				uuid: "",
				entNo:"",
			},
			//企业id
			orgNo:"",
			// companyName:'',
			//修改回显数据
			updateUserData: [],
			myTitle: '',
			pages:10,
			eKey:{},
			current:1
		}
		//表格数据
		this.tableList = []


		//角色列表
		// this.roleData = []
		//公司列表
		// this.companyName = []
		//查询数据
		this.param = {
			loginName: "",
			userName: "",
			userPhone: "",
			page: { pageNo: 1}
		}

	}

	componentWillUnmount(){
		 list.splice(0,list.length)	
		 list2.splice(0,list2.length)	
		 roleData.splice(0,roleData.length)
		 companyList.splice(0,companyList.length)
	}

	componentDidMount() {
		this.startTable(this.param)	
		getRolesByUser().then(res=>{
			if (res.code === "0000") {
				res.resultList.map((item, index) => {
					if(!list.includes(item.uuid)){
						list.push(item.uuid)
						roleData.push({
							id: item.uuid,
							uid: item.uuid,
							name: item.roleName
						})
					}
				})
			}
		})
		this.getData()

	}
	//获取公司列表
	getData = () => {
		querySysEnterpriseList({}).then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				tableList.forEach((item, index) => {
					if(!list2.includes(item.id)){
						list2.push(item.id)
						companyList.push({
							id:item.id,
							// uid:item.orgNo,
							name:item.companyName,
						})
					}
				})
				// console.log("companyList",companyList)
			}
		})
	}
	
	// 用户列表
	startTable = (e) => {
		userGet(e).then(res => {
			if (res.code === "0000") {
				let tableList = []
				// let page = Math.ceil(res.pager.totalCount/10)
				res.resultList.map((item, index) => {
					const dom = {
						key: item.id,
						loginName: item.loginName,
						userName: item.userName,
						roleName: item.roleName,
						userPhone: item.userPhone,
						idCard: item.idCard,
						remark: item.remark,
						idCard: item.idCard,
						companyName:item.companyName,
						createBy: item.createBy,
						status: utils.contractType(item.status),
						id: item.id,
						orgNo:item.orgNo
					}
					tableList.push(dom)
				})
				this.setState({
					tableList,
					pages: res.pager.totalCount,
				})
				// console.log('页码数',this.state.pages)
			}
		})
	}
	onChange = value => {
		this.setState({ value });
	};

	//分页管理
	onChangePage=page=>{
		// console.log('分页吗',page)
		this.param.page.pageNo = page
		this.startTable(this.param)
		this.setState({current:page})
	}
	showTotal = (total)=>{
		return `共${total}条`
	}

	//条件查询
	handleFilter = (e) => {
		let param = {...e,page:{pageNo:1}}
		this.param = param
		this.startTable(param)
		this.setState({current:1})
	}

	del = (e) => {
		delUser({ id: e.key }).then(res => {
			if (res.code === "0000") {
				message.success(res.msg)
				this.startTable(this.param)
			}
		})
	}


	add = () => {
		addUserData.forEach(item=>{
			item.initialValue = null
		})
		
		this.state.updateUserData.forEach(res => {
			res.initialValue = ''
		})

		this.setState({
			isRoleVisible: true,
			flag: false,
			myTitle: '添加成员',
		})
	}

	//表单数据修改
	addUserDataBack = (e) => {
		if(e.password){
			e.password = this.$md5(e.password)
		}
		// console.log(e)
		for (let key in e) {
			this.state.addData[key] = e[key]
		}
	
	}

	handleRoleSubmit = () => {
		let param = this.state.addData
		if (this.state.flag) {
			let obj = {
				...param,
			}
			
			updateUser(obj).then(res => {
				if (res.code === "0000") {
					this.startTable(this.param)
					message.success(res.msg)
					this.setState({
						isRoleVisible: false
					})
				} else {
					message.warning(res.msg)
				}
			})
		} else {
			// param.password = this.$md5(param.password)
			const inputList = [{ loginName: "登录名" }, { userName: "用户名" }, { roleUuid: "角色" }, { userPhone: "手机号" }, { entNo: "公司名称" }];
			let open = this.isEmpty(inputList)
			if(open !== undefined)return
			
			addUser(param).then(res => {
				if (res.code === "0000") {
					this.startTable(this.param)
					message.success(res.msg)
					this.setState({
						isRoleVisible: false
					})
				} else {
					message.error(res.msg)
				}
			})
		}

	}


	isEmpty = (inputList) => {
		const Ver = new Verification()
		if (Ver.verificationAddInput.call(this, inputList) === false) {
			return false;
		}
	}
	edit = (e) => {
		// console.log('编辑成员',e)
		// return 公司编码id  e.orgNo
		this.state.orgNo = e.orgNo
		this.setState({ myTitle: '编辑成员' })
		this.detailData(e.id)

	}

	detailData = (val) => {
		this.state.updateUserData = addUserData
		detailUser({ id: val }).then(res => {
			if (res.code === "0000") {
				this.state.updateUserData.forEach((item, index) => {
					switch (item.field) {
						case "entNo":
							// console.log("companyName",res.resultObj.orgNo)
							item.initialValue = res.resultObj.entNo ;
							break;
						case "loginName":
							item.initialValue = res.resultObj.loginName ;
							break;
						case "userName":
							item.initialValue = String(res.resultObj.userName);
							break;
						case "userPhone":
							item.initialValue = res.resultObj.userPhone;
							break;
						case "idCard":
							item.initialValue = res.resultObj.idCard;
							break;
						case "roleUuid":
							item.initialValue = res.resultObj.roleUuid;
							break;
					}

				})
				for (let key in res.resultObj) {

					this.state.addData[key] = res.resultObj[key]
				}
				this.setState({
					isRoleVisible: true,
					flag: true
				})
			}
		})
	}
	modalDatas=(e)=>{
		this.state.eKey = e
	}

	downLoad=()=>{
		exportUserList(this.state.eKey).then(res=>{
			utils.downLoad(res,"成员列表")
		})
	}

	render() {
		const columns = [
			{
				title: '序号',
				dataIndex: 'key',
				render:(text,recode,index)=>index+1
			},
			{
				title: '登录名',
				dataIndex: 'loginName',
				key: 'loginName',
			},
			{
				title: '所属企业',
				dataIndex: 'companyName',
				key: 'companyName',
				
			},
			{
				title: '角色名称',
				dataIndex: 'roleName',
				key: 'roleName',
			},
			{
				title: '用户名',
				dataIndex: 'userName',
				key: 'userName',
			},
			{
				title: '用户手机',
				dataIndex: 'userPhone',
				key: 'userPhone',
			},
			{
				title: '用户身份证号码',
				dataIndex: 'idCard',
				key: 'idCard',
			},
			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				render: (text, record) => (
					<div>
						<Button type="primary" style={{ marginRight: 10 }} onClick={() => { this.edit(record) }}>编辑</Button>
						<Button type="danger" onClick={() => { this.del(record) }}>删除</Button>
					</div>
				),
			}
		];
		const formList = [
			{
				type: 'INPUT',
				label: '登录名',
				field: 'loginName',
				placeholder: '请输入登录名',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '用户名',
				field: 'userName',
				placeholder: '请输入用户名',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '手机号',
				field: 'userPhone',
				placeholder: '请输入手机号',
				width: "100%",
			},
			{
				type: 'INPUT',
				label: '所属企业',
				field: 'companyName',
				placeholder: '请输入所属企业',
			},
		]

		return (
			<div className="menberManager">
				<div className="menberManager-title">
					<h2>{sessionStorage.getItem("pageTitle")||"成员管理"}</h2>
				</div>
				<Divider />
				<Layout>
					<Content className="menberManager-centent"  >
						<div className="menberManager-form" style={{ padding: "10px 0" }}>
							<BaseForm formList={formList} filterSubmit={this.handleFilter} modalDatas={this.modalDatas} />
							<Button type="primary" style={{margin:"0 10px 0 50px"}} onClick={() => { this.add() }}>新增</Button>
							<Button type="primary" onClick={() => { this.downLoad() }}>导出</Button>
						</div>
						
						<div className="menberManager-table">
							<Table columns={columns} dataSource={this.state.tableList} 
							pagination={{
								onChange: this.onChangePage, 
								total: this.state.pages,
								showTotal:this.showTotal.bind(this, this.state.pages),
								current:this.state.current
								}} />
						</div>
						{
							this.state.isRoleVisible?(<Modal
								width={700}
								title={this.state.myTitle}
								visible={this.state.isRoleVisible}
								onOk={this.handleRoleSubmit}
								onCancel={() => {
									this.setState({
										isRoleVisible: false
									})
								}}
							>
								<ModalForm formList={this.state.flag ? this.state.updateUserData : addUserData} setWidth={false} modalData={this.addUserDataBack.bind(this)} />
							</Modal>):null
						}
						
					</Content>
				</Layout>

			</div>
		)
	}
}
import React, { Component } from "react"
import './StoreQRcode.less'
import { Button, Divider, Table, message, Modal, Tree, Input, Menu, Dropdown, Select, Spin } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal.fork'
import { selectListPage, getBelongEnterprisesByUser, update, addStore, deleteStore, queryFirstLevelPublicGoodsNo, queryPublicGoodsNoByPage, queryPublicCategoryGoodsLevelList, updateShopSlvBatch,exportShopList,querySysEnterpriseList } from '../../../api'
import { UnorderedListOutlined } from '@ant-design/icons'
import utils from '../../../utils'
import QRCode from 'qrcode.react'



const { TreeNode } = Tree
const { Search } = Input
const { Option } = Select;

const yhzcbsSelectVal = [
    { id: '0', name: '否' },
    { id: '1', name: '是' }
]

const statusList = [
    { id: '1', name: '正常' },
    { id: '2', name: '禁用' }
]

const lslbsSelectVal = [
    { id: '1', name: '出口免税和其他免税优惠政策（免税）' },
    { id: '2', name: '不征增值税（不征税）' },
    { id: '3', name: '普通零税率（0%）' }
]

let slSelectVal = [
    { id: '0.13', name: '13%' },
    { id: '0.09', name: '9%' },
    { id: '0.06', name: '6%' },
    { id: '0.05', name: '5%' },
    { id: '0.03', name: '3%' },
    { id: '0.02', name: '2%' },
    { id: '0.015', name: '1.5%' },
    { id: '0.01', name: '1%' },
    { id: '0.00', name: '0%' },]


let registeredNoList = []
let lis = [];

let addData = [
    {
        type: 'INPUT',
        label: '门店ID',
        field: 'shopId',
        placeholder: '请输门店ID',
        width: "100%",
    },
    {
        type: 'INPUT',
        label: '门店名称',
        field: 'shopName',
        placeholder: '请输入门店名称',
        width: "100%",
    },
    {
        type: 'AUTOSELECT',
        label: '公司名称',
        field: 'registeredNo',
        placeholder: '请输入公司名称',
        width: "100%",
        initialValue: '',
    },
    {
        type: 'INPUT',
        label: '商品名称',
        field: 'commodityName',
        placeholder: '请输入商品名称',
        width: "100%",
        initialValue: ''
    },
    {
        type: 'SELECT',
        label: '税率',
        field: 'slv',
        placeholder: '',
        width: "100%",
        initialValue: '13%',
        list: slSelectVal
    },
    {
        type: 'INPUT',
        label: '商品分类编码',
        field: 'commodityCode',
        placeholder: '请输入商品分类编码',
        width: "100%",
        initialValue: '',
        abled: true,
        inputProps: {
            addonAfter: <UnorderedListOutlined />
        }
    },
    {
        type: 'INPUT',
        label: '商品分类名称',
        field: 'categoryName',
        placeholder: '请输入商品分类名称',
        width: "100%",
        abled: true,
        initialValue: '',
    },
    {
        type: 'SELECT',
        label: '状态',
        field: 'status',
        placeholder: '',
        width: "100%",
        initialValue: '1',
        list: statusList
    },
    {
        type: 'SELECT',
        label: '纳税人类型',
        field: 'taxType',
        placeholder: '',
        width: "100%",
        initialValue: '',
        list: [
            { id: '1', name: '一般纳税人' },
            { id: '2', name: '小规模纳税人' },
        ]
    },
    {
        type: 'SELECT',
        label: '享受优惠政策',
        field: 'yhzcbs',
        placeholder: '请输入享受优惠政策',
        width: "100%",
        initialValue: '',
        list: yhzcbsSelectVal
    }
]

const hasYhzc = [
    {
        type: 'SELECT',
        label: '优惠政策类型',
        field: 'lslbs',
        placeholder: '请输入优惠政策类型',
        width: "100%",
        initialValue: '1',
        list: lslbsSelectVal
    },
]

//存放更新数据/或添加数据
let editOrAddFormData = {}


export default class StoreQRcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showEditOrAddDialog: false,
            tableList: [], //门店数据
            getDataInfo: {
                id: '', //门店id
                registeredNo: '', //纳税人识别号
                nsrmc: '', //纳税人名称
                page: 1,
                rows: 10
            },
            addData: [],
            resetForm: false,
            showSpbmModal: false,
            treeData: [],
            treeChildrens: [],
            spbmSelectTableData: [],
            showDetails: false,
            myData: "",
            myTitle: '',
            isBlock: false,
            url: '',
            pager: {
                page: 1
            },
            showSl: false,
            //重置税率
            restSls: 0,
            selectedRows: [],
            loading: false,
            countObj: {},
            //长度
            myLength: 10,
            eKey:{},
            totalModal:0,
            pageModal:1,
            current:1,
            goodsNo:'',
            name:"",
            list2:[]
        }
    }

   


    //输入时获取数据
    getEditOrAddData = e => {
        if (!e.slv) { return false }
        for (let item in e) {
            editOrAddFormData[item] = e[item]
        }
        this.changeEditOrAddForm(e)
    }

    changeEditOrAddForm = data => {
        const hasFindedLslbs = addData.find(item => item.field === 'lslbs')
        if (data.yhzcbs === '1' && !hasFindedLslbs) {
            hasYhzc.forEach(item => {
                addData.push(item)
            })
        } else if (data.yhzcbs === '0' && hasFindedLslbs) {
            addData.splice(addData.length - 1, 1)
        }
    }
    componentDidMount() {
        getBelongEnterprisesByUser().then(res => {
            try{
                if(res.code!=="0000"){
                    return message.error(res.msg)
                }
                for (let item of res.resultObj.kids) {
                    let companyName = item.companyName
                    if (!lis.includes(companyName)) {
                        lis.push(companyName)
                        registeredNoList.push({
                            id: item.registeredNo,
                            name: companyName
                        })
                    }
                }
            }catch{
                console.log("出错了")
            }
        })
    }
    componentWillMount() {
        let urlLocation = window.location.href
        let url = ''
        if (urlLocation.includes(process.env.REACT_APP_SEARCH)) {
            url = process.env.REACT_APP_BASEURL+process.env.REACT_APP_SEARCH+'/#/Billing'
        } else {
            url = process.env.REACT_APP_BASEURL+process.env.REACT_APP_SEARCH_NO+'/#/Billing'
        }
        this.setState({
            url
        }, () => {
            this.getData()
            this.getList()
        })
    }

    componentWillUnmount() {
        registeredNoList.splice(0, registeredNoList.length)
        lis.splice(0, lis.length)
    }

     //条件查询数据
     handleFilter = e => {
         this.setState({pager: {page: 1}},()=>{
            this.getData()
            this.setState({current:1})
        })
    }

    // 初始化数据函数
    getData=()=> {
        this.setState({ loading: true })
        let param = {
            ...this.state.eKey,
            ...this.state.pager
        }
        selectListPage(param).then(res => {
            if (res.code === '0000') {
                let { resultList: tableList } = res
                tableList.forEach((item, index) => {
                    item.slv = Number(item.slv) * 100 + '%';
                    item.key = index + 1
                })
                this.setState({
                    tableList,
                    total: res.pager.totalCount,
                })
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    getList = () => {
		let list2 = []
		querySysEnterpriseList().then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				tableList.forEach((item, index) => {
					list2.push({
						id: item.registeredNo,
						name: item.companyName,
					})
				})
                addData[2].list = list2
			}
		})
	}

    setPage = (val) => {
        this.state.pager.page = val
        this.getData()
        this.setState({current:val})
    }

    // 编辑入口
    storeEdit = data => {
        this.resetEditOrAddFormData()
        this.changeEditOrAddForm(data)
        this.setState({ showEditOrAddDialog: true,myTitle:""})
        for (let key in data) {
            addData.forEach((item, index) => {
                if (item.field == key) {
                    editOrAddFormData[key] = data[key]
                    item.initialValue = data[key]
                }

            })
        }
        editOrAddFormData.id = data.id
        // this.forceUpdate()
    }


    //详情 details
    details = (myData) => {
        this.setState({ myData, showDetails: true })
    }
    handleOk = e => {
        this.setState({
            showDetails: false,
        });
    };

    //删除
    del = data => {
        const id = data.id
        this.setState({ loading: true })
        deleteStore({ id }).then(res => {
            if (res.code === '0000') {
                message.success(res.msg)
                this.getData()
                return
            }
            message.error(res.msg)
            this.setState({ loading: false })
        })
    }

    //新增数据
    addNew = (title) => {
        this.resetEditOrAddFormData()
        this.setState({
            showEditOrAddDialog: true,
            myTitle: title
        })
    }

    enterEditOrAdd = () => {
        let param = {...editOrAddFormData}
        
        if (param.lslbs == undefined) {
            param.lslbs = ""
        }
        if (param.yhzcbs == "1") {
            param.slv = 0
        } else {
            param.lslbs = ""
        }
        if (typeof param.slv == "string" && (param.slv).includes("%")) {
            param.slv = (Number(param.slv.split('%')[0]) / 100).toString()
        }
   
        this.setState({ loading: true })
        if (this.state.myTitle == 1) {
            addStore(param).then(res => {
                if (res.code === "0000") {
                    message.success(res.msg)
                    this.setState({ showEditOrAddDialog: false })
                    this.getData()
                } else {
                    message.error(res.msg)
                }
                this.setState({ loading: false })
            })
        } else {
            // if (this.state.countObj.categoryName) {
            //     param = { ...param, ...this.state.countObj }
            // }
            update(param).then(res => {
                if (res.code === "0000") {
                    message.success(res.msg)
                    this.setState({ showEditOrAddDialog: false })
                    this.getData()
                } else {
                    message.error(res.msg)
                }
                this.setState({ loading: false, countObj: {} })
            })
        }
    }

    resetEditOrAddFormData = () => {
        addData.forEach((item, index, arr) => {
            switch (item.field) {
                case 'slv':
                    item.initialValue = '13%'
                    break
                case 'yhzcbs':
                    item.initialValue = '0'
                    break
                case 'lslbs':
                    item.initialValue = '1'
                    break
                default:
                    item.initialValue = ''
                    break
            }

        })
        for (let key in editOrAddFormData) {
             editOrAddFormData[key]=""
        }
        this.setState({ resetForm: true,countObj:{}})

    }

    showSelect = () => {
        this.setState({ loading: true })
        queryFirstLevelPublicGoodsNo().then(res => {
            if (res.code === '0000') {
                let { resultList: treeData } = res
                treeData.forEach((item) => {
                    item.title = item.mc
                    item.key = item.bm
                })
                this.setState({
                    treeData
                })
            } else {
                message.error(res.msg)
            }
            this.setState({
                showSpbmModal: true,
                loading: false
            })
        })
    }

    queryTreeChilrenNode = param => {
        let childrenNode = []
        return queryPublicCategoryGoodsLevelList(param).then(res => {
            if (res.code === '0000') {
                let { resultList } = res
                childrenNode = resultList
            } else {
                message.warning('该分类下没有子节点')
            }
            return childrenNode
        })
    }
    onLoadData = treeNode => {
        const { bm } = treeNode.props
        const param = { goodsNo: bm }
        const { treeData } = this.state;
        treeData.forEach(item => {
            item.key = item.bm
            item.isLeaf = false
        })
        this.setState({
            treeData: [...treeData],
        });

        return new Promise(resolve => {
            if (treeNode.props.children) {
                resolve();
                return;
            }
            this.queryTreeChilrenNode(param).then(children => {
                let treeChildrens
                if (children && children.length !== 0) {
                    treeChildrens = children.map(item => {
                        let obj = { ...item }
                        obj.title = item.mc
                        obj.key = item.bm
                        return obj
                    })
                } else {
                    resolve();
                    return;
                }
                setTimeout(() => {
                    treeNode.props.dataRef.children = treeChildrens;
                    this.setState({
                        treeData: [...this.state.treeData],
                    });
                    resolve();
                }, 10);
            })

        })
    };

    renderTreeNodes = data =>data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} dataRef={item} />;
        });
    
    setPageModal=(val)=>{
        this.state.pageModal = val
        this.getTabelData()
    }

    getTabelData = (val,key=1) => {
        if(Array.isArray(val)&&val&&key!=2){
            this.state.name=""
            this.state.goodsNo = val[0]
            this.state.pageModal = 1
        }else if(!Array.isArray(val)&&val&&key==2){
            this.state.name=val
            this.state.goodsNo = ""
            this.state.pageModal = 1
        }
        const param = {
            name: this.state.name,
            goodsNo: this.state.goodsNo,
            page: this.state.pageModal,
        }
        queryPublicGoodsNoByPage(param).then(res => {

            if (res.code === '0000') {
                const { resultList: spbmSelectTableData, pager:{totalCount:totalModal}} = res
                try{
                    if(Array.isArray(spbmSelectTableData)&&spbmSelectTableData.length>0){
                        spbmSelectTableData.forEach((item,index)=>{
                            item.key = index+1
                        })
                    }
                }catch{}
                this.setState({ spbmSelectTableData ,totalModal})
            }
        })
    }



    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let rows = selectedRows.map(item => {
                return item.id
            })
            this.setState({
                selectedRows: [...rows]
            })
        },
    };


    restSelectSl = () => {

        // let shopIds = (this.state.selectedRows).join(',')
        // let slv = this.state.restSls
        let param = {
            shopIds: (this.state.selectedRows).join(','),
            slv: this.state.restSls
        }
        if (!param.shopIds) {
            return message.error('未选择门店')
        }
        if (!param.slv) {
            return message.error('未选税率')
        }

        updateShopSlvBatch(param).then(res => {
            if (res.code == "0000") {
                this.setState({
                    pager: { page: 1 }
                }, () => {
                    this.getData()
                })
            }
        })
        this.setState({ showSl: false })
    }
    //选中改变的税率
    handleChange = (restSls) => {
        this.setState({
            restSls: String(restSls)
        })
    }

    modalDatas=(eKey)=>{
		this.state.eKey = eKey
	}

    downLoad=()=>{
		exportShopList(this.state.eKey).then(res=>{
            utils.downLoad(res,"门店列表")
		})
    }
    
    ClickDownLoad=()=>{
        try{
            let Qr=document.getElementById('mdQRCode');   
            let image = new Image();
            image.src = Qr.toDataURL("image/png");
            utils.aDownLoad(image.src,this.state.myData.shopId)
        }catch{
            console.log('开小差了！')
        }
    }  


    render() {

        const formList = [

            {
                type: 'INPUT',
                label: '门店名称',
                field: 'shopName',
                placeholder: '门店名称',
                width: "100%",
            },
            {
                type: 'INPUT',
                label: '企业名称/识别号',
                field: 'registeredNo',
                placeholder: '企业名称/识别号',
                width: "100%",
            },
            {
                type: 'SELECT',
                label: '纳税人类型',
                field: 'taxType',
                placeholder: '',
                width: "110px",
                initialValue: '',
                list: [
                    { id: '', name: '全部' },
                    { id: '1', name: ' 一般纳税人' },
                    { id: '2', name: '小规模纳税人' }
                ]
            },
            {
                type: 'SELECT',
                label: '状态',
                field: 'status',
                placeholder: '',
                width: "100%",
                initialValue: '',
                list: [
                    { id: '', name: '全部' },
                    { id: '1', name: '正常' },
                    { id: '2', name: '禁用' }
                ]
            },
        ]

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                width: 50,
            },
            {
                title: '门店ID',
                dataIndex: 'shopId',
                width: 100,
            },
            {
                title: '门店名称',
                dataIndex: 'shopName',
            },
            {
                title: '纳税人类型',
                dataIndex: 'taxType',
                render: (text, record, index) => (
                    <span>
                        {text == 1 ? "一般纳税人" : "小规模纳税人"}
                    </span>
                ),
                width: 120,
            },
            {
                title: '企业名称',
                dataIndex: 'companyName',
                width:200
            },
            {
                title: '纳税人识别号',
                dataIndex: 'registeredNo',
            },
            {
                title: '商品名称',
                dataIndex: 'commodityName'
            },
            {
                title: '商品编码',
                dataIndex: 'commodityCode'
            },
            {
                title: '税率',
                dataIndex: 'slv',
                width: '70px'
            },
            {
                title: '商品分类名称',
                dataIndex: 'categoryName',
            },
            {
                title: '状态',
                dataIndex: 'status',
                width: '60px',
                render: (text, record, index) => (
                    <span>
                        {record.status == 1 ? "正常" : "禁用"}
                    </span>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                width: '250px',
                fixed: 'right',
                render: (text, record) => (
                    <div>
                        <Button type="primary" style={{ marginRight: "10px" }} onClick={() => { this.details(record) }}>详情</Button>
                        <Button type="primary" style={{ marginRight: "10px" }} onClick={() => { this.storeEdit(record) }}>编辑</Button>
                        <Button type="danger" onClick={() => { this.del(record) }}>删除</Button>
                    </div>
                ),
            }
        ]

        let spbmSelectTableColumns = [
            {
                title: '序号',
                dataIndex: 'key',
                width:60,
            },
            { 
                title: '编码', 
                dataIndex: 'hbbm',
            },
            { 
                title: '名称', 
                dataIndex: 'mc',
                ellipsis: true,
            },
            { 
                title: '说明', 
                dataIndex: 'sm',
                ellipsis: true,
            },
            { 
                title: '增值税税率', 
                dataIndex: 'slv',
                ellipsis: true,
            },
            { 
                title: '增值税特殊管理', 
                dataIndex: 'zzstsgl',
                ellipsis: true,
            },
            { 
                title: '增值税政策依据', 
                dataIndex: 'zzstsyj',
                ellipsis: true,
            },
            { 
                title: '增值税特殊内容代码', 
                dataIndex: 'zzstsnrdm',
                ellipsis: true,
            },

            { 
                title: '消费税管理', 
                dataIndex: 'xfsgl',
                ellipsis: true,
            },
            { 
                title: '消费税政策依据', 
                dataIndex: 'xfszcyj',
                ellipsis: true,
            },
            { 
                title: '消费税特殊内容代码', 
                dataIndex: 'xfstsnrdm',
                ellipsis: true,
            },
            { 
                title: '关键字', 
                dataIndex: 'gjz',
                ellipsis: true,
            },
            { 
                title: '是否汇总项', 
                dataIndex: 'hzx',
                ellipsis: true,
            },

            { 
                title: '统计局编码或国民行业代码', 
                dataIndex: 'tjjbm',
                ellipsis: true,
            },
            { 
                title: '海关进出口商品品目', 
                dataIndex: 'hgjcksppm',
                ellipsis: true,
            },
            { 
                title: '版本号', 
                dataIndex: 'bbh',
                ellipsis: true,
            },

            { 
                title: '可用状态', 
                dataIndex: 'kyzt',
                ellipsis: true,
            },
            { 
                title: '启用时间', 
                dataIndex: 'qysj',
                ellipsis: true,
            },
            { 
                title: '过渡期截止时间', 
                dataIndex: 'gdqjzsj',
                ellipsis: true,
            },

            { 
                title: '上级商品编码', 
                dataIndex: 'sjbm',
                ellipsis: true,
            },
            { 
                title: '汇总项', 
                dataIndex: 'wj',
                ellipsis: true,
            },
            { 
                title: '是否隐藏', 
                dataIndex: 'ishide',
                ellipsis: true,
            },
            { 
                title: '享受优惠政策',
                dataIndex: 'yhzc',
                ellipsis: true,
            },
            { 
                title: '优惠政策类型', 
                dataIndex: 'yhzcmc',
                ellipsis: true,
            },
            { 
                title: '简称', 
                dataIndex: 'flbmjc',
                ellipsis: true,
            },
            
        ]

        const tabelRowEvent = record => {
            return {
                onDoubleClick: e => {
                    if(record.hzx==='Y'){
                        Modal.warning({
                            title: '提示',
                            content: (
                              <div>
                                  可用状态值为：Y，该状态不可用！！！
                              </div>
                            ),
                            onOk() {},
                          });
                        return
                    }
                    const idx = addData.findIndex(i => i.field === 'commodityCode')
                    addData[idx].initialValue = record.hbbm

                    const ids = addData.findIndex(i => i.field === 'categoryName')
                    addData[ids].initialValue = record.flbmjc

                    editOrAddFormData.commodityCode = record.hbbm
                    editOrAddFormData.categoryName = record.flbmjc

                    // this.state.countObj.commodityCode = record.hbbm
                    // this.state.countObj.categoryName = record.mc
                    this.setState({ showSpbmModal: false })
                    this.forceUpdate()
                }
            }
        }

        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className="menberManager">
                    <div className="menberManager-title">
                        <h2>{sessionStorage.getItem("pageTitle")||"门店管理"}</h2>
                    </div>
                    <Divider />
                    <div className="menberManager-box">
                        <BaseForm formList={formList} filterSubmit={this.handleFilter} modalDatas={this.modalDatas} />
                        <Button type='primary' className="primary3" onClick={() => { this.setState({ showSl: true, restSls: "" }) }}>更改税率</Button>
                        <Button type='primary' style={{marginRight:20}} onClick={this.addNew.bind(this, 1)}>新增</Button>
                        <Button type="primary" onClick={() => { this.downLoad() }}>导出</Button>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={this.state.tableList}
                            // scroll={{ x: 2000, y: 800 }}
                            rowSelection={this.rowSelection}
                            pagination={{
                                onChange: this.setPage,
                                total: this.state.total,
                                current:this.state.current
                            }} />
                    </div>

                    {
                        this.state.showEditOrAddDialog ? (<Modal
                            width={800}
                            title={this.state.myTitle == 1 ? '新增' : '编辑'}
                            visible={this.state.showEditOrAddDialog}
                            onOk={() => {
                                this.enterEditOrAdd()
                            }}
                            onCancel={() => {
                                this.setState({
                                    showEditOrAddDialog: false,
                                    myTitle: ''
                                })
                                this.resetEditOrAddFormData() //取消修改或增加时触发
                            }}
                            getContainer={false}
                            maskClosable={false}
                            destroyOnClose={true}
                        >
                            <ModalForm formList={addData} modalData={this.getEditOrAddData} myLength={this.state.myLength} setWidth={false} resetForm={this.state.resetForm} addonAfterClickEvent={this.showSelect} />
                        </Modal>) : null
                    }

                    {
                    this.state.showSpbmModal ?
                        (<Modal visible={this.state.showSpbmModal} title='税收分类编码管理' onCancel={() => this.setState({ showSpbmModal: false })} width='1200px' className='select-table' getContainer={false} maskClosable={false}>
                        <div className='spbm-container'>
                            <div className='left-tree'>
                                <Tree loadData={this.onLoadData} onSelect={this.getTabelData} height={200} >{this.renderTreeNodes(this.state.treeData)}</Tree>
                            </div>
                            <div className='right-table'>
                                <Search style={{ width: 200, marginBottom: 20 }} onSearch={(e)=>this.getTabelData(e,2)} />
                                <Table className='cant-select' columns={spbmSelectTableColumns} dataSource={this.state.spbmSelectTableData} scroll={{ x: spbmSelectTableColumns.length*180 }}
                                 pagination={{
                                    onChange: this.setPageModal,
                                    total: this.state.totalModal,
                                    current:this.state.pageModal
                                }} onRow={tabelRowEvent} />
                            </div>
                        </div>
                    </Modal>) : null
                    }

                    <Modal
                        title="详情"
                        visible={this.state.showDetails}
                        onOk={this.handleOk}
                        onCancel={this.handleOk}
                        className="xiangqing"
                    >
                        <div>
                            <label htmlFor="id">门店ID：</label><span id="id">{this.state.myData.shopId}</span>
                        </div>
                        <div>
                            <label htmlFor="registeredNo">纳税人识别号：</label><span id="registeredNo">{this.state.myData.registeredNo}</span>
                        </div>
                        <div>
                            <label htmlFor="categoryName">商品名称：</label><span id="categoryName">{this.state.myData.commodityName}</span>
                        </div>
                        <div>
                            <label htmlFor="slv">税率：</label><span id="slv">{this.state.myData.slv}</span>
                        </div>
                        <div>
                            <label htmlFor="commodityCode">商品编码：</label><span id="commodityCode">{this.state.myData.commodityCode}</span>
                        </div>
                        <div>
                            <label htmlFor="commodityName">商品分类名称：</label><span id="commodityName">{this.state.myData.categoryName}</span>
                        </div>
                        <div>
                            <label htmlFor="yhzcbs">享受优惠政策：</label><span id="xsyhzc">{this.state.myData.yhzcbs == 0 ? '否' : '是'}</span>
                        </div>
                        <div style={{ display: this.state.myData.yhzcbs == 0 ? 'none' : 'block' }}>
                            <label htmlFor="yhlx">优惠类型：</label><span id="yhlx">{this.state.myData.lslbs == 1 ? "出口免税和其他免税优惠政策（免税）" :this.state.myData.lslbs == 2 ? "不征增值税（不征税）" : "普通零税率（0%）"}</span>
                        </div>
                        <div>
                            <label htmlFor="erwema" style={{ verticalAlign: 'top' }}>二维码：</label><span id="erwema" >
                                <QRCode id="mdQRCode" title="" level="Q"  value={this.state.url + '?id=' + this.state.myData.id} onClick={this.ClickDownLoad} />
                                {/* imageSettings={{src:require("../../../assets/img/logo/logo.png"),height:50,width:50}} */}
                                {/* <div>{this.state.url + '?id=' + this.state.myData.id}</div> */}
                            </span>
                        </div>
                    </Modal>
                    {
                        this.state.showSl ? (<Modal title="批量更改税率" visible={this.state.showSl} onOk={this.restSelectSl} onCancel={() => { this.setState({ showSl: false }) }}>
                            <div className="mySlSelect">
                                <div>选择税率:</div>
                                <Select defaultValue="请选择税率" style={{ width: 120 }} onChange={this.handleChange}>
                                    {
                                        slSelectVal.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </Modal>) : null
                    }


                </div>
            </Spin>
        )
    }
}
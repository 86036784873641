import React, { Component } from "react"
import './repairInvoice.less'
import { Button, Divider, Table, message, Modal, Input, Icon,Spin } from 'antd';
import BaseForm from '../../../../components/baseForm/baseForm'
import { invoiceRepair, queryTaxPlateByNsrsbh } from '../../../../api'
import utils from "../../../../utils";
import Highlighter from 'react-highlight-words';
import moment from "moment"

const { confirm } = Modal;


export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			tableList2: [], //商户列表
			getDataInfo: {
				fplxdm: '',
				jqbh: '', //控税盘编号
				nsrmc: '',
				nsrsbh: '',
				qsrq: '',
				zzrq: '',
			},
			deviceNo: '', //控税盘编号获取
			fplxdm: [],
			isRoleVisible: false,
		}
	}

	handleFilter = e => {
		this.setState({ getDataInfo: { ...this.state.getDataInfo, ...e } }, () => {
			this.getData()
		})
	}

	getData() {
		const param = { ...this.state.getDataInfo }
		if (param.fplxdm == "请选择发票类型") return message.error('发票类型不能为空！')
		if (!param.nsrsbh) return message.error('纳税人识别号不能为空！')
		if (!param.qsrq) return message.error('起始日期不能为空！')
		if (!param.zzrq) return message.error('终止日期不能为空！')
		param.qsrq = moment(param.qsrq).format("YYYYMMDD")
		param.zzrq = moment(param.zzrq).format("YYYYMMDD")
		let num = (moment(param.zzrq)-moment(param.qsrq))/(24*60*60*1000)
		if(num>180)return message.error('终止日期-起始日期不大于180天！')
		if(num<0)return message.error('日期选取错误！')
		this.setState({loading:true})
		invoiceRepair(param).then(res => {
			this.setState({loading:false})
			if (res.code == "0000") {
				message.success(res.msg)
				this.props.openRepair(true)
			} else {
				message.error(res.msg)
			}

		})
	}

	add = () => {
		queryTaxPlateByNsrsbh().then(res => {
			const { resultObj: tableList2 } = res
			if (tableList2 && tableList2.length !== 0) {
				tableList2.forEach((item, index) => {
					item.key = index + 1
				})
				return this.setState({ tableList2, isRoleVisible: true })
			}
			message.error('获取税盘列表失败')
		})
	}

	handleRoleSubmit = () => {
		this.setState({
			isRoleVisible: false,
		})
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`查询 纳税人名称`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					查询
			</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					重置
			</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type="search" style={{ color: filtered ? '#1890ff' : "#000" }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
					text
				),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};



	render() {
		const columns2 = [
			{
				title: '序号',
				dataIndex: 'key',
			},
			{
				title: '纳税人名称',
				dataIndex: 'nsrmc',
				key: 'nsrmc',
				...this.getColumnSearchProps('nsrmc'),
			},
			{
				title: '纳税人识别号',
				dataIndex: 'nsrsbh',
				key: 'nsrsbh',
			},
			{
				title: '税控盘编号',
				dataIndex: 'jqbh',
				key: 'jqbh',
			},

		];

		const formList = [
			{
				type: 'SELECT',
				label: '发 票 类 型',
				field: 'fplxdm',
				placeholder: '',
				width: "170px",
				initialValue: '请选择发票类型',
				list: this.state.fplxdm
			},
			{
				type: 'INPUT',
				label: '纳税人名称',
				field: 'nsrmc',
				placeholder: '',
				initialValue: this.state.getDataInfo.nsrmc,
				width: "170px",
				disabled: true,
			},
			{
				type: 'DATEDAY',
				label: '起 始 日 期',
				field: 'qsrq',
				placeholder: '请选择起始日期',
				width: '170px',
				initialValue: ''
			},
			{
				type: 'DATEDAY',
				label: '终 止 日 期',
				field: 'zzrq',
				placeholder: '请选择终止日期',
				width: '170px',
				initialValue: ''
			}
		]


		const tabelRowEvent = record => {
			return {
				onDoubleClick: e => {
					let fplxdm = utils.getFpdm(record.fplxdm.split(','))
					this.setState({
						deviceNo: record.jqbh,
						fplxdm,
						getDataInfo: { ...this.state.getDataInfo, jqbh: record.jqbh, nsrsbh: record.nsrsbh,nsrmc: record.nsrmc },
						isRoleVisible: false
					})
				}
			}
		}

		return (
			<Spin spinning={this.state.loading} size="large" >
				<div className="repairInvoice">
					<div className="repairInvoice_box">
						<div >
							<span >税控盘编号：</span>
							<Button onDoubleClick={() => this.add()}>{this.state.deviceNo === '' ? '双击选择税控盘' : this.state.deviceNo}</Button>
						</div>
						<BaseForm formList={formList} style={{ width: 400 }} filterSubmit={this.handleFilter} resetBtn={false} btnName="修复"/>
					</div>
					<Modal
						width={820}
						title="选择税控设备"
						visible={this.state.isRoleVisible}
						onOk={this.handleRoleSubmit}
						onCancel={this.handleRoleSubmit}
					>
						<Table columns={columns2} dataSource={this.state.tableList2} onRow={tabelRowEvent} pagination={{ hideOnSinglePage: true }} />
					</Modal>
				</div>
			</Spin>
		)
	}
}
import React, { Component } from "react"
import { Button,Divider,Table, Modal, Form, Input } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
import ModalForm from '../../../components/modal/modal'
const addData=[
	{
		type: 'SELECT',
		label: '盘组设备名称',
		field: 'userName',
		placeholder: '请选择盘组设备名称',
		width: "100%",
		initialValue:'0',
		list: [
            {id: '0', name: '集中托管开票员'},
            {id: '1', name: '企业开票员'}
        ]
	},
	{
		type: 'INPUT',
		label: '税控盘编号',
		field: 'roleUuid',
		width: "100%",
		initialValue:'',
		placeholder: '请输入税控盘编号',
	},
	{
		type: 'INPUT',
		label: '纳税人识别号',
		field: 'userPhone',
		placeholder: '请输入纳税人识别号',
		width: "100%",
		initialValue:'',
	},
	{
		type: 'INPUT',
		label: '纳税人名称',
		field: 'idCard',
		placeholder: '请输入纳税人名称',
		width: "100%",
		initialValue:'',
	}
]

export default class TaxCopy extends Component {
	constructor(props) {
		super(props);
		this.state={
            tableList: [],
            tableList2: [],
            //修改回显数据
			updateData:[],
			isRoleVisible: false,
			flag:false,
		}
    }
    add=()=>{
		this.state.updateData.forEach(res=>{
			res.initialValue=''
		})
        this.setState({
			isRoleVisible:true,
			flag:false
		})
	}
    handleRoleSubmit=()=>{}
    addUserDataBack=(e)=>{
		// if (Object.keys(e).length !== 0){
		//     for(let key in e){
		// 		this.state.addData[key]=e[key]
		// 	}
		// }else{
		//     return false
		// } 
	}
	render() {
        const columns = [
            {
                title: '序号',
              //   dataIndex: '',
              //   key: '',
                render: (text, record, index) => (
                  <span>
                      {index+1}
                  </span>
                ),
            },
            {
			    title: '税控盘编号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '纳税人名称',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '纳税人识别号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '纳税人性质',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '发票类型',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '类型',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '状态',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '反写结果',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '操作',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '税收起日期',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '税收止日期',
			    dataIndex: 'userName',
			    key: 'userName',
			},
        ];
        const columns2 = [
            {
                title: '序号',
              //   dataIndex: '',
              //   key: '',
                render: (text, record, index) => (
                  <span>
                      {index+1}
                  </span>
                ),
            },
            {
			    title: '纳税人名称',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '纳税人识别号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '税控盘编号',
			    dataIndex: 'userName',
			    key: 'userName',
            },
            {
			    title: '服务地址或域名',
			    dataIndex: 'userName',
			    key: 'userName',
			},
        ];
		const formList = [
            {
                type: 'SELECT',
                label: '盘组设备名称',
                field: 'userName',
                placeholder: '请选择盘组设备名称',
                width: "100%",
                initialValue:'0',
                list: [
                    {id: '0', name: '全部'},
                    {id: '1', name: '通过'},
                    {id: '2', name: '未通过'},
                ]
            },
            {
				type: 'INPUT',
				label: '纳税人识别号',
				field: 'fileName',
				placeholder: '请输入纳税人识别号',
				width: "100%",
            },
            {
				type: 'INPUT',
				label: '抄报月份',
				field: 'fileName',
				placeholder: '请输入抄报月份',
				width: "100%",
            },
            {
                type: 'SELECT',
                label: '汇总反写查询',
                field: 'userName',
                placeholder: '请选择汇总反写查询',
                width: "100%",
                initialValue:'0',
                list: [
                    {id: '0', name: '抄报成功'},
                    {id: '1', name: '抄报失败'},
                    {id: '2', name: '反写成功'},
                    {id: '3', name: '反写失败'},
                ]
            },
		]
		return (
			<div>
				<div>
					<h2>按税种抄报</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0", display: 'flex', alignItems: 'center'}}>   
                    <div style={{marginRight: '20px'}}>
                        <span>税控盘编号：</span>
                        <Button onDoubleClick={() => this.add()}>双击选择税控盘</Button>
                    </div> 
                    <BaseForm formList={formList} filterSubmit={this.handleFilter} />    
                </div>
				<div style={{ background: '#C7E2FF', padding: '6px 12px'}}>
                    <Button type="link" style={{color: '#333333'}}><img src={require('../../../assets/icon/copy.png')} style={{marginRight: '10px'}}/>选择抄报</Button>
                </div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>
                <Modal
                    width={820}
                    title="选择税控设备"
                    visible={this.state.isRoleVisible}
                    onOk={this.handleRoleSubmit}
                    onCancel={()=>{
                        this.setState({
                            isRoleVisible:false
                        })
                    }}
                >
                    <div style={{background: '#C7E2FF'}}>
                        <ModalForm formList={this.state.flag?this.state.updateData:addData} modalData={this.addUserDataBack.bind(this)}/>
                    </div>
                    <p style={{lineHeight: '32px', marginBottom: '0'}}>双击选择税控盘</p>
                    <Table columns={columns2} dataSource={this.state.tableList2} />
                </Modal>
			</div>
		)
	}
}
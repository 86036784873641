const initState = {
	jumpLink: [],
};

export default (state = initState, action) => {
	switch (action.type) {
		case "jumpLink":
			return { ...state, jumpLink: action.text };
		case "clearSystem":
			return { ...state, jumpLink: [] };
		default:
			return state;
	}
};

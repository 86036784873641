import React, { Component } from "react"
import { Button,Divider,Table } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
export default class InvoiceSumQuery extends Component {
	constructor(props) {
		super(props);
		this.state={
		}
	}
	handleFilter=(e)=>{
	}
	render() {
		const formList = [
            {
				type: 'INPUT',
				label: '客户名称',
				field: 'fileName',
				placeholder: '请输入客户名称',
				width: "100%",
			},{
				type: 'INPUT',
				label: '客户税号',
				field: 'fileName',
				placeholder: '请输入客户税号',
				width: "100%",
			},{
				type: 'DATE',
				label: '开票起始日期',
				field: 'date',
				placeholder: '请选择开票起始日期',
				width: "100%",
			},{
				type: 'DATE',
				label: '开票终止日期',
				field: 'date',
				placeholder: '请选择开票终止日期',
				width: "100%",
			},
        ]
        const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
            },
            {
			    title: '客户名称',
			    dataIndex: 'userName',
			    key: 'userName',
			},

			{
			    title: '客户税号',
				dataIndex: 'pan',
				key: 'pan',
			},
			{
			    title: '专用发票（张）',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: '普通发票（张）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '卷式发票（张）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '机动车发票（张）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '二手车发票（张）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '总金额（¥）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '总发票数（张）',
				dataIndex: 'remark',
				key: 'remark',
            },
            {
				title: '总税额（¥）',
				dataIndex: 'remark',
				key: 'remark',
            },
		  ];
		return (
			<div>
				<div>
					<h2>发票汇总查询</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
                <div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>
			</div>
		)
	}
}
import React, { Component } from 'react'
import { Descriptions, Form, Input, Select, message, Modal, Button, Row, Spin, DatePicker } from 'antd'
import { DashOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { queryBuyerInfoByPage, queryGoodsNoByUser, queryEnterpriseInfoByUser, createInvoice, queryInvoiceInfoByFpdmAndFphm, redInfoPaperApply, createNegativeInvoice, queryByPage, gsDataFind,querySysEnterpriseList,queryTaxPlateByPage } from '../../api'
// import ModalForm from '../../components/modal/modal.fork'
import UpFile from "../../components/upload/uploadFile"
import utils from "../../utils"
import SelectTable from '../selectTable/selectTable'
import { SearchOutlined } from '@ant-design/icons'
import './invoice.less'

const { Option } = Select;
const { TextArea } = Input;


export default class Invoice extends Component {
    constructor() {
        super()
        this.state = {
            goodsInfoList: [{ ...this.goodsInfo }],
            ghdwmc: '',
            ghdwsbh: '',
            ghdwdzdh: '',
            ghdwyhzh: '',
            invoiceType: '请选择发票类型',
            tdyslxdm: '',
            kplx: '',
            tspz: '',
            gfkhyx: '',
            gfkhdh: '',
            sf: ['￥0.00'],
            showfmcSelect: false,
            showSpmcSelect: false,
            bdcInfo:{
                region: '',
                realEstateUnitCode: '',
                detailedAddress: '',
                crossCitySign: '1',
                areaUnit: 'm²',
                fwcqzshm: '',
                leaseTerm: '',
                zlqq: '',
                zlqz: '',
                titleNo: '' 
            },
            saleInfo: {
                bz: '',
                companyName: '',
                address: '',
                registeredNo: '',
                bankInfo: '',
                reviewer: '',
                payee: '',
                biller:""
            },
            showPreInvoice: false,
            search: [],
            loading: false,
            faPiao: [],
            showUp:false,
            list2:[],
            showTrue: false,
            listRemove: false,
        }
    }

    componentWillMount(){
        this.getCompanyInfoByUser()
    }

    componentDidMount() {
        this.getList()
    }

    getInvoice=()=>{
        let nsrsbh = this.state.saleInfo.registeredNo
        let param ={
            nsrmc: "",
            nsrsbh,
            page: 1,
            plateNo: "",
            rows: 10,
        }
        // let faPiaos = JSON.parse(sessionStorage.getItem('faPiaoQx')).map(item => {
        //     let key = item
        //     if (key == "004") {
        //         return { id: key, name: '增值税专用发票' }
        //     } else if (key == "007") {
        //         return { id: key, name: '增值税普通发票' }
        //     } else if (key == "025") {
        //         return { id: key, name: '卷式发票' }
        //     }else if (key == "028") {
        //         return { id: key, name: '电子专用发票' }
        //     } else if (key == "026") {
        //         return { id: key, name: '增值税电子发票' }
        //     }
        // })
        let faPiaos = [
            { id: '004', name: '增值税专用发票' },
            { id: '007', name: '增值税普通发票' },
            { id: '025', name: '卷式发票' },
            { id: '026', name: '增值税电子发票' },
            { id: '028', name: '增值税电子专用发票' },
            { id: '81', name: '全电专用发票' },
            { id: '82', name: '全电普通发票' }
        ]
        queryTaxPlateByPage(param).then(res=>{
            if(res.code==="0000"){
                
                if(Array.isArray(res.resultList)&&res.resultList.length>0){
                    res.resultList.forEach(e=>{
                        let faPiaoList = e.fplxdm.split(',').filter(e=>{
                            if(e)return e
                        })
                        let faPiao = faPiaos.filter(e=>{
                            if(faPiaoList.includes(e.id))return e
                        })
                        this.setState({faPiao},()=>{
                            if(faPiaoList.includes('026')){
                                this.setState({invoiceType:"026"})
                            }else{
                                this.setState({invoiceType:"请选择发票类型"})
                            }
                        })
                    })
                }else{
                    this.setState({ faPiao:[],invoiceType: '请选择发票类型',})
                    console.log('出错了')
                }
            }else{
                this.setState({ faPiao:[],invoiceType: '请选择发票类型',})
                console.log('出错了')
            }
        })
    }

    handleChange = (invoiceType) => {
        this.setState({ invoiceType })
    }

    //表单数据控制
    handleFormChange = changedFields => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    fmcData = []
    spmcData = []
    buyClientParam = {
        buyerName: '',
        page: 1,
        rows: 10
    }
    goodsListParam = {
        spmc: '',
        page: 1,
        rows: 10
    }

    goodsInfo = {
        spmc: '', //商品名称
        spbm: '', //商品编码
        spsl: '1', //商品数量
        dw: '', //单位
        dj: '', //单价
        je: '0.00', //金额
        sl: '0%', //税率
        ggxh: '', //规格型号
        hsbz: 0, //含税标志
        lslbs: '', //零税率标识
        yhzcbs: '', //优惠政策标识
        spsm:'',   //分类编码名称
    }

    nowSelectGoodIdx = ''

    slOption = [
        { id: '0.13', name: '13%' },
        { id: '0.09', name: '9%' },
        { id: '0.06', name: '6%' },
        { id: '0.05', name: '5%' },
        { id: '0.03', name: '3%' },
        { id: '0.02', name: '2%' },
        { id: '0.015', name: '1.5%' },
        { id: '0.01', name: '1%' },
        { id: '0.00', name: '0%' }
    ]

    goodsInfoField = ['spmc', 'ggxh', 'spsl', 'dw', 'dj', 'je', 'sl', 'se', <PlusOutlined onClick={() => this.addList()} />]

    getCompanyInfoByUser=()=> {
        queryEnterpriseInfoByUser().then(res => {
            if (res.code === '0000') {
                const { address, contactPhone, registeredNo, companyName, bankName, bankAccount, biller, reviewer, payee } = res.resultObj
                const needSetData = {
                    address: address + ' ' + contactPhone,
                    registeredNo,
                    companyName,
                    bankInfo: bankName + bankAccount,
                    reviewer,
                    payee,
                    biller
                }
                this.setState({ saleInfo: {...this.state.saleInfo, ...needSetData } },()=>{
                    this.getInvoice()
                })
            }
        })
    }
    // 购方信息查询 
    selectClient = () => {
        queryBuyerInfoByPage(this.buyClientParam).then(res => {
            if (res.code === '0000') {
                const { resultList } = res
                this.fmcData = resultList
                this.setState({ showfmcSelect: true })
            } else {
                message.error(res.msg)
            }
        })
    }
    //购方信息搜素
    queryUser = (val) => {
        this.buyClientParam.buyerName = val
        this.selectClient()
    }

    selectGood = idx => {
        queryGoodsNoByUser(this.goodsListParam).then(res => {
            if (res.code === '0000') {
                this.nowSelectGoodIdx = idx
                const { resultList } = res
                resultList.forEach((item,index)=>{
                    item.key = index+1
                })
                this.spmcData = resultList
                this.setState({ showSpmcSelect: true })
            } else {
                message.error(res.msg)
            }
        })
    }
    //查询按商品名
    query = (val) => {
        this.goodsListParam.spmc = val
        this.selectGood(this.nowSelectGoodIdx)
    }

    addList = () => {
        let goodsInfoList = [...this.state.goodsInfoList]
        let list = JSON.parse(JSON.stringify(this.goodsInfo))
        
        goodsInfoList.push(list)
        this.state.sf.push('￥0.00')
        this.setState({ goodsInfoList })
    }

    getInputVal = (key, idx, e) => {
        let goodsInfoList = [...this.state.goodsInfoList]
        goodsInfoList[idx][key] = e instanceof Object ? e.target.value : e

        if (key == 'je') {
            this.setState({ goodsInfoList })
            //计算单价-税额
            this.getNumber(idx)

        } else {
            this.setState({ goodsInfoList })
            this.getSf(idx)
        }

    }
    //金额
    getNumber(idx) {
        let { spsl, dj, je, sl } = this.state.goodsInfoList[idx]
        let mdj = Number(je) / Number(spsl)
        if (typeof sl == "string" && sl.includes("%")) {
            sl = parseFloat(sl) / 100
        }
        this.state.goodsInfoList[idx].dj = mdj

        const computedSe = `￥${(je === '' || sl === '0') ? '0.00' : (je - (je / (1 + sl))).toFixed(2)}`
        let { sf } = this.state
        sf[idx] = computedSe
        return this.setState({ sf })
    }


    removeByIdx = idx => {
        if (this.state.goodsInfoList.length <= 1) return message.error('最少保留一项')
        let goodsInfoList = [...this.state.goodsInfoList]
        goodsInfoList.splice(idx, 1)
        this.state.sf.splice(idx, 1)
        this.setState({ goodsInfoList })
    }

    getGoodsListInfoNodes = () => {
        const nodes = this.goodsInfoField.map((item, index) => {
            return (
                <Descriptions.Item label={this.filterLabelName(item)}>
                    {
                        this.state.goodsInfoList.map((inp, idx) => this.filterNodes(inp, item, idx))
                    }
                </Descriptions.Item>
            )
        })
        return nodes
    }
    //税额
    getSf(idx) {
        let { spsl, dj, je, sl } = this.state.goodsInfoList[idx]
        if (typeof sl == "string" && sl.includes("%")) {
            sl = parseFloat(sl) / 100
        }
        const jes = Number(spsl) * Number(dj)
        this.state.goodsInfoList[idx].je = jes 
        const computedSe = `￥${(je === '' || sl === '0') ? '0.00' : (jes - (jes / (1 + sl))).toFixed(2)}`
        let { sf } = this.state
        sf[idx] = computedSe
        return this.setState({ sf })
    }

    filterNodes = (inp, key, idx) => {
        const defaultValue = this.state.goodsInfoList[idx][key]
        if (typeof key === 'string') {
            if (key !== 'se' && key !== 'sl') return (<Input size='small' type={key == 'spsl' || key == "dj" || key == "je" ? 'number' : 'text'} min={0} value={defaultValue} addonAfter={key === 'spmc' && <DashOutlined onClick={() => this.selectGood(idx)} />} onChange={this.getInputVal.bind(this, key, idx)} />)
            if (key === 'se') return <p className="my_ant-select-sm" >{this.state.sf[idx] || '￥0.00'}</p>
            if (key === 'sl') return (<Select value={defaultValue} size='small' style={{ width: 120 }} onChange={this.getInputVal.bind(this, key, idx)}>{this.slOption.map((item, index) => (<Option key={index} value={Number(item.id)} >{item.name}</Option>))}</Select>)
        } else {
            return <MinusOutlined onClick={() => this.removeByIdx(idx)} className="my_ant-select-sm2" />
        }
    }

    filterLabelName(val) {
        if (typeof val !== 'string') return val
        switch (val) {
            case 'spmc':
                return '货物或应税劳务、服务名称'
            case 'spsl':
                return '商品数量'
            case 'dw':
                return '单位'
            case 'dj':
                return '单价（含税）'
            case 'je':
                return '金额（含税）'
            case 'sl':
                return '税率'
            case 'ggxh':
                return '规格型号'
            case 'se':
                return '税额'
            default:
                return ''
        }
    }

    fmcIsSelect = val => {
        const { buyerName, buyerBankName, buyerBankNo, buyerPhone, buyerAddress, buyerRegiestNo } = val
        const needChangeState = {
            ghdwdzdh: buyerAddress + buyerPhone,
            ghdwmc: buyerName,
            ghdwsbh: buyerRegiestNo,
            ghdwyhzh: buyerBankName + buyerBankNo,
        }

        this.setState({
            ...needChangeState,
            showfmcSelect: false
        })
    }

    spmcIsSelect = val => {
        let goodsInfoList = JSON.parse(JSON.stringify(this.state.goodsInfoList))
        if(!(val.sl).includes("%")){
            val.sl = (parseFloat(val.sl) * 100) + "%"
        }
        let list = goodsInfoList[this.nowSelectGoodIdx]
        for (let key in val) {
            for (let item in list) {
                if (key == item) {
                    list[item] = val[key]
                }
            }
        }
        list.dj = list.dj || 0
        list['je'] = (Number(list['spsl']) * Number(list['dj'])).toString()

        this.setState({
            goodsInfoList: [...goodsInfoList],
            showSpmcSelect: false
        })

        for (let g in goodsInfoList){
            let goods = goodsInfoList[g];
            if(goods.spbm.startsWith('304050')){
                // 不动产租赁
                this.setState({
                    tdyslxdm:'06'
                })
                return 
            }else if (goods.spbm.startsWith("5010299000000000000") ||goods.spbm.startsWith("5030000000000000000")  ){
                // 不动产租赁
                this.setState({
                    tdyslxdm:'05'
                })
                return 
            }
        }
        this.setState({
            tdyslxdm:''
        })
    }
    getList = () => {
		let list2 = []
		querySysEnterpriseList({}).then(res => {
			if (res.code == "0000") {
				let tableList = res.resultList || []
				tableList.forEach((item, index) => {
                    let {biller,payee,reviewer,registeredNo,companyName,bankName,bankAccount,contactPhone} = item
					list2.push({
						registeredNo,
						companyName,
						bankName,
						bankAccount,
						contactAddress: item.address,
                        contactPhone,
                        biller,
                        payee,
                        reviewer
                    })
                })
				this.setState({list2})
			}
		})
    }
    
    onChangeCompanyName=(val)=>{
        let obj = this.state.list2.find(item=>item.registeredNo==val)
        let {bankName,bankAccount,contactAddress,contactPhone}=obj
        let address = contactAddress+" "+contactPhone
        let bankInfo = bankName+bankAccount
        this.setState({saleInfo:{...this.state.saleInfo,...obj,address,bankInfo}},()=>{
            this.getInvoice()
        })
    }


    toCreateInvoice = () => {
        let {biller:kpr,payee:skr,reviewer:fhr,bz,registeredNo:xhdwsbh,address:xhdwdzdh,companyName:xhdwmc,bankInfo:xhdwyhzh} = this.state.saleInfo
        let param = {
            ...this.state,
            bz,
            kpr,
            skr,
            fhr,
            xhdwsbh,
            xhdwdzdh,
            xhdwmc,
            xhdwyhzh
        }

        
        if (param.invoiceType=="请选择发票类型") {
            return message.error('请选择发票类型，此为必选项')
        }
        if (param.gfkhyx === ""&&this.getShowTrue()) {
            return message.error('请填写邮箱号，此为必填项（注意格式）')
        }

        param.goodsInfoList.forEach(item => {
            if (typeof item.sl == "string" && item.sl.includes('%')) {
                item.sl = parseFloat(item.sl) / 100
            }
        })
        let param2 ={

        };
        if(this.state.tdyslxdm == '05'){
            param2.realEstateSale = this.state.bdcInfo;
            if( this.state.bdcInfo.areaUnit === '' ||  this.state.bdcInfo.region === '' || this.state.bdcInfo.detailedAddress === ''   ){
                return message.error('请填写不动产信息，此为必填项（注意格式）')
            }
            param.param2 = JSON.stringify(param2);
        }else if (this.state.tdyslxdm == '06'){
            param2.realEstateRentalService = this.state.bdcInfo;
            if( this.state.bdcInfo.areaUnit === '' ||  this.state.bdcInfo.region === '' || this.state.bdcInfo.detailedAddress === ''   ||
                this.state.bdcInfo.zlqq  === '' || this.state.bdcInfo.zlqz   === '' || this.state.bdcInfo.zlqz.titleNo === ''
             ){
                return message.error('请填写不动产信息，此为必填项（注意格式）')
            }
            param2.leaseTerm = this.state.bdcInfo.zlqq + " " + this.state.bdcInfo.zlqz
            param.param2 = JSON.stringify(param2);
        }

        param.goodsVos = param.goodsInfoList
        this.setState({ loading: true })

        createInvoice(param).then(res => {
            if (res.code === "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    changePfType = invoiceType => {
        this.setState({ invoiceType })
    }

    preInvoice = () => {
        this.setState({ showPreInvoice: true })
    }

    get computedAllTotalMoney() {
        let totalMoney = 0
        for (let item of this.state.goodsInfoList) {
            totalMoney += Number(item['je'])
        }
        // totalMoney -= this.computedTaxMoney
        return totalMoney.toFixed(2)
    }
    get computedTaxMoney() {
        let totalMoney = 0
        for (let item of this.state.sf) {
            try{
                totalMoney += Number(item.split('￥').pop())
            }catch{
                console.log(totalMoney,item)
            }
        }
        return totalMoney.toFixed(2)
    }

    preInvoiceFormData = {}
    getPreInvoiceFormData = data => {
        this.preInvoiceFormData = data
    }

    preInvoiceForm = [
        {
            type: 'INPUT',
            label: '发票代码',
            field: 'fpdm',
            width: "100%",
            initialValue: '',
            placeholder: '请输入发票代码',
        },
        {
            type: 'INPUT',
            label: '发票号码',
            field: 'fphm',
            width: "100%",
            initialValue: '',
            placeholder: '请输入发票号码',
        },
        {
            type: 'INPUT',
            label: '确认发票代码',
            field: 'enterFpdm',
            width: "100%",
            initialValue: '',
            placeholder: '请确认发票号码',
        },
        {
            type: 'INPUT',
            label: '确认发票号码',
            field: 'enterFphm',
            width: "100%",
            initialValue: '',
            placeholder: '请确认发票号码',
        },
    ]

    hcInput = {
        type: 'INPUT',
        label: '红字信息表编号',
        field: 'xxbbh',
        width: "100%",
        initialValue: '',
        placeholder: '请输入红字信息表编号',
        inputProps: {
            addonAfter: <SearchOutlined />
        }
    }

    searchIsHc = () => {
        const { fphm, fpdm } = this.preInvoiceFormData
        const param = { fphm, fpdm }
        redInfoPaperApply(param).then(res => {
            res.code === '0000' || message.error(res.msg)
        })
    }

    toPreInvoice = () => {
        const { fphm, fpdm, enterFphm, enterFpdm } = this.preInvoiceFormData
        if (fphm !== enterFphm) return message.error('两次输入的发票号码不一致')
        if (fpdm !== enterFpdm) return message.error('两次输入的发票代码不一致')
        const param = { fphm, fpdm }
        queryInvoiceInfoByFpdmAndFphm(param).then(res => {
            const { invoiceType, status } = res.resultObj
            if (invoiceType === '004' && status === 2) {
                this.preInvoiceForm.push(this.hcInput)
                this.forceUpdate()
                // debugger
                // return this.setState({ showPreInvoice: false })
            } else {
                const preParam = { fphm, fpdm, xxbbh: '' }
                createNegativeInvoice(preParam).then(res => {
                    if (res.code === '0000') {
                        message.success(res.msg)
                        return this.setState({ showPreInvoice: false })
                    } else {
                        message.error(res.msg)
                    }
                })
            }
        })
    }

    //获取email 邮箱：gfkhyx  手机号 gfkhdh
    getEmail = (e) => {
        let gfkhyx = e.target.value
        // var reg3 = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        var reg3 = /.+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg3.test(gfkhyx)) {
            this.setState({
                gfkhyx
            })
        }
    }
    getPhone = (e) => {
        let phone = /^1(3|4|5|6|7|8|9)\d{9}$/
        let gfkhdh = e.target.value
        if (phone.test(gfkhdh)) {
            this.setState({
                gfkhdh
            })
        }
    }

    //金额转换
    resSetMoney = () => {
        let num = this.computedAllTotalMoney 
        var strOutput = "",
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += "00";
        var intPos = num.indexOf('.');
        if (intPos >= 0) {
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (var i = 0; i < num.length; i++) {
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")

    }

    onChangeValues = (key, e) => {
        this.state[key] = e.target.value
        this.setState({ ...this.state })
        if (key == 'ghdwmc') {
            this.setState({ghdwsbh:""})
            this.searchGhdwmc(e.target.value)
        }
    }

    onChangeBdc = (key, e) => {
        this.state.bdcInfo[key] = e.target.value
        this.setState({ ...this.state })
    }
    onChangeBdc1 = (key, e) => {
        this.state.bdcInfo[key] = e
        this.setState({ ...this.state })
    }

    xsValue = (key, e) => {
        this.state.saleInfo[key] = e.target.value
        this.setState({ ...this.state })
    }
    //检索购方名称
    searchGhdwmc = (val) => {
        gsDataFind({ searchName: val, token: "WxDev", }).then((res) => {
            this.setState({
                search: [],
            },()=>{
                if (Array.isArray(res.object)&&res.object.length > 0) {
                    this.setState({
                        search: [...res.object],
                    });
                }
            });
        });
    }
    //选择购方名称
    selectOption(ghdwsbh, name) {
        this.setState({
            ghdwsbh,
            ghdwmc: name,
            search: []
        })
    }

    resetKaiPiao = (e,key)=>{
        this.setState({saleInfo:{...this.state.saleInfo,[key]:e}})
    }

    getShowTrue=()=>{
        if(this.state.invoiceType=='026'){
            return true
        }else{
            return false
        }
    }

    removeList() {
        setTimeout(() => {
            this.setState({ listRemove: false })
        }, 150);
    }
    addLists() {
        this.setState({ listRemove: true })
    }

    render() {
        const buyClientColumns = [{ title: '企业名称', dataIndex: 'buyerName' }, { title: '纳税人识别号', dataIndex: 'buyerRegiestNo' },]
        const GoodsColumns = [{ title: '序号', dataIndex: 'key' },{ title: '商品名称', dataIndex: 'spmc' }, { title: '规格型号', dataIndex: 'ggxh' }, { title: '单位', dataIndex: 'dw' },
        { title: '单价', dataIndex: 'dj' }, { title: '税收分类编码', dataIndex: 'spbm' }, { title: '税率', dataIndex: 'sl' },]
        const formLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };
        const { ghdwmc, ghdwsbh, ghdwdzdh, ghdwyhzh } = this.state

        let bg3 = {
            width: "100%",
            height: "160px",
            backgroundImage: `url(${require("./../../assets/icon/dz.png")})`,
            overflow: "hidden",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        };
        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className='invoice'>
                    <div style={bg3} className="bt-title">
                        <div>{utils.myFaPiaoType(this.state.invoiceType)}</div>
                    </div>
                    <div>
                        <Button type="link" style={{ color: '#333333',float:'right',display:'flex',alignItems:'center' }} onClick={() => { this.setState({ showUp: true }) }}><img src={require('../../assets/icon/piLiang.png')} style={{ marginRight: '10px' }} /><span style={{color:"#145cd3"}}>批量开票</span></Button>
                    </div>
                    <Row className="print-div-row">
                        <div className="mySlSelect">
                            <label htmlFor={"selectId"}><span>*</span>发票类型：</label>
                            <Select id="selectId" value={this.state.invoiceType} style={{ width: "160px", marginBottom: 0 }} onChange={this.handleChange}>
                                {  
                                    this.state.faPiao.map((item,index) => {
                                        return <Option value={item.id} key={index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div>
                            <label htmlFor={"ipid"}><span style={{color:this.getShowTrue()?'#ff691a':'transparent'}}>*</span>消费者邮箱：</label> <Input id={"ipid"} autoComplete="off" className="myInfo" onChange={this.getEmail} />
                        </div>
                        <div>
                            <label htmlFor={"slid"}><span>&nbsp;&nbsp;</span>消费者手机号：</label><Input id={"slid"} autoComplete="off" className="myInfo" onChange={this.getPhone} />
                        </div>
                    </Row>

                    <Descriptions bordered size="small" column={2} className='small-table-header-1 small-table-header'>
                        <Descriptions.Item label="购买方" style={{ width: '20px' }} >
                            <Form {...formLayout} className="forms" >

                                <Form.Item label='购买方名称' className="myPosition-gg">
                                    <Input size='small' onFocus={this.addLists.bind(this)} onBlur={this.removeList.bind(this)} onChange={this.onChangeValues.bind(this, 'ghdwmc')} defaultValue={ghdwmc} autoComplete="off" value={this.state.ghdwmc} />
                                    <ul className="search" style={{ display: this.state.search.length > 0 && this.state.listRemove ? "block" : "none",maxHeight: 138,overflow: "auto", }}
                                    >
                                        {this.state.search.map((item, index) => {
                                            return (
                                                <li key={index} onClick={() => { this.selectOption(item.nsrsbh, item.nsrmc); }}
                                                > {item.nsrmc}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Form.Item>
                                <Form.Item label='纳税人识别号'>
                                    <Input size='small' onChange={this.onChangeValues.bind(this, 'ghdwsbh')} autoComplete="off" defaultValue={ghdwsbh} value={this.state.ghdwsbh} />
                                </Form.Item>
                                <Form.Item label='地址、电话'>
                                    <Input size='small' onChange={this.onChangeValues.bind(this, 'ghdwdzdh')} autoComplete="off" defaultValue={ghdwdzdh} value={this.state.ghdwdzdh} />
                                </Form.Item>
                                <Form.Item label='开户及帐号'>
                                    <Input size='small' onChange={this.onChangeValues.bind(this, 'ghdwyhzh')} autoComplete="off" defaultValue={ghdwyhzh} value={this.state.ghdwyhzh} />
                                </Form.Item>
                            </Form>

                        </Descriptions.Item>
                        <Descriptions.Item label="密码区" style={{ width: '20px' }}>
                            <div style={{width: '20vw', height: '100%' }}></div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions size="small" column={9} layout='vertical' bordered className="myAllInput">
                        {
                            this.getGoodsListInfoNodes()
                        }
                    </Descriptions>
                    <div style={{ height: 40, border: "1px solid #e8e8e8", borderTop: 0 }}></div>

                    <Descriptions size='small' column={12} className='money' style={{ padding: 0, borderRadius: 0 }}>
                        <div span={8}>
                            <span className="myColor" style={{ marginLeft: 44 }}>合计</span>
                        </div>
                        <Descriptions.Item label='金额' span={2}>
                            <span>￥{(this.computedAllTotalMoney - this.computedTaxMoney).toFixed(2)}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label='税额'>
                            <span>￥{this.computedTaxMoney}</span>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions size='small' column={1} bordered className='statistics-money' >
                        <Descriptions.Item label='价税合计（大写）'>
                            <span>{this.resSetMoney()}</span>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions size='small' column={2} bordered className='small-table-header'>
                        <Descriptions.Item label='销售方' span={1} className="pInput">
                            <div>
                                <label htmlFor="companyName">销售方名称：</label>
                                <Select
                                    showSearch
                                    onChange={this.onChangeCompanyName}
                                    className="selectBox"
                                    optionFilterProp="children"
                                    value={this.state.saleInfo.registeredNo}
                                    filterOption={(input, option) =>  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                                >
                                    {
                                        this.state.list2.map((item,index)=>{
                                            return <Option value={item.registeredNo} key={index+1}>{item.companyName}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div>
                                <label htmlFor="registeredNo">纳税人识别号：</label><Input id="registeredNo" autoComplete="off" defaultValue={this.state.saleInfo.registeredNo} value={this.state.saleInfo.registeredNo} />
                            </div>
                            <div>
                                <label htmlFor="address">地址、电话：</label><Input id="address" autoComplete="off" defaultValue={this.state.saleInfo.address} value={this.state.saleInfo.address} />
                            </div>
                            <div>
                                <label htmlFor="bankInfo">开户及账号：</label><Input id="bankInfo" autoComplete="off" defaultValue={this.state.saleInfo.bankInfo} value={this.state.saleInfo.bankInfo} />
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label='备注' className="beiZhu">
                            <div>
                                <TextArea className="myArea" onChange={this.xsValue.bind(this, 'bz')} rows={9} />
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                        {
                            this.state.tdyslxdm == '05' || this.state.tdyslxdm == '06'?       
                        <Descriptions  size='small' column={1} bordered className='small-table-header'>
                            <Descriptions.Item label="不动产信息" className="pInput" style={{width: '100% !important' }}>
                            <div>
                                <label htmlFor="region">跨地市标志：</label>
                                <Select
                                    showSearch
                                    onChange={(val)=>  
                                    this.setState({bdcInfo:{
                                        crossCitySign:val    
                                    }})}
                                    className="selectBox"
                                    optionFilterProp="children"
                                    value={this.state.bdcInfo.crossCitySign}
                                >
                                    <Option value='1'>是</Option>
                                    <Option value='0'>否</Option>
                                </Select>
                            </div>
                            <div>
                                <label htmlFor="region">不动产所在省市区：</label><Input id="region"  onChange={(v)=>this.onChangeBdc('region',v)} autoComplete="off" defaultValue={this.state.bdcInfo.region} value={this.state.bdcInfo.region} />
                            </div>
                            <div>
                                <label htmlFor="detailedAddress">不动产详细地址：</label><Input id="detailedAddress"  onChange={(v)=>this.onChangeBdc('detailedAddress',v)} autoComplete="off" defaultValue={this.state.bdcInfo.detailedAddress} value={this.state.bdcInfo.detailedAddress} />
                            </div>
                            <div>
                                <label htmlFor="areaUnit">面积单位（m²）：</label>
                                <Select
                                    onChange={(val)=>  
                                    this.setState({bdcInfo:{
                                        areaUnit:val    
                                    }})}
                                    className="selectBox"
                                    optionFilterProp="children"
                                    value={this.state.bdcInfo.areaUnit}
                                >
                                    <Option value='m²'>亩</Option>
                                    <Option value='m²'>平方米</Option>
                                    <Option value='hm²'>公顷</Option>
                                    <Option value='km²'>平方千米</Option>
                                </Select>
                            </div>
                            {
                               this.state.tdyslxdm == '06' ?
                               <div>
                                 <label htmlFor="titleNo">房屋产权证书：</label><Input id="titleNo" onChange={(v)=>this.onChangeBdc('titleNo',v)} autoComplete="off" defaultValue={this.state.bdcInfo.titleNo} value={this.state.bdcInfo.titleNo}  />
                                </div>
                               :null          
                            }
                            {
                               this.state.tdyslxdm == '06' ?
                               <div>
                                 <label htmlFor="zlqq">租赁期：</label>
                                 <DatePicker  onChange={(v,v1)=>this.onChangeBdc1('zlqq',v1)} format="YYYY-MM-DD" placeholder="租赁期起"/>
                                 <DatePicker  onChange={(v,v1)=>this.onChangeBdc1('zlqz',v1)} format="YYYY-MM-DD" placeholder="租赁期止"/>
                                </div>
                               :null          
                            }
                            </Descriptions.Item>
                        </Descriptions>    : null 
                        }        

                    <Descriptions size='small' column={7} style={{marginTop:15}}>
                         <Descriptions.Item label="开票人" style={{ width: '20px' }} span={2}>
                            <Input size='small' value={this.state.saleInfo.biller} onChange={(e)=>this.resetKaiPiao(e.target.value,"biller")} autoComplete="off" />
                        </Descriptions.Item>
                        <Descriptions.Item label="收款人" style={{ width: '20px' }} span={2}>
                            <Input size='small' value={this.state.saleInfo.payee} onChange={(e)=>this.resetKaiPiao(e.target.value,"payee")}  autoComplete="off" />
                        </Descriptions.Item>
                        <Descriptions.Item label="复核人" style={{ width: '20px' }} span={2}>
                            <Input size='small' value={this.state.saleInfo.reviewer} onChange={(e)=>this.resetKaiPiao(e.target.value,"reviewer")}  autoComplete="off"/>
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="销售方：（章）" style={{ width: '20px' }} span={1}>
                    </Descriptions.Item> */}
                        <div span={1} >
                            <span className="myColor">销售方：（章）</span>
                        </div>
                    </Descriptions>
                    <div className='to-create-invoice'>
                        <Button type='primary' style={{ display: 'inline-block', margin: "auto" }} onClick={this.toCreateInvoice}>确认开票</Button>
                    </div>

                    <SelectTable getData={this.queryUser} showModal={this.state.showfmcSelect} columns={buyClientColumns} tableData={this.fmcData} closeModal={() => this.setState({ showfmcSelect: false })} isSelect={this.fmcIsSelect} />
                    <SelectTable getData={this.query} showModal={this.state.showSpmcSelect} columns={GoodsColumns} tableData={this.spmcData} closeModal={() => this.setState({ showSpmcSelect: false })} isSelect={this.spmcIsSelect} />
                    



                    {/* <Modal visible={this.state.showPreInvoice} title='负数发票代码号码填写' onCancel={() => this.setState({ showPreInvoice: false })} onOk={this.toPreInvoice} getContainer={false} maskClosable={false}>
                    <ModalForm formList={this.preInvoiceForm} modalData={this.getPreInvoiceFormData} addonAfterClickEvent={this.searchIsHc}></ModalForm>
                </Modal> */}
                    {
                        this.state.showUp ? (
                        <Modal visible={this.state.showUp} title="批量开票" footer={null} destroyOnClose={true} keyboard={true}
                            onCancel={() => { this.setState({ showUp: false }) }} >
                            <UpFile />
                        </Modal>) : null
                    }
                </div>
            </Spin>
        )
    }
}



import React, { Component } from "react"
import './setDiskPort.less'
import { Button,Divider,Table } from 'antd';
import BaseForm from '../../../components/baseForm/baseForm'
export default class EnsurePlate extends Component {
	constructor(props) {
		super(props);
		this.state={
			tableList: []
		}
	}
	handleFilter=(e)=>{
	}
	render() {
		const columns = [
			{
			  title: '序号',
			//   dataIndex: '',
			//   key: '',
			  render: (text, record, index) => (
				<span>
					{index+1}
				</span>
			  ),
            },
            {
			    title: '税控盘编号',
			    dataIndex: 'userName',
			    key: 'userName',
			},
			{
                title: '税控盘口令',
                dataIndex: 'idCard',
                key: 'idCard',
			},
			{
			    title: '证书口令',
				dataIndex: 'createBy',
				key: 'createBy',
			},
			{
			    title: 'IP地址',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '端口号',
				dataIndex: 'x',
				key: 'x',
            },
            {
			    title: '状态',
				dataIndex: 'x',
				key: 'x',
            },
            { 
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <div>
                        <Button type="primary" onClick={()=>{this.edit(record)}}>编辑</Button>
                        <Button type="primary" style={{margin: "0 10px"}}>更新</Button>
                        <Button type="danger" onClick={()=>{this.del(record)}}>删除</Button>
                    </div>
                  ),
              }
		  ];
		const formList = [
			{
				type: 'SELECT',
				label: '盘组设备名称',
				field: '1',
				placeholder: '请选择盘组设备名称',
				width: "100%",
				initialValue:'0',
				list:[
					{
						id:'0',name:"未发送"
					},
					
				]
			}, {
				type: 'INPUT',
				label: '税控盘编号',
				field: 'fileName',
				placeholder: '请输入税控盘编号',
				width: "100%",
			}
		]
		return (
			<div className="menberManager">
				<div className="menberManager-title">
					<h2>盘口令设置</h2> 
				</div>
				<Divider />
				<div style={{padding:"10px 0"}}>
					<BaseForm formList={formList} filterSubmit={this.handleFilter} />
                </div>
				<div style={{ background: '#C7E2FF'}}>
                    <Button type="link" style={{color: '#333333'}}><img src={require('../../../assets/icon/query.png')} style={{marginRight: '10px'}}/>盘口令查询</Button>
                    <Button type="link" style={{color: '#333333',margin:"0 20px"}}><img src={require('../../../assets/icon/set.png')} style={{marginRight: '10px'}}/>口令设置</Button>
                    <Button type="link" style={{color: '#333333'}}><img src={require('../../../assets/icon/del.png')} style={{marginRight: '10px'}}/>全部删除</Button>
				</div>
				<div>
					<Table columns={columns} dataSource={this.state.tableList} />
				</div>

			</div>
		)
	}
}
import React, { Component } from "react";
import { Button ,message,Spin} from "antd";
// import axios from "axios";
import "./index.less";
import {getWxCardPkgAuthUrl} from "../../../../api" 
// import Utils from "../../../../utils"
let wx = window.wx

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			display:false,
			list:[]
		};
	}
	
	componentDidMount(){
		let list;
		try{
			list = JSON.parse(localStorage.getItem('listKp'))
			if(list.length>1){
				list.forEach((item,index) => {
					item.index = index+1
					item.name = item.pdfUrl.split('/').pop()
				});
			}
		}catch{
			list = []
		}
		// console.log(list)
		this.setState({ list })
		//检测坏境
		var userAgent = navigator.userAgent.toLowerCase();

		if (userAgent.match(/Alipay/i) == 'alipay') {
			this.environmental = "alipay";
		} else if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
			this.environmental = "micromessenger";
			this.setState({ display:true })
		}else{
			this.setState({ display:false })
		}
	}

	onAbled=()=>{
		this.setState({ loading:true },()=>{
			let timeid = setTimeout(()=>{
				this.setState({loading:false})
				clearTimeout(timeid)
			},1000)
		})
	}

	onAbleds=(pdfUrl="")=>{
		if(!pdfUrl)return message.error('链接不存在！')
		this.setState({loading:true})
		let oa = document.createElement('a');
		oa.href = pdfUrl;
		oa.setAttribute('target', '_blank');
		document.body.appendChild(oa);
		oa.click();
		oa.remove();
		this.setState({loading:false})
	}

	addCard=(id="")=>{
		if(!id)return message.error('缺少ID值！')
		this.setState({loading:true})
		getWxCardPkgAuthUrl({id}).then(res=>{
			if(res.code=="0000"){
				let url = res.resultObj
				window.location.href = url
				message.success(res.msg)
			}else{
				message.error(res.msg)
			}
			this.setState({loading:false})
		})
	}

	go = () => {
		this.props.history.go(-1);
	};
	
	close =()=>{
		window.opener = null;
		window.open("", "_self");
		window.close();
		wx.closeWindow();
	}

	render() {
		return (
			<Spin spinning={this.state.loading} size="large"  >
			<div className="content_s2">
				<div className="successTitle">
					<div className="left">
						<img onClick={this.go} src={require("../../../../assets/img/success/go.png")} alt="" />
						<span className="title">电子发票</span>
					</div>
					<img src={require("../../../../assets/img/success/more.png")} alt="" />
				</div>
				<div className="inv-success-content">
					<img src={require("../../../../assets/img/success/chenggong.png")} alt="" />
					<p>发票开具成功</p>

					<div className="inv-suc-case">
						{
							this.state.list.map((item,index)=>{
								return (<div className="confirms" key={index+1} >
								{
									this.state.display?(<a onClick={this.onAbled} href={item.pdfUrl} download={item.name}>点击下载发票{item.index?"("+item.index+")":null}</a>):<Button type="link" onClick={()=>this.onAbleds(item.pdfUrl)} >点击下载发票{item.index?"("+item.index+")":null}</Button>
								}
								{
									this.state.display?(<Button type="link"  onClick={()=>this.addCard(item.id)}>加入卡包{item.index?"("+item.index+")":null}</Button>):null
								}
								</div>)
							})
						}
						<Button type="default" style={{marginTop:"15pt"}} onClick={this.close} >关闭页面</Button>
					</div>
				</div>
			</div>
			</Spin>
		);
	}
}

import React from 'react'
import { Input, Select, Form, Button, TreeSelect, Row, Col, Checkbox, Radio, DatePicker } from 'antd'

import Utils from '../../utils/index.js';
const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_PARENT } = TreeSelect;

const treeData = [
    {
        title: 'Node1',
        value: '0-0',
        key: '0-0',
        children: [
            {
                title: 'Child Node1',
                value: '0-0-0',
                key: '0-0-0',
            },
        ],
    },
    {
        title: 'Node2',
        value: '0-1',
        key: '0-1',
        children: [
            {
                title: 'Child Node3',
                value: '0-1-0',
                key: '0-1-0',
            },
            {
                title: 'Child Node4',
                value: '0-1-1',
                key: '0-1-1',
            },
            {
                title: 'Child Node5',
                value: '0-1-2',
                key: '0-1-2',
            },
        ],
    },
];


class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lg: 8,
            lengOpen: true,
            mySl:"0%"
        }
    }

    state = {
        value: [],
    };

    onChange = value => {
        this.setState({ value });
        this.props.TreeSelect(value);

    };
    //  reset = ()=>{
    //     this.props.form.resetFields();
    // }
    componentWillMount(){
        let mySl = ""
        this.props.formList.forEach(item=>{
            if(item.field=="sl"|| item.field == 'slv'){
                mySl = item.initialValue
            }
        })
        this.setState({mySl})
    }
    componentDidUpdate(){
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.modalData(fieldsValue);
    }

    componentDidMount() {
        let lg = this.props.myLength
        this.setState({ lg })
    }

    initFormList = () => {
        let { getFieldDecorator } = this.props.form;
        let formList = this.props.formList;

        //控制显示数据
        // console.log("formList",formList)

        if (formList.length > this.state.lg) {
            formList.forEach(item => {
                if (item.field === 'sl' || item.field === 'slv') {
                    item.list = [{ name: "0%", id: '0.00' }]
                    item.initialValue = "0%"
                    this.state.lengOpen = false
                }
            })
        } else {
            formList.forEach(item => {
                if (item.field === 'sl' || item.field === 'slv') {
                    item.initialValue = this.state.mySl
                    item.list = [
                        { id: '0.13', name: '13%' },
                        { id: '0.09', name: '9%' },
                        { id: '0.06', name: '6%' },
                        { id: '0.05', name: '5%' },
                        { id: '0.03', name: '3%' },
                        { id: '0.02', name: '2%' },
                        { id: '0.015', name: '1.5%' },
                        { id: '0.01', name: '1%' },
                        { id: '0.00', name: '0%' },
                    ]
                }
            })
            this.state.lengOpen = true
        }
        const treeData = this.props.treeData
        const formItemList = [];
        const tProps = {
            treeData,
            value: this.state.value,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: 'Please select',
            style: {
                width: '100%',
            },
        };

        if (this.props.TreeinitialValue) {
            this.state.value = this.props.TreeinitialValue
        }
        if (formList && formList.length > 0) {
            formList.forEach((item, i) => {
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                let abled = item.abled || false;
                if (item.type == '城市') {

                    const city = <FormItem label="城市" key={field}>
                        {
                            getFieldDecorator('city', {
                                initialValue: '0'
                            })(
                                <Select
                                    style={{ width: 80 }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList([{ id: '0', name: '全部' }, { id: '1', name: '北京' }, { id: '2', name: '上海' }, { id: '3', name: '天津' }, { id: '4', name: '杭州' }])}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(city)
                } else if (item.type == '时间查询') {
                    const begin_time = <FormItem label="订单时间" key={field}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type === 'INPUT') {
                    let { inputProps } = item
                    if (inputProps) {
                        let { addonAfter } = inputProps
                        addonAfter = React.cloneElement(addonAfter, { onClick: () => this.props.addonAfterClickEvent() })
                        inputProps.addonAfter = addonAfter
                    }
                    const INPUT = <Col span={12} key={field}>
                        <FormItem label={label} >
                            {
                                getFieldDecorator(field, {
                                    initialValue: initialValue
                                })(
                                    <Input type={placeholder == "请输入密码" ? "password" : (placeholder == "请输入单价" ? "number" : "text")} style={{ width: width }} placeholder={placeholder} {...inputProps} disabled={abled} autoComplete="off" />
                                )
                            }
                        </FormItem>
                    </Col>;
                    formItemList.push(INPUT)
                }else if (item.type === 'AUTOSELECT') {
                    const SELECT = <Col span={12} key={field}>
                        <FormItem label={label} >
                            {
                                getFieldDecorator(field, {
                                    initialValue: initialValue,
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `${label}必填，不能为空`,
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        initialValue=""
                                        style={{ width: width }}
                                        optionFilterProp="children"
                                        placeholder={placeholder}
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                    >
                                        {/* <Option value="" >{placeholder}</Option> */}
                                        {
                                            placeholder==="请选择当前模板名称"?(Utils.getOptionList2(item.list)):(Utils.getOptionList(item.list))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                    </Col>;
                    formItemList.push(SELECT)
                }  else if (item.type === 'SELECT') {
                    const SELECT = <Col span={12} key={field}><FormItem label={label} >
                        {
                            getFieldDecorator(field, {
                                // initialValue: (field =="lslbs"|| field =="status" )?'1':((this.state.lengOpen || field =="yhzcbs" || field == 'registeredNo'|| field == 'taxType') ? initialValue : '0%')
                                initialValue: (field == "lslbs" || field == "status") ? '1' : ((this.state.lengOpen || field != "slv" && field != 'sl') ? initialValue : '0%')
                            })(
                                <Select
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }


                    </FormItem>
                    </Col>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <Col span={12} key={field}> <FormItem label={label} >
                        {
                            getFieldDecorator([field], {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>
                    </Col>
                    formItemList.push(CHECKBOX)
                } else if (item.type == 'DATE') {
                    const Date = <Col span={12} key={field}><FormItem label={label} >
                        {
                            getFieldDecorator([field])(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>
                    </Col>;
                    formItemList.push(Date)
                } else if (item.type == 'TREE') {
                    const Date = <Col span={12} key={field}><FormItem label={label} >
                        {
                            <TreeSelect {...tProps} />
                        }
                    </FormItem>
                    </Col>;
                    formItemList.push(Date)
                }
            })
        }
        return formItemList;
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props) === JSON.stringify(nextProps)) return
        const { resetFields } = this.props.form;
        const { resetForm } = this.props
        resetForm && resetFields()
    }


    render() {
        let setWidth;
        try{
            setWidth = this.props.setWidth
        }catch{
            setWidth = false
        }
        const formItemLayout = setWidth?{
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }:{}
        const { getFieldDecorator } = this.props.form;
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.modalData(fieldsValue);
        return (
            <Form {...formItemLayout}>
                <Row gutter={24}>
                    {this.initFormList()}
                </Row>
            </Form>
        );
    }
}
export default Form.create({})(Modal);
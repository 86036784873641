import React, { Component } from "react"
import './course.less'

import b1_1 from "../../assets/img/home/b1_1.png";
import b1_2 from "../../assets/img/home/b1_2.png";
import b1_3 from "../../assets/img/home/b1_3.png";
import b2_1 from "../../assets/img/home/b2_1.png";
import b2_2 from "../../assets/img/home/b2_2.png";
import b2_3 from "../../assets/img/home/b2_3.png";
import b3_1 from "../../assets/img/home/b3_1.png";
import b3_2 from "../../assets/img/home/b3_2.png";
import b3_3 from "../../assets/img/home/b3_3.png";

import left from "../../assets/img/home/left.png";
import right from "../../assets/img/home/right.png";


export default class Course extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgList: [],  //图片列表
            allWidth: 9999,
            oneWidth: 0,     //每个元素宽度
            index: 0,        //当前位置
            num: 0,          //数量
            divWidth: 0,    //一个轮播宽度
            n: 0,           //一个轮播几个元素
            speed: 2000,    //速度
            speed2: 1,    //速度
            allLength: 0,   //总长度
            open:'',
            open1:false,
        }
    }

    componentWillMount() {
        let obj = {
            oneWidth: 180+20,     //每个元素宽度
            num: 9,           //数量
            divWidth: 980+20,    //一个轮播宽度
            n: 5,             //一个轮播几个元素
            speed: 2500,      //图片切换速度毫秒
            speed2:1,
        }
        let imgList = [
            { url:b1_1, title:"电商" },
            { url:b1_2, title:"停车场" },
            { url:b1_3, title:"餐饮" },
            { url:b2_1, title:"快递物流" },
            { url:b2_2, title:"地产物业" },
            { url:b2_3, title:"酒店行业" },
            { url:b3_1, title:"连锁行业" },
            { url:b3_2, title:"中小微企业" },
            { url:b3_3, title:"商超行业" },
        ]
        this.setState({ imgList: [...imgList, ...imgList.slice(0, obj.n)], ...obj, allLength: obj.num + obj.n })
    }
    componentDidMount() {
        //设置容器宽度
        let allWidth = this.state.oneWidth * (this.state.allLength);

        this.setState({
            allWidth
        }, () => {
            this.timeAll()
        })
    }
    handlePrev = () => {
        this.moveTo(-1)
    }
    handleNext = () => {
        this.moveTo(1)
    }

    

    moveTo = (to=0) => {

        let box = document.getElementById("CourseBoxImg")
        
        if (to >=0 ) {
            let index = this.state.index
            this.setState({open:true})
            to = index + 1
            let distance = -this.state.oneWidth * to;
            box.style.transform = 'translateX(' + distance + 'px)';
            //刷新下次图片显示位置 9
            this.setState({ index: to },()=>{
                if (to >= this.state.num) {
                    let id = setTimeout(() => {
                        this.setState({ index: 0 ,open:false})
                        box.style.transform = 'translateX(' + 0 + 'px)';
                        clearTimeout(id)
                    },this.state.speed2*1000)
                }
            })
        }

        if(to<0) { //上一张 -1
            let index = this.state.index
            if(index<=0){
                let to = this.state.num
                this.setState({open:false})
                let distance = -this.state.oneWidth * to;
                box.style.transform = 'translateX(' + distance + 'px)';
                this.setState({index:to},()=>{
                    let id1 = setTimeout(() => {
                        this.handlePrev()
                        clearTimeout(id1)
                    })
                })
                return
            }else{
                let index = this.state.index
                this.setState({open:true})
                let to = index - 1
                let distance = -this.state.oneWidth * to;
                box.style.transform = 'translateX(' + distance + 'px)';
                this.setState({ index: to })
            }

        }

    }
    //自动
    timeAll = () => {
        this.timeId = setInterval(() => {
            this.moveTo()
        }, this.state.speed)
    }

    componentWillUnmount(){
        clearInterval(this.timeId)
    }

    clearAlltimeid=()=>{
        clearInterval(this.timeId)
    }

    addAlltimeid=()=>{
        this.timeAll()
    }

    render() {

        return (

            <div className="CourseBox" onMouseOver={this.clearAlltimeid} onMouseOut={this.addAlltimeid}>
                <div  onClick={this.handlePrev} ><img src={left} alt=""/></div>
                <div className="boxImg"  style={{ width: this.state.divWidth}}>
                    <ul id="CourseBoxImg" style={{ width: this.state.allWidth * 2, transition: this.state.open? `all ${this.state.speed2}s` : null  }}>
                        {
                            this.state.imgList.map((item, index) => {
                                return (<li className="imgLi" key={index}>
                                        <img src={item.url} alt="" />
                                        <div>{item.title}</div>
                                    </li>)
                            })
                        }
                    </ul>
                </div>
                <div  onClick={this.handleNext} ><img src={right} alt=""/></div>
            </div>
        )
    }
}
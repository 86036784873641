import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./financial.less";

import imgBan from "../../../../assets/img/solution/banner3.png";
import nextImg from "../../../../assets/img/solution/next.png";


import i11 from "../../../../assets/img/solution/16.png";
import i12 from "../../../../assets/img/solution/17.png";
import i13 from "../../../../assets/img/solution/14.png";
import i19 from "../../../../assets/img/solution/19.png";
import i20 from "../../../../assets/img/solution/20.png";

import i21 from "../../../../assets/img/solution/21.png";
import i22 from "../../../../assets/img/solution/31.png";
import i23 from "../../../../assets/img/solution/41.png";


import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";





export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer:{
				title: "我想要咨询此行业",
				phone:'400-9958-578'
			},
			active:[true,false,false,false]
			
		};
	}
	
	clickActive=(key)=>{
		let list  = this.state.active
		for(let i in list){
			if(i==key){
				list[i] = true
			}else{
				list[i] = false
			}
		}
		this.setState({active:[...list]})
		let str = 'active'+key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
		}
	}


	render() {
		const bgImg1 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};
		

		return (
			<div className="solution-content solution-content-3">

				<section className="solution-1">
					<img alt="" src={imgBan} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0]?'active':''} onClick={this.clickActive.bind(this,0)}>行业趋势</li>
						<li className={this.state.active[1]?'active':''} onClick={this.clickActive.bind(this,1)}>行业痛点</li>
						<li className={this.state.active[2]?'active':''} onClick={this.clickActive.bind(this,2)}>解决方案</li>
						<li className={this.state.active[3]?'active':''} onClick={this.clickActive.bind(this,3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>优化管理行业模式</span>
							<span>积极探索如何运用云计算、大数据等应用于金融行业链条中</span>
						</li>
						<li  style={bgImg2}>
							<span>行业深度转变</span>
							<span>拥抱新变化，以信息化、智能化、节约型方向探索发票开具流程，优化自身模式</span>
						</li>
						<li  style={bgImg3}>
							<span>电子化趋势</span>
							<span>推行电子化在线办理业务，对电子化票据凭证提出更高要求</span>
						</li>
					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<span>开票周期长</span>
							<span>金融行业网点众多，开票需要到分公司申请，开票周期长，无法立即取到发票</span>
						</li>
						<li>
							<span>开票成本高</span>
							<span>网上业务办理，纸质发票快递成本高，且不易于保存</span>
						</li>
						<li>
							<span>不利于减负</span>
							<span>金融行业由营业税率调整，面临发票使用量快速增加</span>
						</li>
					</ul>
				</section>
				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>消者在线上办国业后自接开发,手机号起作为接量课道可进行一记录、归档、查询</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i12} />
								<span>网银开票</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i11} />
								<span>APP扫码开票</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						
						<li>
							<img alt="" src={i19} />
							<span>银行业务系统系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<li>
							<img alt="" src={i13} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<div  className="ul_div">
							<li>
								<img alt="" src={i20} />
								<span>局端系统(A省)</span>
							</li>
							<li>
								<img alt="" src={i20} />
								<span>局端系统(B省)</span>
							</li>
						</div>
						
					</ul>
				</section>
				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>发票智能化管理</span>
								<img alt="" src={i21} />
							</div>
							<p>在线开票、扫码开票等方式，让开票更加便捷，消费者自行申请或扫码开票，大大提高了企业的工作效率</p>
						</li>
						<li>
							<div>
								<span>成本低收效高</span>
								<img alt="" src={i22} />
							</div>
							<p>顾客网上办理业务同时能开具发票，并且是电子发票方式，节约成本，收效高</p>
						</li>
						<li>
							<div>
								<span>提升顾客满意度</span>
								<img alt="" src={i23} />
							</div>
							<p>省去等待开票环节，提高用户体验和用户粘度</p>
						</li>
					</ul>
				</section>
				
				

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

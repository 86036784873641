import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Chain from './solution_1/solution_1'
import Enterprise from './solution_2/solution_2'
import Financial from './solution_3/solution_3'
import Restaurant from './solution_4/solution_4'
import Market from './solution_5/solution_5'
import Property from './solution_6/solution_6'
import OnlineRetailers from './solution_7/solution_7'
import Hotel from './solution_8/solution_8'
import Express from './solution_9/solution_9'
import ParkingLot from './solution_10/solution_10'

export default class Solution extends Component {
    componentDidMount() {
        //this.props.history.push({ pathname: '/invoice/step1', state: { invoiceNumber: '222' } }) ParkingLot
    }
    render() {
        return (
            <div>

                <Switch>
                    <Route path='/web/solution/chain' component={Chain} />
                    <Route path='/web/solution/enterprise' component={Enterprise} />
                    <Route path='/web/solution/financial' component={Financial} />
                    <Route path='/web/solution/restaurant' component={Restaurant} />
                    <Route path='/web/solution/market' component={Market} />
                    <Route path='/web/solution/property' component={Property} />
                    <Route path='/web/solution/onlineRetailers' component={OnlineRetailers} />
                    <Route path='/web/solution/hotel' component={Hotel} />
                    <Route path='/web/solution/express' component={Express} />
                    <Route path='/web/solution/parkingLot' component={ParkingLot} />
                    <Redirect to='/web/solution/chain' />
                </Switch>

            </div>
        )
    }
}

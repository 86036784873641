import React, { Component } from "react";
import { Layout, ConfigProvider, Modal, message } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import en_US from "antd/es/locale/en_US";
import "moment/locale/zh-cn";
import HeaderBar from "../../../components/headerBar/headerBar.jsx";
import NavJump from "../../../components/navigationJump/navigationJump.jsx";
import SlideNav from "../../../components/slideNav/slideNav.jsx";
// import Setting from "../../../components/setting/setting.jsx";
import HeaderTwo from "../../../components/headerTwo/headerTwo.jsx";
import { Router } from "../../../router/index";
import emitter from "../../../services/event";
import store from "../../../store";
import action from "../../../actions/action";
import "./enter.less";
import { updatePassword } from "../../../api";
//import '../../../assets/css/global.less'
const { Header, Sider, Content, Footer } = Layout;
export default class Enter extends Component {
	state = {
		collapsed: false,
		mode: 1,
		locale: zhCN,
		showLogin: "",
		password: "",
		newPassword: "",
		newPassword2: "",
		canCancel:false
	};

	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
			locale: zhCN,
		});
	};

	locale = (e) => {
		this.setState({
			locale: e === "zhCN" ? zhCN : en_US,
		});
	};
	//组件渲染完成 执行一次
	componentDidMount() {
		document.title = "中税管理系统"
		//在组件挂载完成后声明一个自定义事件
		let showLogin1 = store.getState().web.firstLogin;
		let showLogin2 = store.getState().web.lastUpdateTime;
		let showLogin = ""
		if(showLogin1||showLogin2){
			showLogin = false
		}else{
			showLogin = false
		}
		this.setState({ showLogin });
		//是否登录
		let isLogin = store.getState().web.isLogin;
		if(!isLogin){
			this.resetLogin(1)
		}
		emitter.addListener("headerType", (item) => {
			//监听事件
			this.setState({
				mode: item,
			});
		});
		emitter.addListener("isLogin", (item) => {
			//监听事件 token是否过期
			if (!item) {
				this.resetLogin(2)
			}
		});
	}

	resetLogin=(val)=>{
		const that = this;
		Modal.error({
			title: '请重新登录',
			content: `${val===1?'您还没有登录，请登录 ！！！':'秘钥已失效，请重新登录 ！！！'}`,
			okText:'确定',
			onOk() {
				Modal.destroyAll();
				store.dispatch(action("clearSystem",""))
				that.props.history.push("/web/login");
			},
			keyboard:false,
			maskClosable:false
		  });

	}

	getColor = (e) => {
		document.getElementById("theme").className = "theme" + e;
	};
	//组件重新渲染完成
	componentDidUpdate() {}
	//卸载组件
	componentWillUnmount() {
		//组件销毁前移除事件监听
		emitter.removeListener("headerType", (msg) => {});
	}
	//password
	change = (value, key) => {
		
		let obj = {};
		obj[key] = value
		this.setState({ ...obj });
	};

	resetPassword = () => {
		let reg = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{11,}$/;
		let { password, newPassword, newPassword2 } = this.state;
		
		if (!password) {
			return message.info("请输入原密码！！！");
		}else{
			password = this.$md5(password)
		}

		if (newPassword != newPassword2) {
			message.warning("两次新密码输入不一致，请重新输入！");
			return;
		}

		if (!newPassword) {
			return message.info("请输入新密码！！！");
		}else{
			if(reg.test(newPassword)&&newPassword.length>10){
				newPassword = this.$md5(newPassword)
			}else if(newPassword.length<=10){
				return message.warning('密码长度不大于10，请重新设置！！！')
			}else{
				return message.warning('密码至少包含三种字符，请重新设置！！！')
			}
		}

		if (!newPassword2) {
			return message.info("请输入确认新密码！！！");
		}

		updatePassword({ password, newPassword }).then((res) => {
			if (res.code === "0000") {
				message.success(res.msg);
				localStorage.clear();
				sessionStorage.clear();
				this.props.history.push("/web/login")
			} else {
				message.error(res.msg);
			}
		});
	};
	resetMm=(showLogin)=>{
		this.setState({showLogin,canCancel:showLogin})
	}
	cancel=()=>{
		if(this.state.canCancel){
			this.setState({showLogin:false,canCancel:false})
		}else{}
	}
	onCollapse = (collapsed) => {
		this.setState({ collapsed });
	};
	render() {
		const { locale } = this.state;
		return (
			<ConfigProvider locale={locale}>
				<Layout id="theme" className="theme theme_content_box">
					<Header
						className="headerBar"
						style={{
							padding: 0,
							display: this.state.mode === 1 ? "block" : "none",
						}}
					>
						<div className="content-title">
							<img
								src={require("../../../assets/img/logo/logo2x.png")}
							/>
							<span>集中托管发票管理系统</span>
						</div>
						<HeaderBar
							props={this.props}
							languageToggle={this.locale.bind(this)}
							resetMm={this.resetMm.bind(this)}
						/>
					</Header>
					
					<Layout className="layout-warp">
						<Sider
							className="slideNav mt"
							// trigger={null}
							collapsible
							collapsed={this.state.collapsed}
							onCollapse={this.onCollapse}
							style={{ display: this.state.mode === 1 ? "block" : "none"}}
						>
							<SlideNav />
						</Sider>
						<Layout>
							<div className="navJump" style={{ display: this.state.mode === 1 ? "block" : "none" }} >
								<NavJump />
							</div>
							<div className="headerTwo" style={{ display: this.state.mode === 2 ? "block" : "none"}} >
								<HeaderTwo props={this.props} />
							</div>
							{/* 设置 */}
							{/* <Setting  themeColor={this.getColor.bind(this)}/> */}
							<Content className="enter_height" >
								{Router}
							</Content>
						</Layout>
					</Layout>
					<Layout className="banBen">
						欢迎使用集中托管发票管理系统&nbsp;&nbsp;v2.0
					</Layout>
				</Layout>
				{this.state.showLogin ? (
						<Modal
							title={this.state.canCancel?"修改密码":'修改密码(第一次登录/90天)！'}
							visible={this.state.showLogin}
							onOk={this.resetPassword}
							onCancel={this.cancel}
							keyboard={false}
							maskClosable={false}
						>
							<ul className="resetPassword">
								<li>
									<label>原密码</label>：
									<input autoComplete="off" type="password" onChange={(e) => this.change( e.target.value, "password" ) } />
								</li>
								<li>
									<label>新密码</label>：
									<input autoComplete="off" type="password" onChange={(e) => this.change( e.target.value, "newPassword" ) } />
								</li>
								<li>
									<label>确认新密码</label>：
									<input autoComplete="off" type="password" onChange={(e) => this.change( e.target.value, "newPassword2" ) } />
								</li>
								<li>
									<h3>备注：</h3>
									<p>
										密码由数字、大写字母、小写字母、特殊字符中至少三种构成，且长度10位以上（密码要90天修改一次）
									</p>
								</li>
							</ul>
						</Modal>
					) : null}
			</ConfigProvider>
		);
	}
}

import React, { Component } from "react";
import Footer2 from "../../../../components/webfooter2/webFooter2";

import "./enterprise.less";

import imgBan2 from "../../../../assets/img/solution/banner2.png";
import nextImg from "../../../../assets/img/solution/next.png";

import i1 from "../../../../assets/img/solution/1.png";
import i2 from "../../../../assets/img/solution/5.png";
import i3 from "../../../../assets/img/solution/6.png";
import i4 from "../../../../assets/img/solution/7.png";

import i11 from "../../../../assets/img/solution/16.png";
import i12 from "../../../../assets/img/solution/17.png";
import i13 from "../../../../assets/img/solution/14.png";
import i14 from "../../../../assets/img/solution/15.png";
import i15 from "../../../../assets/img/solution/18.png";

import i21 from "../../../../assets/img/solution/31.png";
import i22 from "../../../../assets/img/solution/32.png";
import i23 from "../../../../assets/img/solution/33.png";
import i24 from "../../../../assets/img/solution/34.png";

import bg1 from "../../../../assets/img/solution/bg4.png";
import bg2 from "../../../../assets/img/solution/bg5.png";
import bg3 from "../../../../assets/img/solution/bg6.png";





export default class Enterprise extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footer:{
				title: "我想要咨询此行业",
				phone:'400-9958-578'
			},
			active:[true,false,false,false]
			
		};
	}
	
	clickActive=(key)=>{
		let list  = this.state.active
		for(let i in list){
			if(i==key){
				list[i] = true
			}else{
				list[i] = false
			}
		}
		this.setState({active:[...list]})
		let str = 'active'+key
		this.scrollToAnchor(str)
	}
	//锚点滚动
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
		}
	}


	render() {
		const bgImg1 = {
			
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg1 + ")", //图片的路径
		};
		const bgImg2 = {
			
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg2 + ")", //图片的路径
		};
		const bgImg3 = {
			
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bg3 + ")", //图片的路径
		};
		

		return (
			<div className="solution-content solution-content-2">

				<section className="solution-1">
					<img alt="" src={imgBan2} />
				</section>

				<section className="solution-2">
					<ul>
						<li className={this.state.active[0]?'active':''} onClick={this.clickActive.bind(this,0)}>行业趋势</li>
						<li className={this.state.active[1]?'active':''} onClick={this.clickActive.bind(this,1)}>行业痛点</li>
						<li className={this.state.active[2]?'active':''} onClick={this.clickActive.bind(this,2)}>解决方案</li>
						<li className={this.state.active[3]?'active':''} onClick={this.clickActive.bind(this,3)}>方案优势</li>
					</ul>
				</section>

				<section className="solution-3" id="active0">
					<h3>行业趋势</h3>
					<ul>
						<li style={bgImg1}>
							<span>数据化引导</span>
							<span>连锁化发展，需要建立票据体系化标准，实现数据化管理</span>
						</li>
						<li  style={bgImg2}>
							<span>数据化引导</span>
							<span>商家注重通过线上营销增加消费粘性，电子发票成为新的渠道</span>
						</li>
						<li  style={bgImg3}>
							<span>数据化引导</span>
							<span>小微企业一体化收银+支付+开票硬件，使得开票成本越来越低，也愈发普及</span>
						</li>
					</ul>
				</section>
				<section className="solution-4" id="active1">
					<h3>行业痛点</h3>
					<ul>
						<li>
							<div>客户需要排队开票，耗时长；邮寄发票，物流成本较高</div>
							<img alt="" src={i1} />
							<span>开票成本高</span>
						</li>
						<li>
							<div>手工输入开票数据，出错率高，且开票抬头复杂，经常出错</div>
							<img alt="" src={i2} />
							<span>开票错误率高</span>
						</li>
						<li>
							<div>每月去税务局排队领发票，耗费人力、时间</div>
							<img alt="" src={i3} />
							<span>工作效率低下</span>
						</li>
						<li>
							<div>业务数据和发票数据无法实时汇总，数据难以实时管理带来经营风险</div>
							<img alt="" src={i4} />
							<span>数据无法集中管理</span>
						</li>
					</ul>
				</section>
				<section className="solution-5" id="active2">
					<h3>解决方案</h3>
					<div>客户结账，商家打印出带有二维码的小票，客户扫描二维码，填写发票抬头、手机号或者邮箱，提交开票申请，系统自动开具电子发票，推送到客户邮箱。</div>
					<ul>
						<div className="ul_div">
							<li>
								<img alt="" src={i11} />
								<span>APP开票</span>
								<img alt="" src={nextImg}/>
							</li>
							<li>
								<img alt="" src={i12} />
								<span>网页开票</span>
								<img alt="" src={nextImg}/>
							</li>
						</div>
						
						<li>
							<img alt="" src={i13} />
							<span>中税开票系统</span>
							<img alt="" src={nextImg}/>
						</li>
						<div  className="ul_div">
							<li>
								<img alt="" src={i14} />
								<span>推送电子发票</span>
							</li>
							<li>
								<img alt="" src={i15} />
								<span>对接客户系统</span>
							</li>
						</div>
						
					</ul>
				</section>
				<section className="solution-6" id="active3">
					<h3>方案优势</h3>
					<ul>
						<li>
							<div>
								<span>节约成本</span>
								<img alt="" src={i21} />
							</div>
							<p>网上领取电子发票，开具发票后不需要打印，降低企业成本</p>
						</li>
						<li>
							<div>
								<span>自动记账</span>
								<img alt="" src={i22} />
							</div>
							<p>减轻邮寄票据成本，对接财务软件，无缝衔接财务公司</p>
						</li>
						<li>
							<div>
								<span>冲红发票</span>
								<img alt="" src={i23} />
							</div>
							<p>冲红发票更简便易行</p>
						</li>
						<li>
							<div>
								<span>易于保存</span>
								<img alt="" src={i24} />
							</div>
							<p>电子发票方便保管，及时向企业经营者提供决策支持</p>
						</li>
					</ul>
				</section>
				
				

				<Footer2 title={this.state.footer} />
			</div>
		);
	}
}

import React, { Component } from "react"
import { Button, Divider, Tooltip, Table, Modal, message, Select, Popconfirm, Spin, TreeSelect, Input, Form } from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';
import BaseForm from '../../../components/baseForm/baseForm'
// import ModalForm from '../../../components/modal/modal'
import Details from "./details/details"
import RepairInvoice from "./repairInvoice/repairInvoice"
import { queryInvoiceByPage, invoiceVoid, pushDzfp, invoiceReapet, queryTaxPlateByNsrsbh, consentInvoiceAudit, exportSuccessInvoices, createNegativeInvoice, redInfoPaperApply, redInfoPaperQuery } from '../../../api'
import moment from 'moment'
import utils from "../../../utils/index"
import loadZip from "../../../utils/fileUtil"
import './invoiceQuery.less'
import Ewm from "./details/ewm"
const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

let treeData = []

export default class WritingState extends Component {
    constructor(props) {
        super(props);
        this.menberManager = React.createRef()
        this.state = {
            ie: undefined,
            switchDetails: false,
            invoiceVoids: false,
            tableList: [],
            getDataInfo: {
                fpdm: '',
                fphm: '',
                jqbh: '',
                minAmount: '',
                maxAmount: '',
                startTime: '',
                endTime: '',
                biller: '',
                buyerName: '',
                invoiceType: '',
                slv: '',
                orderNo: '',
                nsrsbh: this.props.location.state,
                status: '',
                error: false,
                page: 1,
                rows: 10
            },
            tableList2: [],
            total: 1,
            xq: {},
            showInfo: false,
            detailInfo: '',
            loading: false,
            openKey: false,
            value: [],
            showEmail: false,
            paramPul: {},
            showStatus: false,
            showRed : false,
            showEwm : false,
            paramStatus: {},
            selectedRows: [],
            statusList: [
                { id: 0, name: "已抵扣" },
                { id: 1, name: "未抵扣" },
                { id: 2, name: "销方申请" },
            ],
            redReasonList:[
                { id: "01", name: "开票有误" },
                { id: "03", name: "销货退回" },
                { id: "03", name: "服务中止" },
                { id: "04", name: "销售折让" },
            ],
            openRepair: false,
        }
    }

    componentDidMount() {
        this.getData()
        let lists = JSON.parse(sessionStorage.getItem('switchDetails')) || [];
        let switchDetails, invoiceVoids;
        try {
            switchDetails = lists.includes("createNegaticeInvoice")
        } catch {
            switchDetails = false
        }
        try {
            invoiceVoids = lists.includes("copyAndReport/invoiceVoid")
        } catch {
            invoiceVoids = false
        }
        this.setState({ switchDetails, invoiceVoids })
    }




    getData() {
        const { getDataInfo: param } = this.state
        this.setState({ loading: true })
        queryInvoiceByPage(param).then(res => {
            if (res.code === '0000') {
                const { resultList: tableList } = res
                tableList.forEach((element, index) => {
                    element.key = index + 1
                });

                const { currentPage: page, pageSize: rows, totalCount: total } = res.pager
                this.setState({
                    tableList,
                    getDataInfo: { ...this.state.getDataInfo, page, rows },
                    total
                })
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    //推送
    pushInvoice = data => {
        let { fpdm, fphm, gfkhyx: email } = data
        let paramPul = { fpdm, fphm, email }
        this.setState({ showEmail: true, paramPul })
    }


    //导出
    exports = () => {
        let { startTime, endTime, invoiceType, fpdm, fphm, buyerName, nsrsbh, orderNo } = this.state.getDataInfo

        if (!startTime) {
            return message.error('开票起始日期,不能为空')
        }
        if (!endTime) {
            return message.error('开票结束时间,不能为空')
        }

        // exportSuccessInvoices({startTime, endTime, invoiceType,fpdm,fphm,buyerName,nsrsbh,orderNo}).then(res=>{
        //     console.log(res)
        //         utils.downLoad(res,"票据列表")
        // })
        // return

        let token = sessionStorage.getItem("token")
        let urlLocation = window.location.href
        let baseUrl = ''

        if (urlLocation.includes(process.env.REACT_APP_SEARCH)) {
            baseUrl = process.env.REACT_APP_KPBASE_PRO
        } else {
            baseUrl = process.env.REACT_APP_KPBASE
        }

        let url = baseUrl + `/simpleInvoice/exportSuccessInvoices?startTime=${startTime}&endTime=${endTime}&invoiceType=${invoiceType}&token=${token}&fpdm=${fpdm}&fphm=${fphm}&buyerName=${buyerName}&nsrsbh=${nsrsbh}&orderNo=${orderNo}`;
        utils.aDownLoad(url)
    }

        
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let rows = selectedRows.filter(item=> item.pdfUrl != null && item.pdfUrl != '').map(item => {
                return  {
                    fvName: item.fphm + ".pdf",
                    fvUrl : item.pdfUrl
                }
            })
            this.setState({
                selectedRows: [...rows]
            })
        },
    };

    //导出
    downloadFps = () => {
       if (this.state.selectedRows.length > 0){
        loadZip(this.state.selectedRows,(new Date()).Format("yyyyMMddHHmmss"))
       } else{
         return message.error('请选择开票完成的发票')
       }
    }

    getInfo = data => {
        console.log(data)    
        this.setState({ showInfo: true, detailInfo: data })
    }

    filterInvoiceType(val) {
        switch (val) {
            case '004':
                return '增值税专用发票'
            case '007':
                return '增值税普通发票'
            case '025':
                return '卷式发票'
            case '026':
                return '增值税电子发票'
            case '028':
                return '电子专用发票'
            case '81':
                return '全电专用发票'
            case '82':
                return '全电普通发票'
            default:
                return '-'
        }
    }

    filterKplx(val) {
        switch (val) {
            case 1:
                return '待填写抬头'
            case 2:
                return '开票完成'
            case 3:
                return '开具错误'
            case 4:
                return '正数作废'
            case 5:
                return '负数发票'
            case 6:
                return '负数作废'
            case 7:
                return '空白作废'
            case 8:
                return '开票前准备'
            case 9:
                return '门店审核'
            case 10:
                return '驳回'
            case 11:
                return '商家作废二维码'
            case 20:
                return '开票中'
            case 22:
                return (
                    <span>
                        开票失败
                    </span>)
            default:
                return '-'
        }
    }

    filterTime(val) {
        if (!val) return
        return moment(typeof val === 'string' ? Number(val) : val, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
    }

    invoiceToVoid = data => {
        const { kpzdbs, invoiceType: fplxdm, fpdm, fphm } = data
        const param = { kpzdbs, fplxdm, zflx: 1, fpdm, fphm }
        this.setState({ loading: true })
        invoiceVoid(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    invoiceToReapet = data => {
        const { xhdwsbh,orderNo } = data
        const param = { orderNo,salerTaxNum:xhdwsbh }
        this.setState({ loading: true })
        invoiceReapet(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }
    goRed = (data) => {
        // this.setState({ loading: true })
        console.log(data)
        this.setState({ showRed: true, detailInfo: data })
        // createNegativeInvoice(data).then(res => {
        //     if (res.code == "0000") {
        //         message.success(res.msg)
        //         this.getData()
        //     } else {
        //         message.error(res.msg)
        //     }
        //     this.setState({ loading: false })
        // })
    }

    submitRed = () => {
        this.setState({ loading: true })
        createNegativeInvoice(this.state.detailInfo).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
                this.getData()
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
        this.setState({ showRed: false})
    }

    paginationChange = val => {
        this.setState({
            getDataInfo: { ...this.state.getDataInfo, page: val.current, rows: val.pageSize },
            total: val.total
        }, () => this.getData())
    }

    search() {
        this.getData()
    }

    getDate = (val, key) => {
        if (!val) return
        if (key === 1) {
            return moment(val).format("YYYYMMDD000000");
        } else {
            return moment(val).format("YYYYMMDD235959");
        }
    }

    getSearchVal = val => {
        if (val.startTime) {
            val.startTime = this.getDate(val.startTime, 1)
        }
        if (val.endTime) {
            val.endTime = this.getDate(val.endTime, 2)
        }
        this.setState({ getDataInfo: { ...this.state.getDataInfo, ...val, page: 1, } }, () => this.getData())
    }

    modalDatas = (val) => {
        let { startTime, endTime, invoiceType } = val
        if (startTime) {
            startTime = this.getDate(startTime, 1)
        }
        if (endTime) {
            endTime = this.getDate(endTime, 2)
        }
        this.state.getDataInfo.startTime = startTime
        this.state.getDataInfo.endTime = endTime
        this.state.getDataInfo.invoiceType = invoiceType
    }

    onChange = value => {
        this.setState({ value });
    };

    onOk = () => {
        this.setState({ openKey: false })
    }

    changeEmail = (email) => {
        this.setState({
            paramPul: { ...this.state.paramPul, email }
        })
    }
    submitEmial = () => {
        let param = this.state.paramPul
        this.setState({ loading: true })
        pushDzfp(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.warning(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    redCodes = (data) => {
        let { fpdm, fphm } = data
        let paramStatus = { fpdm, fphm }
        this.setState({ showStatus: true, paramStatus })
    }
    selectStatus = (applyReason) => {
        // console.log("123",applyReason)
        this.setState({
            paramStatus: { ...this.state.paramStatus, applyReason }
        })
    }
    selectRed = (applyReason) => {
        this.state.detailInfo.redReason = applyReason
    }
    statusOk = () => {
        let param = this.state.paramStatus
        redInfoPaperApply(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        })
    }

    getCodes = (data) => {
        let { fpdm, fphm } = data
        redInfoPaperQuery({ fpdm, fphm }).then(res => {
            if (res.code == "0000") {
                let { redInfoPaperNo, status } = res.resultObj
                if (status == 0) {
                    message.info("未处理")
                } else if (status == 1) {
                    message.info("已提交")
                } else if (status == 2) {
                    message.info("审核通过")
                    data.tzdbh = redInfoPaperNo
                    this.setState({})
                }
                else if (status == 3) {
                    message.info("审核不通过")
                }
            } else {
                message.error(res.msg)
            }
        })
    }

    closeEwm = () => {
        if(this.state.ie){
            clearInterval(this.state.ie)
            this.setState({ie:undefined})
        }
        this.setState({ showEwm: false })
    }

    render() {

        let columns = [
            {
                title: '序号',
                dataIndex: "key",
                key: 'key',
                width: 60,
            },
            {
                title: '订单号',
                dataIndex: 'orderNo',
                key: 'orderNo',
                width: 200
            },
            {
                title: '销货单位名称',
                dataIndex: 'xhdwmc',
                key: 'xhdwmc',
                width: 230
            },
            {
                title: '发票代码/发票号码',
                dataIndex: 'fpdm',
                key: 'fpdm',
                width: 150,
                render:(_,record)=>(
                    <div>
                        <span>{record.fpdm}</span><br/>
                        <span>{record.fphm}</span>
                    </div>
                )
            },
            // {
            //     title: '发票号码',
            //     dataIndex: 'fphm',
            //     key: 'fphm',
            //     width: 100,
            // },
            {
                title: '购货单位名称',
                dataIndex: 'ghdwmc',
                key: 'ghdwmc',
                width: 230
            },
            {
                title: '含税金额',
                dataIndex: 'jshj',
                key: 'jshj',
                width: 100
            },
            {
                title: '开票日期',
                dataIndex: 'kprq',
                key: 'kprq',
                render: (text, record) => <span>{this.filterTime(text)}</span>,
                width: 140,
            },
            {
                title: '开票状态',
                dataIndex: 'status',
                key: 'status',
                width: 140,
                render: (text, record) => <span>{this.filterKplx(record.status)}{record.status==22?
                    <Tooltip placement="right" getTooltipContainer={() => this.menberManager.current}
                        overlayClassName="errorMagTooltip" title={record.errorMsg}>
                        <QuestionCircleOutlined className="errorIcon" /></Tooltip>:null }</span>
            },
            {
                title: '发票类型',
                dataIndex: 'invoiceType',
                key: 'invoiceType',
                render: (text, record) => <span>{this.filterInvoiceType(record.invoiceType)}</span>,
                width: 170
            },
            {
                title: '销货单位识别号',
                dataIndex: 'xhdwsbh',
                key: 'xhdwsbh',
                width: 210
            },
            // {
            //     title: '开票类型',
            //     dataIndex: 'status',
            //     key: 'status',
            //     render: (text, record) => <span>{this.filterKplx(record.status)}</span>,
            //     width: 120
            // },

            {
                title: '购货单位识别号',
                dataIndex: 'ghdwsbh',
                key: 'ghdwsbh',
                width: 190
            },

            {
                title: '机器编号',
                dataIndex: 'kpzdbs',
                key: 'kpzdbs',
                width: 130
            },
            {
                title: '发票请求流水号',
                dataIndex: 'fpqqlsh',
                key: 'fpqqlsh',
                width: 230
            },
            {
                title: '原发票代码',
                dataIndex: 'yfpdm',
                key: 'yfpdm',
                width: 130
            },
            {
                title: '原发票号码',
                dataIndex: 'yfphm',
                key: 'yfphm',
                width: 130
            },
            {
                title: '原pdf发票链接',
                dataIndex: 'ypdfurl',
                key: 'ypdfurl',
                width: 150
            },
            {
                title: '原ofd发票链接',
                dataIndex: 'yofdurl',
                key: 'yofdurl',
                width: 150
            },
            {
                title: '作废人',
                dataIndex: 'invalidUser',
                key: 'invalidUser',
                width: 90
            },
            {
                title: '作废时间',
                dataIndex: 'invalidTime',
                key: 'invalidTime',
                width: 170,
            },
            {
                title: '负数发票开票日期',
                dataIndex: 'negativeKprq',
                key: 'negativeKprq',
                width: 170,
            },
            {
                title: '信息表编号',
                dataIndex: 'tzdbh',
                key: 'tzdbh',
                width: 120,
            },
            {
                title: '购方客户电话',
                dataIndex: 'gfkhdh',
                key: 'gfkhdh',
                width: 120,
            },
            {
                title: '购方客户邮箱',
                dataIndex: 'gfkhyx',
                key: 'gfkhyx',
                width: 200,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                width: 190,
                align: 'center',
                render: (_, record) => {
                    if (record.status === 2) {
                        if (record.kplx === '0'){
                            return <div className="operation-buttons">
                                <Button type="link" onClick={() => {
                                    this.getInfo(record)
                                }}>详情</Button>
                                <Button type="link"  onClick={() => this.pushInvoice(record)}>推送</Button>
                                 <Button type="link"  onClick={() => this.goRed(record)} >冲红</Button>
                            </div>
                        } else {
                            return <div className="operation-buttons">
                            <Button type="link" onClick={() => {
                                this.getInfo(record)
                            }}>详情</Button>
                            <Button type="link"  onClick={() => this.pushInvoice(record)}>推送</Button>
                             </div>
                        }
                    }
                    if (record.status === 22) {
                        return <div>
                            <Popconfirm title='确认重新开票吗' onConfirm={() => this.invoiceToReapet(record)}><Button type="link" >重新开票</Button></Popconfirm>
                        </div>
                    }
                },
            }
        ];
        ((columns) => {
            let list = []
            columns.forEach(item => {
                list.push({
                    value: item.key,
                    ...item
                })
            })
            let lastItem = list.pop()
            treeData = list
            let num = columns.length

            if (this.state.value.length == 0) {
                return false
            } else {
                let values = []
                let value = this.state.value
                value.forEach((item, index) => {
                    // if(item=="operation"){
                    //     value.splice(index,1)
                    //     value.push("operation")
                    // }
                    if (item == "key" && index != 0) {
                        value.splice(index, 1)
                        value.unshift("key")
                    }
                })

                for (let key of this.state.value) {
                    columns.forEach(item => {
                        if (item.key == key) {
                            values.push(item)
                        }
                    })
                }
                columns.splice(0, num, ...values, lastItem)
            }
        })(columns);

        const tProps = {
            treeData,
            value: this.state.value,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: "请选择",
            style: {
                width: "100%"
            },
            allowClear: true
        };



        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        const { page: current, rows: pageSize } = this.state.getDataInfo

        const pagination = { current, pageSize, total: this.state.total }

        const statusSelectValue = [{ id: '', name: '请选择发票状态' }, { id: '1', name: '待填写抬头' }, { id: '2', name: '正数发票' }, { id: '3', name: '开具错误' }, { id: '4', name: '正数作废' }, { id: '5', name: '负数发票' }, { id: '6', name: '负数作废' }, { id: '7', name: '空白作废' }, { id: '8', name: '开票前准备' }, { id: '9', name: '门店审核' }, { id: '10', name: '驳回' }, { id: '11', name: '商家作废二维码' }]
        const invoiceTypeSelectValue = [
            { id: '', name: '请选择发票类型' },
            { id: '025', name: '卷式发票' },
            { id: '028', name: '电子专用发票' },
            { id: '004', name: '增值税专用发票' },
            { id: '007', name: '增值税普通发票' },
            { id: '026', name: '增值税电子发票' },
            { id: '81', name: '全电专用发票' },
            { id: '82', name: '全电普通发票' }
        ]
        let state = this.props.location.state;
        if(state) {
          this.state.getDataInfo.error = true
        }
        const baseFormData = [
            { type: 'SELECT', label: '发票类型', field: 'invoiceType', placeholder: '', width: '128%', initialValue: '', list: invoiceTypeSelectValue },
            { type: 'INPUT', label: '购方名称', field: 'buyerName', placeholder: '请输入购方名称', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '销方名称/识别号', field: 'nsrsbh', placeholder: '请输入销方名称/识别号', width: '100%', initialValue: state },
            { type: 'INPUT', label: '订单号', field: 'orderNo', placeholder: '请输入订单号', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '发票代码', field: 'fpdm', placeholder: '请输入发票代码', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '发票号码', field: 'fphm', placeholder: '请输入发票号码', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '最小合计金额', field: 'minAmount', placeholder: '请输入最小合计金额', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '最大合计金额', field: 'maxAmount', placeholder: '请输入最大合计金额', width: '100%', initialValue: '' },
            { type: 'DATEDAY', label: '开票起始日期', field: 'startTime', placeholder: '请选择开票起始日期', width: '100%', initialValue: '' },
            { type: 'DATEDAY', label: '开票终止日期', field: 'endTime', placeholder: '请选择开票终止日期', width: '100%', initialValue: '' },
            { type: 'INPUT', label: '开票人', field: 'biller', placeholder: '请输入开票人', width: '100%', initialValue: '' },
            { type: 'SELECT', label: '状态', field: 'status', placeholder: '请输入开票人', width: '128%', initialValue: '', list: statusSelectValue },
        ]


        const formItemLayout = {
            labelCol: {
              xs: { span: 40 },
              sm: { span: 8 },
            }
            ,
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };


        return (
            <Spin spinning={this.state.loading} size="large" >
                <div className="menberManager" ref={this.menberManager}>
                    <div className="menberManager-title">
                        <h2>{sessionStorage.getItem("pageTitle") || "发票查询"}</h2>
                    </div>
                    <Divider />
                    <div style={{ padding: "10px 0", }} className="baseForm">
                        <BaseForm formList={baseFormData} style={{ width: '1600px' }} filterSubmit={this.getSearchVal} modalDatas={this.modalDatas} />
                    </div>
                    <div style={{ background: '#C7E2FF' }} className="macXx" >
                        <Button type="link" style={{ color: '#333333' }} onClick={() => {
                            this.exports()
                        }}><img src={require('../../../assets/icon/ss.png')} /><span>导出记录</span></Button>
                        <Button type="link" onClick={() => { this.setState({ openRepair: true }) }} >发票修复</Button>
                        <Button type="link" onClick={() => { this.setState({ openKey: true }) }} >设置</Button>
                        <Button type="link" onClick={() => { this.setState({ showEwm: true }) }} >扫码认证</Button>
                        <Button type="link" onClick={() => { this.downloadFps() }} >发票下载</Button>
                    </div>
                    <div>
                        <Table columns={columns}   rowSelection={this.rowSelection} dataSource={this.state.tableList} scroll={{ x: this.state.tableList.length * 100, y: 800 }} pagination={pagination} onChange={this.paginationChange} />
                    </div>

                    {
                        this.state.showInfo ? (
                        <Modal width={1180} visible={this.state.showInfo} footer={null} keyboard={true}
                            onCancel={() => {
                                this.setState({ showInfo: false })
                            }} >
                            <Details detailInfo={this.state.detailInfo} />
                        </Modal>) : null
                    }
                    {
                        this.state.openRepair ? (
                            <Modal visible={this.state.openRepair} title="修复发票" width={350} onOk={() => { this.setState({ openRepair: false }) }} keyboard={true}
                                onCancel={() => { this.setState({ openRepair: false }) }} footer={null} >
                                <RepairInvoice openRepair={(openRepair) => {
                                    this.setState({ openRepair: openRepair ? false : true })
                                }} />
                            </Modal>) : null
                    }
                    {
                        this.state.openKey ? (
                            <Modal visible={this.state.openKey} title="显示列头" onOk={this.onOk} destroyOnClose={true} keyboard={true}
                                onCancel={() => { this.setState({ openKey: false }) }} >
                                <TreeSelect {...tProps} />
                                <ul style={{ marginTop: 40 }}>
                                    <li>提示</li>
                                    <li>1、按顺序选中目标项，表头只显示选中的列</li>
                                    <li>2、清空所有为默认所有列</li>
                                </ul>
                            </Modal>) : null
                    }
                    {
                        this.state.showEmail ? (
                            <Modal visible={this.state.showEmail} title="提示" onOk={this.submitEmial} destroyOnClose={true} keyboard={true}
                                onCancel={() => { this.setState({ showEmail: false }) }} >
                                <span>确认邮箱</span>:
                                <Input id="showEail1" style={{ width: 320, marginLeft: 15 }} value={this.state.paramPul.email} onChange={(e) => this.changeEmail(e.target.value)} />
                            </Modal>) : null
                    }
                    {
                        this.state.showStatus ? (
                            <Modal visible={this.state.showStatus} title="提示" onOk={this.statusOk} destroyOnClose={true} keyboard={true}
                                onCancel={() => { this.setState({ showStatus: false }) }} >
                                <span>选择状态</span>:
                                <Select style={{ width: 200, marginLeft: 15 }} onChange={this.selectStatus} placeholder="请选择" >
                                    {
                                        this.state.statusList.map((item, index) => {
                                            return <Option value={item.id} key={index + 1}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Modal>) : null
                    }
                    {
                        this.state.showRed ? (
                            <Modal visible={this.state.showRed} title="冲红" width={500}  onOk={this.submitRed} destroyOnClose={true} keyboard={true}
                                onCancel={() => { this.setState({ showRed: false }) }} >
                                    <Form  {...formItemLayout} >
                                        <Form.Item label="发票代码：" >
                                         <span>
                                             {this.state.detailInfo.fpdm }
                                         </span>
                                        </Form.Item>  
                                        <Form.Item label="发票号码：">
                                        <span>
                                            {this.state.detailInfo.fphm }
                                         </span>
                                        </Form.Item>  
                                        <Form.Item label="价税合计：">
                                        <span>
                                        {this.state.detailInfo.jshj}
                                         </span>
                                        </Form.Item> 
                                        <Form.Item label="发票金额(不含税)：">
                                        <span>
                                        {this.state.detailInfo.hjje }
                                         </span>
                                        </Form.Item> 
                                        <Form.Item label="原发票税额：">
                                        <span>
                                        {this.state.detailInfo.hjse }
                                         </span>
                                        </Form.Item> 
                                        <Form.Item label="剩余可冲红金额：">
                                        <span>
                                        {this.state.detailInfo.hjje }
                                         </span>
                                        </Form.Item> 
                                        <Form.Item label="剩余可冲红税额：">
                                        <span>
                                        {this.state.detailInfo.hjse }
                                         </span>
                                        </Form.Item> 
                                        <Form.Item label="冲红原因：">
                                            <Select style={{ width: 200, marginLeft: 15 }} onChange={this.selectRed} placeholder="请选择" >
                                                {
                                                    this.state.redReasonList.map((item, index) => {
                                                        return <Option value={item.id} key={index + 1}>{item.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>   
                                 </Form>
                            </Modal>
                        ) : null
                    }
                    {
                        this.state.showEwm ? (
                            <Modal visible={this.state.showEwm} title="扫码认证" width={500}     destroyOnClose={true} keyboard={true}
                                onCancel={this.closeEwm} onOk={this.closeEwm} >
                                 <Ewm setIe = {(ie)=>{this.setState({ie: ie })}} />
                            </Modal>
                        ) : null
                    }
                </div>
            </Spin>
        )
    }
}

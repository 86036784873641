
import React from "react"
import { Route, Switch,Redirect} from 'react-router-dom'

import WebHome from '../view/websites/home'
import WebLogin from '../view/websites/login'
import WebRegister from '../view/websites/register'
import Success from '../view/websites/success'
import Infomation from '../view/websites/information'
import AboutMe from '../view/websites/aboutMe'
import AboutMe2 from '../view/websites/aboutMe2'
import Business from '../view/websites/business'
import Contract from '../view/websites/contract'
import Invoice from '../view/websites/invoice'
import Electronic from '../view/websites/electronic'
import Solution from '../view/websites/solution'
import Income from '../view/websites/income'
import Coordination from '../view/websites/coordination/coordination'
import Flexible from '../view/websites/flexible/flexible'
import Check from '../view/websites/check'
import Assistance from '../view/websites/assistance'
// assistance


import Desk from '../view/system/desk/desk'
import AllContract from '../view/system/allContract/allContract'
import SignContract from '../view/system/signContract/signContract'
import SendContract from '../view/system/sendContract/sendContract'
import FileDetail from '../view/system/fileDetail/fileDetail'
import ContractSign from '../view/system/contractSign/contractSign'
import BatchContract from '../view/system/batchContract/batchContract'
import MemberManager from '../view/system/MemberManager/MemberManager'
import RoleManager from '../view/system/roleManager/roleManager'
import RechargeRecord from '../view/system/rechargeRecord/rechargeRecord'
import UserRecord from '../view/system/userRecord/userRecord'
import SealManager from '../view/system/sealManager/sealManager'
import MenuManager from '../view/system/menuManager/menuManager'
import Certification from '../view/system/enterpriseCertification/enterpriseCertification'
import Authentication from '../view/system/Authentication/Authentication'
import DeviceManagement from '../view/system/deviceManagement/deviceManagement'
import EnsurePlate from '../view/system/ensurePlate/ensurePlate'
import EnsureState from '../view/system/ensureState/ensureState'
import SetDiskPort from '../view/system/setDiskPort/setDiskPort'
import UploadServer from '../view/system/uploadServer/uploadServer'
import UserManager from '../view/system/userManager/userManager'
import CompanyInfo from '../view/system/companyInfo/companyInfo'
import TicketsOnline from '../view/system/ticketsOnline/ticketsOnline'
import SetReport from '../view/system/setReport/setReport'
import TaxCopy from '../view/system/taxCopy/taxCopy'
import WritingState from '../view/system/writingState/writingState'
import InvoiceQuery from '../view/system/invoiceQuery/invoiceQuery'
import SumStatistics from '../view/system/sumStatistics/sumStatistics'
import InvoiceSumQuery from '../view/system/invoiceSumQuery/invoiceSumQuery'
import SystemManager from '../view/system/systemManager/systemManager'
import TicketCompany from '../view/system/ticketCompany/ticketCompany'
import GoodsNo from '../view/system/goodsNo/goodsNo'
import CreateInvoice from '../view/system/createInvoice/createInvoice'
import printInvoiceQRcode from "../view/system/printInvoiceQRcode/printInvoiceQRcode"
import StoreQRcode from "../view/system/StoreQRcode/StoreQRcode"
import RemainingTickets from "../view/system/remainingTickets/RemainingTickets"
import WriteBackMonitoring from "../view/system/writeBackMonitoring/writeBackMonitoring"
import ApplyInvoice from "../view/system/applyInvoice/applyInvoice"
import MonitorInfo from "../view/system/monitorInfo/monitorInfo"
import Checks from "../view/system/checks/checks"
import LongRange from "../view/system/longRange/longRange"
import Statistics from "../view/system/statistics/statistics"
import InvoiceDownload from "../view/system/invoiceDownload/invoiceDownload"
import AuditLog from "../view/system/auditLog/auditLog"
import UkeyInventory from "../view/system/ukeyInventory"
import ErrorInvoice from "../view/system/errorInvoice/errorInvoice"
import ErrorInvoiceDetail from "../view/system/errorInvoice/invoiceList/errorInvoiceQuery"



export const Router=(
  <Switch>
    <Route path='/system/desk' component={Desk} />
    <Route path='/system/allContract' component={AllContract}/>
    <Route path='/system/signContract' component={SignContract}/>
    <Route path='/system/sendContract' component={SendContract}/>
    <Route path='/system/fileDetail'component={FileDetail}/>
    <Route path='/system/contractSign' component={ContractSign}/>
    <Route path='/system/batchContract' component={BatchContract}/>
    <Route path='/system/MemberManager' component={MemberManager}/>
    <Route path='/system/roleManager'component={RoleManager}/>
    <Route path='/system/rechargeRecord' component={RechargeRecord}/>
    <Route path='/system/userRecord' component={UserRecord}/>
    <Route path='/system/sealManager' component={SealManager}/>
    <Route path='/system/menuManager' component={MenuManager}/>
    <Route path='/system/Certification' component={Certification}/>
    <Route path='/system/Authentication' component={Authentication}/>
    <Route path='/system/deviceManagement' component={DeviceManagement}/>
    <Route path='/system/ensurePlate' component={EnsurePlate}/>
    <Route path='/system/ensureState' component={EnsureState}/>
    <Route path='/system/setDiskPort' component={SetDiskPort}/>
    <Route path='/system/uploadServer' component={UploadServer}/>
    <Route path='/system/userManager' component={UserManager}/>
    <Route path='/system/companyInfo' component={CompanyInfo}/>
    <Route path='/system/ticketsOnline' component={TicketsOnline}/>
    <Route path='/system/setReport' component={SetReport}/>
    <Route path='/system/taxCopy' component={TaxCopy}/>
    <Route path='/system/WritingState' component={WritingState}/>
    <Route path='/system/invoiceQuery' component={InvoiceQuery}/>
    <Route path='/system/sumStatistics' component={SumStatistics}/>
    <Route path='/system/invoiceSumQuery' component={InvoiceSumQuery}/>
    <Route path='/system/systemManager' component={SystemManager}/>
    <Route path='/system/ticketCompany' component={TicketCompany}/>
    <Route path='/system/goodsNo' component={GoodsNo}/>
    <Route path='/system/createInvoice' component={CreateInvoice}/>
    <Route path='/system/printInvoiceQRcode' component={printInvoiceQRcode}/>
    <Route path='/system/StoreQRcode' component={StoreQRcode}/>
    <Route path='/system/RemainingTickets' component={RemainingTickets}/>
    <Route path='/system/writeBackMonitoring' component={WriteBackMonitoring}/>
    <Route path='/system/applyInvoice' component={ApplyInvoice}/>
    <Route path='/system/monitorInfo' component={MonitorInfo}/>
    <Route path='/system/checks' component={Checks}/>
    <Route path='/system/longRange' component={LongRange}/>
    <Route path='/system/statistics' component={Statistics}/>
    <Route path='/system/invoiceDownload' component={InvoiceDownload}/>
    <Route path='/system/auditLog' component={AuditLog}/>
    <Route path='/system/ukeyInventory' component={UkeyInventory}/>
    <Route path='/system/errorInvoice' component={ErrorInvoice}/>
    <Route path='/system/errorInvoiceDetail' component={ErrorInvoiceDetail}/>

    <Redirect to='/system/desk'/>
  </Switch>
)

export const webRouter=(
  <Switch>
    <Route path='/web/home' component={WebHome}  />
    <Route path='/web/login' component={WebLogin}/>
    <Route path='/web/register' component={WebRegister}/>
    <Route path='/web/success' component={Success}/>
    <Route path='/web/information' component={Infomation}/>
    <Route path='/web/aboutMe' component={AboutMe}/>
    <Route path='/web/service' component={AboutMe2}/>
    <Route path='/web/business' component={Business}/>
    <Route path='/web/contract' component={Contract}/>
    <Route path='/web/invoice' component={Invoice}/>
    <Route path='/web/electronic' component={Electronic}/>
    <Route path='/web/solution' component={Solution}/>
    <Route path='/web/income' component={Income}/>
    <Route path='/web/coordination' component={Coordination}/>
    <Route path='/web/flexible' component={Flexible}/>
    <Route path='/web/check' component={Check}/>
    <Route path='/web/assistance' component={Assistance}/>
    <Redirect to='/web/home'/>
  </Switch>
)


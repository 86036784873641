import React, { Component } from "react"
import { Table,Button,Layout,Divider,Menu,Select,message} from 'antd'
import downLoad from 'downloadjs'

import Utils from '../../../utils'
import Verification from '../../../utils/verification'
import {selectSponsorId,selectBatch} from '../../../api/index'
import BaseForm from '../../../components/baseForm/baseForm'
import  Pdf from '../../../assets/pdf/01.pdf'
import './allContract.less'

const { SubMenu } = Menu;
const { Sider, Content } = Layout;
const {Option} =Select
const verification=new Verification
export default class User extends Component {
	constructor(props) {
		  super(props);
          this.state={
			tableList:[],
			param:{
				sponsorId:"",
				type:"",
				stime:"",
				etime:"",
				contractName:"",
				sponsorName:"",
				datailsName:"",
				contractState:"",
			}
		  }
	}
	componentDidMount(){
		this.startTable(this.state.param)
        
	}
    startTable=(data)=>{
		selectSponsorId(data).then(res=>{
		if(res.code===0){		
			this.state.tableList=[]
			const result=res.map.list
			result.forEach((item)=>{
				const dom={
					key:item.id,
					fileName:item.contractName,
					sendMen:item.sponsorName,
					address:item.ecContractOrderDetails.reduce((pre,list)=>{return list.datailsName?pre+list.datailsName+'/':""},""),
					createTime:item.createTime,
					status:Utils.contractType(item.contractState),
					order:item.contractSerial,
					url:item.contractUrl
				}
				this.setState({
					tableList:[...this.state.tableList,dom]
				})
			 })
		   }  
	   })			
	}

    selectRow=(e)=>{
        this.props.history.push({pathname:"/system/fileDetail",state:{order:e.order}})
	}
	conctractStatus=(e)=>{
		verification.updateTable.call(this,{type:e})
	}
    handleFilter=(data)=>{
	    const dom={
			type:data.status,
			stime:Utils.moment(data.begin_time),
			etime:Utils.moment(data.end_time),
			contractName:data.fileName,
			sponsorName:data.sendMen,
			datailsName:"",
			contractState:"",
		}
		verification.updateTable.call(this,dom)
	}
	selectOption=(e,row)=>{
		const param={
			OrderResult:JSON.stringify(
				[
				 {contractSerial:row.order,type:e}
			    ]
			)
		}
		if(e==="2"){
			// downLoad(row.url,row.fileName,'application/pdf')
			// return false
			let a = document.createElement("a");
			a.href = row.url;
			a.download = row.fileName;
			a.setAttribute('target','_blank');
			document.body.appendChild(a);
			a.click();
			a.remove();
		}
		selectBatch(param).then(res=>{
			if(res.code===0){
				message.success("操作成功")
				this.startTable(this.state.param)
			}else{
				message.success(res.message)
			}
			})
		}

	render() {
		const formList = [
			{
				type: '时间查询',
				label: '请选择时间',
				field: 'begin',
				field1: 'end',
				placeholder: '请输入日期',
			},
			{
				type: 'INPUT',
				label: '合同标题',
				field: 'fileName',
				placeholder: '请输入合同标题',
				width: "100%",
			}, {
				type: 'INPUT',
				label: '发起人',
				field: 'sendMen',
				placeholder: '个人/企业名称或账号',
				width: "100%",
			},
			{
				type: 'SELECT',
				label: '是否禁用',
				field: 'status',
				width: "100%",
				initialValue:'0',
				list:[
					{
					id:'0',name:"未发送"
					},
					{
					id:'1',name:"已发送"
					},
					{
					id:'2',name:"已完成"
					},
					{
					id:'3',name:"已撤销"
					},
					{
					id:'4',name:"已拒绝"
					},
					{
					id:'5',name:"已过期"
					}
				]
			}, 
		]
		const columns = [
				{
				  title: '文件名称',
				  dataIndex: 'fileName',
				  key: 'fileName',
				  render: (text,record) => <a onClick={(text)=> this.selectRow(record)}>{text}</a>,
				},
				{
				  title: '发件人',
				  dataIndex: 'sendMen',
				  key: 'sendMen',
				},
				{
				  title: '收件人',
				  dataIndex: 'address',
				  key: 'address',
				  render: (text,record) => <a onClick={(text)=> this.selectRow(record)}>{text}</a>,
				},
				{
				  title: '发起时间',
				  dataIndex: 'createTime',
				  key: 'createTime',
				},
				{
				  title: '状态',
				  dataIndex: 'status',
				  key: 'status',
				},
				{ 
				  title: '操作',
				  dataIndex: 'operation',
				  key: 'operation',
				  render: (text, record) => (
					  <div>
						   <Select defaultValue="2" style={{ width: 120 }} onSelect={(e)=>{this.selectOption(e,record)}}>
								<Option value="1" >
									删除
								</Option>
								<Option value="0" disabled={record.status=="已发送"?false:true}>撤销</Option>
								<Option value="2" >下载</Option>
						  </Select>
					  </div>
					),
				}
			  ];
			
		return (
			<div className='all-contract'>
				<Layout style={{paddingTop:20}}>
					<Sider style={{backgroundColor:"#fff",height:"100vh",marginLeft:20}}>
						<div className="contract-btn">
							<Button type='primary' onClick={()=>{this.props.history.push("/system/signContract")}}>发起签约</Button>
						</div>
						<Divider/>
						<div className="contract-menu">
						    <Menu
								mode="inline"
								style={{ lineHeight: '64px' }}
								defaultOpenKeys={['0']}
								defaultSelectedKeys={['1']}
								theme='light'
							>
								<SubMenu key='0' title="合同">
									<Menu.Item key="1" onClick={()=>{this.conctractStatus("")}}>全部文件</Menu.Item>
									<Menu.Item key="2" onClick={()=>{this.conctractStatus("")}}>带我签</Menu.Item>
									<Menu.Item key="3" onClick={()=>{this.conctractStatus("")}}>代他人签</Menu.Item>
									<Menu.Item key="4" onClick={()=>{this.conctractStatus("2")}}>已完成</Menu.Item>
									<Menu.Item key="5" onClick={()=>{this.conctractStatus("5")}}>已过期</Menu.Item>
									<Menu.Item key="6" onClick={()=>{this.conctractStatus("3")}}>已撤销</Menu.Item>
									<Menu.Item key="7" onClick={()=>{this.conctractStatus("4")}}>已拒绝</Menu.Item>
								</SubMenu>	
							</Menu>
						</div>
					</Sider>
					<Content style={{marginLeft:20}}>
						<div className="contract-serach">
						<BaseForm formList={formList} filterSubmit={this.handleFilter} />
						</div>
						<div className="contract-content" style={{marginTop:40}}>
						<Table dataSource={this.state.tableList} columns={columns} 
						
						/>
						</div>
					</Content>
				</Layout>
			</div>
		)
	}
}



import React, { Component } from "react"
import {Button,Divider,Drawer,Input,Tabs} from 'antd'
import {companySeal} from '../../../api'
import emitter from '../../../services/event'
import './sealManager.less'
import Sign from "../../../components/sign/sign.jsx"
const { TabPane } = Tabs;
export default class User extends Component {
	constructor(props) {
		super(props);
		this.state={ 
			visible: false,
			companyName:"",
			code:""
		
		};
		
	}
	showDrawer = () => {
		this.setState({
		visible: true,
		});
		emitter.emit('setBtn',false);
	};

	onClose = () => {
		const param={
			companyName:this.state.companyName,
			number:this.state.code
		}
		companySeal(param).then(res=>{
			
		})
		this.setState({
		  visible: false,
		});
		emitter.emit('setBtn',true);
	};
	callback=(e)=>{
		console.log(e)
	}
	handelChange=(e,flag)=>{
		
		if(flag===1){
			this.setState({
				companyName:e.target.value
			})
		}else{
			this.setState({
				code:e.target.value
			})
		}
       
	}

	render() {
		return (
			<div className='sealManager'>
				
			   <h2>印章管理</h2> 
			   <Divider />
			   <div>
			      <Sign></Sign>
			   </div>
			   <Divider />
			   <div>
				   <Button type='primary' onClick={this.showDrawer}>添加印章</Button>
			   </div>
			   <Drawer
					title="添加印章"
					placement="right"
					closable={false}
					onClose={this.onClose}
					visible={this.state.visible}
					style={{width:200}}
					>
					<div className='seal-generate'>
						<Tabs defaultActiveKey="1" onChange={()=>{this.callback()}}>
							<TabPane tab="公司印章" key="1">
								<div className='seal-type'>
								<li>
									<label htmlFor="">公司名称</label>
									<Input type="text" placeholder="请输入公司名称" onChange={(e)=>{this.handelChange(e,1)}} defaultValue={this.state.companyName}/>
								</li>
								<li>
									<label htmlFor="">公章编码</label>
									<Input type="text" placeholder="请输入公章编码" onChange={(e)=>{this.handelChange(e,2)}} defaultValue={this.state.code}/>
								</li>
								</div>
							</TabPane>
							<TabPane tab="个人签名" key="2">
									个人签名
							</TabPane>
						</Tabs>
						<div
							style={{
							position: 'absolute',
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e8e8e8',
							padding: '10px 16px',
							textAlign: 'right',
							left: 0,
							background: '#fff',
							borderRadius: '0 0 4px 4px',
							}}
						>
							<Button
							style={{
								marginRight: 8,
							}}
							onClick={this.onClose}
							>
							取消
							</Button>
							<Button onClick={this.onClose} type="primary">
							保存
							</Button>
						</div>
					</div>
			   </Drawer>
			</div>
		)
	}
}
import React from 'react'
import './webFooter1.less'
import { Waypoint } from 'react-waypoint';
import bgImg from "../../assets/img/footers/footer1.png"


export default class webFooter1 extends React.Component {
	_handleWaypointEnter = () => {
		let offsettop = document.querySelectorAll('.home-content8-ul > li > h1 > span')
		offsettop.forEach(element => {
			element.style.cssText = " font-size:32px; line-height: 84px; height: 84px;"
		});
	}
	_handleWaypointLeave = () => {
		let offsettop = document.querySelectorAll('.home-content8-ul > li > h1 > span')
		offsettop.forEach(element => {
			element.style.cssText = " font-size:12px; line-height: 48px; height: 48px;"
		});
	}
	render() {
		const bg = {
			backgroundRepeat: "no-repeat",
			backgroundImage: "url(" + bgImg + ")", //图片的路径
			backgroundSize:"100% 140px",
		};
		return (
			<Waypoint
				onEnter={this._handleWaypointEnter}
				onLeave={this._handleWaypointLeave}
			>
				<div className="home-content8" id="home-content8">
					<ul className='home-content8-ul'>
						<li>
							<h1>
								<span id="span1">20载</span>
							</h1>
							<div>深耕税务系统</div>
						</li>
						<li>
							<h1>
								<span id="span2">7×24h</span>
							</h1>
							<div>贴心陪伴</div>
						</li>
						<li>
							<h1>
								<span id="span3">3000</span>
							</h1>
							<div>客户坚定守候</div>
						</li>

					</ul>
				</div>
			</Waypoint>
		);
	}
}
